body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@charset "UTF-8";

@font-face {
  font-family: "socicon";
  src: url(/static/media/socicon.64485e30.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "socicon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="socicon-"]:before,
[class*=" socicon-"]:before {
  font-family: "socicon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.socicon-modelmayhem:before {
  content: "\e000";
}
.socicon-mixcloud:before {
  content: "\e001";
}
.socicon-drupal:before {
  content: "\e002";
}
.socicon-swarm:before {
  content: "\e003";
}
.socicon-istock:before {
  content: "\e004";
}
.socicon-yammer:before {
  content: "\e005";
}
.socicon-ello:before {
  content: "\e006";
}
.socicon-stackoverflow:before {
  content: "\e007";
}
.socicon-persona:before {
  content: "\e008";
}
.socicon-triplej:before {
  content: "\e009";
}
.socicon-houzz:before {
  content: "\e00a";
}
.socicon-rss:before {
  content: "\e00b";
}
.socicon-paypal:before {
  content: "\e00c";
}
.socicon-odnoklassniki:before {
  content: "\e00d";
}
.socicon-airbnb:before {
  content: "\e00e";
}
.socicon-periscope:before {
  content: "\e00f";
}
.socicon-outlook:before {
  content: "\e010";
}
.socicon-coderwall:before {
  content: "\e011";
}
.socicon-tripadvisor:before {
  content: "\e012";
}
.socicon-appnet:before {
  content: "\e013";
}
.socicon-goodreads:before {
  content: "\e014";
}
.socicon-tripit:before {
  content: "\e015";
}
.socicon-lanyrd:before {
  content: "\e016";
}
.socicon-slideshare:before {
  content: "\e017";
}
.socicon-buffer:before {
  content: "\e018";
}
.socicon-disqus:before {
  content: "\e019";
}
.socicon-vkontakte:before {
  content: "\e01a";
}
.socicon-whatsapp:before {
  content: "\e01b";
}
.socicon-patreon:before {
  content: "\e01c";
}
.socicon-storehouse:before {
  content: "\e01d";
}
.socicon-pocket:before {
  content: "\e01e";
}
.socicon-mail:before {
  content: "\e01f";
}
.socicon-blogger:before {
  content: "\e020";
}
.socicon-technorati:before {
  content: "\e021";
}
.socicon-reddit:before {
  content: "\e022";
}
.socicon-dribbble:before {
  content: "\e023";
}
.socicon-stumbleupon:before {
  content: "\e024";
}
.socicon-digg:before {
  content: "\e025";
}
.socicon-envato:before {
  content: "\e026";
}
.socicon-behance:before {
  content: "\e027";
}
.socicon-delicious:before {
  content: "\e028";
}
.socicon-deviantart:before {
  content: "\e029";
}
.socicon-forrst:before {
  content: "\e02a";
}
.socicon-play:before {
  content: "\e02b";
}
.socicon-zerply:before {
  content: "\e02c";
}
.socicon-wikipedia:before {
  content: "\e02d";
}
.socicon-apple:before {
  content: "\e02e";
}
.socicon-flattr:before {
  content: "\e02f";
}
.socicon-github:before {
  content: "\e030";
}
.socicon-renren:before {
  content: "\e031";
}
.socicon-friendfeed:before {
  content: "\e032";
}
.socicon-newsvine:before {
  content: "\e033";
}
.socicon-identica:before {
  content: "\e034";
}
.socicon-bebo:before {
  content: "\e035";
}
.socicon-zynga:before {
  content: "\e036";
}
.socicon-steam:before {
  content: "\e037";
}
.socicon-xbox:before {
  content: "\e038";
}
.socicon-windows:before {
  content: "\e039";
}
.socicon-qq:before {
  content: "\e03a";
}
.socicon-douban:before {
  content: "\e03b";
}
.socicon-meetup:before {
  content: "\e03c";
}
.socicon-playstation:before {
  content: "\e03d";
}
.socicon-android:before {
  content: "\e03e";
}
.socicon-snapchat:before {
  content: "\e03f";
}
.socicon-twitter:before {
  content: "\e040";
}
.socicon-facebook:before {
  content: "\e041";
}
.socicon-googleplus:before {
  content: "\e042";
}
.socicon-pinterest:before {
  content: "\e043";
}
.socicon-foursquare:before {
  content: "\e044";
}
.socicon-yahoo:before {
  content: "\e045";
}
.socicon-skype:before {
  content: "\e046";
}
.socicon-yelp:before {
  content: "\e047";
}
.socicon-feedburner:before {
  content: "\e048";
}
.socicon-linkedin:before {
  content: "\e049";
}
.socicon-viadeo:before {
  content: "\e04a";
}
.socicon-xing:before {
  content: "\e04b";
}
.socicon-myspace:before {
  content: "\e04c";
}
.socicon-soundcloud:before {
  content: "\e04d";
}
.socicon-spotify:before {
  content: "\e04e";
}
.socicon-grooveshark:before {
  content: "\e04f";
}
.socicon-lastfm:before {
  content: "\e050";
}
.socicon-youtube:before {
  content: "\e051";
}
.socicon-vimeo:before {
  content: "\e052";
}
.socicon-dailymotion:before {
  content: "\e053";
}
.socicon-vine:before {
  content: "\e054";
}
.socicon-flickr:before {
  content: "\e055";
}
.socicon-500px:before {
  content: "\e056";
}
.socicon-instagram:before {
  content: "\e057";
}
.socicon-wordpress:before {
  content: "\e058";
}
.socicon-tumblr:before {
  content: "\e059";
}
.socicon-twitch:before {
  content: "\e05a";
}
.socicon-8tracks:before {
  content: "\e05b";
}
.socicon-amazon:before {
  content: "\e05c";
}
.socicon-icq:before {
  content: "\e05d";
}
.socicon-smugmug:before {
  content: "\e05e";
}
.socicon-ravelry:before {
  content: "\e05f";
}
.socicon-weibo:before {
  content: "\e060";
}
.socicon-baidu:before {
  content: "\e061";
}
.socicon-angellist:before {
  content: "\e062";
}
.socicon-ebay:before {
  content: "\e063";
}
.socicon-imdb:before {
  content: "\e064";
}
.socicon-stayfriends:before {
  content: "\e065";
}
.socicon-residentadvisor:before {
  content: "\e066";
}
.socicon-google:before {
  content: "\e067";
}
.socicon-yandex:before {
  content: "\e068";
}
.socicon-sharethis:before {
  content: "\e069";
}
.socicon-bandcamp:before {
  content: "\e06a";
}
.socicon-itunes:before {
  content: "\e06b";
}
.socicon-deezer:before {
  content: "\e06c";
}
.socicon-medium:before {
  content: "\e06d";
}
.socicon-telegram:before {
  content: "\e06e";
}
.socicon-openid:before {
  content: "\e06f";
}
.socicon-amplement:before {
  content: "\e070";
}

@font-face {
	font-family: 'iconsmind';
	src:url(/static/media/iconsmind.5a93ca37.eot?#iefix-rdmvgc) format('embedded-opentype');
	src:url(/static/media/iconsmind.2864469c.woff) format('woff'),
		url(/static/media/iconsmind.f0409337.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'iconsmind';
	speak: none;
	font-style: normal;
	font-weight: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-A-Z:before {
	content: "\e600";
}
.icon-Aa:before {
	content: "\e601";
}
.icon-Add-Bag:before {
	content: "\e602";
}
.icon-Add-Basket:before {
	content: "\e603";
}
.icon-Add-Cart:before {
	content: "\e604";
}
.icon-Add-File:before {
	content: "\e605";
}
.icon-Add-SpaceAfterParagraph:before {
	content: "\e606";
}
.icon-Add-SpaceBeforeParagraph:before {
	content: "\e607";
}
.icon-Add-User:before {
	content: "\e608";
}
.icon-Add-UserStar:before {
	content: "\e609";
}
.icon-Add-Window:before {
	content: "\e60a";
}
.icon-Add:before {
	content: "\e60b";
}
.icon-Address-Book:before {
	content: "\e60c";
}
.icon-Address-Book2:before {
	content: "\e60d";
}
.icon-Administrator:before {
	content: "\e60e";
}
.icon-Aerobics-2:before {
	content: "\e60f";
}
.icon-Aerobics-3:before {
	content: "\e610";
}
.icon-Aerobics:before {
	content: "\e611";
}
.icon-Affiliate:before {
	content: "\e612";
}
.icon-Aim:before {
	content: "\e613";
}
.icon-Air-Balloon:before {
	content: "\e614";
}
.icon-Airbrush:before {
	content: "\e615";
}
.icon-Airship:before {
	content: "\e616";
}
.icon-Alarm-Clock:before {
	content: "\e617";
}
.icon-Alarm-Clock2:before {
	content: "\e618";
}
.icon-Alarm:before {
	content: "\e619";
}
.icon-Alien-2:before {
	content: "\e61a";
}
.icon-Alien:before {
	content: "\e61b";
}
.icon-Aligator:before {
	content: "\e61c";
}
.icon-Align-Center:before {
	content: "\e61d";
}
.icon-Align-JustifyAll:before {
	content: "\e61e";
}
.icon-Align-JustifyCenter:before {
	content: "\e61f";
}
.icon-Align-JustifyLeft:before {
	content: "\e620";
}
.icon-Align-JustifyRight:before {
	content: "\e621";
}
.icon-Align-Left:before {
	content: "\e622";
}
.icon-Align-Right:before {
	content: "\e623";
}
.icon-Alpha:before {
	content: "\e624";
}
.icon-Ambulance:before {
	content: "\e625";
}
.icon-AMX:before {
	content: "\e626";
}
.icon-Anchor-2:before {
	content: "\e627";
}
.icon-Anchor:before {
	content: "\e628";
}
.icon-Android-Store:before {
	content: "\e629";
}
.icon-Android:before {
	content: "\e62a";
}
.icon-Angel-Smiley:before {
	content: "\e62b";
}
.icon-Angel:before {
	content: "\e62c";
}
.icon-Angry:before {
	content: "\e62d";
}
.icon-Apple-Bite:before {
	content: "\e62e";
}
.icon-Apple-Store:before {
	content: "\e62f";
}
.icon-Apple:before {
	content: "\e630";
}
.icon-Approved-Window:before {
	content: "\e631";
}
.icon-Aquarius-2:before {
	content: "\e632";
}
.icon-Aquarius:before {
	content: "\e633";
}
.icon-Archery-2:before {
	content: "\e634";
}
.icon-Archery:before {
	content: "\e635";
}
.icon-Argentina:before {
	content: "\e636";
}
.icon-Aries-2:before {
	content: "\e637";
}
.icon-Aries:before {
	content: "\e638";
}
.icon-Army-Key:before {
	content: "\e639";
}
.icon-Arrow-Around:before {
	content: "\e63a";
}
.icon-Arrow-Back3:before {
	content: "\e63b";
}
.icon-Arrow-Back:before {
	content: "\e63c";
}
.icon-Arrow-Back2:before {
	content: "\e63d";
}
.icon-Arrow-Barrier:before {
	content: "\e63e";
}
.icon-Arrow-Circle:before {
	content: "\e63f";
}
.icon-Arrow-Cross:before {
	content: "\e640";
}
.icon-Arrow-Down:before {
	content: "\e641";
}
.icon-Arrow-Down2:before {
	content: "\e642";
}
.icon-Arrow-Down3:before {
	content: "\e643";
}
.icon-Arrow-DowninCircle:before {
	content: "\e644";
}
.icon-Arrow-Fork:before {
	content: "\e645";
}
.icon-Arrow-Forward:before {
	content: "\e646";
}
.icon-Arrow-Forward2:before {
	content: "\e647";
}
.icon-Arrow-From:before {
	content: "\e648";
}
.icon-Arrow-Inside:before {
	content: "\e649";
}
.icon-Arrow-Inside45:before {
	content: "\e64a";
}
.icon-Arrow-InsideGap:before {
	content: "\e64b";
}
.icon-Arrow-InsideGap45:before {
	content: "\e64c";
}
.icon-Arrow-Into:before {
	content: "\e64d";
}
.icon-Arrow-Join:before {
	content: "\e64e";
}
.icon-Arrow-Junction:before {
	content: "\e64f";
}
.icon-Arrow-Left:before {
	content: "\e650";
}
.icon-Arrow-Left2:before {
	content: "\e651";
}
.icon-Arrow-LeftinCircle:before {
	content: "\e652";
}
.icon-Arrow-Loop:before {
	content: "\e653";
}
.icon-Arrow-Merge:before {
	content: "\e654";
}
.icon-Arrow-Mix:before {
	content: "\e655";
}
.icon-Arrow-Next:before {
	content: "\e656";
}
.icon-Arrow-OutLeft:before {
	content: "\e657";
}
.icon-Arrow-OutRight:before {
	content: "\e658";
}
.icon-Arrow-Outside:before {
	content: "\e659";
}
.icon-Arrow-Outside45:before {
	content: "\e65a";
}
.icon-Arrow-OutsideGap:before {
	content: "\e65b";
}
.icon-Arrow-OutsideGap45:before {
	content: "\e65c";
}
.icon-Arrow-Over:before {
	content: "\e65d";
}
.icon-Arrow-Refresh:before {
	content: "\e65e";
}
.icon-Arrow-Refresh2:before {
	content: "\e65f";
}
.icon-Arrow-Right:before {
	content: "\e660";
}
.icon-Arrow-Right2:before {
	content: "\e661";
}
.icon-Arrow-RightinCircle:before {
	content: "\e662";
}
.icon-Arrow-Shuffle:before {
	content: "\e663";
}
.icon-Arrow-Squiggly:before {
	content: "\e664";
}
.icon-Arrow-Through:before {
	content: "\e665";
}
.icon-Arrow-To:before {
	content: "\e666";
}
.icon-Arrow-TurnLeft:before {
	content: "\e667";
}
.icon-Arrow-TurnRight:before {
	content: "\e668";
}
.icon-Arrow-Up:before {
	content: "\e669";
}
.icon-Arrow-Up2:before {
	content: "\e66a";
}
.icon-Arrow-Up3:before {
	content: "\e66b";
}
.icon-Arrow-UpinCircle:before {
	content: "\e66c";
}
.icon-Arrow-XLeft:before {
	content: "\e66d";
}
.icon-Arrow-XRight:before {
	content: "\e66e";
}
.icon-Ask:before {
	content: "\e66f";
}
.icon-Assistant:before {
	content: "\e670";
}
.icon-Astronaut:before {
	content: "\e671";
}
.icon-At-Sign:before {
	content: "\e672";
}
.icon-ATM:before {
	content: "\e673";
}
.icon-Atom:before {
	content: "\e674";
}
.icon-Audio:before {
	content: "\e675";
}
.icon-Auto-Flash:before {
	content: "\e676";
}
.icon-Autumn:before {
	content: "\e677";
}
.icon-Baby-Clothes:before {
	content: "\e678";
}
.icon-Baby-Clothes2:before {
	content: "\e679";
}
.icon-Baby-Cry:before {
	content: "\e67a";
}
.icon-Baby:before {
	content: "\e67b";
}
.icon-Back2:before {
	content: "\e67c";
}
.icon-Back-Media:before {
	content: "\e67d";
}
.icon-Back-Music:before {
	content: "\e67e";
}
.icon-Back:before {
	content: "\e67f";
}
.icon-Background:before {
	content: "\e680";
}
.icon-Bacteria:before {
	content: "\e681";
}
.icon-Bag-Coins:before {
	content: "\e682";
}
.icon-Bag-Items:before {
	content: "\e683";
}
.icon-Bag-Quantity:before {
	content: "\e684";
}
.icon-Bag:before {
	content: "\e685";
}
.icon-Bakelite:before {
	content: "\e686";
}
.icon-Ballet-Shoes:before {
	content: "\e687";
}
.icon-Balloon:before {
	content: "\e688";
}
.icon-Banana:before {
	content: "\e689";
}
.icon-Band-Aid:before {
	content: "\e68a";
}
.icon-Bank:before {
	content: "\e68b";
}
.icon-Bar-Chart:before {
	content: "\e68c";
}
.icon-Bar-Chart2:before {
	content: "\e68d";
}
.icon-Bar-Chart3:before {
	content: "\e68e";
}
.icon-Bar-Chart4:before {
	content: "\e68f";
}
.icon-Bar-Chart5:before {
	content: "\e690";
}
.icon-Bar-Code:before {
	content: "\e691";
}
.icon-Barricade-2:before {
	content: "\e692";
}
.icon-Barricade:before {
	content: "\e693";
}
.icon-Baseball:before {
	content: "\e694";
}
.icon-Basket-Ball:before {
	content: "\e695";
}
.icon-Basket-Coins:before {
	content: "\e696";
}
.icon-Basket-Items:before {
	content: "\e697";
}
.icon-Basket-Quantity:before {
	content: "\e698";
}
.icon-Bat-2:before {
	content: "\e699";
}
.icon-Bat:before {
	content: "\e69a";
}
.icon-Bathrobe:before {
	content: "\e69b";
}
.icon-Batman-Mask:before {
	content: "\e69c";
}
.icon-Battery-0:before {
	content: "\e69d";
}
.icon-Battery-25:before {
	content: "\e69e";
}
.icon-Battery-50:before {
	content: "\e69f";
}
.icon-Battery-75:before {
	content: "\e6a0";
}
.icon-Battery-100:before {
	content: "\e6a1";
}
.icon-Battery-Charge:before {
	content: "\e6a2";
}
.icon-Bear:before {
	content: "\e6a3";
}
.icon-Beard-2:before {
	content: "\e6a4";
}
.icon-Beard-3:before {
	content: "\e6a5";
}
.icon-Beard:before {
	content: "\e6a6";
}
.icon-Bebo:before {
	content: "\e6a7";
}
.icon-Bee:before {
	content: "\e6a8";
}
.icon-Beer-Glass:before {
	content: "\e6a9";
}
.icon-Beer:before {
	content: "\e6aa";
}
.icon-Bell-2:before {
	content: "\e6ab";
}
.icon-Bell:before {
	content: "\e6ac";
}
.icon-Belt-2:before {
	content: "\e6ad";
}
.icon-Belt-3:before {
	content: "\e6ae";
}
.icon-Belt:before {
	content: "\e6af";
}
.icon-Berlin-Tower:before {
	content: "\e6b0";
}
.icon-Beta:before {
	content: "\e6b1";
}
.icon-Betvibes:before {
	content: "\e6b2";
}
.icon-Bicycle-2:before {
	content: "\e6b3";
}
.icon-Bicycle-3:before {
	content: "\e6b4";
}
.icon-Bicycle:before {
	content: "\e6b5";
}
.icon-Big-Bang:before {
	content: "\e6b6";
}
.icon-Big-Data:before {
	content: "\e6b7";
}
.icon-Bike-Helmet:before {
	content: "\e6b8";
}
.icon-Bikini:before {
	content: "\e6b9";
}
.icon-Bilk-Bottle2:before {
	content: "\e6ba";
}
.icon-Billing:before {
	content: "\e6bb";
}
.icon-Bing:before {
	content: "\e6bc";
}
.icon-Binocular:before {
	content: "\e6bd";
}
.icon-Bio-Hazard:before {
	content: "\e6be";
}
.icon-Biotech:before {
	content: "\e6bf";
}
.icon-Bird-DeliveringLetter:before {
	content: "\e6c0";
}
.icon-Bird:before {
	content: "\e6c1";
}
.icon-Birthday-Cake:before {
	content: "\e6c2";
}
.icon-Bisexual:before {
	content: "\e6c3";
}
.icon-Bishop:before {
	content: "\e6c4";
}
.icon-Bitcoin:before {
	content: "\e6c5";
}
.icon-Black-Cat:before {
	content: "\e6c6";
}
.icon-Blackboard:before {
	content: "\e6c7";
}
.icon-Blinklist:before {
	content: "\e6c8";
}
.icon-Block-Cloud:before {
	content: "\e6c9";
}
.icon-Block-Window:before {
	content: "\e6ca";
}
.icon-Blogger:before {
	content: "\e6cb";
}
.icon-Blood:before {
	content: "\e6cc";
}
.icon-Blouse:before {
	content: "\e6cd";
}
.icon-Blueprint:before {
	content: "\e6ce";
}
.icon-Board:before {
	content: "\e6cf";
}
.icon-Bodybuilding:before {
	content: "\e6d0";
}
.icon-Bold-Text:before {
	content: "\e6d1";
}
.icon-Bone:before {
	content: "\e6d2";
}
.icon-Bones:before {
	content: "\e6d3";
}
.icon-Book:before {
	content: "\e6d4";
}
.icon-Bookmark:before {
	content: "\e6d5";
}
.icon-Books-2:before {
	content: "\e6d6";
}
.icon-Books:before {
	content: "\e6d7";
}
.icon-Boom:before {
	content: "\e6d8";
}
.icon-Boot-2:before {
	content: "\e6d9";
}
.icon-Boot:before {
	content: "\e6da";
}
.icon-Bottom-ToTop:before {
	content: "\e6db";
}
.icon-Bow-2:before {
	content: "\e6dc";
}
.icon-Bow-3:before {
	content: "\e6dd";
}
.icon-Bow-4:before {
	content: "\e6de";
}
.icon-Bow-5:before {
	content: "\e6df";
}
.icon-Bow-6:before {
	content: "\e6e0";
}
.icon-Bow:before {
	content: "\e6e1";
}
.icon-Bowling-2:before {
	content: "\e6e2";
}
.icon-Bowling:before {
	content: "\e6e3";
}
.icon-Box2:before {
	content: "\e6e4";
}
.icon-Box-Close:before {
	content: "\e6e5";
}
.icon-Box-Full:before {
	content: "\e6e6";
}
.icon-Box-Open:before {
	content: "\e6e7";
}
.icon-Box-withFolders:before {
	content: "\e6e8";
}
.icon-Box:before {
	content: "\e6e9";
}
.icon-Boy:before {
	content: "\e6ea";
}
.icon-Bra:before {
	content: "\e6eb";
}
.icon-Brain-2:before {
	content: "\e6ec";
}
.icon-Brain-3:before {
	content: "\e6ed";
}
.icon-Brain:before {
	content: "\e6ee";
}
.icon-Brazil:before {
	content: "\e6ef";
}
.icon-Bread-2:before {
	content: "\e6f0";
}
.icon-Bread:before {
	content: "\e6f1";
}
.icon-Bridge:before {
	content: "\e6f2";
}
.icon-Brightkite:before {
	content: "\e6f3";
}
.icon-Broke-Link2:before {
	content: "\e6f4";
}
.icon-Broken-Link:before {
	content: "\e6f5";
}
.icon-Broom:before {
	content: "\e6f6";
}
.icon-Brush:before {
	content: "\e6f7";
}
.icon-Bucket:before {
	content: "\e6f8";
}
.icon-Bug:before {
	content: "\e6f9";
}
.icon-Building:before {
	content: "\e6fa";
}
.icon-Bulleted-List:before {
	content: "\e6fb";
}
.icon-Bus-2:before {
	content: "\e6fc";
}
.icon-Bus:before {
	content: "\e6fd";
}
.icon-Business-Man:before {
	content: "\e6fe";
}
.icon-Business-ManWoman:before {
	content: "\e6ff";
}
.icon-Business-Mens:before {
	content: "\e700";
}
.icon-Business-Woman:before {
	content: "\e701";
}
.icon-Butterfly:before {
	content: "\e702";
}
.icon-Button:before {
	content: "\e703";
}
.icon-Cable-Car:before {
	content: "\e704";
}
.icon-Cake:before {
	content: "\e705";
}
.icon-Calculator-2:before {
	content: "\e706";
}
.icon-Calculator-3:before {
	content: "\e707";
}
.icon-Calculator:before {
	content: "\e708";
}
.icon-Calendar-2:before {
	content: "\e709";
}
.icon-Calendar-3:before {
	content: "\e70a";
}
.icon-Calendar-4:before {
	content: "\e70b";
}
.icon-Calendar-Clock:before {
	content: "\e70c";
}
.icon-Calendar:before {
	content: "\e70d";
}
.icon-Camel:before {
	content: "\e70e";
}
.icon-Camera-2:before {
	content: "\e70f";
}
.icon-Camera-3:before {
	content: "\e710";
}
.icon-Camera-4:before {
	content: "\e711";
}
.icon-Camera-5:before {
	content: "\e712";
}
.icon-Camera-Back:before {
	content: "\e713";
}
.icon-Camera:before {
	content: "\e714";
}
.icon-Can-2:before {
	content: "\e715";
}
.icon-Can:before {
	content: "\e716";
}
.icon-Canada:before {
	content: "\e717";
}
.icon-Cancer-2:before {
	content: "\e718";
}
.icon-Cancer-3:before {
	content: "\e719";
}
.icon-Cancer:before {
	content: "\e71a";
}
.icon-Candle:before {
	content: "\e71b";
}
.icon-Candy-Cane:before {
	content: "\e71c";
}
.icon-Candy:before {
	content: "\e71d";
}
.icon-Cannon:before {
	content: "\e71e";
}
.icon-Cap-2:before {
	content: "\e71f";
}
.icon-Cap-3:before {
	content: "\e720";
}
.icon-Cap-Smiley:before {
	content: "\e721";
}
.icon-Cap:before {
	content: "\e722";
}
.icon-Capricorn-2:before {
	content: "\e723";
}
.icon-Capricorn:before {
	content: "\e724";
}
.icon-Car-2:before {
	content: "\e725";
}
.icon-Car-3:before {
	content: "\e726";
}
.icon-Car-Coins:before {
	content: "\e727";
}
.icon-Car-Items:before {
	content: "\e728";
}
.icon-Car-Wheel:before {
	content: "\e729";
}
.icon-Car:before {
	content: "\e72a";
}
.icon-Cardigan:before {
	content: "\e72b";
}
.icon-Cardiovascular:before {
	content: "\e72c";
}
.icon-Cart-Quantity:before {
	content: "\e72d";
}
.icon-Casette-Tape:before {
	content: "\e72e";
}
.icon-Cash-Register:before {
	content: "\e72f";
}
.icon-Cash-register2:before {
	content: "\e730";
}
.icon-Castle:before {
	content: "\e731";
}
.icon-Cat:before {
	content: "\e732";
}
.icon-Cathedral:before {
	content: "\e733";
}
.icon-Cauldron:before {
	content: "\e734";
}
.icon-CD-2:before {
	content: "\e735";
}
.icon-CD-Cover:before {
	content: "\e736";
}
.icon-CD:before {
	content: "\e737";
}
.icon-Cello:before {
	content: "\e738";
}
.icon-Celsius:before {
	content: "\e739";
}
.icon-Chacked-Flag:before {
	content: "\e73a";
}
.icon-Chair:before {
	content: "\e73b";
}
.icon-Charger:before {
	content: "\e73c";
}
.icon-Check-2:before {
	content: "\e73d";
}
.icon-Check:before {
	content: "\e73e";
}
.icon-Checked-User:before {
	content: "\e73f";
}
.icon-Checkmate:before {
	content: "\e740";
}
.icon-Checkout-Bag:before {
	content: "\e741";
}
.icon-Checkout-Basket:before {
	content: "\e742";
}
.icon-Checkout:before {
	content: "\e743";
}
.icon-Cheese:before {
	content: "\e744";
}
.icon-Cheetah:before {
	content: "\e745";
}
.icon-Chef-Hat:before {
	content: "\e746";
}
.icon-Chef-Hat2:before {
	content: "\e747";
}
.icon-Chef:before {
	content: "\e748";
}
.icon-Chemical-2:before {
	content: "\e749";
}
.icon-Chemical-3:before {
	content: "\e74a";
}
.icon-Chemical-4:before {
	content: "\e74b";
}
.icon-Chemical-5:before {
	content: "\e74c";
}
.icon-Chemical:before {
	content: "\e74d";
}
.icon-Chess-Board:before {
	content: "\e74e";
}
.icon-Chess:before {
	content: "\e74f";
}
.icon-Chicken:before {
	content: "\e750";
}
.icon-Chile:before {
	content: "\e751";
}
.icon-Chimney:before {
	content: "\e752";
}
.icon-China:before {
	content: "\e753";
}
.icon-Chinese-Temple:before {
	content: "\e754";
}
.icon-Chip:before {
	content: "\e755";
}
.icon-Chopsticks-2:before {
	content: "\e756";
}
.icon-Chopsticks:before {
	content: "\e757";
}
.icon-Christmas-Ball:before {
	content: "\e758";
}
.icon-Christmas-Bell:before {
	content: "\e759";
}
.icon-Christmas-Candle:before {
	content: "\e75a";
}
.icon-Christmas-Hat:before {
	content: "\e75b";
}
.icon-Christmas-Sleigh:before {
	content: "\e75c";
}
.icon-Christmas-Snowman:before {
	content: "\e75d";
}
.icon-Christmas-Sock:before {
	content: "\e75e";
}
.icon-Christmas-Tree:before {
	content: "\e75f";
}
.icon-Christmas:before {
	content: "\e760";
}
.icon-Chrome:before {
	content: "\e761";
}
.icon-Chrysler-Building:before {
	content: "\e762";
}
.icon-Cinema:before {
	content: "\e763";
}
.icon-Circular-Point:before {
	content: "\e764";
}
.icon-City-Hall:before {
	content: "\e765";
}
.icon-Clamp:before {
	content: "\e766";
}
.icon-Clapperboard-Close:before {
	content: "\e767";
}
.icon-Clapperboard-Open:before {
	content: "\e768";
}
.icon-Claps:before {
	content: "\e769";
}
.icon-Clef:before {
	content: "\e76a";
}
.icon-Clinic:before {
	content: "\e76b";
}
.icon-Clock-2:before {
	content: "\e76c";
}
.icon-Clock-3:before {
	content: "\e76d";
}
.icon-Clock-4:before {
	content: "\e76e";
}
.icon-Clock-Back:before {
	content: "\e76f";
}
.icon-Clock-Forward:before {
	content: "\e770";
}
.icon-Clock:before {
	content: "\e771";
}
.icon-Close-Window:before {
	content: "\e772";
}
.icon-Close:before {
	content: "\e773";
}
.icon-Clothing-Store:before {
	content: "\e774";
}
.icon-Cloud--:before {
	content: "\e775";
}
.icon-Cloud-:before {
	content: "\e776";
}
.icon-Cloud-Camera:before {
	content: "\e777";
}
.icon-Cloud-Computer:before {
	content: "\e778";
}
.icon-Cloud-Email:before {
	content: "\e779";
}
.icon-Cloud-Hail:before {
	content: "\e77a";
}
.icon-Cloud-Laptop:before {
	content: "\e77b";
}
.icon-Cloud-Lock:before {
	content: "\e77c";
}
.icon-Cloud-Moon:before {
	content: "\e77d";
}
.icon-Cloud-Music:before {
	content: "\e77e";
}
.icon-Cloud-Picture:before {
	content: "\e77f";
}
.icon-Cloud-Rain:before {
	content: "\e780";
}
.icon-Cloud-Remove:before {
	content: "\e781";
}
.icon-Cloud-Secure:before {
	content: "\e782";
}
.icon-Cloud-Settings:before {
	content: "\e783";
}
.icon-Cloud-Smartphone:before {
	content: "\e784";
}
.icon-Cloud-Snow:before {
	content: "\e785";
}
.icon-Cloud-Sun:before {
	content: "\e786";
}
.icon-Cloud-Tablet:before {
	content: "\e787";
}
.icon-Cloud-Video:before {
	content: "\e788";
}
.icon-Cloud-Weather:before {
	content: "\e789";
}
.icon-Cloud:before {
	content: "\e78a";
}
.icon-Clouds-Weather:before {
	content: "\e78b";
}
.icon-Clouds:before {
	content: "\e78c";
}
.icon-Clown:before {
	content: "\e78d";
}
.icon-CMYK:before {
	content: "\e78e";
}
.icon-Coat:before {
	content: "\e78f";
}
.icon-Cocktail:before {
	content: "\e790";
}
.icon-Coconut:before {
	content: "\e791";
}
.icon-Code-Window:before {
	content: "\e792";
}
.icon-Coding:before {
	content: "\e793";
}
.icon-Coffee-2:before {
	content: "\e794";
}
.icon-Coffee-Bean:before {
	content: "\e795";
}
.icon-Coffee-Machine:before {
	content: "\e796";
}
.icon-Coffee-toGo:before {
	content: "\e797";
}
.icon-Coffee:before {
	content: "\e798";
}
.icon-Coffin:before {
	content: "\e799";
}
.icon-Coin:before {
	content: "\e79a";
}
.icon-Coins-2:before {
	content: "\e79b";
}
.icon-Coins-3:before {
	content: "\e79c";
}
.icon-Coins:before {
	content: "\e79d";
}
.icon-Colombia:before {
	content: "\e79e";
}
.icon-Colosseum:before {
	content: "\e79f";
}
.icon-Column-2:before {
	content: "\e7a0";
}
.icon-Column-3:before {
	content: "\e7a1";
}
.icon-Column:before {
	content: "\e7a2";
}
.icon-Comb-2:before {
	content: "\e7a3";
}
.icon-Comb:before {
	content: "\e7a4";
}
.icon-Communication-Tower:before {
	content: "\e7a5";
}
.icon-Communication-Tower2:before {
	content: "\e7a6";
}
.icon-Compass-2:before {
	content: "\e7a7";
}
.icon-Compass-3:before {
	content: "\e7a8";
}
.icon-Compass-4:before {
	content: "\e7a9";
}
.icon-Compass-Rose:before {
	content: "\e7aa";
}
.icon-Compass:before {
	content: "\e7ab";
}
.icon-Computer-2:before {
	content: "\e7ac";
}
.icon-Computer-3:before {
	content: "\e7ad";
}
.icon-Computer-Secure:before {
	content: "\e7ae";
}
.icon-Computer:before {
	content: "\e7af";
}
.icon-Conference:before {
	content: "\e7b0";
}
.icon-Confused:before {
	content: "\e7b1";
}
.icon-Conservation:before {
	content: "\e7b2";
}
.icon-Consulting:before {
	content: "\e7b3";
}
.icon-Contrast:before {
	content: "\e7b4";
}
.icon-Control-2:before {
	content: "\e7b5";
}
.icon-Control:before {
	content: "\e7b6";
}
.icon-Cookie-Man:before {
	content: "\e7b7";
}
.icon-Cookies:before {
	content: "\e7b8";
}
.icon-Cool-Guy:before {
	content: "\e7b9";
}
.icon-Cool:before {
	content: "\e7ba";
}
.icon-Copyright:before {
	content: "\e7bb";
}
.icon-Costume:before {
	content: "\e7bc";
}
.icon-Couple-Sign:before {
	content: "\e7bd";
}
.icon-Cow:before {
	content: "\e7be";
}
.icon-CPU:before {
	content: "\e7bf";
}
.icon-Crane:before {
	content: "\e7c0";
}
.icon-Cranium:before {
	content: "\e7c1";
}
.icon-Credit-Card:before {
	content: "\e7c2";
}
.icon-Credit-Card2:before {
	content: "\e7c3";
}
.icon-Credit-Card3:before {
	content: "\e7c4";
}
.icon-Cricket:before {
	content: "\e7c5";
}
.icon-Criminal:before {
	content: "\e7c6";
}
.icon-Croissant:before {
	content: "\e7c7";
}
.icon-Crop-2:before {
	content: "\e7c8";
}
.icon-Crop-3:before {
	content: "\e7c9";
}
.icon-Crown-2:before {
	content: "\e7ca";
}
.icon-Crown:before {
	content: "\e7cb";
}
.icon-Crying:before {
	content: "\e7cc";
}
.icon-Cube-Molecule:before {
	content: "\e7cd";
}
.icon-Cube-Molecule2:before {
	content: "\e7ce";
}
.icon-Cupcake:before {
	content: "\e7cf";
}
.icon-Cursor-Click:before {
	content: "\e7d0";
}
.icon-Cursor-Click2:before {
	content: "\e7d1";
}
.icon-Cursor-Move:before {
	content: "\e7d2";
}
.icon-Cursor-Move2:before {
	content: "\e7d3";
}
.icon-Cursor-Select:before {
	content: "\e7d4";
}
.icon-Cursor:before {
	content: "\e7d5";
}
.icon-D-Eyeglasses:before {
	content: "\e7d6";
}
.icon-D-Eyeglasses2:before {
	content: "\e7d7";
}
.icon-Dam:before {
	content: "\e7d8";
}
.icon-Danemark:before {
	content: "\e7d9";
}
.icon-Danger-2:before {
	content: "\e7da";
}
.icon-Danger:before {
	content: "\e7db";
}
.icon-Dashboard:before {
	content: "\e7dc";
}
.icon-Data-Backup:before {
	content: "\e7dd";
}
.icon-Data-Block:before {
	content: "\e7de";
}
.icon-Data-Center:before {
	content: "\e7df";
}
.icon-Data-Clock:before {
	content: "\e7e0";
}
.icon-Data-Cloud:before {
	content: "\e7e1";
}
.icon-Data-Compress:before {
	content: "\e7e2";
}
.icon-Data-Copy:before {
	content: "\e7e3";
}
.icon-Data-Download:before {
	content: "\e7e4";
}
.icon-Data-Financial:before {
	content: "\e7e5";
}
.icon-Data-Key:before {
	content: "\e7e6";
}
.icon-Data-Lock:before {
	content: "\e7e7";
}
.icon-Data-Network:before {
	content: "\e7e8";
}
.icon-Data-Password:before {
	content: "\e7e9";
}
.icon-Data-Power:before {
	content: "\e7ea";
}
.icon-Data-Refresh:before {
	content: "\e7eb";
}
.icon-Data-Save:before {
	content: "\e7ec";
}
.icon-Data-Search:before {
	content: "\e7ed";
}
.icon-Data-Security:before {
	content: "\e7ee";
}
.icon-Data-Settings:before {
	content: "\e7ef";
}
.icon-Data-Sharing:before {
	content: "\e7f0";
}
.icon-Data-Shield:before {
	content: "\e7f1";
}
.icon-Data-Signal:before {
	content: "\e7f2";
}
.icon-Data-Storage:before {
	content: "\e7f3";
}
.icon-Data-Stream:before {
	content: "\e7f4";
}
.icon-Data-Transfer:before {
	content: "\e7f5";
}
.icon-Data-Unlock:before {
	content: "\e7f6";
}
.icon-Data-Upload:before {
	content: "\e7f7";
}
.icon-Data-Yes:before {
	content: "\e7f8";
}
.icon-Data:before {
	content: "\e7f9";
}
.icon-David-Star:before {
	content: "\e7fa";
}
.icon-Daylight:before {
	content: "\e7fb";
}
.icon-Death:before {
	content: "\e7fc";
}
.icon-Debian:before {
	content: "\e7fd";
}
.icon-Dec:before {
	content: "\e7fe";
}
.icon-Decrase-Inedit:before {
	content: "\e7ff";
}
.icon-Deer-2:before {
	content: "\e800";
}
.icon-Deer:before {
	content: "\e801";
}
.icon-Delete-File:before {
	content: "\e802";
}
.icon-Delete-Window:before {
	content: "\e803";
}
.icon-Delicious:before {
	content: "\e804";
}
.icon-Depression:before {
	content: "\e805";
}
.icon-Deviantart:before {
	content: "\e806";
}
.icon-Device-SyncwithCloud:before {
	content: "\e807";
}
.icon-Diamond:before {
	content: "\e808";
}
.icon-Dice-2:before {
	content: "\e809";
}
.icon-Dice:before {
	content: "\e80a";
}
.icon-Digg:before {
	content: "\e80b";
}
.icon-Digital-Drawing:before {
	content: "\e80c";
}
.icon-Diigo:before {
	content: "\e80d";
}
.icon-Dinosaur:before {
	content: "\e80e";
}
.icon-Diploma-2:before {
	content: "\e80f";
}
.icon-Diploma:before {
	content: "\e810";
}
.icon-Direction-East:before {
	content: "\e811";
}
.icon-Direction-North:before {
	content: "\e812";
}
.icon-Direction-South:before {
	content: "\e813";
}
.icon-Direction-West:before {
	content: "\e814";
}
.icon-Director:before {
	content: "\e815";
}
.icon-Disk:before {
	content: "\e816";
}
.icon-Dj:before {
	content: "\e817";
}
.icon-DNA-2:before {
	content: "\e818";
}
.icon-DNA-Helix:before {
	content: "\e819";
}
.icon-DNA:before {
	content: "\e81a";
}
.icon-Doctor:before {
	content: "\e81b";
}
.icon-Dog:before {
	content: "\e81c";
}
.icon-Dollar-Sign:before {
	content: "\e81d";
}
.icon-Dollar-Sign2:before {
	content: "\e81e";
}
.icon-Dollar:before {
	content: "\e81f";
}
.icon-Dolphin:before {
	content: "\e820";
}
.icon-Domino:before {
	content: "\e821";
}
.icon-Door-Hanger:before {
	content: "\e822";
}
.icon-Door:before {
	content: "\e823";
}
.icon-Doplr:before {
	content: "\e824";
}
.icon-Double-Circle:before {
	content: "\e825";
}
.icon-Double-Tap:before {
	content: "\e826";
}
.icon-Doughnut:before {
	content: "\e827";
}
.icon-Dove:before {
	content: "\e828";
}
.icon-Down-2:before {
	content: "\e829";
}
.icon-Down-3:before {
	content: "\e82a";
}
.icon-Down-4:before {
	content: "\e82b";
}
.icon-Down:before {
	content: "\e82c";
}
.icon-Download-2:before {
	content: "\e82d";
}
.icon-Download-fromCloud:before {
	content: "\e82e";
}
.icon-Download-Window:before {
	content: "\e82f";
}
.icon-Download:before {
	content: "\e830";
}
.icon-Downward:before {
	content: "\e831";
}
.icon-Drag-Down:before {
	content: "\e832";
}
.icon-Drag-Left:before {
	content: "\e833";
}
.icon-Drag-Right:before {
	content: "\e834";
}
.icon-Drag-Up:before {
	content: "\e835";
}
.icon-Drag:before {
	content: "\e836";
}
.icon-Dress:before {
	content: "\e837";
}
.icon-Drill-2:before {
	content: "\e838";
}
.icon-Drill:before {
	content: "\e839";
}
.icon-Drop:before {
	content: "\e83a";
}
.icon-Dropbox:before {
	content: "\e83b";
}
.icon-Drum:before {
	content: "\e83c";
}
.icon-Dry:before {
	content: "\e83d";
}
.icon-Duck:before {
	content: "\e83e";
}
.icon-Dumbbell:before {
	content: "\e83f";
}
.icon-Duplicate-Layer:before {
	content: "\e840";
}
.icon-Duplicate-Window:before {
	content: "\e841";
}
.icon-DVD:before {
	content: "\e842";
}
.icon-Eagle:before {
	content: "\e843";
}
.icon-Ear:before {
	content: "\e844";
}
.icon-Earphones-2:before {
	content: "\e845";
}
.icon-Earphones:before {
	content: "\e846";
}
.icon-Eci-Icon:before {
	content: "\e847";
}
.icon-Edit-Map:before {
	content: "\e848";
}
.icon-Edit:before {
	content: "\e849";
}
.icon-Eggs:before {
	content: "\e84a";
}
.icon-Egypt:before {
	content: "\e84b";
}
.icon-Eifel-Tower:before {
	content: "\e84c";
}
.icon-eject-2:before {
	content: "\e84d";
}
.icon-Eject:before {
	content: "\e84e";
}
.icon-El-Castillo:before {
	content: "\e84f";
}
.icon-Elbow:before {
	content: "\e850";
}
.icon-Electric-Guitar:before {
	content: "\e851";
}
.icon-Electricity:before {
	content: "\e852";
}
.icon-Elephant:before {
	content: "\e853";
}
.icon-Email:before {
	content: "\e854";
}
.icon-Embassy:before {
	content: "\e855";
}
.icon-Empire-StateBuilding:before {
	content: "\e856";
}
.icon-Empty-Box:before {
	content: "\e857";
}
.icon-End2:before {
	content: "\e858";
}
.icon-End-2:before {
	content: "\e859";
}
.icon-End:before {
	content: "\e85a";
}
.icon-Endways:before {
	content: "\e85b";
}
.icon-Engineering:before {
	content: "\e85c";
}
.icon-Envelope-2:before {
	content: "\e85d";
}
.icon-Envelope:before {
	content: "\e85e";
}
.icon-Environmental-2:before {
	content: "\e85f";
}
.icon-Environmental-3:before {
	content: "\e860";
}
.icon-Environmental:before {
	content: "\e861";
}
.icon-Equalizer:before {
	content: "\e862";
}
.icon-Eraser-2:before {
	content: "\e863";
}
.icon-Eraser-3:before {
	content: "\e864";
}
.icon-Eraser:before {
	content: "\e865";
}
.icon-Error-404Window:before {
	content: "\e866";
}
.icon-Euro-Sign:before {
	content: "\e867";
}
.icon-Euro-Sign2:before {
	content: "\e868";
}
.icon-Euro:before {
	content: "\e869";
}
.icon-Evernote:before {
	content: "\e86a";
}
.icon-Evil:before {
	content: "\e86b";
}
.icon-Explode:before {
	content: "\e86c";
}
.icon-Eye-2:before {
	content: "\e86d";
}
.icon-Eye-Blind:before {
	content: "\e86e";
}
.icon-Eye-Invisible:before {
	content: "\e86f";
}
.icon-Eye-Scan:before {
	content: "\e870";
}
.icon-Eye-Visible:before {
	content: "\e871";
}
.icon-Eye:before {
	content: "\e872";
}
.icon-Eyebrow-2:before {
	content: "\e873";
}
.icon-Eyebrow-3:before {
	content: "\e874";
}
.icon-Eyebrow:before {
	content: "\e875";
}
.icon-Eyeglasses-Smiley:before {
	content: "\e876";
}
.icon-Eyeglasses-Smiley2:before {
	content: "\e877";
}
.icon-Face-Style:before {
	content: "\e878";
}
.icon-Face-Style2:before {
	content: "\e879";
}
.icon-Face-Style3:before {
	content: "\e87a";
}
.icon-Face-Style4:before {
	content: "\e87b";
}
.icon-Face-Style5:before {
	content: "\e87c";
}
.icon-Face-Style6:before {
	content: "\e87d";
}
.icon-Facebook-2:before {
	content: "\e87e";
}
.icon-Facebook:before {
	content: "\e87f";
}
.icon-Factory-2:before {
	content: "\e880";
}
.icon-Factory:before {
	content: "\e881";
}
.icon-Fahrenheit:before {
	content: "\e882";
}
.icon-Family-Sign:before {
	content: "\e883";
}
.icon-Fan:before {
	content: "\e884";
}
.icon-Farmer:before {
	content: "\e885";
}
.icon-Fashion:before {
	content: "\e886";
}
.icon-Favorite-Window:before {
	content: "\e887";
}
.icon-Fax:before {
	content: "\e888";
}
.icon-Feather:before {
	content: "\e889";
}
.icon-Feedburner:before {
	content: "\e88a";
}
.icon-Female-2:before {
	content: "\e88b";
}
.icon-Female-Sign:before {
	content: "\e88c";
}
.icon-Female:before {
	content: "\e88d";
}
.icon-File-Block:before {
	content: "\e88e";
}
.icon-File-Bookmark:before {
	content: "\e88f";
}
.icon-File-Chart:before {
	content: "\e890";
}
.icon-File-Clipboard:before {
	content: "\e891";
}
.icon-File-ClipboardFileText:before {
	content: "\e892";
}
.icon-File-ClipboardTextImage:before {
	content: "\e893";
}
.icon-File-Cloud:before {
	content: "\e894";
}
.icon-File-Copy:before {
	content: "\e895";
}
.icon-File-Copy2:before {
	content: "\e896";
}
.icon-File-CSV:before {
	content: "\e897";
}
.icon-File-Download:before {
	content: "\e898";
}
.icon-File-Edit:before {
	content: "\e899";
}
.icon-File-Excel:before {
	content: "\e89a";
}
.icon-File-Favorite:before {
	content: "\e89b";
}
.icon-File-Fire:before {
	content: "\e89c";
}
.icon-File-Graph:before {
	content: "\e89d";
}
.icon-File-Hide:before {
	content: "\e89e";
}
.icon-File-Horizontal:before {
	content: "\e89f";
}
.icon-File-HorizontalText:before {
	content: "\e8a0";
}
.icon-File-HTML:before {
	content: "\e8a1";
}
.icon-File-JPG:before {
	content: "\e8a2";
}
.icon-File-Link:before {
	content: "\e8a3";
}
.icon-File-Loading:before {
	content: "\e8a4";
}
.icon-File-Lock:before {
	content: "\e8a5";
}
.icon-File-Love:before {
	content: "\e8a6";
}
.icon-File-Music:before {
	content: "\e8a7";
}
.icon-File-Network:before {
	content: "\e8a8";
}
.icon-File-Pictures:before {
	content: "\e8a9";
}
.icon-File-Pie:before {
	content: "\e8aa";
}
.icon-File-Presentation:before {
	content: "\e8ab";
}
.icon-File-Refresh:before {
	content: "\e8ac";
}
.icon-File-Search:before {
	content: "\e8ad";
}
.icon-File-Settings:before {
	content: "\e8ae";
}
.icon-File-Share:before {
	content: "\e8af";
}
.icon-File-TextImage:before {
	content: "\e8b0";
}
.icon-File-Trash:before {
	content: "\e8b1";
}
.icon-File-TXT:before {
	content: "\e8b2";
}
.icon-File-Upload:before {
	content: "\e8b3";
}
.icon-File-Video:before {
	content: "\e8b4";
}
.icon-File-Word:before {
	content: "\e8b5";
}
.icon-File-Zip:before {
	content: "\e8b6";
}
.icon-File:before {
	content: "\e8b7";
}
.icon-Files:before {
	content: "\e8b8";
}
.icon-Film-Board:before {
	content: "\e8b9";
}
.icon-Film-Cartridge:before {
	content: "\e8ba";
}
.icon-Film-Strip:before {
	content: "\e8bb";
}
.icon-Film-Video:before {
	content: "\e8bc";
}
.icon-Film:before {
	content: "\e8bd";
}
.icon-Filter-2:before {
	content: "\e8be";
}
.icon-Filter:before {
	content: "\e8bf";
}
.icon-Financial:before {
	content: "\e8c0";
}
.icon-Find-User:before {
	content: "\e8c1";
}
.icon-Finger-DragFourSides:before {
	content: "\e8c2";
}
.icon-Finger-DragTwoSides:before {
	content: "\e8c3";
}
.icon-Finger-Print:before {
	content: "\e8c4";
}
.icon-Finger:before {
	content: "\e8c5";
}
.icon-Fingerprint-2:before {
	content: "\e8c6";
}
.icon-Fingerprint:before {
	content: "\e8c7";
}
.icon-Fire-Flame:before {
	content: "\e8c8";
}
.icon-Fire-Flame2:before {
	content: "\e8c9";
}
.icon-Fire-Hydrant:before {
	content: "\e8ca";
}
.icon-Fire-Staion:before {
	content: "\e8cb";
}
.icon-Firefox:before {
	content: "\e8cc";
}
.icon-Firewall:before {
	content: "\e8cd";
}
.icon-First-Aid:before {
	content: "\e8ce";
}
.icon-First:before {
	content: "\e8cf";
}
.icon-Fish-Food:before {
	content: "\e8d0";
}
.icon-Fish:before {
	content: "\e8d1";
}
.icon-Fit-To:before {
	content: "\e8d2";
}
.icon-Fit-To2:before {
	content: "\e8d3";
}
.icon-Five-Fingers:before {
	content: "\e8d4";
}
.icon-Five-FingersDrag:before {
	content: "\e8d5";
}
.icon-Five-FingersDrag2:before {
	content: "\e8d6";
}
.icon-Five-FingersTouch:before {
	content: "\e8d7";
}
.icon-Flag-2:before {
	content: "\e8d8";
}
.icon-Flag-3:before {
	content: "\e8d9";
}
.icon-Flag-4:before {
	content: "\e8da";
}
.icon-Flag-5:before {
	content: "\e8db";
}
.icon-Flag-6:before {
	content: "\e8dc";
}
.icon-Flag:before {
	content: "\e8dd";
}
.icon-Flamingo:before {
	content: "\e8de";
}
.icon-Flash-2:before {
	content: "\e8df";
}
.icon-Flash-Video:before {
	content: "\e8e0";
}
.icon-Flash:before {
	content: "\e8e1";
}
.icon-Flashlight:before {
	content: "\e8e2";
}
.icon-Flask-2:before {
	content: "\e8e3";
}
.icon-Flask:before {
	content: "\e8e4";
}
.icon-Flick:before {
	content: "\e8e5";
}
.icon-Flickr:before {
	content: "\e8e6";
}
.icon-Flowerpot:before {
	content: "\e8e7";
}
.icon-Fluorescent:before {
	content: "\e8e8";
}
.icon-Fog-Day:before {
	content: "\e8e9";
}
.icon-Fog-Night:before {
	content: "\e8ea";
}
.icon-Folder-Add:before {
	content: "\e8eb";
}
.icon-Folder-Archive:before {
	content: "\e8ec";
}
.icon-Folder-Binder:before {
	content: "\e8ed";
}
.icon-Folder-Binder2:before {
	content: "\e8ee";
}
.icon-Folder-Block:before {
	content: "\e8ef";
}
.icon-Folder-Bookmark:before {
	content: "\e8f0";
}
.icon-Folder-Close:before {
	content: "\e8f1";
}
.icon-Folder-Cloud:before {
	content: "\e8f2";
}
.icon-Folder-Delete:before {
	content: "\e8f3";
}
.icon-Folder-Download:before {
	content: "\e8f4";
}
.icon-Folder-Edit:before {
	content: "\e8f5";
}
.icon-Folder-Favorite:before {
	content: "\e8f6";
}
.icon-Folder-Fire:before {
	content: "\e8f7";
}
.icon-Folder-Hide:before {
	content: "\e8f8";
}
.icon-Folder-Link:before {
	content: "\e8f9";
}
.icon-Folder-Loading:before {
	content: "\e8fa";
}
.icon-Folder-Lock:before {
	content: "\e8fb";
}
.icon-Folder-Love:before {
	content: "\e8fc";
}
.icon-Folder-Music:before {
	content: "\e8fd";
}
.icon-Folder-Network:before {
	content: "\e8fe";
}
.icon-Folder-Open:before {
	content: "\e8ff";
}
.icon-Folder-Open2:before {
	content: "\e900";
}
.icon-Folder-Organizing:before {
	content: "\e901";
}
.icon-Folder-Pictures:before {
	content: "\e902";
}
.icon-Folder-Refresh:before {
	content: "\e903";
}
.icon-Folder-Remove-:before {
	content: "\e904";
}
.icon-Folder-Search:before {
	content: "\e905";
}
.icon-Folder-Settings:before {
	content: "\e906";
}
.icon-Folder-Share:before {
	content: "\e907";
}
.icon-Folder-Trash:before {
	content: "\e908";
}
.icon-Folder-Upload:before {
	content: "\e909";
}
.icon-Folder-Video:before {
	content: "\e90a";
}
.icon-Folder-WithDocument:before {
	content: "\e90b";
}
.icon-Folder-Zip:before {
	content: "\e90c";
}
.icon-Folder:before {
	content: "\e90d";
}
.icon-Folders:before {
	content: "\e90e";
}
.icon-Font-Color:before {
	content: "\e90f";
}
.icon-Font-Name:before {
	content: "\e910";
}
.icon-Font-Size:before {
	content: "\e911";
}
.icon-Font-Style:before {
	content: "\e912";
}
.icon-Font-StyleSubscript:before {
	content: "\e913";
}
.icon-Font-StyleSuperscript:before {
	content: "\e914";
}
.icon-Font-Window:before {
	content: "\e915";
}
.icon-Foot-2:before {
	content: "\e916";
}
.icon-Foot:before {
	content: "\e917";
}
.icon-Football-2:before {
	content: "\e918";
}
.icon-Football:before {
	content: "\e919";
}
.icon-Footprint-2:before {
	content: "\e91a";
}
.icon-Footprint-3:before {
	content: "\e91b";
}
.icon-Footprint:before {
	content: "\e91c";
}
.icon-Forest:before {
	content: "\e91d";
}
.icon-Fork:before {
	content: "\e91e";
}
.icon-Formspring:before {
	content: "\e91f";
}
.icon-Formula:before {
	content: "\e920";
}
.icon-Forsquare:before {
	content: "\e921";
}
.icon-Forward:before {
	content: "\e922";
}
.icon-Fountain-Pen:before {
	content: "\e923";
}
.icon-Four-Fingers:before {
	content: "\e924";
}
.icon-Four-FingersDrag:before {
	content: "\e925";
}
.icon-Four-FingersDrag2:before {
	content: "\e926";
}
.icon-Four-FingersTouch:before {
	content: "\e927";
}
.icon-Fox:before {
	content: "\e928";
}
.icon-Frankenstein:before {
	content: "\e929";
}
.icon-French-Fries:before {
	content: "\e92a";
}
.icon-Friendfeed:before {
	content: "\e92b";
}
.icon-Friendster:before {
	content: "\e92c";
}
.icon-Frog:before {
	content: "\e92d";
}
.icon-Fruits:before {
	content: "\e92e";
}
.icon-Fuel:before {
	content: "\e92f";
}
.icon-Full-Bag:before {
	content: "\e930";
}
.icon-Full-Basket:before {
	content: "\e931";
}
.icon-Full-Cart:before {
	content: "\e932";
}
.icon-Full-Moon:before {
	content: "\e933";
}
.icon-Full-Screen:before {
	content: "\e934";
}
.icon-Full-Screen2:before {
	content: "\e935";
}
.icon-Full-View:before {
	content: "\e936";
}
.icon-Full-View2:before {
	content: "\e937";
}
.icon-Full-ViewWindow:before {
	content: "\e938";
}
.icon-Function:before {
	content: "\e939";
}
.icon-Funky:before {
	content: "\e93a";
}
.icon-Funny-Bicycle:before {
	content: "\e93b";
}
.icon-Furl:before {
	content: "\e93c";
}
.icon-Gamepad-2:before {
	content: "\e93d";
}
.icon-Gamepad:before {
	content: "\e93e";
}
.icon-Gas-Pump:before {
	content: "\e93f";
}
.icon-Gaugage-2:before {
	content: "\e940";
}
.icon-Gaugage:before {
	content: "\e941";
}
.icon-Gay:before {
	content: "\e942";
}
.icon-Gear-2:before {
	content: "\e943";
}
.icon-Gear:before {
	content: "\e944";
}
.icon-Gears-2:before {
	content: "\e945";
}
.icon-Gears:before {
	content: "\e946";
}
.icon-Geek-2:before {
	content: "\e947";
}
.icon-Geek:before {
	content: "\e948";
}
.icon-Gemini-2:before {
	content: "\e949";
}
.icon-Gemini:before {
	content: "\e94a";
}
.icon-Genius:before {
	content: "\e94b";
}
.icon-Gentleman:before {
	content: "\e94c";
}
.icon-Geo--:before {
	content: "\e94d";
}
.icon-Geo-:before {
	content: "\e94e";
}
.icon-Geo-Close:before {
	content: "\e94f";
}
.icon-Geo-Love:before {
	content: "\e950";
}
.icon-Geo-Number:before {
	content: "\e951";
}
.icon-Geo-Star:before {
	content: "\e952";
}
.icon-Geo:before {
	content: "\e953";
}
.icon-Geo2--:before {
	content: "\e954";
}
.icon-Geo2-:before {
	content: "\e955";
}
.icon-Geo2-Close:before {
	content: "\e956";
}
.icon-Geo2-Love:before {
	content: "\e957";
}
.icon-Geo2-Number:before {
	content: "\e958";
}
.icon-Geo2-Star:before {
	content: "\e959";
}
.icon-Geo2:before {
	content: "\e95a";
}
.icon-Geo3--:before {
	content: "\e95b";
}
.icon-Geo3-:before {
	content: "\e95c";
}
.icon-Geo3-Close:before {
	content: "\e95d";
}
.icon-Geo3-Love:before {
	content: "\e95e";
}
.icon-Geo3-Number:before {
	content: "\e95f";
}
.icon-Geo3-Star:before {
	content: "\e960";
}
.icon-Geo3:before {
	content: "\e961";
}
.icon-Gey:before {
	content: "\e962";
}
.icon-Gift-Box:before {
	content: "\e963";
}
.icon-Giraffe:before {
	content: "\e964";
}
.icon-Girl:before {
	content: "\e965";
}
.icon-Glass-Water:before {
	content: "\e966";
}
.icon-Glasses-2:before {
	content: "\e967";
}
.icon-Glasses-3:before {
	content: "\e968";
}
.icon-Glasses:before {
	content: "\e969";
}
.icon-Global-Position:before {
	content: "\e96a";
}
.icon-Globe-2:before {
	content: "\e96b";
}
.icon-Globe:before {
	content: "\e96c";
}
.icon-Gloves:before {
	content: "\e96d";
}
.icon-Go-Bottom:before {
	content: "\e96e";
}
.icon-Go-Top:before {
	content: "\e96f";
}
.icon-Goggles:before {
	content: "\e970";
}
.icon-Golf-2:before {
	content: "\e971";
}
.icon-Golf:before {
	content: "\e972";
}
.icon-Google-Buzz:before {
	content: "\e973";
}
.icon-Google-Drive:before {
	content: "\e974";
}
.icon-Google-Play:before {
	content: "\e975";
}
.icon-Google-Plus:before {
	content: "\e976";
}
.icon-Google:before {
	content: "\e977";
}
.icon-Gopro:before {
	content: "\e978";
}
.icon-Gorilla:before {
	content: "\e979";
}
.icon-Gowalla:before {
	content: "\e97a";
}
.icon-Grave:before {
	content: "\e97b";
}
.icon-Graveyard:before {
	content: "\e97c";
}
.icon-Greece:before {
	content: "\e97d";
}
.icon-Green-Energy:before {
	content: "\e97e";
}
.icon-Green-House:before {
	content: "\e97f";
}
.icon-Guitar:before {
	content: "\e980";
}
.icon-Gun-2:before {
	content: "\e981";
}
.icon-Gun-3:before {
	content: "\e982";
}
.icon-Gun:before {
	content: "\e983";
}
.icon-Gymnastics:before {
	content: "\e984";
}
.icon-Hair-2:before {
	content: "\e985";
}
.icon-Hair-3:before {
	content: "\e986";
}
.icon-Hair-4:before {
	content: "\e987";
}
.icon-Hair:before {
	content: "\e988";
}
.icon-Half-Moon:before {
	content: "\e989";
}
.icon-Halloween-HalfMoon:before {
	content: "\e98a";
}
.icon-Halloween-Moon:before {
	content: "\e98b";
}
.icon-Hamburger:before {
	content: "\e98c";
}
.icon-Hammer:before {
	content: "\e98d";
}
.icon-Hand-Touch:before {
	content: "\e98e";
}
.icon-Hand-Touch2:before {
	content: "\e98f";
}
.icon-Hand-TouchSmartphone:before {
	content: "\e990";
}
.icon-Hand:before {
	content: "\e991";
}
.icon-Hands:before {
	content: "\e992";
}
.icon-Handshake:before {
	content: "\e993";
}
.icon-Hanger:before {
	content: "\e994";
}
.icon-Happy:before {
	content: "\e995";
}
.icon-Hat-2:before {
	content: "\e996";
}
.icon-Hat:before {
	content: "\e997";
}
.icon-Haunted-House:before {
	content: "\e998";
}
.icon-HD-Video:before {
	content: "\e999";
}
.icon-HD:before {
	content: "\e99a";
}
.icon-HDD:before {
	content: "\e99b";
}
.icon-Headphone:before {
	content: "\e99c";
}
.icon-Headphones:before {
	content: "\e99d";
}
.icon-Headset:before {
	content: "\e99e";
}
.icon-Heart-2:before {
	content: "\e99f";
}
.icon-Heart:before {
	content: "\e9a0";
}
.icon-Heels-2:before {
	content: "\e9a1";
}
.icon-Heels:before {
	content: "\e9a2";
}
.icon-Height-Window:before {
	content: "\e9a3";
}
.icon-Helicopter-2:before {
	content: "\e9a4";
}
.icon-Helicopter:before {
	content: "\e9a5";
}
.icon-Helix-2:before {
	content: "\e9a6";
}
.icon-Hello:before {
	content: "\e9a7";
}
.icon-Helmet-2:before {
	content: "\e9a8";
}
.icon-Helmet-3:before {
	content: "\e9a9";
}
.icon-Helmet:before {
	content: "\e9aa";
}
.icon-Hipo:before {
	content: "\e9ab";
}
.icon-Hipster-Glasses:before {
	content: "\e9ac";
}
.icon-Hipster-Glasses2:before {
	content: "\e9ad";
}
.icon-Hipster-Glasses3:before {
	content: "\e9ae";
}
.icon-Hipster-Headphones:before {
	content: "\e9af";
}
.icon-Hipster-Men:before {
	content: "\e9b0";
}
.icon-Hipster-Men2:before {
	content: "\e9b1";
}
.icon-Hipster-Men3:before {
	content: "\e9b2";
}
.icon-Hipster-Sunglasses:before {
	content: "\e9b3";
}
.icon-Hipster-Sunglasses2:before {
	content: "\e9b4";
}
.icon-Hipster-Sunglasses3:before {
	content: "\e9b5";
}
.icon-Hokey:before {
	content: "\e9b6";
}
.icon-Holly:before {
	content: "\e9b7";
}
.icon-Home-2:before {
	content: "\e9b8";
}
.icon-Home-3:before {
	content: "\e9b9";
}
.icon-Home-4:before {
	content: "\e9ba";
}
.icon-Home-5:before {
	content: "\e9bb";
}
.icon-Home-Window:before {
	content: "\e9bc";
}
.icon-Home:before {
	content: "\e9bd";
}
.icon-Homosexual:before {
	content: "\e9be";
}
.icon-Honey:before {
	content: "\e9bf";
}
.icon-Hong-Kong:before {
	content: "\e9c0";
}
.icon-Hoodie:before {
	content: "\e9c1";
}
.icon-Horror:before {
	content: "\e9c2";
}
.icon-Horse:before {
	content: "\e9c3";
}
.icon-Hospital-2:before {
	content: "\e9c4";
}
.icon-Hospital:before {
	content: "\e9c5";
}
.icon-Host:before {
	content: "\e9c6";
}
.icon-Hot-Dog:before {
	content: "\e9c7";
}
.icon-Hotel:before {
	content: "\e9c8";
}
.icon-Hour:before {
	content: "\e9c9";
}
.icon-Hub:before {
	content: "\e9ca";
}
.icon-Humor:before {
	content: "\e9cb";
}
.icon-Hurt:before {
	content: "\e9cc";
}
.icon-Ice-Cream:before {
	content: "\e9cd";
}
.icon-ICQ:before {
	content: "\e9ce";
}
.icon-ID-2:before {
	content: "\e9cf";
}
.icon-ID-3:before {
	content: "\e9d0";
}
.icon-ID-Card:before {
	content: "\e9d1";
}
.icon-Idea-2:before {
	content: "\e9d2";
}
.icon-Idea-3:before {
	content: "\e9d3";
}
.icon-Idea-4:before {
	content: "\e9d4";
}
.icon-Idea-5:before {
	content: "\e9d5";
}
.icon-Idea:before {
	content: "\e9d6";
}
.icon-Identification-Badge:before {
	content: "\e9d7";
}
.icon-ImDB:before {
	content: "\e9d8";
}
.icon-Inbox-Empty:before {
	content: "\e9d9";
}
.icon-Inbox-Forward:before {
	content: "\e9da";
}
.icon-Inbox-Full:before {
	content: "\e9db";
}
.icon-Inbox-Into:before {
	content: "\e9dc";
}
.icon-Inbox-Out:before {
	content: "\e9dd";
}
.icon-Inbox-Reply:before {
	content: "\e9de";
}
.icon-Inbox:before {
	content: "\e9df";
}
.icon-Increase-Inedit:before {
	content: "\e9e0";
}
.icon-Indent-FirstLine:before {
	content: "\e9e1";
}
.icon-Indent-LeftMargin:before {
	content: "\e9e2";
}
.icon-Indent-RightMargin:before {
	content: "\e9e3";
}
.icon-India:before {
	content: "\e9e4";
}
.icon-Info-Window:before {
	content: "\e9e5";
}
.icon-Information:before {
	content: "\e9e6";
}
.icon-Inifity:before {
	content: "\e9e7";
}
.icon-Instagram:before {
	content: "\e9e8";
}
.icon-Internet-2:before {
	content: "\e9e9";
}
.icon-Internet-Explorer:before {
	content: "\e9ea";
}
.icon-Internet-Smiley:before {
	content: "\e9eb";
}
.icon-Internet:before {
	content: "\e9ec";
}
.icon-iOS-Apple:before {
	content: "\e9ed";
}
.icon-Israel:before {
	content: "\e9ee";
}
.icon-Italic-Text:before {
	content: "\e9ef";
}
.icon-Jacket-2:before {
	content: "\e9f0";
}
.icon-Jacket:before {
	content: "\e9f1";
}
.icon-Jamaica:before {
	content: "\e9f2";
}
.icon-Japan:before {
	content: "\e9f3";
}
.icon-Japanese-Gate:before {
	content: "\e9f4";
}
.icon-Jeans:before {
	content: "\e9f5";
}
.icon-Jeep-2:before {
	content: "\e9f6";
}
.icon-Jeep:before {
	content: "\e9f7";
}
.icon-Jet:before {
	content: "\e9f8";
}
.icon-Joystick:before {
	content: "\e9f9";
}
.icon-Juice:before {
	content: "\e9fa";
}
.icon-Jump-Rope:before {
	content: "\e9fb";
}
.icon-Kangoroo:before {
	content: "\e9fc";
}
.icon-Kenya:before {
	content: "\e9fd";
}
.icon-Key-2:before {
	content: "\e9fe";
}
.icon-Key-3:before {
	content: "\e9ff";
}
.icon-Key-Lock:before {
	content: "\ea00";
}
.icon-Key:before {
	content: "\ea01";
}
.icon-Keyboard:before {
	content: "\ea02";
}
.icon-Keyboard3:before {
	content: "\ea03";
}
.icon-Keypad:before {
	content: "\ea04";
}
.icon-King-2:before {
	content: "\ea05";
}
.icon-King:before {
	content: "\ea06";
}
.icon-Kiss:before {
	content: "\ea07";
}
.icon-Knee:before {
	content: "\ea08";
}
.icon-Knife-2:before {
	content: "\ea09";
}
.icon-Knife:before {
	content: "\ea0a";
}
.icon-Knight:before {
	content: "\ea0b";
}
.icon-Koala:before {
	content: "\ea0c";
}
.icon-Korea:before {
	content: "\ea0d";
}
.icon-Lamp:before {
	content: "\ea0e";
}
.icon-Landscape-2:before {
	content: "\ea0f";
}
.icon-Landscape:before {
	content: "\ea10";
}
.icon-Lantern:before {
	content: "\ea11";
}
.icon-Laptop-2:before {
	content: "\ea12";
}
.icon-Laptop-3:before {
	content: "\ea13";
}
.icon-Laptop-Phone:before {
	content: "\ea14";
}
.icon-Laptop-Secure:before {
	content: "\ea15";
}
.icon-Laptop-Tablet:before {
	content: "\ea16";
}
.icon-Laptop:before {
	content: "\ea17";
}
.icon-Laser:before {
	content: "\ea18";
}
.icon-Last-FM:before {
	content: "\ea19";
}
.icon-Last:before {
	content: "\ea1a";
}
.icon-Laughing:before {
	content: "\ea1b";
}
.icon-Layer-1635:before {
	content: "\ea1c";
}
.icon-Layer-1646:before {
	content: "\ea1d";
}
.icon-Layer-Backward:before {
	content: "\ea1e";
}
.icon-Layer-Forward:before {
	content: "\ea1f";
}
.icon-Leafs-2:before {
	content: "\ea20";
}
.icon-Leafs:before {
	content: "\ea21";
}
.icon-Leaning-Tower:before {
	content: "\ea22";
}
.icon-Left--Right:before {
	content: "\ea23";
}
.icon-Left--Right3:before {
	content: "\ea24";
}
.icon-Left-2:before {
	content: "\ea25";
}
.icon-Left-3:before {
	content: "\ea26";
}
.icon-Left-4:before {
	content: "\ea27";
}
.icon-Left-ToRight:before {
	content: "\ea28";
}
.icon-Left:before {
	content: "\ea29";
}
.icon-Leg-2:before {
	content: "\ea2a";
}
.icon-Leg:before {
	content: "\ea2b";
}
.icon-Lego:before {
	content: "\ea2c";
}
.icon-Lemon:before {
	content: "\ea2d";
}
.icon-Len-2:before {
	content: "\ea2e";
}
.icon-Len-3:before {
	content: "\ea2f";
}
.icon-Len:before {
	content: "\ea30";
}
.icon-Leo-2:before {
	content: "\ea31";
}
.icon-Leo:before {
	content: "\ea32";
}
.icon-Leopard:before {
	content: "\ea33";
}
.icon-Lesbian:before {
	content: "\ea34";
}
.icon-Lesbians:before {
	content: "\ea35";
}
.icon-Letter-Close:before {
	content: "\ea36";
}
.icon-Letter-Open:before {
	content: "\ea37";
}
.icon-Letter-Sent:before {
	content: "\ea38";
}
.icon-Libra-2:before {
	content: "\ea39";
}
.icon-Libra:before {
	content: "\ea3a";
}
.icon-Library-2:before {
	content: "\ea3b";
}
.icon-Library:before {
	content: "\ea3c";
}
.icon-Life-Jacket:before {
	content: "\ea3d";
}
.icon-Life-Safer:before {
	content: "\ea3e";
}
.icon-Light-Bulb:before {
	content: "\ea3f";
}
.icon-Light-Bulb2:before {
	content: "\ea40";
}
.icon-Light-BulbLeaf:before {
	content: "\ea41";
}
.icon-Lighthouse:before {
	content: "\ea42";
}
.icon-Like-2:before {
	content: "\ea43";
}
.icon-Like:before {
	content: "\ea44";
}
.icon-Line-Chart:before {
	content: "\ea45";
}
.icon-Line-Chart2:before {
	content: "\ea46";
}
.icon-Line-Chart3:before {
	content: "\ea47";
}
.icon-Line-Chart4:before {
	content: "\ea48";
}
.icon-Line-Spacing:before {
	content: "\ea49";
}
.icon-Line-SpacingText:before {
	content: "\ea4a";
}
.icon-Link-2:before {
	content: "\ea4b";
}
.icon-Link:before {
	content: "\ea4c";
}
.icon-Linkedin-2:before {
	content: "\ea4d";
}
.icon-Linkedin:before {
	content: "\ea4e";
}
.icon-Linux:before {
	content: "\ea4f";
}
.icon-Lion:before {
	content: "\ea50";
}
.icon-Livejournal:before {
	content: "\ea51";
}
.icon-Loading-2:before {
	content: "\ea52";
}
.icon-Loading-3:before {
	content: "\ea53";
}
.icon-Loading-Window:before {
	content: "\ea54";
}
.icon-Loading:before {
	content: "\ea55";
}
.icon-Location-2:before {
	content: "\ea56";
}
.icon-Location:before {
	content: "\ea57";
}
.icon-Lock-2:before {
	content: "\ea58";
}
.icon-Lock-3:before {
	content: "\ea59";
}
.icon-Lock-User:before {
	content: "\ea5a";
}
.icon-Lock-Window:before {
	content: "\ea5b";
}
.icon-Lock:before {
	content: "\ea5c";
}
.icon-Lollipop-2:before {
	content: "\ea5d";
}
.icon-Lollipop-3:before {
	content: "\ea5e";
}
.icon-Lollipop:before {
	content: "\ea5f";
}
.icon-Loop:before {
	content: "\ea60";
}
.icon-Loud:before {
	content: "\ea61";
}
.icon-Loudspeaker:before {
	content: "\ea62";
}
.icon-Love-2:before {
	content: "\ea63";
}
.icon-Love-User:before {
	content: "\ea64";
}
.icon-Love-Window:before {
	content: "\ea65";
}
.icon-Love:before {
	content: "\ea66";
}
.icon-Lowercase-Text:before {
	content: "\ea67";
}
.icon-Luggafe-Front:before {
	content: "\ea68";
}
.icon-Luggage-2:before {
	content: "\ea69";
}
.icon-Macro:before {
	content: "\ea6a";
}
.icon-Magic-Wand:before {
	content: "\ea6b";
}
.icon-Magnet:before {
	content: "\ea6c";
}
.icon-Magnifi-Glass-:before {
	content: "\ea6d";
}
.icon-Magnifi-Glass:before {
	content: "\ea6e";
}
.icon-Magnifi-Glass2:before {
	content: "\ea6f";
}
.icon-Mail-2:before {
	content: "\ea70";
}
.icon-Mail-3:before {
	content: "\ea71";
}
.icon-Mail-Add:before {
	content: "\ea72";
}
.icon-Mail-Attachement:before {
	content: "\ea73";
}
.icon-Mail-Block:before {
	content: "\ea74";
}
.icon-Mail-Delete:before {
	content: "\ea75";
}
.icon-Mail-Favorite:before {
	content: "\ea76";
}
.icon-Mail-Forward:before {
	content: "\ea77";
}
.icon-Mail-Gallery:before {
	content: "\ea78";
}
.icon-Mail-Inbox:before {
	content: "\ea79";
}
.icon-Mail-Link:before {
	content: "\ea7a";
}
.icon-Mail-Lock:before {
	content: "\ea7b";
}
.icon-Mail-Love:before {
	content: "\ea7c";
}
.icon-Mail-Money:before {
	content: "\ea7d";
}
.icon-Mail-Open:before {
	content: "\ea7e";
}
.icon-Mail-Outbox:before {
	content: "\ea7f";
}
.icon-Mail-Password:before {
	content: "\ea80";
}
.icon-Mail-Photo:before {
	content: "\ea81";
}
.icon-Mail-Read:before {
	content: "\ea82";
}
.icon-Mail-Removex:before {
	content: "\ea83";
}
.icon-Mail-Reply:before {
	content: "\ea84";
}
.icon-Mail-ReplyAll:before {
	content: "\ea85";
}
.icon-Mail-Search:before {
	content: "\ea86";
}
.icon-Mail-Send:before {
	content: "\ea87";
}
.icon-Mail-Settings:before {
	content: "\ea88";
}
.icon-Mail-Unread:before {
	content: "\ea89";
}
.icon-Mail-Video:before {
	content: "\ea8a";
}
.icon-Mail-withAtSign:before {
	content: "\ea8b";
}
.icon-Mail-WithCursors:before {
	content: "\ea8c";
}
.icon-Mail:before {
	content: "\ea8d";
}
.icon-Mailbox-Empty:before {
	content: "\ea8e";
}
.icon-Mailbox-Full:before {
	content: "\ea8f";
}
.icon-Male-2:before {
	content: "\ea90";
}
.icon-Male-Sign:before {
	content: "\ea91";
}
.icon-Male:before {
	content: "\ea92";
}
.icon-MaleFemale:before {
	content: "\ea93";
}
.icon-Man-Sign:before {
	content: "\ea94";
}
.icon-Management:before {
	content: "\ea95";
}
.icon-Mans-Underwear:before {
	content: "\ea96";
}
.icon-Mans-Underwear2:before {
	content: "\ea97";
}
.icon-Map-Marker:before {
	content: "\ea98";
}
.icon-Map-Marker2:before {
	content: "\ea99";
}
.icon-Map-Marker3:before {
	content: "\ea9a";
}
.icon-Map:before {
	content: "\ea9b";
}
.icon-Map2:before {
	content: "\ea9c";
}
.icon-Marker-2:before {
	content: "\ea9d";
}
.icon-Marker-3:before {
	content: "\ea9e";
}
.icon-Marker:before {
	content: "\ea9f";
}
.icon-Martini-Glass:before {
	content: "\eaa0";
}
.icon-Mask:before {
	content: "\eaa1";
}
.icon-Master-Card:before {
	content: "\eaa2";
}
.icon-Maximize-Window:before {
	content: "\eaa3";
}
.icon-Maximize:before {
	content: "\eaa4";
}
.icon-Medal-2:before {
	content: "\eaa5";
}
.icon-Medal-3:before {
	content: "\eaa6";
}
.icon-Medal:before {
	content: "\eaa7";
}
.icon-Medical-Sign:before {
	content: "\eaa8";
}
.icon-Medicine-2:before {
	content: "\eaa9";
}
.icon-Medicine-3:before {
	content: "\eaaa";
}
.icon-Medicine:before {
	content: "\eaab";
}
.icon-Megaphone:before {
	content: "\eaac";
}
.icon-Memory-Card:before {
	content: "\eaad";
}
.icon-Memory-Card2:before {
	content: "\eaae";
}
.icon-Memory-Card3:before {
	content: "\eaaf";
}
.icon-Men:before {
	content: "\eab0";
}
.icon-Menorah:before {
	content: "\eab1";
}
.icon-Mens:before {
	content: "\eab2";
}
.icon-Metacafe:before {
	content: "\eab3";
}
.icon-Mexico:before {
	content: "\eab4";
}
.icon-Mic:before {
	content: "\eab5";
}
.icon-Microphone-2:before {
	content: "\eab6";
}
.icon-Microphone-3:before {
	content: "\eab7";
}
.icon-Microphone-4:before {
	content: "\eab8";
}
.icon-Microphone-5:before {
	content: "\eab9";
}
.icon-Microphone-6:before {
	content: "\eaba";
}
.icon-Microphone-7:before {
	content: "\eabb";
}
.icon-Microphone:before {
	content: "\eabc";
}
.icon-Microscope:before {
	content: "\eabd";
}
.icon-Milk-Bottle:before {
	content: "\eabe";
}
.icon-Mine:before {
	content: "\eabf";
}
.icon-Minimize-Maximize-Close-Window:before {
	content: "\eac0";
}
.icon-Minimize-Window:before {
	content: "\eac1";
}
.icon-Minimize:before {
	content: "\eac2";
}
.icon-Mirror:before {
	content: "\eac3";
}
.icon-Mixer:before {
	content: "\eac4";
}
.icon-Mixx:before {
	content: "\eac5";
}
.icon-Money-2:before {
	content: "\eac6";
}
.icon-Money-Bag:before {
	content: "\eac7";
}
.icon-Money-Smiley:before {
	content: "\eac8";
}
.icon-Money:before {
	content: "\eac9";
}
.icon-Monitor-2:before {
	content: "\eaca";
}
.icon-Monitor-3:before {
	content: "\eacb";
}
.icon-Monitor-4:before {
	content: "\eacc";
}
.icon-Monitor-5:before {
	content: "\eacd";
}
.icon-Monitor-Analytics:before {
	content: "\eace";
}
.icon-Monitor-Laptop:before {
	content: "\eacf";
}
.icon-Monitor-phone:before {
	content: "\ead0";
}
.icon-Monitor-Tablet:before {
	content: "\ead1";
}
.icon-Monitor-Vertical:before {
	content: "\ead2";
}
.icon-Monitor:before {
	content: "\ead3";
}
.icon-Monitoring:before {
	content: "\ead4";
}
.icon-Monkey:before {
	content: "\ead5";
}
.icon-Monster:before {
	content: "\ead6";
}
.icon-Morocco:before {
	content: "\ead7";
}
.icon-Motorcycle:before {
	content: "\ead8";
}
.icon-Mouse-2:before {
	content: "\ead9";
}
.icon-Mouse-3:before {
	content: "\eada";
}
.icon-Mouse-4:before {
	content: "\eadb";
}
.icon-Mouse-Pointer:before {
	content: "\eadc";
}
.icon-Mouse:before {
	content: "\eadd";
}
.icon-Moustache-Smiley:before {
	content: "\eade";
}
.icon-Movie-Ticket:before {
	content: "\eadf";
}
.icon-Movie:before {
	content: "\eae0";
}
.icon-Mp3-File:before {
	content: "\eae1";
}
.icon-Museum:before {
	content: "\eae2";
}
.icon-Mushroom:before {
	content: "\eae3";
}
.icon-Music-Note:before {
	content: "\eae4";
}
.icon-Music-Note2:before {
	content: "\eae5";
}
.icon-Music-Note3:before {
	content: "\eae6";
}
.icon-Music-Note4:before {
	content: "\eae7";
}
.icon-Music-Player:before {
	content: "\eae8";
}
.icon-Mustache-2:before {
	content: "\eae9";
}
.icon-Mustache-3:before {
	content: "\eaea";
}
.icon-Mustache-4:before {
	content: "\eaeb";
}
.icon-Mustache-5:before {
	content: "\eaec";
}
.icon-Mustache-6:before {
	content: "\eaed";
}
.icon-Mustache-7:before {
	content: "\eaee";
}
.icon-Mustache-8:before {
	content: "\eaef";
}
.icon-Mustache:before {
	content: "\eaf0";
}
.icon-Mute:before {
	content: "\eaf1";
}
.icon-Myspace:before {
	content: "\eaf2";
}
.icon-Navigat-Start:before {
	content: "\eaf3";
}
.icon-Navigate-End:before {
	content: "\eaf4";
}
.icon-Navigation-LeftWindow:before {
	content: "\eaf5";
}
.icon-Navigation-RightWindow:before {
	content: "\eaf6";
}
.icon-Nepal:before {
	content: "\eaf7";
}
.icon-Netscape:before {
	content: "\eaf8";
}
.icon-Network-Window:before {
	content: "\eaf9";
}
.icon-Network:before {
	content: "\eafa";
}
.icon-Neutron:before {
	content: "\eafb";
}
.icon-New-Mail:before {
	content: "\eafc";
}
.icon-New-Tab:before {
	content: "\eafd";
}
.icon-Newspaper-2:before {
	content: "\eafe";
}
.icon-Newspaper:before {
	content: "\eaff";
}
.icon-Newsvine:before {
	content: "\eb00";
}
.icon-Next2:before {
	content: "\eb01";
}
.icon-Next-3:before {
	content: "\eb02";
}
.icon-Next-Music:before {
	content: "\eb03";
}
.icon-Next:before {
	content: "\eb04";
}
.icon-No-Battery:before {
	content: "\eb05";
}
.icon-No-Drop:before {
	content: "\eb06";
}
.icon-No-Flash:before {
	content: "\eb07";
}
.icon-No-Smoking:before {
	content: "\eb08";
}
.icon-Noose:before {
	content: "\eb09";
}
.icon-Normal-Text:before {
	content: "\eb0a";
}
.icon-Note:before {
	content: "\eb0b";
}
.icon-Notepad-2:before {
	content: "\eb0c";
}
.icon-Notepad:before {
	content: "\eb0d";
}
.icon-Nuclear:before {
	content: "\eb0e";
}
.icon-Numbering-List:before {
	content: "\eb0f";
}
.icon-Nurse:before {
	content: "\eb10";
}
.icon-Office-Lamp:before {
	content: "\eb11";
}
.icon-Office:before {
	content: "\eb12";
}
.icon-Oil:before {
	content: "\eb13";
}
.icon-Old-Camera:before {
	content: "\eb14";
}
.icon-Old-Cassette:before {
	content: "\eb15";
}
.icon-Old-Clock:before {
	content: "\eb16";
}
.icon-Old-Radio:before {
	content: "\eb17";
}
.icon-Old-Sticky:before {
	content: "\eb18";
}
.icon-Old-Sticky2:before {
	content: "\eb19";
}
.icon-Old-Telephone:before {
	content: "\eb1a";
}
.icon-Old-TV:before {
	content: "\eb1b";
}
.icon-On-Air:before {
	content: "\eb1c";
}
.icon-On-Off-2:before {
	content: "\eb1d";
}
.icon-On-Off-3:before {
	content: "\eb1e";
}
.icon-On-off:before {
	content: "\eb1f";
}
.icon-One-Finger:before {
	content: "\eb20";
}
.icon-One-FingerTouch:before {
	content: "\eb21";
}
.icon-One-Window:before {
	content: "\eb22";
}
.icon-Open-Banana:before {
	content: "\eb23";
}
.icon-Open-Book:before {
	content: "\eb24";
}
.icon-Opera-House:before {
	content: "\eb25";
}
.icon-Opera:before {
	content: "\eb26";
}
.icon-Optimization:before {
	content: "\eb27";
}
.icon-Orientation-2:before {
	content: "\eb28";
}
.icon-Orientation-3:before {
	content: "\eb29";
}
.icon-Orientation:before {
	content: "\eb2a";
}
.icon-Orkut:before {
	content: "\eb2b";
}
.icon-Ornament:before {
	content: "\eb2c";
}
.icon-Over-Time:before {
	content: "\eb2d";
}
.icon-Over-Time2:before {
	content: "\eb2e";
}
.icon-Owl:before {
	content: "\eb2f";
}
.icon-Pac-Man:before {
	content: "\eb30";
}
.icon-Paint-Brush:before {
	content: "\eb31";
}
.icon-Paint-Bucket:before {
	content: "\eb32";
}
.icon-Paintbrush:before {
	content: "\eb33";
}
.icon-Palette:before {
	content: "\eb34";
}
.icon-Palm-Tree:before {
	content: "\eb35";
}
.icon-Panda:before {
	content: "\eb36";
}
.icon-Panorama:before {
	content: "\eb37";
}
.icon-Pantheon:before {
	content: "\eb38";
}
.icon-Pantone:before {
	content: "\eb39";
}
.icon-Pants:before {
	content: "\eb3a";
}
.icon-Paper-Plane:before {
	content: "\eb3b";
}
.icon-Paper:before {
	content: "\eb3c";
}
.icon-Parasailing:before {
	content: "\eb3d";
}
.icon-Parrot:before {
	content: "\eb3e";
}
.icon-Password-2shopping:before {
	content: "\eb3f";
}
.icon-Password-Field:before {
	content: "\eb40";
}
.icon-Password-shopping:before {
	content: "\eb41";
}
.icon-Password:before {
	content: "\eb42";
}
.icon-pause-2:before {
	content: "\eb43";
}
.icon-Pause:before {
	content: "\eb44";
}
.icon-Paw:before {
	content: "\eb45";
}
.icon-Pawn:before {
	content: "\eb46";
}
.icon-Paypal:before {
	content: "\eb47";
}
.icon-Pen-2:before {
	content: "\eb48";
}
.icon-Pen-3:before {
	content: "\eb49";
}
.icon-Pen-4:before {
	content: "\eb4a";
}
.icon-Pen-5:before {
	content: "\eb4b";
}
.icon-Pen-6:before {
	content: "\eb4c";
}
.icon-Pen:before {
	content: "\eb4d";
}
.icon-Pencil-Ruler:before {
	content: "\eb4e";
}
.icon-Pencil:before {
	content: "\eb4f";
}
.icon-Penguin:before {
	content: "\eb50";
}
.icon-Pentagon:before {
	content: "\eb51";
}
.icon-People-onCloud:before {
	content: "\eb52";
}
.icon-Pepper-withFire:before {
	content: "\eb53";
}
.icon-Pepper:before {
	content: "\eb54";
}
.icon-Petrol:before {
	content: "\eb55";
}
.icon-Petronas-Tower:before {
	content: "\eb56";
}
.icon-Philipines:before {
	content: "\eb57";
}
.icon-Phone-2:before {
	content: "\eb58";
}
.icon-Phone-3:before {
	content: "\eb59";
}
.icon-Phone-3G:before {
	content: "\eb5a";
}
.icon-Phone-4G:before {
	content: "\eb5b";
}
.icon-Phone-Simcard:before {
	content: "\eb5c";
}
.icon-Phone-SMS:before {
	content: "\eb5d";
}
.icon-Phone-Wifi:before {
	content: "\eb5e";
}
.icon-Phone:before {
	content: "\eb5f";
}
.icon-Photo-2:before {
	content: "\eb60";
}
.icon-Photo-3:before {
	content: "\eb61";
}
.icon-Photo-Album:before {
	content: "\eb62";
}
.icon-Photo-Album2:before {
	content: "\eb63";
}
.icon-Photo-Album3:before {
	content: "\eb64";
}
.icon-Photo:before {
	content: "\eb65";
}
.icon-Photos:before {
	content: "\eb66";
}
.icon-Physics:before {
	content: "\eb67";
}
.icon-Pi:before {
	content: "\eb68";
}
.icon-Piano:before {
	content: "\eb69";
}
.icon-Picasa:before {
	content: "\eb6a";
}
.icon-Pie-Chart:before {
	content: "\eb6b";
}
.icon-Pie-Chart2:before {
	content: "\eb6c";
}
.icon-Pie-Chart3:before {
	content: "\eb6d";
}
.icon-Pilates-2:before {
	content: "\eb6e";
}
.icon-Pilates-3:before {
	content: "\eb6f";
}
.icon-Pilates:before {
	content: "\eb70";
}
.icon-Pilot:before {
	content: "\eb71";
}
.icon-Pinch:before {
	content: "\eb72";
}
.icon-Ping-Pong:before {
	content: "\eb73";
}
.icon-Pinterest:before {
	content: "\eb74";
}
.icon-Pipe:before {
	content: "\eb75";
}
.icon-Pipette:before {
	content: "\eb76";
}
.icon-Piramids:before {
	content: "\eb77";
}
.icon-Pisces-2:before {
	content: "\eb78";
}
.icon-Pisces:before {
	content: "\eb79";
}
.icon-Pizza-Slice:before {
	content: "\eb7a";
}
.icon-Pizza:before {
	content: "\eb7b";
}
.icon-Plane-2:before {
	content: "\eb7c";
}
.icon-Plane:before {
	content: "\eb7d";
}
.icon-Plant:before {
	content: "\eb7e";
}
.icon-Plasmid:before {
	content: "\eb7f";
}
.icon-Plaster:before {
	content: "\eb80";
}
.icon-Plastic-CupPhone:before {
	content: "\eb81";
}
.icon-Plastic-CupPhone2:before {
	content: "\eb82";
}
.icon-Plate:before {
	content: "\eb83";
}
.icon-Plates:before {
	content: "\eb84";
}
.icon-Plaxo:before {
	content: "\eb85";
}
.icon-Play-Music:before {
	content: "\eb86";
}
.icon-Plug-In:before {
	content: "\eb87";
}
.icon-Plug-In2:before {
	content: "\eb88";
}
.icon-Plurk:before {
	content: "\eb89";
}
.icon-Pointer:before {
	content: "\eb8a";
}
.icon-Poland:before {
	content: "\eb8b";
}
.icon-Police-Man:before {
	content: "\eb8c";
}
.icon-Police-Station:before {
	content: "\eb8d";
}
.icon-Police-Woman:before {
	content: "\eb8e";
}
.icon-Police:before {
	content: "\eb8f";
}
.icon-Polo-Shirt:before {
	content: "\eb90";
}
.icon-Portrait:before {
	content: "\eb91";
}
.icon-Portugal:before {
	content: "\eb92";
}
.icon-Post-Mail:before {
	content: "\eb93";
}
.icon-Post-Mail2:before {
	content: "\eb94";
}
.icon-Post-Office:before {
	content: "\eb95";
}
.icon-Post-Sign:before {
	content: "\eb96";
}
.icon-Post-Sign2ways:before {
	content: "\eb97";
}
.icon-Posterous:before {
	content: "\eb98";
}
.icon-Pound-Sign:before {
	content: "\eb99";
}
.icon-Pound-Sign2:before {
	content: "\eb9a";
}
.icon-Pound:before {
	content: "\eb9b";
}
.icon-Power-2:before {
	content: "\eb9c";
}
.icon-Power-3:before {
	content: "\eb9d";
}
.icon-Power-Cable:before {
	content: "\eb9e";
}
.icon-Power-Station:before {
	content: "\eb9f";
}
.icon-Power:before {
	content: "\eba0";
}
.icon-Prater:before {
	content: "\eba1";
}
.icon-Present:before {
	content: "\eba2";
}
.icon-Presents:before {
	content: "\eba3";
}
.icon-Press:before {
	content: "\eba4";
}
.icon-Preview:before {
	content: "\eba5";
}
.icon-Previous:before {
	content: "\eba6";
}
.icon-Pricing:before {
	content: "\eba7";
}
.icon-Printer:before {
	content: "\eba8";
}
.icon-Professor:before {
	content: "\eba9";
}
.icon-Profile:before {
	content: "\ebaa";
}
.icon-Project:before {
	content: "\ebab";
}
.icon-Projector-2:before {
	content: "\ebac";
}
.icon-Projector:before {
	content: "\ebad";
}
.icon-Pulse:before {
	content: "\ebae";
}
.icon-Pumpkin:before {
	content: "\ebaf";
}
.icon-Punk:before {
	content: "\ebb0";
}
.icon-Punker:before {
	content: "\ebb1";
}
.icon-Puzzle:before {
	content: "\ebb2";
}
.icon-QIK:before {
	content: "\ebb3";
}
.icon-QR-Code:before {
	content: "\ebb4";
}
.icon-Queen-2:before {
	content: "\ebb5";
}
.icon-Queen:before {
	content: "\ebb6";
}
.icon-Quill-2:before {
	content: "\ebb7";
}
.icon-Quill-3:before {
	content: "\ebb8";
}
.icon-Quill:before {
	content: "\ebb9";
}
.icon-Quotes-2:before {
	content: "\ebba";
}
.icon-Quotes:before {
	content: "\ebbb";
}
.icon-Radio:before {
	content: "\ebbc";
}
.icon-Radioactive:before {
	content: "\ebbd";
}
.icon-Rafting:before {
	content: "\ebbe";
}
.icon-Rain-Drop:before {
	content: "\ebbf";
}
.icon-Rainbow-2:before {
	content: "\ebc0";
}
.icon-Rainbow:before {
	content: "\ebc1";
}
.icon-Ram:before {
	content: "\ebc2";
}
.icon-Razzor-Blade:before {
	content: "\ebc3";
}
.icon-Receipt-2:before {
	content: "\ebc4";
}
.icon-Receipt-3:before {
	content: "\ebc5";
}
.icon-Receipt-4:before {
	content: "\ebc6";
}
.icon-Receipt:before {
	content: "\ebc7";
}
.icon-Record2:before {
	content: "\ebc8";
}
.icon-Record-3:before {
	content: "\ebc9";
}
.icon-Record-Music:before {
	content: "\ebca";
}
.icon-Record:before {
	content: "\ebcb";
}
.icon-Recycling-2:before {
	content: "\ebcc";
}
.icon-Recycling:before {
	content: "\ebcd";
}
.icon-Reddit:before {
	content: "\ebce";
}
.icon-Redhat:before {
	content: "\ebcf";
}
.icon-Redirect:before {
	content: "\ebd0";
}
.icon-Redo:before {
	content: "\ebd1";
}
.icon-Reel:before {
	content: "\ebd2";
}
.icon-Refinery:before {
	content: "\ebd3";
}
.icon-Refresh-Window:before {
	content: "\ebd4";
}
.icon-Refresh:before {
	content: "\ebd5";
}
.icon-Reload-2:before {
	content: "\ebd6";
}
.icon-Reload-3:before {
	content: "\ebd7";
}
.icon-Reload:before {
	content: "\ebd8";
}
.icon-Remote-Controll:before {
	content: "\ebd9";
}
.icon-Remote-Controll2:before {
	content: "\ebda";
}
.icon-Remove-Bag:before {
	content: "\ebdb";
}
.icon-Remove-Basket:before {
	content: "\ebdc";
}
.icon-Remove-Cart:before {
	content: "\ebdd";
}
.icon-Remove-File:before {
	content: "\ebde";
}
.icon-Remove-User:before {
	content: "\ebdf";
}
.icon-Remove-Window:before {
	content: "\ebe0";
}
.icon-Remove:before {
	content: "\ebe1";
}
.icon-Rename:before {
	content: "\ebe2";
}
.icon-Repair:before {
	content: "\ebe3";
}
.icon-Repeat-2:before {
	content: "\ebe4";
}
.icon-Repeat-3:before {
	content: "\ebe5";
}
.icon-Repeat-4:before {
	content: "\ebe6";
}
.icon-Repeat-5:before {
	content: "\ebe7";
}
.icon-Repeat-6:before {
	content: "\ebe8";
}
.icon-Repeat-7:before {
	content: "\ebe9";
}
.icon-Repeat:before {
	content: "\ebea";
}
.icon-Reset:before {
	content: "\ebeb";
}
.icon-Resize:before {
	content: "\ebec";
}
.icon-Restore-Window:before {
	content: "\ebed";
}
.icon-Retouching:before {
	content: "\ebee";
}
.icon-Retro-Camera:before {
	content: "\ebef";
}
.icon-Retro:before {
	content: "\ebf0";
}
.icon-Retweet:before {
	content: "\ebf1";
}
.icon-Reverbnation:before {
	content: "\ebf2";
}
.icon-Rewind:before {
	content: "\ebf3";
}
.icon-RGB:before {
	content: "\ebf4";
}
.icon-Ribbon-2:before {
	content: "\ebf5";
}
.icon-Ribbon-3:before {
	content: "\ebf6";
}
.icon-Ribbon:before {
	content: "\ebf7";
}
.icon-Right-2:before {
	content: "\ebf8";
}
.icon-Right-3:before {
	content: "\ebf9";
}
.icon-Right-4:before {
	content: "\ebfa";
}
.icon-Right-ToLeft:before {
	content: "\ebfb";
}
.icon-Right:before {
	content: "\ebfc";
}
.icon-Road-2:before {
	content: "\ebfd";
}
.icon-Road-3:before {
	content: "\ebfe";
}
.icon-Road:before {
	content: "\ebff";
}
.icon-Robot-2:before {
	content: "\ec00";
}
.icon-Robot:before {
	content: "\ec01";
}
.icon-Rock-andRoll:before {
	content: "\ec02";
}
.icon-Rocket:before {
	content: "\ec03";
}
.icon-Roller:before {
	content: "\ec04";
}
.icon-Roof:before {
	content: "\ec05";
}
.icon-Rook:before {
	content: "\ec06";
}
.icon-Rotate-Gesture:before {
	content: "\ec07";
}
.icon-Rotate-Gesture2:before {
	content: "\ec08";
}
.icon-Rotate-Gesture3:before {
	content: "\ec09";
}
.icon-Rotation-390:before {
	content: "\ec0a";
}
.icon-Rotation:before {
	content: "\ec0b";
}
.icon-Router-2:before {
	content: "\ec0c";
}
.icon-Router:before {
	content: "\ec0d";
}
.icon-RSS:before {
	content: "\ec0e";
}
.icon-Ruler-2:before {
	content: "\ec0f";
}
.icon-Ruler:before {
	content: "\ec10";
}
.icon-Running-Shoes:before {
	content: "\ec11";
}
.icon-Running:before {
	content: "\ec12";
}
.icon-Safari:before {
	content: "\ec13";
}
.icon-Safe-Box:before {
	content: "\ec14";
}
.icon-Safe-Box2:before {
	content: "\ec15";
}
.icon-Safety-PinClose:before {
	content: "\ec16";
}
.icon-Safety-PinOpen:before {
	content: "\ec17";
}
.icon-Sagittarus-2:before {
	content: "\ec18";
}
.icon-Sagittarus:before {
	content: "\ec19";
}
.icon-Sailing-Ship:before {
	content: "\ec1a";
}
.icon-Sand-watch:before {
	content: "\ec1b";
}
.icon-Sand-watch2:before {
	content: "\ec1c";
}
.icon-Santa-Claus:before {
	content: "\ec1d";
}
.icon-Santa-Claus2:before {
	content: "\ec1e";
}
.icon-Santa-onSled:before {
	content: "\ec1f";
}
.icon-Satelite-2:before {
	content: "\ec20";
}
.icon-Satelite:before {
	content: "\ec21";
}
.icon-Save-Window:before {
	content: "\ec22";
}
.icon-Save:before {
	content: "\ec23";
}
.icon-Saw:before {
	content: "\ec24";
}
.icon-Saxophone:before {
	content: "\ec25";
}
.icon-Scale:before {
	content: "\ec26";
}
.icon-Scarf:before {
	content: "\ec27";
}
.icon-Scissor:before {
	content: "\ec28";
}
.icon-Scooter-Front:before {
	content: "\ec29";
}
.icon-Scooter:before {
	content: "\ec2a";
}
.icon-Scorpio-2:before {
	content: "\ec2b";
}
.icon-Scorpio:before {
	content: "\ec2c";
}
.icon-Scotland:before {
	content: "\ec2d";
}
.icon-Screwdriver:before {
	content: "\ec2e";
}
.icon-Scroll-Fast:before {
	content: "\ec2f";
}
.icon-Scroll:before {
	content: "\ec30";
}
.icon-Scroller-2:before {
	content: "\ec31";
}
.icon-Scroller:before {
	content: "\ec32";
}
.icon-Sea-Dog:before {
	content: "\ec33";
}
.icon-Search-onCloud:before {
	content: "\ec34";
}
.icon-Search-People:before {
	content: "\ec35";
}
.icon-secound:before {
	content: "\ec36";
}
.icon-secound2:before {
	content: "\ec37";
}
.icon-Security-Block:before {
	content: "\ec38";
}
.icon-Security-Bug:before {
	content: "\ec39";
}
.icon-Security-Camera:before {
	content: "\ec3a";
}
.icon-Security-Check:before {
	content: "\ec3b";
}
.icon-Security-Settings:before {
	content: "\ec3c";
}
.icon-Security-Smiley:before {
	content: "\ec3d";
}
.icon-Securiy-Remove:before {
	content: "\ec3e";
}
.icon-Seed:before {
	content: "\ec3f";
}
.icon-Selfie:before {
	content: "\ec40";
}
.icon-Serbia:before {
	content: "\ec41";
}
.icon-Server-2:before {
	content: "\ec42";
}
.icon-Server:before {
	content: "\ec43";
}
.icon-Servers:before {
	content: "\ec44";
}
.icon-Settings-Window:before {
	content: "\ec45";
}
.icon-Sewing-Machine:before {
	content: "\ec46";
}
.icon-Sexual:before {
	content: "\ec47";
}
.icon-Share-onCloud:before {
	content: "\ec48";
}
.icon-Share-Window:before {
	content: "\ec49";
}
.icon-Share:before {
	content: "\ec4a";
}
.icon-Sharethis:before {
	content: "\ec4b";
}
.icon-Shark:before {
	content: "\ec4c";
}
.icon-Sheep:before {
	content: "\ec4d";
}
.icon-Sheriff-Badge:before {
	content: "\ec4e";
}
.icon-Shield:before {
	content: "\ec4f";
}
.icon-Ship-2:before {
	content: "\ec50";
}
.icon-Ship:before {
	content: "\ec51";
}
.icon-Shirt:before {
	content: "\ec52";
}
.icon-Shoes-2:before {
	content: "\ec53";
}
.icon-Shoes-3:before {
	content: "\ec54";
}
.icon-Shoes:before {
	content: "\ec55";
}
.icon-Shop-2:before {
	content: "\ec56";
}
.icon-Shop-3:before {
	content: "\ec57";
}
.icon-Shop-4:before {
	content: "\ec58";
}
.icon-Shop:before {
	content: "\ec59";
}
.icon-Shopping-Bag:before {
	content: "\ec5a";
}
.icon-Shopping-Basket:before {
	content: "\ec5b";
}
.icon-Shopping-Cart:before {
	content: "\ec5c";
}
.icon-Short-Pants:before {
	content: "\ec5d";
}
.icon-Shoutwire:before {
	content: "\ec5e";
}
.icon-Shovel:before {
	content: "\ec5f";
}
.icon-Shuffle-2:before {
	content: "\ec60";
}
.icon-Shuffle-3:before {
	content: "\ec61";
}
.icon-Shuffle-4:before {
	content: "\ec62";
}
.icon-Shuffle:before {
	content: "\ec63";
}
.icon-Shutter:before {
	content: "\ec64";
}
.icon-Sidebar-Window:before {
	content: "\ec65";
}
.icon-Signal:before {
	content: "\ec66";
}
.icon-Singapore:before {
	content: "\ec67";
}
.icon-Skate-Shoes:before {
	content: "\ec68";
}
.icon-Skateboard-2:before {
	content: "\ec69";
}
.icon-Skateboard:before {
	content: "\ec6a";
}
.icon-Skeleton:before {
	content: "\ec6b";
}
.icon-Ski:before {
	content: "\ec6c";
}
.icon-Skirt:before {
	content: "\ec6d";
}
.icon-Skrill:before {
	content: "\ec6e";
}
.icon-Skull:before {
	content: "\ec6f";
}
.icon-Skydiving:before {
	content: "\ec70";
}
.icon-Skype:before {
	content: "\ec71";
}
.icon-Sled-withGifts:before {
	content: "\ec72";
}
.icon-Sled:before {
	content: "\ec73";
}
.icon-Sleeping:before {
	content: "\ec74";
}
.icon-Sleet:before {
	content: "\ec75";
}
.icon-Slippers:before {
	content: "\ec76";
}
.icon-Smart:before {
	content: "\ec77";
}
.icon-Smartphone-2:before {
	content: "\ec78";
}
.icon-Smartphone-3:before {
	content: "\ec79";
}
.icon-Smartphone-4:before {
	content: "\ec7a";
}
.icon-Smartphone-Secure:before {
	content: "\ec7b";
}
.icon-Smartphone:before {
	content: "\ec7c";
}
.icon-Smile:before {
	content: "\ec7d";
}
.icon-Smoking-Area:before {
	content: "\ec7e";
}
.icon-Smoking-Pipe:before {
	content: "\ec7f";
}
.icon-Snake:before {
	content: "\ec80";
}
.icon-Snorkel:before {
	content: "\ec81";
}
.icon-Snow-2:before {
	content: "\ec82";
}
.icon-Snow-Dome:before {
	content: "\ec83";
}
.icon-Snow-Storm:before {
	content: "\ec84";
}
.icon-Snow:before {
	content: "\ec85";
}
.icon-Snowflake-2:before {
	content: "\ec86";
}
.icon-Snowflake-3:before {
	content: "\ec87";
}
.icon-Snowflake-4:before {
	content: "\ec88";
}
.icon-Snowflake:before {
	content: "\ec89";
}
.icon-Snowman:before {
	content: "\ec8a";
}
.icon-Soccer-Ball:before {
	content: "\ec8b";
}
.icon-Soccer-Shoes:before {
	content: "\ec8c";
}
.icon-Socks:before {
	content: "\ec8d";
}
.icon-Solar:before {
	content: "\ec8e";
}
.icon-Sound-Wave:before {
	content: "\ec8f";
}
.icon-Sound:before {
	content: "\ec90";
}
.icon-Soundcloud:before {
	content: "\ec91";
}
.icon-Soup:before {
	content: "\ec92";
}
.icon-South-Africa:before {
	content: "\ec93";
}
.icon-Space-Needle:before {
	content: "\ec94";
}
.icon-Spain:before {
	content: "\ec95";
}
.icon-Spam-Mail:before {
	content: "\ec96";
}
.icon-Speach-Bubble:before {
	content: "\ec97";
}
.icon-Speach-Bubble2:before {
	content: "\ec98";
}
.icon-Speach-Bubble3:before {
	content: "\ec99";
}
.icon-Speach-Bubble4:before {
	content: "\ec9a";
}
.icon-Speach-Bubble5:before {
	content: "\ec9b";
}
.icon-Speach-Bubble6:before {
	content: "\ec9c";
}
.icon-Speach-Bubble7:before {
	content: "\ec9d";
}
.icon-Speach-Bubble8:before {
	content: "\ec9e";
}
.icon-Speach-Bubble9:before {
	content: "\ec9f";
}
.icon-Speach-Bubble10:before {
	content: "\eca0";
}
.icon-Speach-Bubble11:before {
	content: "\eca1";
}
.icon-Speach-Bubble12:before {
	content: "\eca2";
}
.icon-Speach-Bubble13:before {
	content: "\eca3";
}
.icon-Speach-BubbleAsking:before {
	content: "\eca4";
}
.icon-Speach-BubbleComic:before {
	content: "\eca5";
}
.icon-Speach-BubbleComic2:before {
	content: "\eca6";
}
.icon-Speach-BubbleComic3:before {
	content: "\eca7";
}
.icon-Speach-BubbleComic4:before {
	content: "\eca8";
}
.icon-Speach-BubbleDialog:before {
	content: "\eca9";
}
.icon-Speach-Bubbles:before {
	content: "\ecaa";
}
.icon-Speak-2:before {
	content: "\ecab";
}
.icon-Speak:before {
	content: "\ecac";
}
.icon-Speaker-2:before {
	content: "\ecad";
}
.icon-Speaker:before {
	content: "\ecae";
}
.icon-Spell-Check:before {
	content: "\ecaf";
}
.icon-Spell-CheckABC:before {
	content: "\ecb0";
}
.icon-Spermium:before {
	content: "\ecb1";
}
.icon-Spider:before {
	content: "\ecb2";
}
.icon-Spiderweb:before {
	content: "\ecb3";
}
.icon-Split-FourSquareWindow:before {
	content: "\ecb4";
}
.icon-Split-Horizontal:before {
	content: "\ecb5";
}
.icon-Split-Horizontal2Window:before {
	content: "\ecb6";
}
.icon-Split-Vertical:before {
	content: "\ecb7";
}
.icon-Split-Vertical2:before {
	content: "\ecb8";
}
.icon-Split-Window:before {
	content: "\ecb9";
}
.icon-Spoder:before {
	content: "\ecba";
}
.icon-Spoon:before {
	content: "\ecbb";
}
.icon-Sport-Mode:before {
	content: "\ecbc";
}
.icon-Sports-Clothings1:before {
	content: "\ecbd";
}
.icon-Sports-Clothings2:before {
	content: "\ecbe";
}
.icon-Sports-Shirt:before {
	content: "\ecbf";
}
.icon-Spot:before {
	content: "\ecc0";
}
.icon-Spray:before {
	content: "\ecc1";
}
.icon-Spread:before {
	content: "\ecc2";
}
.icon-Spring:before {
	content: "\ecc3";
}
.icon-Spurl:before {
	content: "\ecc4";
}
.icon-Spy:before {
	content: "\ecc5";
}
.icon-Squirrel:before {
	content: "\ecc6";
}
.icon-SSL:before {
	content: "\ecc7";
}
.icon-St-BasilsCathedral:before {
	content: "\ecc8";
}
.icon-St-PaulsCathedral:before {
	content: "\ecc9";
}
.icon-Stamp-2:before {
	content: "\ecca";
}
.icon-Stamp:before {
	content: "\eccb";
}
.icon-Stapler:before {
	content: "\eccc";
}
.icon-Star-Track:before {
	content: "\eccd";
}
.icon-Star:before {
	content: "\ecce";
}
.icon-Starfish:before {
	content: "\eccf";
}
.icon-Start2:before {
	content: "\ecd0";
}
.icon-Start-3:before {
	content: "\ecd1";
}
.icon-Start-ways:before {
	content: "\ecd2";
}
.icon-Start:before {
	content: "\ecd3";
}
.icon-Statistic:before {
	content: "\ecd4";
}
.icon-Stethoscope:before {
	content: "\ecd5";
}
.icon-stop--2:before {
	content: "\ecd6";
}
.icon-Stop-Music:before {
	content: "\ecd7";
}
.icon-Stop:before {
	content: "\ecd8";
}
.icon-Stopwatch-2:before {
	content: "\ecd9";
}
.icon-Stopwatch:before {
	content: "\ecda";
}
.icon-Storm:before {
	content: "\ecdb";
}
.icon-Street-View:before {
	content: "\ecdc";
}
.icon-Street-View2:before {
	content: "\ecdd";
}
.icon-Strikethrough-Text:before {
	content: "\ecde";
}
.icon-Stroller:before {
	content: "\ecdf";
}
.icon-Structure:before {
	content: "\ece0";
}
.icon-Student-Female:before {
	content: "\ece1";
}
.icon-Student-Hat:before {
	content: "\ece2";
}
.icon-Student-Hat2:before {
	content: "\ece3";
}
.icon-Student-Male:before {
	content: "\ece4";
}
.icon-Student-MaleFemale:before {
	content: "\ece5";
}
.icon-Students:before {
	content: "\ece6";
}
.icon-Studio-Flash:before {
	content: "\ece7";
}
.icon-Studio-Lightbox:before {
	content: "\ece8";
}
.icon-Stumbleupon:before {
	content: "\ece9";
}
.icon-Suit:before {
	content: "\ecea";
}
.icon-Suitcase:before {
	content: "\eceb";
}
.icon-Sum-2:before {
	content: "\ecec";
}
.icon-Sum:before {
	content: "\eced";
}
.icon-Summer:before {
	content: "\ecee";
}
.icon-Sun-CloudyRain:before {
	content: "\ecef";
}
.icon-Sun:before {
	content: "\ecf0";
}
.icon-Sunglasses-2:before {
	content: "\ecf1";
}
.icon-Sunglasses-3:before {
	content: "\ecf2";
}
.icon-Sunglasses-Smiley:before {
	content: "\ecf3";
}
.icon-Sunglasses-Smiley2:before {
	content: "\ecf4";
}
.icon-Sunglasses-W:before {
	content: "\ecf5";
}
.icon-Sunglasses-W2:before {
	content: "\ecf6";
}
.icon-Sunglasses-W3:before {
	content: "\ecf7";
}
.icon-Sunglasses:before {
	content: "\ecf8";
}
.icon-Sunrise:before {
	content: "\ecf9";
}
.icon-Sunset:before {
	content: "\ecfa";
}
.icon-Superman:before {
	content: "\ecfb";
}
.icon-Support:before {
	content: "\ecfc";
}
.icon-Surprise:before {
	content: "\ecfd";
}
.icon-Sushi:before {
	content: "\ecfe";
}
.icon-Sweden:before {
	content: "\ecff";
}
.icon-Swimming-Short:before {
	content: "\ed00";
}
.icon-Swimming:before {
	content: "\ed01";
}
.icon-Swimmwear:before {
	content: "\ed02";
}
.icon-Switch:before {
	content: "\ed03";
}
.icon-Switzerland:before {
	content: "\ed04";
}
.icon-Sync-Cloud:before {
	content: "\ed05";
}
.icon-Sync:before {
	content: "\ed06";
}
.icon-Synchronize-2:before {
	content: "\ed07";
}
.icon-Synchronize:before {
	content: "\ed08";
}
.icon-T-Shirt:before {
	content: "\ed09";
}
.icon-Tablet-2:before {
	content: "\ed0a";
}
.icon-Tablet-3:before {
	content: "\ed0b";
}
.icon-Tablet-Orientation:before {
	content: "\ed0c";
}
.icon-Tablet-Phone:before {
	content: "\ed0d";
}
.icon-Tablet-Secure:before {
	content: "\ed0e";
}
.icon-Tablet-Vertical:before {
	content: "\ed0f";
}
.icon-Tablet:before {
	content: "\ed10";
}
.icon-Tactic:before {
	content: "\ed11";
}
.icon-Tag-2:before {
	content: "\ed12";
}
.icon-Tag-3:before {
	content: "\ed13";
}
.icon-Tag-4:before {
	content: "\ed14";
}
.icon-Tag-5:before {
	content: "\ed15";
}
.icon-Tag:before {
	content: "\ed16";
}
.icon-Taj-Mahal:before {
	content: "\ed17";
}
.icon-Talk-Man:before {
	content: "\ed18";
}
.icon-Tap:before {
	content: "\ed19";
}
.icon-Target-Market:before {
	content: "\ed1a";
}
.icon-Target:before {
	content: "\ed1b";
}
.icon-Taurus-2:before {
	content: "\ed1c";
}
.icon-Taurus:before {
	content: "\ed1d";
}
.icon-Taxi-2:before {
	content: "\ed1e";
}
.icon-Taxi-Sign:before {
	content: "\ed1f";
}
.icon-Taxi:before {
	content: "\ed20";
}
.icon-Teacher:before {
	content: "\ed21";
}
.icon-Teapot:before {
	content: "\ed22";
}
.icon-Technorati:before {
	content: "\ed23";
}
.icon-Teddy-Bear:before {
	content: "\ed24";
}
.icon-Tee-Mug:before {
	content: "\ed25";
}
.icon-Telephone-2:before {
	content: "\ed26";
}
.icon-Telephone:before {
	content: "\ed27";
}
.icon-Telescope:before {
	content: "\ed28";
}
.icon-Temperature-2:before {
	content: "\ed29";
}
.icon-Temperature-3:before {
	content: "\ed2a";
}
.icon-Temperature:before {
	content: "\ed2b";
}
.icon-Temple:before {
	content: "\ed2c";
}
.icon-Tennis-Ball:before {
	content: "\ed2d";
}
.icon-Tennis:before {
	content: "\ed2e";
}
.icon-Tent:before {
	content: "\ed2f";
}
.icon-Test-Tube:before {
	content: "\ed30";
}
.icon-Test-Tube2:before {
	content: "\ed31";
}
.icon-Testimonal:before {
	content: "\ed32";
}
.icon-Text-Box:before {
	content: "\ed33";
}
.icon-Text-Effect:before {
	content: "\ed34";
}
.icon-Text-HighlightColor:before {
	content: "\ed35";
}
.icon-Text-Paragraph:before {
	content: "\ed36";
}
.icon-Thailand:before {
	content: "\ed37";
}
.icon-The-WhiteHouse:before {
	content: "\ed38";
}
.icon-This-SideUp:before {
	content: "\ed39";
}
.icon-Thread:before {
	content: "\ed3a";
}
.icon-Three-ArrowFork:before {
	content: "\ed3b";
}
.icon-Three-Fingers:before {
	content: "\ed3c";
}
.icon-Three-FingersDrag:before {
	content: "\ed3d";
}
.icon-Three-FingersDrag2:before {
	content: "\ed3e";
}
.icon-Three-FingersTouch:before {
	content: "\ed3f";
}
.icon-Thumb:before {
	content: "\ed40";
}
.icon-Thumbs-DownSmiley:before {
	content: "\ed41";
}
.icon-Thumbs-UpSmiley:before {
	content: "\ed42";
}
.icon-Thunder:before {
	content: "\ed43";
}
.icon-Thunderstorm:before {
	content: "\ed44";
}
.icon-Ticket:before {
	content: "\ed45";
}
.icon-Tie-2:before {
	content: "\ed46";
}
.icon-Tie-3:before {
	content: "\ed47";
}
.icon-Tie-4:before {
	content: "\ed48";
}
.icon-Tie:before {
	content: "\ed49";
}
.icon-Tiger:before {
	content: "\ed4a";
}
.icon-Time-Backup:before {
	content: "\ed4b";
}
.icon-Time-Bomb:before {
	content: "\ed4c";
}
.icon-Time-Clock:before {
	content: "\ed4d";
}
.icon-Time-Fire:before {
	content: "\ed4e";
}
.icon-Time-Machine:before {
	content: "\ed4f";
}
.icon-Time-Window:before {
	content: "\ed50";
}
.icon-Timer-2:before {
	content: "\ed51";
}
.icon-Timer:before {
	content: "\ed52";
}
.icon-To-Bottom:before {
	content: "\ed53";
}
.icon-To-Bottom2:before {
	content: "\ed54";
}
.icon-To-Left:before {
	content: "\ed55";
}
.icon-To-Right:before {
	content: "\ed56";
}
.icon-To-Top:before {
	content: "\ed57";
}
.icon-To-Top2:before {
	content: "\ed58";
}
.icon-Token-:before {
	content: "\ed59";
}
.icon-Tomato:before {
	content: "\ed5a";
}
.icon-Tongue:before {
	content: "\ed5b";
}
.icon-Tooth-2:before {
	content: "\ed5c";
}
.icon-Tooth:before {
	content: "\ed5d";
}
.icon-Top-ToBottom:before {
	content: "\ed5e";
}
.icon-Touch-Window:before {
	content: "\ed5f";
}
.icon-Tourch:before {
	content: "\ed60";
}
.icon-Tower-2:before {
	content: "\ed61";
}
.icon-Tower-Bridge:before {
	content: "\ed62";
}
.icon-Tower:before {
	content: "\ed63";
}
.icon-Trace:before {
	content: "\ed64";
}
.icon-Tractor:before {
	content: "\ed65";
}
.icon-traffic-Light:before {
	content: "\ed66";
}
.icon-Traffic-Light2:before {
	content: "\ed67";
}
.icon-Train-2:before {
	content: "\ed68";
}
.icon-Train:before {
	content: "\ed69";
}
.icon-Tram:before {
	content: "\ed6a";
}
.icon-Transform-2:before {
	content: "\ed6b";
}
.icon-Transform-3:before {
	content: "\ed6c";
}
.icon-Transform-4:before {
	content: "\ed6d";
}
.icon-Transform:before {
	content: "\ed6e";
}
.icon-Trash-withMen:before {
	content: "\ed6f";
}
.icon-Tree-2:before {
	content: "\ed70";
}
.icon-Tree-3:before {
	content: "\ed71";
}
.icon-Tree-4:before {
	content: "\ed72";
}
.icon-Tree-5:before {
	content: "\ed73";
}
.icon-Tree:before {
	content: "\ed74";
}
.icon-Trekking:before {
	content: "\ed75";
}
.icon-Triangle-ArrowDown:before {
	content: "\ed76";
}
.icon-Triangle-ArrowLeft:before {
	content: "\ed77";
}
.icon-Triangle-ArrowRight:before {
	content: "\ed78";
}
.icon-Triangle-ArrowUp:before {
	content: "\ed79";
}
.icon-Tripod-2:before {
	content: "\ed7a";
}
.icon-Tripod-andVideo:before {
	content: "\ed7b";
}
.icon-Tripod-withCamera:before {
	content: "\ed7c";
}
.icon-Tripod-withGopro:before {
	content: "\ed7d";
}
.icon-Trophy-2:before {
	content: "\ed7e";
}
.icon-Trophy:before {
	content: "\ed7f";
}
.icon-Truck:before {
	content: "\ed80";
}
.icon-Trumpet:before {
	content: "\ed81";
}
.icon-Tumblr:before {
	content: "\ed82";
}
.icon-Turkey:before {
	content: "\ed83";
}
.icon-Turn-Down:before {
	content: "\ed84";
}
.icon-Turn-Down2:before {
	content: "\ed85";
}
.icon-Turn-DownFromLeft:before {
	content: "\ed86";
}
.icon-Turn-DownFromRight:before {
	content: "\ed87";
}
.icon-Turn-Left:before {
	content: "\ed88";
}
.icon-Turn-Left3:before {
	content: "\ed89";
}
.icon-Turn-Right:before {
	content: "\ed8a";
}
.icon-Turn-Right3:before {
	content: "\ed8b";
}
.icon-Turn-Up:before {
	content: "\ed8c";
}
.icon-Turn-Up2:before {
	content: "\ed8d";
}
.icon-Turtle:before {
	content: "\ed8e";
}
.icon-Tuxedo:before {
	content: "\ed8f";
}
.icon-TV:before {
	content: "\ed90";
}
.icon-Twister:before {
	content: "\ed91";
}
.icon-Twitter-2:before {
	content: "\ed92";
}
.icon-Twitter:before {
	content: "\ed93";
}
.icon-Two-Fingers:before {
	content: "\ed94";
}
.icon-Two-FingersDrag:before {
	content: "\ed95";
}
.icon-Two-FingersDrag2:before {
	content: "\ed96";
}
.icon-Two-FingersScroll:before {
	content: "\ed97";
}
.icon-Two-FingersTouch:before {
	content: "\ed98";
}
.icon-Two-Windows:before {
	content: "\ed99";
}
.icon-Type-Pass:before {
	content: "\ed9a";
}
.icon-Ukraine:before {
	content: "\ed9b";
}
.icon-Umbrela:before {
	content: "\ed9c";
}
.icon-Umbrella-2:before {
	content: "\ed9d";
}
.icon-Umbrella-3:before {
	content: "\ed9e";
}
.icon-Under-LineText:before {
	content: "\ed9f";
}
.icon-Undo:before {
	content: "\eda0";
}
.icon-United-Kingdom:before {
	content: "\eda1";
}
.icon-United-States:before {
	content: "\eda2";
}
.icon-University-2:before {
	content: "\eda3";
}
.icon-University:before {
	content: "\eda4";
}
.icon-Unlike-2:before {
	content: "\eda5";
}
.icon-Unlike:before {
	content: "\eda6";
}
.icon-Unlock-2:before {
	content: "\eda7";
}
.icon-Unlock-3:before {
	content: "\eda8";
}
.icon-Unlock:before {
	content: "\eda9";
}
.icon-Up--Down:before {
	content: "\edaa";
}
.icon-Up--Down3:before {
	content: "\edab";
}
.icon-Up-2:before {
	content: "\edac";
}
.icon-Up-3:before {
	content: "\edad";
}
.icon-Up-4:before {
	content: "\edae";
}
.icon-Up:before {
	content: "\edaf";
}
.icon-Upgrade:before {
	content: "\edb0";
}
.icon-Upload-2:before {
	content: "\edb1";
}
.icon-Upload-toCloud:before {
	content: "\edb2";
}
.icon-Upload-Window:before {
	content: "\edb3";
}
.icon-Upload:before {
	content: "\edb4";
}
.icon-Uppercase-Text:before {
	content: "\edb5";
}
.icon-Upward:before {
	content: "\edb6";
}
.icon-URL-Window:before {
	content: "\edb7";
}
.icon-Usb-2:before {
	content: "\edb8";
}
.icon-Usb-Cable:before {
	content: "\edb9";
}
.icon-Usb:before {
	content: "\edba";
}
.icon-User:before {
	content: "\edbb";
}
.icon-Ustream:before {
	content: "\edbc";
}
.icon-Vase:before {
	content: "\edbd";
}
.icon-Vector-2:before {
	content: "\edbe";
}
.icon-Vector-3:before {
	content: "\edbf";
}
.icon-Vector-4:before {
	content: "\edc0";
}
.icon-Vector-5:before {
	content: "\edc1";
}
.icon-Vector:before {
	content: "\edc2";
}
.icon-Venn-Diagram:before {
	content: "\edc3";
}
.icon-Vest-2:before {
	content: "\edc4";
}
.icon-Vest:before {
	content: "\edc5";
}
.icon-Viddler:before {
	content: "\edc6";
}
.icon-Video-2:before {
	content: "\edc7";
}
.icon-Video-3:before {
	content: "\edc8";
}
.icon-Video-4:before {
	content: "\edc9";
}
.icon-Video-5:before {
	content: "\edca";
}
.icon-Video-6:before {
	content: "\edcb";
}
.icon-Video-GameController:before {
	content: "\edcc";
}
.icon-Video-Len:before {
	content: "\edcd";
}
.icon-Video-Len2:before {
	content: "\edce";
}
.icon-Video-Photographer:before {
	content: "\edcf";
}
.icon-Video-Tripod:before {
	content: "\edd0";
}
.icon-Video:before {
	content: "\edd1";
}
.icon-Vietnam:before {
	content: "\edd2";
}
.icon-View-Height:before {
	content: "\edd3";
}
.icon-View-Width:before {
	content: "\edd4";
}
.icon-Vimeo:before {
	content: "\edd5";
}
.icon-Virgo-2:before {
	content: "\edd6";
}
.icon-Virgo:before {
	content: "\edd7";
}
.icon-Virus-2:before {
	content: "\edd8";
}
.icon-Virus-3:before {
	content: "\edd9";
}
.icon-Virus:before {
	content: "\edda";
}
.icon-Visa:before {
	content: "\eddb";
}
.icon-Voice:before {
	content: "\eddc";
}
.icon-Voicemail:before {
	content: "\eddd";
}
.icon-Volleyball:before {
	content: "\edde";
}
.icon-Volume-Down:before {
	content: "\eddf";
}
.icon-Volume-Up:before {
	content: "\ede0";
}
.icon-VPN:before {
	content: "\ede1";
}
.icon-Wacom-Tablet:before {
	content: "\ede2";
}
.icon-Waiter:before {
	content: "\ede3";
}
.icon-Walkie-Talkie:before {
	content: "\ede4";
}
.icon-Wallet-2:before {
	content: "\ede5";
}
.icon-Wallet-3:before {
	content: "\ede6";
}
.icon-Wallet:before {
	content: "\ede7";
}
.icon-Warehouse:before {
	content: "\ede8";
}
.icon-Warning-Window:before {
	content: "\ede9";
}
.icon-Watch-2:before {
	content: "\edea";
}
.icon-Watch-3:before {
	content: "\edeb";
}
.icon-Watch:before {
	content: "\edec";
}
.icon-Wave-2:before {
	content: "\eded";
}
.icon-Wave:before {
	content: "\edee";
}
.icon-Webcam:before {
	content: "\edef";
}
.icon-weight-Lift:before {
	content: "\edf0";
}
.icon-Wheelbarrow:before {
	content: "\edf1";
}
.icon-Wheelchair:before {
	content: "\edf2";
}
.icon-Width-Window:before {
	content: "\edf3";
}
.icon-Wifi-2:before {
	content: "\edf4";
}
.icon-Wifi-Keyboard:before {
	content: "\edf5";
}
.icon-Wifi:before {
	content: "\edf6";
}
.icon-Wind-Turbine:before {
	content: "\edf7";
}
.icon-Windmill:before {
	content: "\edf8";
}
.icon-Window-2:before {
	content: "\edf9";
}
.icon-Window:before {
	content: "\edfa";
}
.icon-Windows-2:before {
	content: "\edfb";
}
.icon-Windows-Microsoft:before {
	content: "\edfc";
}
.icon-Windows:before {
	content: "\edfd";
}
.icon-Windsock:before {
	content: "\edfe";
}
.icon-Windy:before {
	content: "\edff";
}
.icon-Wine-Bottle:before {
	content: "\ee00";
}
.icon-Wine-Glass:before {
	content: "\ee01";
}
.icon-Wink:before {
	content: "\ee02";
}
.icon-Winter-2:before {
	content: "\ee03";
}
.icon-Winter:before {
	content: "\ee04";
}
.icon-Wireless:before {
	content: "\ee05";
}
.icon-Witch-Hat:before {
	content: "\ee06";
}
.icon-Witch:before {
	content: "\ee07";
}
.icon-Wizard:before {
	content: "\ee08";
}
.icon-Wolf:before {
	content: "\ee09";
}
.icon-Woman-Sign:before {
	content: "\ee0a";
}
.icon-WomanMan:before {
	content: "\ee0b";
}
.icon-Womans-Underwear:before {
	content: "\ee0c";
}
.icon-Womans-Underwear2:before {
	content: "\ee0d";
}
.icon-Women:before {
	content: "\ee0e";
}
.icon-Wonder-Woman:before {
	content: "\ee0f";
}
.icon-Wordpress:before {
	content: "\ee10";
}
.icon-Worker-Clothes:before {
	content: "\ee11";
}
.icon-Worker:before {
	content: "\ee12";
}
.icon-Wrap-Text:before {
	content: "\ee13";
}
.icon-Wreath:before {
	content: "\ee14";
}
.icon-Wrench:before {
	content: "\ee15";
}
.icon-X-Box:before {
	content: "\ee16";
}
.icon-X-ray:before {
	content: "\ee17";
}
.icon-Xanga:before {
	content: "\ee18";
}
.icon-Xing:before {
	content: "\ee19";
}
.icon-Yacht:before {
	content: "\ee1a";
}
.icon-Yahoo-Buzz:before {
	content: "\ee1b";
}
.icon-Yahoo:before {
	content: "\ee1c";
}
.icon-Yelp:before {
	content: "\ee1d";
}
.icon-Yes:before {
	content: "\ee1e";
}
.icon-Ying-Yang:before {
	content: "\ee1f";
}
.icon-Youtube:before {
	content: "\ee20";
}
.icon-Z-A:before {
	content: "\ee21";
}
.icon-Zebra:before {
	content: "\ee22";
}
.icon-Zombie:before {
	content: "\ee23";
}
.icon-Zoom-Gesture:before {
	content: "\ee24";
}
.icon-Zootool:before {
	content: "\ee25";
}

@font-face {
  font-family: 'pillar-interface';
  src: url(/static/media/pillar-interface.5c858ed0.eot);
  src: url(/static/media/pillar-interface.5c858ed0.eot#iefix) format('embedded-opentype'),
       url(/static/media/pillar-interface.cd9d812d.woff2) format('woff2'),
       url(/static/media/pillar-interface.72e9385b.woff) format('woff'),
       url(/static/media/pillar-interface.59937e05.ttf) format('truetype'),
       url(/static/media/pillar-interface.594a86ec.svg#pillar-interface) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'pillar-interface';
    src: url('../font/pillar-interface.svg?95514741#pillar-interface') format('svg');
  }
}
*/
 
 [class^="interface-"]:before, [class*=" interface-"]:before {
  font-family: "pillar-interface";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.interface-down-open-big:before { content: '\e800'; } /* '' */
.interface-left-open-big:before { content: '\e801'; } /* '' */
.interface-right-open-big:before { content: '\e802'; } /* '' */
.interface-up-open-big:before { content: '\e803'; } /* '' */
.interface-bag:before { content: '\e804'; } /* '' */
.interface-search:before { content: '\e805'; } /* '' */
body:after {
    display: none
}

.lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #000;
    filter: alpha(Opacity=80);
    opacity: .8;
    display: none
}

.lightbox {
    position: fixed;
    top: 50% !important;
   	-webkit-transform: translate3d(0,-50%,0);
   	        transform: translate3d(0,-50%,0);
    left: 0;
    width: 100%;
    z-index: 10000;
    text-align: center;
    line-height: 0;
    font-weight: 400
}

.lightbox .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    border-radius: 3px
}

.lightbox a img {
    border: none
}

.lb-outerContainer {
    position: relative;
    background-color: #222;
    *zoom: 1;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px
}

.lb-outerContainer:after {
    content: "";
    display: table;
    clear: both
}

.lb-container {
    padding: 4px
}

.lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    height: 25%;
    width: 100%;
    text-align: center;
    line-height: 0
}

.lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
}

.lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10
}

.lb-container>.nav {
    left: 0
}

.lb-nav a {
    outline: 0;
}

.lb-next,
.lb-prev {
    height: 100%;
    cursor: pointer;
    display: block
}

.lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    filter: alpha(Opacity=0);
    opacity: .5;
    -webkit-transition: opacity .6s;
    transition: opacity .6s
}

.lb-nav a.lb-prev:hover {
    filter: alpha(Opacity=100);
    opacity: 1
}

.lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    filter: alpha(Opacity=0);
    opacity: .5;
    -webkit-transition: opacity .6s;
    transition: opacity .6s
}

.lb-nav a.lb-next:hover {
    filter: alpha(Opacity=100);
    opacity: 1
}

.lb-prev:after,.lb-next:after{
	content: '\2190';
	position: absolute;
	top: 50%;
	color: #fff;
	font-size: 24px;
}
.lb-prev:after{
	left: -60px;
}
.lb-next:after{
	right: -60px;
	content: '\2192';
}

.lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    min-height: 35px;
    *zoom: 1;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

.lb-dataContainer:after {
    content: "";
    display: table;
    clear: both
}

.lb-data {
    padding: 0 4px;
    color: #ccc
}

.lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em
}

.lb-data .lb-caption {
    font-size: 13px;
    font-weight: 700;
    line-height: 1em
}

.lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999
}

.lb-data .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    text-align: right;
    outline: 0;
    filter: alpha(Opacity=70);
    opacity: .7;
    -webkit-transition: opacity .2s;
    transition: opacity .2s
}

.lb-data .lb-close:hover {
    cursor: pointer;
    filter: alpha(Opacity=100);
    opacity: 1
}
/**!**************/
/**! Contents **/
/**! 1. Reset **/
/**! 2. Helper Classes **/
/**! 3. Typography **/
/**! 4. Position **/
/**! 5. Element Size **/
/**! 6. Images **/
/**! 7. Colors **/
/**! 8. Buttons **/
/**! 9. Icons **/
/**! 10. Lists **/
/**! 11. Lightbox **/
/**! 12. Nav Bar **/
/**! 13. Form Elements **/
/**! 14. Accordions **/
/**! 15. Breadcrumbs **/
/**! 16. Pie Charts **/
/**! 17. Tabs **/
/**! 18. Boxes **/
/**! 19. Sliders **/
/**! 20. Hover Elements **/
/**! 21. Masonry **/
/**! 22. Modals **/
/**! 23. Maps **/
/**! 24. Parallax **/
/**! 25. Notifications **/
/**! 26. Video **/
/**! 27. Image Blocks **/
/**! 28. Mail Chimp & Campaign Monitor **/
/**! 29. Twitter **/
/**! 30. Transitions **/
/**! 31. Spacing **/
/**! 32. Theme Overrides **/
/**!**************/
/**!**************/
/**!**************/
/**! 1. Reset **/
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a {
  margin: 0;
  padding: 0;
}
/**! 2. Helper Classes **/
.clearfix {
  overflow: hidden;
}
.allow-overflow {
  overflow: visible;
}
.container .row--gapless {
  padding-left: 15px;
  padding-right: 15px;
}
.container .row--gapless > div[class*='col-'] {
  padding: 0;
}
section > .row--gapless {
  padding-left: 0;
  padding-right: 0;
}
section > .row--gapless > div[class*='col-'] {
  padding: 0;
}
.unpad {
  padding: 0;
}
.unpad--bottom {
  padding-bottom: 0;
}
.unpad--top {
  padding-top: 0;
}
section.unpad--bottom {
  padding-bottom: 0;
}
section.unpad {
  padding: 0;
}
section.unpad--top {
  padding-top: 0;
}
.unmarg--bottom {
  margin-bottom: 0;
}
.unmarg {
  margin: 0;
}
div.right {
  float: right;
}
div.left {
  float: left;
}
/**! 3. Typography **/
html {
  font-size: 100%;
}
@media all and (max-width: 768px) {
  html {
    font-size: 87.5%;
  }
}
body {
  font-size: 1em;
  line-height: 1.625em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lora', 'Helvetica', 'Arial', sans-serif;
  color: #767676;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  /* color: #343434; */
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
}
h1,
.h1 {
  font-size: 4em;
  line-height: 1.015625em;
}
h2,
.h2 {
  font-size: 2.8125em;
  line-height: 1.15555556em;
}
h3,
.h3 {
  font-size: 2em;
  line-height: 1.421875em;
}
h4,
.h4 {
  font-size: 1.4375em;
  line-height: 1.41304348em;
}
h5,
.h5 {
  font-size: 1em;
  line-height: 1.625em;
}
h6,
.h6 {
  font-size: 0.625em;
  line-height: 2.6em;
}
.lead {
  font-size: 1.4375em;
  line-height: 1.69565217em;
}
@media all and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2.8125em;
    line-height: 1.15555556em;
  }
  h2,
  .h2 {
    font-size: 2em;
    line-height: 1.421875em;
  }
  h3,
  .h3 {
    font-size: 1.4375em;
    line-height: 1.625em;
  }
  .lead {
    font-size: 1.4375em;
    line-height: 1.41304348em;
  }
}
p,
ul,
ol,
pre,
table,
blockquote {
  margin-bottom: 1.625em;
}
ul,
ol {
  list-style: none;
}
ul:not(.menu):not(.slides):not(.accordion):not(.tabs):not(.tabs-content),
ol:not(.menu):not(.slides):not(.accordion):not(.tabs):not(.tabs-content) {
  line-height: 2.34375em;
}
ul.bullets {
  list-style: inside;
}
ol {
  list-style-type: upper-roman;
  list-style-position: inside;
}
blockquote {
  font-size: 2em;
  line-height: 1.421875em;
  padding: 0;
  margin: 0;
  border-left: 0;
}
strong {
  font-weight: 600;
}
hr {
  margin: 1.625em 0;
  border-color: #f4f4f4;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}
/*! Typography -- Helpers */
.type--fade {
  opacity: .5;
}
.type--uppercase {
  text-transform: uppercase;
}
.type--bold {
  font-weight: bold;
}
.type--italic {
  font-style: italic;
}
.type--fine-print {
  font-size: 0.6875em;
}
.type--strikethrough {
  text-decoration: line-through;
  opacity: .5;
}
.type--underline {
  text-decoration: underline;
}
.type--body-font {
  font-family: 'Lora', 'Helvetica';
}
/**! 4. Position **/
body {
  overflow-x: hidden;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.pos-absolute.container {
  left: 0;
  right: 0;
}
.pos-top {
  top: 0;
}
.pos-bottom {
  bottom: 0;
}
.pos-right {
  right: 0;
}
.pos-left {
  left: 0;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
@media all and (max-width: 767px) {
  .float-left,
  .float-right {
    float: none;
  }
  .float-left-xs {
    float: left;
  }
  .float-right-xs {
    float: right;
  }
}
.pos-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
@media all and (max-width: 767px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
@media all and (max-height: 600px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
.pos-vertical-align-columns {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.pos-vertical-align-columns > div[class*='col-'] {
  display: table-cell;
  float: none;
  vertical-align: middle;
}
@media all and (max-width: 990px) {
  .pos-vertical-align-columns {
    display: block;
    width: auto;
  }
  .pos-vertical-align-columns > div[class*='col-'] {
    display: block;
  }
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
@media all and (max-width: 767px) {
  .block--xs {
    display: block;
  }
}
@media all and (max-width: 990px) {
  .text-center-md {
    text-align: center;
  }
  .text-left-md {
    text-align: left;
  }
  .text-right-md {
    text-align: right;
  }
}
@media all and (max-width: 767px) {
  .text-center-xs {
    text-align: center;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
}
/**! 5. Element Size **/
.height-100,
.height-90,
.height-80,
.height-70,
.height-60,
.height-50,
.height-40,
.height-30,
.height-20,
.height-10 {
  height: auto;
  padding: 5em 0;
}
@media all and (max-width: 767px) {
  .height-100,
  .height-90,
  .height-80,
  .height-70,
  .height-60,
  .height-50,
  .height-40,
  .height-30,
  .height-20,
  .height-10 {
    height: auto;
    padding: 4em 0;
  }
}
@media all and (min-height: 600px) and (min-width: 767px) {
  .height-10 {
    height: 10vh;
  }
  .height-20 {
    height: 20vh;
  }
  .height-30 {
    height: 30vh;
  }
  .height-40 {
    height: 40vh;
  }
  .height-50 {
    height: 50vh;
  }
  .height-60 {
    height: 60vh;
  }
  .height-70 {
    height: 70vh;
  }
  .height-80 {
    height: 80vh;
  }
  .height-90 {
    height: 90vh;
  }
  .height-100 {
    height: 100vh;
  }
}
/**! 6. Images **/
img {
  max-width: 100%;
  margin-bottom: 1.625em;
}
/*p+img, img:last-child{
	margin-bottom: 0;
}*/
.img--fullwidth {
  width: 100%;
}
[data-grid="2"].masonry {
  -webkit-column-count: 2;
  -webkit-column-gap: 0;
  -moz-column-count: 2;
  -moz-column-gap: 0;
  column-count: 2;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="2"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="2"] li {
  width: 50%;
  display: inline-block;
}
[data-grid="3"].masonry {
  -webkit-column-count: 3;
  -webkit-column-gap: 0;
  -moz-column-count: 3;
  -moz-column-gap: 0;
  column-count: 3;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="3"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="3"] li {
  width: 33.33333333%;
  display: inline-block;
}
[data-grid="4"].masonry {
  -webkit-column-count: 4;
  -webkit-column-gap: 0;
  -moz-column-count: 4;
  -moz-column-gap: 0;
  column-count: 4;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="4"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="4"] li {
  width: 25%;
  display: inline-block;
}
[data-grid="5"].masonry {
  -webkit-column-count: 5;
  -webkit-column-gap: 0;
  -moz-column-count: 5;
  -moz-column-gap: 0;
  column-count: 5;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="5"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="5"] li {
  width: 20%;
  display: inline-block;
}
[data-grid="6"].masonry {
  -webkit-column-count: 6;
  -webkit-column-gap: 0;
  -moz-column-count: 6;
  -moz-column-gap: 0;
  column-count: 6;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="6"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="6"] li {
  width: 16.66666667%;
  display: inline-block;
}
[data-grid="7"].masonry {
  -webkit-column-count: 7;
  -webkit-column-gap: 0;
  -moz-column-count: 7;
  -moz-column-gap: 0;
  column-count: 7;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="7"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="7"] li {
  width: 14.28571429%;
  display: inline-block;
}
[data-grid="8"].masonry {
  -webkit-column-count: 8;
  -webkit-column-gap: 0;
  -moz-column-count: 8;
  -moz-column-gap: 0;
  column-count: 8;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="8"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="8"] li {
  width: 12.5%;
  display: inline-block;
}
@media all and (max-width: 767px) {
  [data-grid]:not(.masonry) li {
    width: 33.333333%;
  }
  [data-grid="2"]:not(.masonry) li {
    width: 50%;
  }
  [data-grid].masonry {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.background-image-holder {
  will-change: transform, top;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
  background: #232323;
}
.background-image-holder:not([class*='col-']) {
  width: 100%;
}
.background-image-holder.background--bottom {
  background-position: 50% 100% !important;
}
.background-image-holder.background--top {
  background-position: 50% 0% !important;
}
.image--light .background-image-holder {
  background: none;
}
.background-image-holder img {
  display: none;
}
[data-overlay] {
  position: relative;
}
[data-overlay]:before {
  position: absolute;
  content: '';
  background: #232323;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
[data-overlay] *:not(.container):not(.background-image-holder) {
  z-index: 2;
}
[data-overlay].image--light:before {
  background: #fff;
}
[data-overlay].bg--primary:before {
  background: #425cbb;
}
[data-overlay="1"]:before {
  opacity: 0.1;
}
[data-overlay="2"]:before {
  opacity: 0.2;
}
[data-overlay="3"]:before {
  opacity: 0.3;
}
[data-overlay="4"]:before {
  opacity: 0.4;
}
[data-overlay="5"]:before {
  opacity: 0.5;
}
[data-overlay="6"]:before {
  opacity: 0.6;
}
[data-overlay="7"]:before {
  opacity: 0.7;
}
[data-overlay="8"]:before {
  opacity: 0.8;
}
[data-overlay="9"]:before {
  opacity: 0.9;
}
[data-overlay="10"]:before {
  opacity: 1;
}
[data-overlay="0"]:before {
  opacity: 0;
}
[data-scrim-bottom] {
  position: relative;
}
[data-scrim-bottom]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, #232323));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #232323 100%);
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #232323 100%);
  bottom: 0;
  left: 0;
  z-index: 1;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
[data-scrim-bottom]:not(.image--light) h1,
[data-scrim-bottom]:not(.image--light) h2,
[data-scrim-bottom]:not(.image--light) h3,
[data-scrim-bottom]:not(.image--light) h4,
[data-scrim-bottom]:not(.image--light) h5,
[data-scrim-bottom]:not(.image--light) h6 {
  color: #b3b3b3;
}
[data-scrim-bottom]:not(.image--light) p,
[data-scrim-bottom]:not(.image--light) span,
[data-scrim-bottom]:not(.image--light) ul {
  color: #f6f6f6;
}
[data-scrim-bottom].image--light:before {
  background: #fff;
}
[data-scrim-bottom="1"]:before {
  opacity: 0.1;
}
[data-scrim-bottom="2"]:before {
  opacity: 0.2;
}
[data-scrim-bottom="3"]:before {
  opacity: 0.3;
}
[data-scrim-bottom="4"]:before {
  opacity: 0.4;
}
[data-scrim-bottom="5"]:before {
  opacity: 0.5;
}
[data-scrim-bottom="6"]:before {
  opacity: 0.6;
}
[data-scrim-bottom="7"]:before {
  opacity: 0.7;
}
[data-scrim-bottom="8"]:before {
  opacity: 0.8;
}
[data-scrim-bottom="9"]:before {
  opacity: 0.9;
}
[data-scrim-bottom="10"]:before {
  opacity: 1;
}
[data-scrim-top] {
  position: relative;
}
[data-scrim-top]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left bottom, left bottom, color-stop(0%, #232323), color-stop(100%, rgba(0, 0, 0, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(bottom, #232323 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: -webkit-linear-gradient(top, #232323 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, #232323 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: 1;
}
[data-scrim-top]:not(.image--light) h1,
[data-scrim-top]:not(.image--light) h2,
[data-scrim-top]:not(.image--light) h3,
[data-scrim-top]:not(.image--light) h4,
[data-scrim-top]:not(.image--light) h5,
[data-scrim-top]:not(.image--light) h6 {
  color: #fff;
}
[data-scrim-top]:not(.image--light) p,
[data-scrim-top]:not(.image--light) span,
[data-scrim-top]:not(.image--light) ul {
  color: #f6f6f6;
}
[data-scrim-top].image--light:before {
  background: #fff;
}
[data-scrim-top="1"]:before {
  opacity: 0.1;
}
[data-scrim-top="2"]:before {
  opacity: 0.2;
}
[data-scrim-top="3"]:before {
  opacity: 0.3;
}
[data-scrim-top="4"]:before {
  opacity: 0.4;
}
[data-scrim-top="5"]:before {
  opacity: 0.5;
}
[data-scrim-top="6"]:before {
  opacity: 0.6;
}
[data-scrim-top="7"]:before {
  opacity: 0.7;
}
[data-scrim-top="8"]:before {
  opacity: 0.8;
}
[data-scrim-top="9"]:before {
  opacity: 0.9;
}
[data-scrim-top="10"]:before {
  opacity: 1;
}
.imagebg {
  position: relative;
}
.imagebg .container {
  z-index: 2;
}
.imagebg .container:not(.pos-absolute) {
  position: relative;
}
.imagebg:not(.image--light) h1,
.imagebg:not(.image--light) h2,
.imagebg:not(.image--light) h3,
.imagebg:not(.image--light) h4,
.imagebg:not(.image--light) h5,
.imagebg:not(.image--light) h6,
.imagebg:not(.image--light) p,
.imagebg:not(.image--light) ul,
.imagebg:not(.image--light) blockquote {
  color: #fff;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6 {
  color: #232323;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white ul {
  color: #767676;
}
div[data-overlay] h1,
div[data-overlay] h2,
div[data-overlay] h3,
div[data-overlay] h4,
div[data-overlay] h5,
div[data-overlay] h6 {
  color: #fff;
}
div[data-overlay] p,
div[data-overlay] ul {
  color: #fff;
}
.parallax {
  overflow: hidden;
}
.parallax .background-image-holder {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
}
.image--xxs {
  max-height: 1.625em;
}
.image--xs {
  max-height: 3.25em;
}
.image--sm {
  max-height: 4.875em;
}
/**! 7. Colors **/
body {
  background: #f8f8f8;
}
.color--primary {
  color: #425cbb !important;
}
a {
  color: #425cbb;
}
.color--primary-1 {
  color: #7cc36a !important;
}
.color--primary-2 {
  color: #d14b4b !important;
}
.color--white {
  color: #fff;
}
.color--dark {
  color: #232323;
}
.bg--dark {
  background: #232323;
}
.bg--dark:not(.nav-bar) {
  color: #f5f5f5;
}
.bg--dark:not(.nav-bar) h1,
.bg--dark:not(.nav-bar) h2,
.bg--dark:not(.nav-bar) h3,
.bg--dark:not(.nav-bar) h4,
.bg--dark:not(.nav-bar) h5,
.bg--dark:not(.nav-bar) h6,
.bg--dark:not(.nav-bar) i,
.bg--dark:not(.nav-bar) span:not(.btn__text),
.bg--dark:not(.nav-bar) p {
  color: #f5f5f5;
}
.bg--dark:not(.nav-bar) a:not(.btn) {
  color: #fff;
}
.bg--site {
  background: #f8f8f8;
}
.bg--secondary {
  background: #f4f4f4;
}
.bg--primary {
  background: #425cbb;
}
.bg--primary p,
.bg--primary span,
.bg--primary ul,
.bg--primary a:not(.btn) {
  color: #fff;
}
.bg--primary h1,
.bg--primary h2,
.bg--primary h3,
.bg--primary h4,
.bg--primary h5,
.bg--primary h6,
.bg--primary i {
  color: #fff;
}
.bg--white {
  background: #fff;
}
.bg--white p,
.bg--white span,
.bg--white ul,
.bg--white a:not(.btn) {
  color: #767676;
}
.bg--white h1,
.bg--white h2,
.bg--white h3,
.bg--white h4,
.bg--white h5,
.bg--white h6,
.bg--white i {
  color: #232323;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white span,
.imagebg:not(.image--light) .bg--white ul,
.imagebg:not(.image--light) .bg--white a:not(.btn) {
  color: #767676;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6,
.imagebg:not(.image--light) .bg--white i {
  color: #232323;
}
.bg--primary-1 {
  background: #7cc36a;
}
.bg--primary-1 p,
.bg--primary-1 span,
.bg--primary-1 ul,
.bg--primary-1 a:not(.btn) {
  color: #fff;
}
.bg--primary-1 h1,
.bg--primary-1 h2,
.bg--primary-1 h3,
.bg--primary-1 h4,
.bg--primary-1 h5,
.bg--primary-1 h6,
.bg--primary-1 i {
  color: #fff;
}
.bg--primary-2 {
  background: #d14b4b;
}
.bg--primary-2 p,
.bg--primary-2 span,
.bg--primary-2 ul,
.bg--primary-2 a:not(.btn) {
  color: #fff;
}
.bg--primary-2 h1,
.bg--primary-2 h2,
.bg--primary-2 h3,
.bg--primary-2 h4,
.bg--primary-2 h5,
.bg--primary-2 h6,
.bg--primary-2 i {
  color: #fff;
}
.image-bg:not(.image-light) *:not(a) {
  color: #fff;
}
.color--facebook {
  color: #3b5998;
}
.color--twitter {
  color: #00aced;
}
.color--googleplus {
  color: #dd4b39;
}
.color--instagram {
  color: #125688;
}
.color--pinterest {
  color: #cb2027;
}
.color--dribbble {
  color: #ea4c89;
}
.color--behance {
  color: #053eff;
}
.bg--facebook {
  background: #3b5998;
  color: #fff;
}
.bg--twitter {
  background: #00aced;
  color: #fff;
}
.bg--googleplus {
  background: #dd4b39;
  color: #fff;
}
.bg--instagram {
  background: #125688;
  color: #fff;
}
.bg--pinterest {
  background: #cb2027;
  color: #fff;
}
.bg--dribbble {
  background: #ea4c89;
  color: #fff;
}
.bg--behance {
  background: #053eff;
  color: #fff;
}
/**! 8. Buttons **/
.btn {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  border-radius: 100px;
  padding-top: 0.54166667em;
  padding-bottom: 0.65em;
  padding-right: 3.25em;
  padding-left: 3.25em;
  border: 1px solid #232323;
  border-width: 2px;
  font-size: inherit;
  line-height: 1.625em;
  text-transform: uppercase;
}
.btn .btn__text,
.btn i {
  color: #343434;
  border-color: #343434;
  font-weight: 400;
  font-size: 0.625em;
}
.btn[class*='col-'] {
  margin-left: 0;
  margin-right: 0;
}
.btn:active {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.btn.bg--facebook,
.btn.bg--twitter,
.btn.bg--instagram,
.btn.bg--googleplus,
.btn.bg--pinterest,
.btn.bg--dribbble,
.btn.bg--behance,
.btn.bg--dark {
  border-color: rgba(0, 0, 0, 0) !important;
}
.btn.bg--facebook .btn__text,
.btn.bg--twitter .btn__text,
.btn.bg--instagram .btn__text,
.btn.bg--googleplus .btn__text,
.btn.bg--pinterest .btn__text,
.btn.bg--dribbble .btn__text,
.btn.bg--behance .btn__text,
.btn.bg--dark .btn__text {
  color: #fff;
}
.btn.bg--facebook .btn__text i,
.btn.bg--twitter .btn__text i,
.btn.bg--instagram .btn__text i,
.btn.bg--googleplus .btn__text i,
.btn.bg--pinterest .btn__text i,
.btn.bg--dribbble .btn__text i,
.btn.bg--behance .btn__text i,
.btn.bg--dark .btn__text i {
  color: #fff;
}
.btn.bg--facebook:hover,
.btn.bg--twitter:hover,
.btn.bg--instagram:hover,
.btn.bg--googleplus:hover,
.btn.bg--pinterest:hover,
.btn.bg--dribbble:hover,
.btn.bg--behance:hover,
.btn.bg--dark:hover {
  opacity: .9;
}
@media all and (min-width: 768px) {
  .btn + .btn {
    margin-left: 1.625em;
  }
}
.btn:first-child {
  margin-left: 0;
}
.btn:last-child {
  margin-right: 0;
}
.btn--xs {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1.625em;
  padding-left: 1.625em;
}
.btn--sm {
  padding-top: 0.36111111em;
  padding-bottom: 0.43333333em;
  padding-right: 2.16666667em;
  padding-left: 2.16666667em;
}
.btn--lg {
  padding-top: 0.67708333em;
  padding-bottom: 0.8125em;
  padding-right: 4.0625em;
  padding-left: 4.0625em;
}
.btn--lg .btn__text {
  font-size: 0.78125em;
}
.btn--primary,
.btn--primary:visited {
  background: #425cbb;
  border-color: #425cbb;
}
.btn--primary .btn__text,
.btn--primary:visited .btn__text {
  color: #fff;
}
.btn--primary .btn__text i,
.btn--primary:visited .btn__text i {
  color: #fff;
}
.btn--primary:hover {
  background: #546cc3;
}
.btn--primary:active {
  background: #3b53a8;
}
.btn--primary-1,
.btn--primary-1:visited {
  background: #7cc36a;
  border-color: #7cc36a;
}
.btn--primary-1 .btn__text,
.btn--primary-1:visited .btn__text {
  color: #fff;
}
.btn--primary-1:hover {
  background: #8cca7c;
}
.btn--primary-1:active {
  background: #6cbc58;
}
.btn--primary-2,
.btn--primary-2:visited {
  background: #d14b4b;
  border-color: #d14b4b;
}
.btn--primary-2 .btn__text,
.btn--primary-2:visited .btn__text {
  color: #fff;
}
.btn--primary-2:hover {
  background: #d65f5f;
}
.btn--primary-2:active {
  background: #cc3737;
}
.btn--secondary {
  background: #f4f4f4;
  border-color: #f4f4f4;
}
.btn--secondary:hover {
  background: #f9f9f9;
}
.btn--secondary:active {
  background: #efefef;
}
.btn--white {
  background: #fff;
  color: #343434;
  border-color: #fff;
}
.btn--white i {
  color: #343434;
}
.btn--transparent {
  background: none;
  border-color: rgba(0, 0, 0, 0);
  padding-left: 0;
  padding-right: 0;
}
.btn--transparent.btn--white .btn__text {
  color: #fff;
}
.btn--unfilled {
  background: none;
}
.btn--unfilled.btn--white .btn__text {
  color: #fff;
}
.btn--unfilled.btn--white i {
  color: #fff;
}
.btn--floating {
  position: fixed;
  bottom: 3.25em;
  right: 3.25em;
  z-index: 10;
}
/**! 9. Icons **/
.icon {
  line-height: 1em;
  font-size: 2.8125em;
}
.icon--xs {
  font-size: 1em;
}
.icon--sm {
  font-size: 2em;
}
.icon--lg {
  font-size: 4em;
}
/**! 10. Lists **/
ul:last-child {
  margin: 0;
}
.list-inline li {
  padding: 0 1em;
}
.list-inline li:first-child {
  padding-left: 0;
}
.list-inline li:last-child {
  padding-right: 0;
}
.list-inline.list-inline--narrow li {
  padding: 0 .5em;
}
.list-inline.list-inline--wide li {
  padding: 0 2em;
}
/**! 11. Lightbox **/
.lb-outerContainer {
  border-radius: 0;
}
.lb-outerContainer .lb-container {
  padding: 0;
}
.lb-outerContainer .lb-container img {
  margin: 0;
}
.lightbox-gallery {
  overflow: hidden;
}
.lightbox-gallery li {
  float: left;
}
.lightbox-gallery li img {
  margin: 0;
  width: 100%;
}
.lightbox-gallery.gallery--gaps li {
  padding: 0.40625em;
}
/**! 12. Nav Bar **/
nav {
  background: none;
  z-index: 10;
}
.nav-bar {
  will-change: scroll-position, transform;
  height: 65px;
  max-height: 65px;
  line-height: 62px;
}
.nav-bar .logo {
  max-height: 40%;
  margin: 0;
}
.nav-bar .nav-module {
  display: inline-block;
  height: 65px;
  padding: 0 1em;
}
.nav-bar .nav-module > a:not(.btn) {
  height: 100%;
  display: inline-block;
}
.nav-bar.nav--fixed {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 999;
  top: 0;
}
.nav-bar.nav--fixed:not(.bg--dark):not(.bg--primary):not(.bg--white) {
  background: #f8f8f8;
}
.nav-bar.nav--fixed + .nav-mobile-toggle {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  color: #222;
}
.nav-module.left {
  float: left;
}
.nav-module.right {
  float: right;
}
.nav-module:first-of-type {
  padding-left: 0;
}
.nav-module:last-of-type {
  padding-right: 0;
}
.nav-bar,
.utility-bar {
  padding: 0 3.25em 0 3.25em;
}
.utility-bar {
  overflow: hidden;
}
.utility-bar:not(.hidden) + .nav-bar + .nav-mobile-toggle {
  top: 65px;
}
@media all and (max-width: 1280px) {
  .nav-bar {
    padding: 0 15px;
  }
}
.container .nav-bar {
  padding: 0;
}
.menu {
  height: 65px;
}
.menu li {
  position: relative;
}
.menu > li {
  position: relative;
  float: left;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0px;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
}
.menu > li > a {
  padding: 0 1.3em;
  height: 100%;
  display: inline-block;
}
.menu > li:first-child > a {
  padding-left: 0;
}
.menu > li:last-child a {
  padding-right: 0;
}
.nav-bar.bg--white .menu > li > a {
  color: #767676;
}
.nav-bar.bg--dark .menu > li > a {
  color: #fff;
}
.menu > li ul {
  left: 0;
  margin: 0;
  line-height: 1.625em !important;
  padding: 1em 0;
  background: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  min-width: 13em;
  float: left;
}
.menu > li > ul a {
  padding: 0 1em 1.625em 1em;
  display: inline-block;
}
.menu > li > ul li:last-child > a {
  padding-bottom: 0;
}
.menu > li:hover > ul,
.menu > li > ul > li:hover ul {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}
.menu > li:hover .multi-column ul {
  position: relative !important;
  visibility: visible !important;
  opacity: 1 !important;
}
.menu > li > ul:not(.multi-column) ul {
  top: 0;
  left: 100%;
}
.multi-column {
  width: auto !important;
  white-space: nowrap;
}
.multi-column li {
  width: 13em;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}
.multi-column li ul {
  position: relative;
}
.multi-column ul li {
  float: none;
  display: block;
}
.module-applet {
  position: relative;
  padding: 0 1em;
}
.module-applet__handle {
  cursor: pointer;
  position: relative;
}
.module-applet__body {
  position: absolute;
  width: 26em;
  background: none;
  z-index: 10;
  padding: 1em;
  visibility: hidden;
  opacity: 0;
}
.module-applet__body * {
  line-height: 1.625em;
}
.module-applet:hover .module-applet__body {
  opacity: 1;
  visibility: visible;
}
.nav-mobile-toggle {
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 99;
  cursor: pointer;
  line-height: 62px;
  padding: 0 1em;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}
@media all and (max-width: 990px) {
  .nav-bar {
    height: auto;
    overflow: hidden;
  }
  .nav-bar .nav-module.left,
  .nav-bar .nav-module.right {
    float: none;
    display: block;
  }
  .nav-bar .nav-module {
    padding: 0;
  }
  .nav-bar .nav-module:not(.logo-module) {
    height: auto;
    line-height: 2em;
  }
  .menu {
    height: auto;
  }
  .menu li {
    float: none;
    width: 100%;
  }
  .menu > li > a {
    padding: 0;
  }
  .menu > li ul {
    position: relative;
    float: none;
    display: none;
    width: 100%;
  }
  .menu > li ul ul {
    left: 0 !important;
    padding-left: 2em;
  }
  .menu > li > ul:not(.multi-column) ul {
    padding-top: 0;
    padding-bottom: 1.5em;
  }
  .menu li.active > ul,
  .menu li.active .multi-column ul {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .menu .multi-column {
    white-space: normal;
    white-space: initial;
  }
  .menu .multi-column ul {
    padding-top: 0;
    padding-bottom: 0;
  }
  .menu .multi-column ul li a {
    padding-left: 0;
  }
  .menu .multi-column ul li:last-child a {
    padding-bottom: 1.625em;
  }
  .module-applet {
    padding: 0;
  }
  .module-applet .module-applet__body {
    position: relative;
    display: none;
    padding: 1em 0;
    opacity: 1;
    visibility: visible;
  }
  .module-applet.active .module-applet__body {
    display: block;
  }
}
.nav-open .nav-bar {
  max-height: 100000px !important;
}
.nav--fixed,
.nav--absolute {
  width: 100%;
  z-index: 99;
}
.nav--fixed {
  position: fixed;
}
.nav--absolute {
  position: absolute;
}
.nav--transparent:not(.nav--fixed) {
  background: none !important;
}
nav:not(.nav--fixed):not(.nav--absolute) {
  position: relative;
}
@media all and (max-width: 990px) {
  .nav-bar.nav--absolute,
  .nav-bar.nav--fixed {
    position: relative;
  }
  .nav-bar.nav--absolute + .nav-mobile-toggle,
  .nav-bar.nav--fixed + .nav-mobile-toggle {
    position: absolute;
  }
}
/**! 13. Form Elements **/
form {
  max-width: 100%;
}
form + form {
  margin-top: 30px;
}
form:before,
form:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
form:after {
  clear: both;
}
label {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
}
input[type],
textarea,
select {
  -webkit-appearance: none;
  background: #ffffff;
  padding: 0.8125em;
  border-radius: 50px;
  border: none;
}
input[type]:focus,
textarea:focus,
select:focus {
  outline: none;
}
input[type]:active,
textarea:active,
select:active {
  outline: none;
}
input[type]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #c3c3c3;
  font-size: 1em;
}
input[type]:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder {
  /* Firefox 18- */
  color: #c3c3c3;
  font-size: 1em;
}
input[type]::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
  /* Firefox 19+ */
  color: #c3c3c3;
  font-size: 1em;
}
input[type]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #c3c3c3;
  font-size: 1em;
}
textarea {
  display: block;
  width: 100%;
  max-width: 100%;
}
select {
  cursor: pointer;
  padding-right: 3.25em;
  -webkit-appearance: none;
}
select::ms-expand {
  display: none;
}
input[type="submit"] {
  background: none;
  outline: none;
  border: none;
  background: #425cbb;
  padding: 0.8125em 3.25em 0.8125em 3.25em;
  color: #fff;
}
@-webkit-keyframes load {
  0% {
    opacity: 0;
    width: 0;
  }
  50% {
    width: 100%;
    opacity: .8;
    left: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
@keyframes load {
  0% {
    opacity: 0;
    width: 0;
  }
  50% {
    width: 100%;
    opacity: .8;
    left: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
button {
  background: none;
}
button[type="submit"].btn--loading {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  color: rgba(0, 0, 0, 0);
}
button[type="submit"].btn--loading * {
  opacity: 0;
}
button[type="submit"].btn--loading:after {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  background: #ddd;
  -webkit-animation: load 1.5s ease-out infinite;
          animation: load 1.5s ease-out infinite;
  left: 0;
  top: 0;
}
button[type="submit"].btn--loading .btn__text {
  opacity: 0;
}
button:focus {
  outline: none !important;
}
.input-checkbox,
.input-radio,
.input-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  cursor: pointer;
}
.input-checkbox .inner,
.input-radio .inner,
.input-select .inner {
  display: inline-block;
}
.input-checkbox input,
.input-radio input,
.input-select input {
  display: none;
}
.input-checkbox.checked .inner,
.input-radio.checked .inner,
.input-select.checked .inner {
  background: #425cbb;
}
.input-checkbox label,
.input-radio label,
.input-select label {
  display: block;
}
.input-checkbox {
  padding: 0;
}
.input-checkbox .inner {
  width: 1.625em;
  height: 1.625em;
  border-radius: 50%;
  background: #000;
}
.input-radio {
  padding: 0;
}
.input-radio .inner {
  width: 1.625em;
  height: 1.625em;
  border-radius: 50%;
  background: #000;
}
.input-select {
  position: relative;
}
.input-select i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 1em;
  font-size: .87em;
}
.input-file {
  position: relative;
  display: inline-block;
}
.input-file input {
  display: none;
}
.form-error {
  margin-top: 1.5625em;
  padding: 0.78125em;
  background: #D84D4D;
  color: #fff;
  position: fixed;
  min-width: 350px;
  left: 50%;
  bottom: 1.5625em;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.form-success {
  margin-top: 1.5625em;
  padding: 0.78125em;
  background: #1DC020;
  color: #fff;
  position: fixed;
  min-width: 350px;
  left: 50%;
  bottom: 1.5625em;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.attempted-submit .field-error {
  border-color: #D84D4D !important;
}
/**! 14. Accordions **/
.accordion li .accordion__title,
.accordion li .accordion__content,
.accordion li .accordion__content * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.accordion li .accordion__title {
  cursor: pointer;
  padding: 1.08333333em 1.625em;
  border: 1px solid #dfdfdf;
  border-bottom: none;
  background: #f4f4f4;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.accordion li:last-child .accordion__title {
  border-bottom: 1px solid #dfdfdf;
}
.accordion li .accordion__content {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}
.accordion li .accordion__content > * {
  display: none;
}
.accordion li .accordion__content *:first-child {
  padding-top: 1.625em;
}
.accordion li .accordion__content *:last-child {
  padding-bottom: 1.625em;
}
.accordion li.active .accordion__title {
  background: #425cbb;
  border-bottom: 1px solid #dfdfdf;
}
.accordion li.active .accordion__content {
  opacity: 1;
  visibility: visible;
  max-height: 500px;
}
.accordion li.active .accordion__content > * {
  display: inline-block;
}
/**! 15. Breadcrumbs **/
.breadcrumb {
  padding: 0;
  margin: 0;
  background: none;
  display: inline-block;
}
.breadcrumb li {
  font-size: 1em;
  text-transform: uppercase;
}
.breadcrumb li + li:before {
  padding: 0 0.40625em;
}
/**! 17. Tabs **/
.tabs {
  display: block;
  margin-bottom: 0;
}
.tabs > li {
  display: inline-block;
  opacity: .5;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tabs > .active,
.tabs:hover {
  opacity: 1;
}
.tabs .tab__title {
  cursor: pointer;
}
.tabs .tab__title:not(.btn) {
  padding: 0 1.625em;
}
.tabs li:first-child .tab__title:not(.btn) {
  padding-left: 0;
}
.tabs .tab__content {
  display: none;
}
.tabs-content {
  margin-top: 1em;
}
.tabs-content li > .tab__content {
  width: 100%;
  display: none;
}
.tabs-content > .active > .tab__content {
  display: block;
}
.tabs-container[data-content-align="left"] .tabs-content {
  text-align: left;
}
/**! 18. Boxes **/
.boxed {
  position: relative;
  overflow: hidden;
  padding: 2.4375em;
  margin-bottom: 30px;
}
.boxed.boxed--lg {
  padding: 3.65625em;
}
.boxed.boxed--sm {
  padding: 1.625em;
}
.boxed.boxed--border {
  border: 1px solid #c9c9c9;
}
.boxed > div[class*='col-']:first-child:not(.boxed) {
  padding-left: 0;
}
.boxed > div[class*='col-']:last-child:not(.boxed) {
  padding-right: 0;
}
img + .boxed {
  margin-top: -1.625em;
}
@media all and (max-width: 767px) {
  .boxed {
    padding: 1.625em;
    margin-bottom: 15px;
  }
  .boxed.boxed--lg {
    padding: 1.625em;
  }
  .boxed div[class*='col-']:not(.boxed) {
    padding: 0;
  }
  .boxed:last-child {
    margin-bottom: 15px;
  }
  .row:last-child div[class*='col-']:last-child .boxed:last-child {
    margin-bottom: 0;
  }
}
/**! 19. Sliders **/
section.slider {
  padding: 0 !important;
  overflow: hidden;
  position: relative;
}
section.slider .owl-theme .owl-controls .owl-nav div.owl-prev,
section.slider .owl-theme .owl-controls .owl-nav div.owl-next {
  color: #fff;
}
.slides {
  margin: 0;
  overflow: hidden;
}
.slides li > [class*='col-']:first-child:last-child:not([class*='offset']) {
  width: 100% !important;
}
.slider {
  position: relative;
  overflow: hidden;
}
.slider .slides li {
  position: relative;
  padding: 6.5em 0;
}
.slider.space--lg .slides li {
  padding: 13em 0;
}
.slider.space--xlg .slides li {
  padding: 26em 0;
}
.slider:hover .flex-direction-nav a {
  opacity: 1;
}
.slider.height-10 {
  height: auto;
}
.slider.height-10 .slides li {
  height: 10vh;
  padding: 0;
}
.slider.height-20 {
  height: auto;
}
.slider.height-20 .slides li {
  height: 20vh;
  padding: 0;
}
.slider.height-30 {
  height: auto;
}
.slider.height-30 .slides li {
  height: 30vh;
  padding: 0;
}
.slider.height-40 {
  height: auto;
}
.slider.height-40 .slides li {
  height: 40vh;
  padding: 0;
}
.slider.height-50 {
  height: auto;
}
.slider.height-50 .slides li {
  height: 50vh;
  padding: 0;
}
.slider.height-60 {
  height: auto;
}
.slider.height-60 .slides li {
  height: 60vh;
  padding: 0;
}
.slider.height-70 {
  height: auto;
}
.slider.height-70 .slides li {
  height: 70vh;
  padding: 0;
}
.slider.height-80 {
  height: auto;
}
.slider.height-80 .slides li {
  height: 80vh;
  padding: 0;
}
.slider.height-90 {
  height: auto;
}
.slider.height-90 .slides li {
  height: 90vh;
  padding: 0;
}
.slider.height-100 {
  height: auto;
}
.slider.height-100 .slides li {
  height: 100vh;
  padding: 0;
}
.slider.slider-controls--white .flex-direction-nav a {
  color: #fff;
}
.slider.slider-controls--white .flex-control-paging a {
  background: #fff;
}
.slider[data-animation="slide"] .slides li {
  -webkit-backface-visibility: hidden;
}
@media all and (max-width: 767px) {
  .slider[class*='height-'] .slides li {
    height: auto;
    padding: 6.5em 0;
  }
  .slider.space--lg .slides li {
    padding: 9.75em 0;
  }
  .slider.space--xlg .slides li {
    padding: 9.75em 0;
  }
}
.owl-theme .owl-controls {
  margin: 0;
}
.owl-theme .owl-controls .owl-dots {
  position: absolute;
  width: 100%;
  bottom: 1.625em;
  z-index: 99;
  text-align: center;
}
.owl-theme .owl-controls .owl-dots .owl-dot {
  display: inline-block;
  margin: 0 0.40625em;
  overflow: hidden;
}
.owl-theme .owl-controls .owl-dots .owl-dot span {
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  border: none;
  box-shadow: none;
  margin: 0;
}
.owl-theme .owl-controls .owl-dots .owl-dot span:hover {
  background: #ffffff;
}
.owl-theme .owl-controls .owl-dots .owl-dot.active span {
  background: #ffffff;
}
.owl-theme .owl-controls .owl-nav {
  position: absolute;
  z-index: 99;
  top: 50%;
  width: 100%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
}
.owl-theme .owl-controls .owl-nav div {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  position: absolute;
  margin: 0;
  padding: 0;
  background: none;
}
.owl-theme .owl-controls .owl-nav div:before {
  font-family: 'pillar-interface';
  font-size: 2em;
  text-align: center;
}
.owl-theme .owl-controls .owl-nav div.owl-next {
  right: 1.625em;
}
.owl-theme .owl-controls .owl-nav div.owl-next:before {
  content: "\e802";
}
.owl-theme .owl-controls .owl-nav div.owl-prev {
  left: 1.625em;
}
.owl-theme .owl-controls .owl-nav div.owl-prev:before {
  content: "\e801";
}
.slider.slider--controlsoutside {
  overflow: visible;
}
.slider.slider--controlsoutside .slides li {
  padding: 0;
}
.slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots {
  position: relative;
  bottom: 0;
}
.slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots .owl-dot {
  display: inline-block;
}
.slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots .owl-dot span {
  background: #232323;
  display: inline-block;
}
.slider.slider--controlsoutside[data-arrows="true"] .owl-theme .owl-controls .owl-nav .owl-next {
  right: -4em;
}
.slider.slider--controlsoutside[data-arrows="true"] .owl-theme .owl-controls .owl-nav .owl-prev {
  left: -4em;
}
.imagebg .slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots,
section.bg--dark .slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots {
  position: relative;
  bottom: 0;
}
.imagebg .slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots .owl-dot span,
section.bg--dark .slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots .owl-dot span {
  background: #fff;
}
/**! 20. Hover Elements **/
.hover-element {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.hover-element * {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.hover-element .hover-element__reveal {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.hover-element .hover-element__reveal .boxed {
  height: 100%;
}
.hover-element:hover .hover-element__reveal,
.hover-element.hover--active .hover-element__reveal {
  opacity: 1;
}
.hover-element img {
  margin-bottom: 0;
}
@media all and (max-width: 1024px) {
  .hover-element {
    cursor: pointer;
  }
}
.row:last-child div[class*='col-']:last-child .hover-element {
  margin-bottom: 0;
}
/**! 21. Masonry **/
.masonry .masonry__container.masonry--active .masonry__item {
  opacity: 1;
  pointer-events: initial;
}
.masonry .masonry__container .masonry__item {
  opacity: 0;
  pointer-events: none;
}
.masonry .masonry__filters li {
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.masonry .masonry__filters li.active {
  cursor: default;
}
/**! 22. Modals **/
.modal-instance .modal-body {
  display: none;
}
.modal-container {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.modal-container.modal-active {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}
.modal-container:before {
  background: rgba(0, 0, 0, 0.85);
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.modal-container .modal-content {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  max-height: 100%;
  overflow-y: auto;
  border: none;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
.modal-container .modal-content:not(.height--natural) {
  width: 50%;
  height: 50%;
}
.modal-container .modal-content .modal-close-cross {
  cursor: pointer;
  position: absolute;
  opacity: .5;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  top: 1em;
  right: 1em;
  z-index: 99;
}
.modal-container .modal-content .modal-close-cross:before {
  content: '\2715';
  font-size: 1.5em;
}
.modal-container .modal-content .modal-close-cross:hover {
  opacity: 1;
}
.modal-container .modal-content.imagebg:not(.image--light) .modal-close-cross:before {
  color: #fff;
}
.modal-container .modal-content iframe {
  width: 100%;
  outline: none;
  border: none;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.modal-container .modal-content iframe:first-child + .modal-close-cross:last-child {
  top: -3.25em;
}
@media all and (max-width: 767px) {
  .modal-container .modal-content {
    width: 97% !important;
    height: auto !important;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
/**! 23. Maps **/
.map-container {
  position: relative;
  overflow: hidden;
}
.map-container iframe,
.map-container .map-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
/**! 24. Parallax **/
.parallax > .background-image-holder,
.parallax .slides li > .background-image-holder {
  height: 100vh;
  top: -50vh;
  transition: opacity 0.3s ease !important;
  -webkit-transition: opacity 0.3s ease !important;
  -webkit-transform-style: preserve-3d;
}
.parallax:first-child .slides li > .background-image-holder,
.parallax:first-child .background-image-holder {
  top: 0;
}
.main-container > a:first-child + .parallax .background-image-holder {
  top: 0;
}
@media all and (max-width: 1024px) {
  .parallax > .background-image-holder,
  .parallax .slides li > .background-image-holder {
    top: 0 !important;
    transform: none !important;
    -webkit-transform: none !important;
    height: 100%;
  }
}
.parallax {
  will-change: contents;
}
/**! 25. Notifications **/
.notification {
  max-width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: fixed;
  z-index: 99;
  pointer-events: none;
  padding: 0;
  margin: 1em;
  opacity: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.notification:not([class*='bg-']) {
  background: #fff;
}
.notification[class*='col-'] {
  min-width: 400px;
}
.notification .notification-close-cross {
  position: absolute;
  top: 1em;
  z-index: 99;
  right: 1em;
  cursor: pointer;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  opacity: .7;
}
.notification .notification-close-cross:before {
  content: '\2715';
  font-size: 1.5em;
}
.notification .notification-close-cross:hover {
  opacity: 1;
}
.notification.notification--reveal {
  z-index: 99;
  pointer-events: initial;
}
.notification.notification--reveal[data-animation="from-bottom"] {
  animation: from-bottom 0.3s linear 0s forwards;
  -webkit-animation: from-bottom 0.3s linear 0s forwards;
  -moz-animation: from-bottom 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-top"] {
  animation: from-top 0.3s linear 0s forwards;
  -webkit-animation: from-top 0.3s linear 0s forwards;
  -moz-animation: from-top 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-left"] {
  animation: from-left 0.3s linear 0s forwards;
  -webkit-animation: from-left 0.3s linear 0s forwards;
  -moz-animation: from-left 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-right"] {
  animation: from-right 0.3s linear 0s forwards;
  -webkit-animation: from-right 0.3s linear 0s forwards;
  -moz-animation: from-right 0.3s linear 0s forwards;
}
.notification.notification--dismissed {
  animation: fade-out 0.4s linear 0s forwards !important;
  -webkit-animation: fade-out 0.4s linear 0s forwards !important;
  -moz-animation: fade-out 0.4s linear 0s forwards !important;
  pointer-events: none;
}
a[data-notification-link] {
  text-decoration: none;
}
a[data-notification-link]:hover {
  text-decoration: none;
}
@media all and (max-width: 767px) {
  .notification[class*='col-'] {
    min-width: 0;
  }
}
@keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/**! 26. Video **/
iframe {
  width: 100%;
  min-height: 350px;
  border: none;
}
@media all and (max-width: 767px) {
  iframe {
    min-height: 220px;
  }
}
.videobg {
  background: #232323;
  position: relative;
  overflow: hidden;
}
.videobg .container,
.videobg .background-image-holder {
  opacity: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.videobg .background-image-holder {
  opacity: 0 !important;
}
.videobg.video-active .container {
  opacity: 1;
}
.videobg.video-active .loading-indicator {
  opacity: 0;
  visibility: hidden;
}
.videobg video {
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  z-index: 0 !important;
  left: 0;
}
@media all and (max-width: 1024px) {
  .videobg .background-image-holder,
  .videobg .container {
    opacity: 1 !important;
  }
  .videobg .loading-indicator {
    display: none;
  }
  .videobg video {
    display: none;
  }
}
.youtube-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 0 !important;
}
.youtube-background .mb_YTPBar {
  opacity: 0;
  height: 0;
  visibility: hidden;
}
@media all and (max-width: 1024px) {
  .youtube-background {
    display: none;
  }
}
.loading-indicator {
  position: absolute !important;
  top: 50%;
  left: 50%;
  z-index: 99 !important;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: loading-spinner 1s infinite ease-in-out;
  animation: loading-spinner 1s infinite ease-in-out;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
@-webkit-keyframes loading-spinner {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes loading-spinner {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.video-cover {
  position: relative;
}
.video-cover video {
  max-width: 100%;
}
.video-cover iframe {
  background: #232323;
}
.video-cover .background-image-holder {
  z-index: 3;
}
.video-cover .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
}
.video-cover .video-play-icon,
.video-cover .background-image-holder {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.video-cover.reveal-video .video-play-icon,
.video-cover.reveal-video .background-image-holder {
  opacity: 0 !important;
  pointer-events: none;
}
.video-cover[data-scrim-bottom]:before,
.video-cover[data-overlay]:before,
.video-cover[data-scrim-top]:before {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  z-index: 4;
}
.video-cover.reveal-video[data-scrim-bottom]:before,
.video-cover.reveal-video[data-overlay]:before,
.video-cover.reveal-video[data-scrim-top]:before {
  opacity: 0;
  pointer-events: none;
}
.video-play-icon {
  width: 6.5em;
  height: 6.5em;
  border-radius: 50%;
  position: relative;
  z-index: 4;
  display: inline-block;
  border: 2px solid #ffffff;
  cursor: pointer;
  background: #ffffff;
}
.video-play-icon.video-play-icon--sm {
  width: 3.25em;
  height: 3.25em;
}
.video-play-icon.video-play-icon--sm:before {
  border-width: 4px 0 4px 9px;
}
.video-play-icon.video-play-icon--xs {
  width: 1.625em;
  height: 1.625em;
}
.video-play-icon.video-play-icon--xs:before {
  border-width: 3px 0 3px 6px;
  margin-left: -3px;
}
.video-play-icon.bg--primary:before {
  border-color: transparent transparent transparent #fff;
}
.video-play-icon:before {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 50%;
  margin-left: -4px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 12px;
  border-color: transparent transparent transparent #ffffff;
  border-color: transparent transparent transparent #808080;
}
.video-play-icon.video-play-icon--dark {
  border-color: #232323;
  background: #232323;
}
.video-play-icon.video-play-icon--dark:before {
  border-color: transparent transparent transparent #232323;
}
.video-play-icon.video-play-icon--dark:before {
  border-color: transparent transparent transparent #fff;
}
@media all and (max-width: 767px) {
  .video-play-icon {
    width: 4.33333333em;
    height: 4.33333333em;
  }
}
div[class*='col-'][class*='12']:not([class*='xs-12']) .video-cover iframe {
  min-height: 550px;
}
@media all and (max-width: 990px) {
  div[class*='col-'][class*='12']:not([class*='xs-12']) .video-cover iframe {
    min-height: 350px;
  }
}
div[class*='col-'][class*='10'] .video-cover iframe {
  min-height: 450px;
}
div[class*='col-'][class*='8'] .video-cover iframe {
  min-height: 400px;
}
div[class*='col-'][class*='6'] .video-cover iframe {
  min-height: 350px;
}
@media all and (max-width: 1200px) {
  div[class*='col-'][class*='6'] .video-cover iframe {
    min-height: 300px;
  }
}
@media all and (max-width: 990px) {
  div[class*='col-'][class*='6'] .video-cover iframe {
    min-height: 220px;
  }
}
@media all and (max-width: 767px) {
  div[class*='col-'] .video-cover iframe {
    min-height: 220px !important;
  }
}
.modal-container video {
  max-width: 100%;
}
/**! 27. Image Blocks **/
.imageblock {
  position: relative;
  padding: 0;
}
.imageblock > .container,
.imageblock > div[class*='col-']:not(.imageblock__content) {
  padding-top: 6.5em;
  padding-bottom: 8.125em;
  float: none;
  overflow: hidden;
}
.imageblock.imageblock--lg > .container,
.imageblock.imageblock--lg > div[class*='col-']:not(.imageblock__content) {
  padding-top: 10.15625em;
  padding-bottom: 10.15625em;
  float: none;
  overflow: hidden;
}
.imageblock .imageblock__content {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;
  padding: 0;
}
.imageblock .imageblock__content .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.imageblock .imageblock__content .slider .slides > li {
  padding: 0;
  min-height: 100%;
  position: absolute !important;
}
.imageblock.allow-overflow .imageblock__content {
  overflow: visible;
}
@media all and (max-width: 767px) {
  .imageblock[class*='space-'] {
    padding-bottom: 0;
    padding-top: 0;
  }
  .imageblock .imageblock__content {
    position: relative;
    min-height: 16.25em;
  }
  .imageblock > .container,
  .imageblock > div[class*='col-']:not(.imageblock__content) {
    padding-top: 4.875em;
    padding-bottom: 4.875em;
    float: none;
    overflow: hidden;
  }
  .imageblock.imageblock--lg > .container,
  .imageblock.imageblock--lg > div[class*='col-']:not(.imageblock__content) {
    padding-top: 4.875em;
    padding-bottom: 4.875em;
    float: none;
    overflow: hidden;
  }
}
/**! 28. MailChimp & Campaign Monitor **/
form[action*='createsend.com'] * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
}
form[action*='createsend.com'].form--active * {
  opacity: 1;
}
form[action*='createsend.com'] .input-checkbox + br {
  display: none;
}
form[action*='createsend.com'].no-labels label {
  display: none;
}
form[action*='createsend.com'] br {
  display: none;
}
form[action*='createsend.com'] p > label:first-child {
  margin-bottom: 0.8125em;
}
form[action*='list-manage.com'] h2 {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 1.4375em;
  line-height: 1.41304348em;
  margin-top: 0;
  margin-bottom: 1.1304347826087em;
}
form[action*='list-manage.com'] .input-group ul {
  overflow: hidden;
}
form[action*='list-manage.com'] .input-group ul li {
  float: left;
}
form[action*='list-manage.com'] * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
}
form[action*='list-manage.com'].form--active * {
  opacity: 1;
}
form[action*='list-manage.com'].no-labels label {
  display: none;
}
form[action*='list-manage.com'] .small-meta {
  font-size: 0.5em;
}
/**! 29. Twitter **/
.twitter-feed .user {
  display: none;
}
.twitter-feed .interact {
  display: none;
}
.twitter-feed .timePosted {
  font-size: .87em;
}
/**! 30. Transitions **/
[class*='transition--'] {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  opacity: 0;
}
[class*='transition--'].transition--active {
  opacity: 1;
}
.transition--scale {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
}
.transition--scale.transition--active {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
}
.transition--slide {
  transform: translate3d(200px, 0, 0);
  -webkit-transform: translate3d(200px, 0, 0);
  transform: translate3d(30vw, 0, 0);
  -webkit-transform: translate3d(30vw, 0, 0);
}
.transition--slide.transition--active {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
/**! 31. Spacing **/
section,
footer {
  padding-top: 6.5em;
  padding-bottom: 8.125em;
}
section.space--xxs,
footer.space--xxs {
  padding-top: 1.625em;
  padding-bottom: 1.625em;
}
section.space--xs,
footer.space--xs {
  padding-top: 3.25em;
  padding-bottom: 3.25em;
}
section.space--sm,
footer.space--sm {
  padding-top: 4.33333333em;
  padding-bottom: 4.33333333em;
}
section.space--md,
footer.space--md {
  padding-top: 9.75em;
  padding-bottom: 9.75em;
}
section.space--lg,
footer.space--lg {
  padding-top: 13em;
  padding-bottom: 13em;
}
section.space--xlg,
footer.space--xlg {
  padding-top: 26em;
  padding-bottom: 32.5em;
}
section.space--0,
footer.space--0 {
  padding: 0;
}
section.section--even,
footer.section--even {
  padding-top: 8.125em;
  padding-bottom: 8.125em;
}
section.space-bottom--sm,
footer.space-bottom--sm {
  padding-bottom: 4.33333333em;
}
@media all and (max-width: 767px) {
  section,
  footer,
  section.section--even {
    padding: 4.875em 0;
  }
  section.space--lg,
  footer.space--lg,
  section.section--even.space--lg {
    padding: 4.875em 0;
  }
  section.space--xlg,
  footer.space--xlg,
  section.section--even.space--xlg {
    padding: 7.3125em 0;
  }
}
div[class*='col-'] > div[class*='col-']:first-child {
  padding-left: 0;
}
div[class*='col-'] > div[class*='col-']:last-child {
  padding-right: 0;
}
@media all and (max-width: 767px) {
  .col-xs-6:nth-child(odd) {
    padding-right: 7.5px;
  }
  .col-xs-6:nth-child(even) {
    padding-left: 7.5px;
  }
}
@media all and (min-width: 768px) {
  .mt--1 {
    margin-top: 1.625em;
  }
  .mt--2 {
    margin-top: 3.25em;
  }
  .mt--3 {
    margin-top: 4.875em;
  }
}
@media all and (max-width: 990px) {
  .mt--1,
  .mt--2 {
    margin-top: 1.625em;
  }
  .mt--3 {
    margin-top: 2.4375em;
  }
}
/**! 32. Theme Overrides **/
/*! -- Pillar Customizers -- */
body.theme--square .btn {
  border-radius: 0 0 0 0;
}
body.theme--square input {
  border-radius: 0 0 0 0;
}
body.theme--square button {
  border-radius: 0 0 0 0 !important;
}
body[data-scroll-reveal="true"] section:not(:first-child) {
  visibility: hidden;
}
body.theme--boxed {
  padding: 4.875em 0;
  background: #eeeeee;
}
body.theme--boxed .main-container,
body.theme--boxed nav,
body.theme--boxed .nav-bar.nav--fixed {
  max-width: 1400px;
  margin: 0 auto;
  left: 0;
  right: 0;
}
body.theme--boxed section:not(.bg--white):not(.bg--dark):not(.bg--secondary):not(.bg--dark):not(.bg--primary):not(.section--overlap) {
  background: #f8f8f8;
}
body.theme--boxed section.section--overlap + section:not(.bg--white):not(.bg--dark):not(.bg--secondary):not(.bg--dark):not(.bg--primary):not(.section--overlap) {
  padding-top: 6.5em !important;
}
@media all and (max-width: 1400px) {
  body.theme--boxed {
    padding: 0;
  }
}
.btn.btn--square {
  border-radius: 0 0 0 0;
}
.btn.btn--transparent {
  border: none !important;
}
.loader {
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
  overflow: hidden;
  pointer-events: none;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.loader.loader--fade {
  transition: none;
  -webkit-transition: none;
  opacity: 0;
}
.loader.loader--fade:before {
  -webkit-animation: none;
          animation: none;
}
.loader:before {
  display: block;
  position: absolute;
  content: "";
  left: -25%;
  width: 25%;
  height: 3px;
  background-color: #425cbb;
  -webkit-animation: loading 5s linear infinite;
          animation: loading 5s linear infinite;
}
@-webkit-keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 125%;
  }
}
@keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 125%;
  }
}
/*! -- Pillar Typography -- */
@media all and (max-width: 1024px) {
  html {
    font-size: 87.5%;
  }
}
@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-SemiBold.cbdf3ab9.otf) format('opentype');
  font-weight: 600;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
}
h1,
.h1 {
  /* margin-bottom: 0.40625em; */
}
h2,
.h2 {
  /* margin-bottom: 0.57777777777778em; */
}
h3,
.h3 {
  /* margin-bottom: 0.8125em; */
}
h4,
.h4 {
  /* margin-bottom: 1.1304347826087em; */
}
h5,
.h5 {
  /* margin-bottom: 1.625em; */
}
h6,
.h6 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
}
@media all and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2em;
    line-height: 1.421875em;
  }
}
.lead {
  margin-bottom: 1.1304347826087em;
  font-weight: 400;
}
.lead:last-child {
  margin-bottom: 0;
}
a {
  color: #232323;
}
a:hover {
  color: #232323;
}
span a,
p a {
  text-decoration: underline;
}
span a:hover,
p a:hover {
  text-decoration: underline;
  opacity: .9;
}
h1 em,
h2 em,
h3 em,
h4 em,
h5 em,
h6 em {
  font-family: 'Lora';
  font-weight: 400;
  font-style: italic;
}
.type--body-font {
  font-weight: 400;
}
.type--body-font em {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
}
.imagebg:not(.image--light) .h1,
.imagebg:not(.image--light) .h2,
.imagebg:not(.image--light) .h3,
.imagebg:not(.image--light) .h4,
.imagebg:not(.image--light) .h5,
.imagebg:not(.image--light) .h6,
.imagebg:not(.image--light) i {
  color: #fff;
}
.imagebg:not(.image--light) p {
  opacity: .9;
}
.imagebg:not(.image--light) p a {
  color: #fff;
}
hr,
.boxed.boxed--border {
  border-color: #ebebeb;
}
.bg--secondary hr {
  border-color: #e7e7e7;
}
blockquote {
  font-size: 1.4375em;
  line-height: 1.69565217391304em;
  font-style: italic;
  margin-bottom: 1.1304347826087em;
}
.bg--primary p,
.bg--primary span,
.bg--primary ul,
.bg--primary a:not(.btn),
.bg--primary .icon {
  color: #fff;
  opacity: .9;
}
.bg--primary h1,
.bg--primary h2,
.bg--primary h3,
.bg--primary h4,
.bg--primary h5,
.bg--primary h6,
.bg--primary i {
  color: #fff;
}
.link-list li:nth-child(even) {
  background: #f4f4f4;
}
.link-list li a {
  display: inline-block;
  padding: 0.40625em;
  font-style: italic;
  opacity: .5;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.link-list li a:before {
  content: '\203A';
  opacity: .5;
  margin-right: 0.8125em;
}
.link-list li a:hover {
  opacity: 1;
}
p[class*='col-'] {
  padding: 0;
}
span.label {
  display: inline-block;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  font-size: 10px;
  padding: 0 8px;
  color: #fff !important;
  background: #64b671;
  position: absolute;
  top: 13px;
  right: 13px;
  margin: 0;
}
.menu li a + span.label {
  right: 26px;
  top: 0;
  max-height: 20px;
  line-height: 20px;
}
/*! -- Pillar Mixins & Variable -- */
.box-shadow {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.box-shadow-light {
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
}
.box-shadow-inset {
  box-shadow: inset 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.box-shadow-wide {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.iconsmind {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*! -- Pillar Buttons -- */
.btn.btn--primary {
  border-color: #425cbb;
}
.btn.btn--primary:hover {
  border-color: #546cc3;
}
.btn.btn--success {
  background: #64b671;
  border-color: #64b671;
}
.btn.btn--success:hover {
  border-color: #75be81;
}
.btn.btn--success .btn__text {
  color: #fff;
}
@media all and (max-width: 767px) {
  .btn + .btn {
    margin-left: 0;
    margin-top: 4px;
  }
}
@media all and (min-width: 990px) {
  .btn--merge {
    border-radius: 100px 0 0 100px;
    position: relative;
  }
  .btn--merge + .btn {
    border-radius: 0 100px 100px 0;
    margin-left: -5px;
  }
  .btn--merge + .btn.btn--primary {
    background: #546cc3;
    border-color: #546cc3;
  }
  .btn--merge + .btn.btn--primary:hover {
    background: #5f75c7;
    border-color: #5f75c7;
  }
}
.btn--xs {
  padding-bottom: 1px;
}
.bg--primary .btn {
  border-color: rgba(255, 255, 255, 0.1);
}
.bg--primary .btn .btn__text {
  color: #fff;
}
.bg--primary .btn:hover {
  border-color: rgba(255, 255, 255, 0.6);
}
.btn-round {
  width: 4.0625em;
  height: 4.0625em;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: 0.2s linear !important;
  -webkit-transition: 0.2s linear !important;
  -moz-transition: 0.2s linear !important;
}
.btn-round i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  font-size: 1.5em;
  width: 100%;
}
.btn-round:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
}
/*! -- Pillar Nav Bar -- */
.logo {
  margin: 0;
}
.nav-bar {
  padding-right: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.nav-bar .logo {
  margin-right: 1.625em;
}
.nav-bar .logo-light {
  display: none;
}
.nav-bar .logo-dark {
  display: inline;
}
.nav-bar.nav--absolute {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-bar.nav--absolute:not(.nav--fixed) .nav-module.right:not(.logo-module):not(.menu-module):not( :nth-last-child(2)) {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-bar .nav-module.right:not(.logo-module):not(.menu-module) {
  padding: 0 1.625em;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.nav-bar .nav-module.right:not(.logo-module):not(.menu-module):not( :nth-last-child(2)) {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding-right: 0;
}
.nav-bar.nav--fixed {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  border-bottom: none;
}
.nav-bar.nav--fixed:not(.no-animation) {
  -webkit-animation: navslide .5s ease forwards;
          animation: navslide .5s ease forwards;
}
.nav-bar.bg--dark .logo-light {
  display: inline;
}
.nav-bar.bg--dark .logo-dark {
  display: none;
}
.nav-bar a.nav-function {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.nav-bar a.nav-function span {
  font-size: 10px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  letter-spacing: 1px;
  margin-left: 0.8125em;
  display: none;
}
.nav-bar a.nav-function i {
  font-size: 1.1em;
  display: inline-block;
  position: relative;
  top: 3px;
}
.nav-bar a.nav-function:hover {
  opacity: .5;
}
@media all and (min-width: 991px) {
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) .menu > li > a {
    color: #fff;
  }
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) .logo-dark {
    display: none;
  }
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) .logo-light {
    display: inline;
  }
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) a.nav-function i,
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) a.nav-function span {
    color: #fff;
  }
}
@media all and (max-width: 767px) {
  .nav-bar a.nav-function span {
    display: inline-block;
  }
}
@-webkit-keyframes navslide {
  from {
    transform: translate3d(0, -100px, 0);
    -webkit-transform: translate3d(0, -100px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes navslide {
  from {
    transform: translate3d(0, -100px, 0);
    -webkit-transform: translate3d(0, -100px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@media all and (max-width: 990px) {
  .nav-bar {
    padding: 0 15px;
  }
  .nav-bar .nav-module.right:not(.logo-module):not(.menu-module) {
    padding: 0;
    margin-bottom: 0.8125em;
  }
}
.menu > li {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.menu > li:hover > a {
  opacity: .6;
}
.menu > li a {
  color: #232323;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.menu > li ul {
  white-space: nowrap;
  background: #232323;
  margin-top: 3px;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  padding: 1.625em;
  min-width: 16em;
}
.menu > li ul li {
  padding-left: 0.42307692307692em;
}
.menu > li ul li a {
  padding-left: 0;
  width: 100%;
  color: #fff;
}
.menu > li ul li a:hover {
  transform: translate3d(5px, 0, 0);
  -webkit-transform: translate3d(5px, 0, 0);
}
.menu > li > ul {
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
}
.menu > li > ul:not(.multi-column) > li.dropdown > a:after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 4px;
  opacity: .4;
  transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  transform: translate3d(-10px, 0, 0);
  -webkit-transform: translate3d(-10px, 0, 0);
}
.menu > li > ul:not(.multi-column) > li.dropdown:hover > a {
  transform: translate3d(5px, 0, 0);
  -webkit-transform: translate3d(5px, 0, 0);
  opacity: 1;
}
.menu > li > ul:not(.multi-column) > li.dropdown:hover > a:after {
  opacity: 1;
  transform: translate3d(-3px, 0, 0);
  -webkit-transform: translate3d(-3px, 0, 0);
}
.menu > li > ul:not(.multi-column) ul {
  left: calc(112%);
}
.menu > li > ul a {
  padding: 0 1em 1.3em 1em;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  opacity: .5;
}
.menu > li > ul a:hover {
  opacity: 1;
}
@media all and (max-width: 990px) {
  .menu > li ul ul {
    padding-left: 0;
  }
}
.multi-column {
  padding: 2.4375em !important;
}
.multi-column li {
  width: 20em;
}
.multi-column li ul {
  padding: 0;
}
.multi-column .multi-column__title {
  display: inline-block;
  color: #fff;
  margin-bottom: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.utility-bar {
  padding-top: 0.8125em;
  padding-bottom: 0.8125em;
  padding-right: 1.625em;
}
.utility-bar .social-list {
  line-height: 1.625em !important;
}
.utility-bar .social-list li a {
  font-size: 13px;
}
.utility-bar .nav-module + .nav-module {
  margin-left: 1.625em;
}
.utility-bar:not(.hidden) + .nav-bar + .nav-mobile-toggle {
  top: 42px;
}
.nav-mobile-toggle {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  opacity: .75;
}
.nav-mobile-toggle i {
  display: inline-block;
  margin-top: 18px;
}
.nav-mobile-toggle:hover {
  opacity: 1;
}
@media all and (max-width: 767px) {
  .utility-bar:not(.hidden) + .nav-bar + .nav-mobile-toggle {
    top: 46px;
  }
}
/*! -- Pillar Nav Stack -- */
.nav-stack .nav-stack__upper {
  overflow: hidden;
  margin: 1.625em 0;
}
.nav-stack .nav-stack__upper .logo {
  max-height: 2.84375em;
  display: inline-block;
}
.nav-stack .nav-bar {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.nav-stack .nav-bar:not(.nav--fixed) {
  box-shadow: none;
}
.nav-stack .nav-bar:not(.nav--fixed) .logo-module {
  display: none !important;
}
.nav-stack .nav-bar:not(.nav--fixed) .menu-module {
  padding-left: 0;
}
.nav-stack .nav-bar.nav--fixed .logo-module {
  padding-left: 1.625em;
}
@media all and (max-width: 990px) {
  .nav-stack .menu-module {
    padding-top: 65px;
  }
  .nav-stack .nav-mobile-toggle {
    width: 100%;
    right: 0;
    text-align: center;
  }
}
/*! -- Pillar Offscreen Nav -- */
.nav-bar + .nav-mobile-toggle[data-modal-id] i {
  display: inline-block;
  margin-right: 7px;
}
.nav-bar + .nav-mobile-toggle[data-modal-id] span {
  position: relative;
  bottom: 10px;
}
@media all and (min-width: 991px) {
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) + .nav-mobile-toggle[data-modal-id] {
    color: #fff;
  }
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) + .nav-mobile-toggle[data-modal-id] .h6 {
    color: #fff;
  }
}
@media all and (max-width: 767px) {
  .nav-bar:not(.bg--dark) + .nav-mobile-toggle[data-modal-id] i,
  .nav-bar:not(.bg--dark) + .nav-mobile-toggle[data-modal-id] .h6 {
    color: #232323 !important;
  }
}
.nav-modal .menu,
.nav-modal .pos-bottom {
  transition: 0.5s ease-out;
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -webkit-transition-delay: .3s;
          transition-delay: .3s;
  opacity: 0;
}
.nav-modal .pos-vertical-center {
  overflow: hidden;
}
.nav-modal .pos-bottom {
  bottom: 3.25em;
  transform: translate3d(0, 100px, 0);
  -webkit-transform: translate3d(0, 100px, 0);
}
.nav-modal .menu {
  display: inline-block;
  transform: rotateY(90deg);
  -webkit-transform: rotateY(90deg);
  text-align: center;
}
.nav-modal .menu > li {
  display: block;
  text-align: center;
  float: none;
}
.nav-modal .menu > li:not(:last-child) {
  margin-bottom: 3.25em;
}
.nav-modal .menu > li > a {
  padding: 0;
}
.nav-modal .menu > li > a span {
  text-transform: capitalize;
  letter-spacing: 0;
}
.nav-modal .menu > li:not(:last-child) > a {
  margin-right: 0;
}
.nav-modal .menu > li ul {
  background: none;
  padding: 1.625em 0 0 0;
  text-align: left;
}
.nav-modal .menu > li ul li {
  padding-left: 0;
}
.nav-modal .bg--dark {
  background: rgba(35, 35, 35, 0.9);
}
.nav-modal .bg--primary {
  background: rgba(66, 92, 187, 0.9);
}
.nav-modal.modal-active .menu {
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  opacity: 1;
}
.nav-modal.modal-active .pos-bottom {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  opacity: 1;
}
@media all and (max-width: 767px) {
  .nav-modal .modal-content {
    width: 100% !important;
    height: 100% !important;
    padding: 0;
  }
  .nav-modal .modal-content .pos-vertical-center {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
  }
  .nav-modal .menu > li:not(:last-child) > a {
    margin-right: 0;
  }
}
/*! -- Pillar Nav Slide -- */
.nav-slide {
  min-height: 100vh;
  height: 100vh;
  padding: 3.25em;
}
.nav-slide .logo {
  display: block;
  margin-bottom: 3.25em;
}
.nav-slide .logo img {
  max-height: 4.875em;
}
.nav-slide .menu {
  height: auto;
  display: inline-block;
}
.nav-slide .menu li {
  float: none;
  margin-right: 0;
}
.nav-slide .menu li:not(:last-child) {
  margin-bottom: 1.625em;
}
.nav-slide .menu li a {
  padding: 0;
}
.nav-slide .menu li a span {
  text-transform: capitalize;
  letter-spacing: 0px;
}
.nav-slide .pos-bottom {
  bottom: 1.625em;
  width: 100%;
  left: 0;
}
.nav-slide .pos-bottom .social-list {
  margin-bottom: 0.40625em;
}
.nav-slide .pos-bottom span.type--fine-print {
  opacity: .5;
}
.nav-slide .nav-slide__content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  min-height: 100vh;
  opacity: 0;
}
.nav-slide.notification--reveal:not(.notification--dismissed) .nav-slide__content {
  animation: slidein .5s ease .3s forwards;
  -webkit-animation: slidein .5s ease .3s forwards;
}
@keyframes slidein {
  from {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
    -webkit-transform: translate3d(200px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slidein {
  from {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
    -webkit-transform: translate3d(200px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@media all and (max-width: 767px) {
  .nav-slide__content {
    padding-top: 4.875em;
  }
  .nav-slide__content .logo img {
    max-height: 3.25em;
  }
}
/*! -- Pillar Buttons -- */
.btn {
  border-color: rgba(35, 35, 35, 0.1);
}
.btn .btn__text {
  letter-spacing: 1px;
}
.btn:hover {
  border-color: rgba(35, 35, 35, 0.9);
}
/*! -- Pillar Forms -- */
::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}
form div[class*='col-'] > input {
  width: 100%;
}
form div[class*='col-'] > button[type="submit"] {
  width: 100%;
}
form .row {
  margin-left: 0;
  margin-right: 0;
}
form.form--labels .row button[type="submit"] {
  position: relative;
  top: 39px;
}
form.form--center-submit-button button[type="submit"] {
  display: block;
  margin: 0 auto;
}
form.attempted-submit .field-error {
  color: #e34747;
}
form.attempted-submit .field-error::-webkit-input-placeholder {
  color: #e34747;
  font-size: 1em;
}
form.attempted-submit .field-error:-moz-placeholder {
  /* Firefox 18- */
  color: #e34747;
  font-size: 1em;
}
form.attempted-submit .field-error::-moz-placeholder {
  /* Firefox 19+ */
  color: #e34747;
  font-size: 1em;
}
form.attempted-submit .field-error:-ms-input-placeholder {
  color: #e34747;
  font-size: 1em;
}
@media all and (max-width: 1024px) {
  form.form--labels .row button[type="submit"] {
    top: 34px;
  }
}
@media all and (max-width: 767px) {
  form.form--labels .row button[type="submit"] {
    top: 0;
  }
  form input[type='text'],
  form input[type='address'],
  form input[type='url'],
  form input[type='password'],
  form input[type='email'],
  form input[type="search"],
  form input[type="tel"],
  form textearea {
    margin-bottom: 1.625em;
  }
}
input[type='text'],
input[type='address'],
input[type='url'],
input[type='password'],
input[type='email'],
input[type="search"],
input[type="tel"] {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  color: #767676;
  padding-left: 1.625em;
  padding-right: 1.625em;
  height: 52px;
}
input[type='text']:focus,
input[type='address']:focus,
input[type='url']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type="search"]:focus,
input[type="tel"]:focus {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
textarea {
  margin-bottom: 1.625em;
}
.input-with-icon {
  position: relative;
}
.input-with-icon i {
  position: absolute;
  font-size: 1.4375em;
  margin-left: 1.1304347826087em;
  top: 13px;
  left: 0;
}
.input-with-icon label + i {
  top: 52px;
}
.input-with-icon input {
  padding-left: 4.0625em;
  width: 100%;
}
.input-with-icon label + i + input {
  margin-top: 0.8125em;
}
.input-with-icon:not(:last-child) {
  margin-bottom: 1.625em;
}
.input-with-icon + div > textarea {
  padding-left: 1.625em;
}
@media all and (max-width: 767px) {
  .input-with-icon:not(:last-child) {
    margin: 0;
  }
}
.imagebg:not(.image--light):not(.form--dark) input[type='text'],
.imagebg:not(.image--light):not(.form--dark) input[type='address'],
.imagebg:not(.image--light):not(.form--dark) input[type='url'],
.imagebg:not(.image--light):not(.form--dark) input[type='password'],
.imagebg:not(.image--light):not(.form--dark) input[type='email'],
.imagebg:not(.image--light):not(.form--dark) input[type="search"] {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}
.imagebg:not(.image--light):not(.form--dark) input[type='text']::-webkit-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='address']::-webkit-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='url']::-webkit-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='password']::-webkit-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='email']::-webkit-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type="search"]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
  font-size: 1em;
}
.imagebg:not(.image--light):not(.form--dark) input[type='text']:-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='address']:-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='url']:-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='password']:-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='email']:-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type="search"]:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.75);
  font-size: 1em;
}
.imagebg:not(.image--light):not(.form--dark) input[type='text']::-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='address']::-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='url']::-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='password']::-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='email']::-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type="search"]::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.75);
  font-size: 1em;
}
.imagebg:not(.image--light):not(.form--dark) input[type='text']:-ms-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='address']:-ms-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='url']:-ms-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='password']:-ms-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='email']:-ms-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type="search"]:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
  font-size: 1em;
}
.imagebg:not(.image--light):not(.form--dark) .input-with-icon i {
  color: #fff;
}
.imagebg:not(.image--light):not(.form--dark) label {
  color: #fff;
}
.form--dark .input-with-icon i {
  color: #232323;
}
.bg--white input[type='text'],
.bg--white input[type='address'],
.bg--white input[type='url'],
.bg--white input[type='password'],
.bg--white input[type='email'],
.bg--white input[type="search"] {
  background: #f8f8f8;
}
label {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  display: block;
  margin: 0;
  margin-left: 2.6em;
}
label + input {
  margin-top: 0.8125em;
}
button {
  height: 52px;
  font-size: 0.625em !important;
  letter-spacing: 1px;
  color: #fff;
  background: #425cbb;
  border-color: #425cbb;
}
button:hover,
button:focus,
button:active {
  color: #fff !important;
}
button:active,
button:hover,
button:focus {
  border-color: #425cbb !important;
}
button.btn--loading:after {
  background: #8c9cd7;
}
button.btn--loading:after {
  background: #fff;
  border-radius: 100px;
}
.form--merge {
  min-width: 100%;
  overflow: hidden;
}
.form--merge input {
  border-radius: 50px 0 0 50px;
}
.form--merge input + button[type="submit"] {
  margin-left: -4px;
}
.form--merge button[type="submit"] {
  border-radius: 0 50px 50px 0;
  position: relative;
  margin: 0;
}
.form--merge button[type="submit"].btn--loading:after {
  border-radius: 0 100px 100px 0;
}
.form--merge.form--square input,
.form--merge.form--square select {
  border-radius: 2px 0 0 2px;
}
.form--merge.form--square button[type="submit"] {
  border-radius: 0 2px 2px 0;
}
@media all and (max-width: 767px) {
  .form--merge input {
    width: 100%;
    border-radius: 50px;
  }
  .form--merge input + button[type="submit"] {
    margin-left: 0;
  }
  .form--merge button[type="submit"] {
    bottom: 0;
    border-radius: 50px;
    width: 100%;
  }
}
.form--square input,
.form--square textarea,
.form--square button,
.form--square select {
  border-radius: 5px;
}
select {
  max-width: 100%;
  min-width: 100%;
  padding-left: 1.625em;
}
.input-checkbox label {
  margin-left: 0;
}
.input-checkbox + span {
  position: relative;
  bottom: 2px;
}
.input-checkbox .inner {
  background: #ececec;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.input-checkbox .inner:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.input-checkbox .inner:before {
  opacity: 0;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.input-checkbox.checked .inner {
  text-align: center;
  position: relative;
  background: #64b671;
}
.input-checkbox.checked .inner:before {
  content: '\2713';
  position: absolute;
  color: #fff;
  width: 100%;
  left: 0;
  opacity: 1;
  font-size: 13px;
}
.input-checkbox + br + .input-checkbox {
  margin-left: 1.625em;
}
li .input-checkbox {
  line-height: 1.625em !important;
}
li + li .input-checkbox {
  margin-left: 1.625em;
}
.input-radio {
  display: block;
}
.input-radio .inner {
  position: relative;
  background: #ececec;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.input-radio .inner:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.input-radio .inner:after {
  content: '';
  position: absolute;
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background: #fff;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  opacity: 0;
}
.input-radio.checked .inner {
  background: #64b671;
}
.input-radio.checked .inner:after {
  opacity: 1;
}
.input-radio + label {
  margin-left: 0;
}
.input-select {
  width: 100%;
}
.input-select:not(:last-child) {
  margin-bottom: 1.625em;
}
.input-select select {
  width: 100%;
  padding-left: 1.625em;
}
/*! -- Pillar Subscribe Forms -- */
.subscribe .subscribe__title h1,
.subscribe .subscribe__title h2,
.subscribe .subscribe__title h3,
.subscribe .subscribe__title h4,
.subscribe .subscribe__title h5,
.subscribe .subscribe__title h6 {
  margin: 0;
}
.subscribe-1 {
  padding-bottom: 6.5em;
}
.subscribe-1 .subscribe__title {
  padding-top: 0.40625em;
}
.subscribe-1 + .footer-2 {
  padding-top: 0;
}
@media all and (max-width: 767px) {
  .subscribe-1 {
    padding-bottom: 4.875em;
  }
  .subscribe-1 .subscribe__title {
    margin-bottom: 1.625em;
  }
}
.subscribe-2 .subscribe__title {
  margin-bottom: 3.25em;
}
.subscribe-2 form {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .subscribe-2 form {
    margin-bottom: 1.625em;
  }
}
.subscribe-3 .subscribe__title {
  margin-bottom: 3.25em;
}
.subscribe-3 .subscribe__title p.lead {
  margin-top: 1.1304347826087em;
}
.subscribe-4 {
  padding-bottom: 0;
  position: relative;
}
.subscribe-4 .subscribe__title {
  margin-top: 1.625em;
  margin-bottom: 3.25em;
}
.subscribe-4 .subscribe__title h4 {
  margin-bottom: 1.1304347826087em;
}
.subscribe-4 .input-with-icon {
  margin-bottom: 1.625em;
}
@media all and (max-width: 1200px) {
  .subscribe-4 {
    padding-bottom: 8.125em;
  }
  .subscribe-4 .subscribe__avatar {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
@media all and (max-width: 767px) {
  .subscribe-4 {
    padding-bottom: 0;
  }
  .subscribe-4 .subscribe__avatar {
    position: relative;
    margin-top: 1.625em;
  }
}
.subscribe-5 .subscribe__title {
  margin-bottom: 3.25em;
}
.subscribe-5 .subscribe__title h4 {
  margin-bottom: 1.1304347826087em;
}
.subscribe-5 form .input-with-icon {
  margin-bottom: 1.625em;
}
.subscribe-5 form .input-with-icon:last-of-type {
  margin-bottom: 3.25em;
}
.subscribe-5 form div:last-child {
  margin-top: 3.25em;
}
.subscribe-5 form button[type="submit"]:last-child {
  display: block;
  margin-top: 4.875em;
}
.subscribe-5 .subscribe__feature {
  margin-bottom: 3.25em;
}
.subscribe-5 .subscribe__feature:first-child {
  margin-top: 4.875em;
}
.subscribe-5 .subscribe__feature h6 {
  margin-bottom: 1.3em;
}
@media all and (max-width: 767px) {
  .subscribe-5 .subscribe__feature {
    text-align: center;
    margin-bottom: 1.625em;
  }
  .subscribe-5 .subscribe__feature:first-child {
    margin-top: 1.625em;
  }
}
.subscribe-6 .subscribe__title {
  margin-bottom: 4.875em;
}
.subscribe-6 .subscribe__features {
  margin-bottom: 4.875em;
}
.subscribe-6 .subscribe__features li {
  display: inline-block;
}
.subscribe-6 .subscribe__features li:not(:last-child) {
  margin-right: 3.25em;
}
.subscribe-6 .subscribe__features li .checkmark {
  margin-right: 0.8125em;
}
.subscribe-6 .subscribe__features li h6 {
  display: inline-block;
  position: relative;
  bottom: 9px;
  margin: 0;
}
.subscribe-6 form {
  margin-bottom: 3.25em;
}
@media all and (max-width: 990px) {
  .subscribe-6 .subscribe__features li:not(:last-child) {
    margin-right: 1.625em;
  }
}
@media all and (max-width: 767px) {
  .subscribe-6 .subscribe__title {
    margin-bottom: 3.25em;
  }
  .subscribe-6 .subscribe__features {
    margin-bottom: 1.625em;
  }
  .subscribe-6 .subscribe__features li {
    display: block;
    margin-bottom: 0.8125em;
  }
  .subscribe-6 .subscribe__features li:not(:last-child) {
    margin-right: 0;
  }
  .subscribe-6 form {
    margin-bottom: 0;
  }
}
.form-subscribe-1 p.lead {
  margin-bottom: 1.76923076923076em;
}
/*! -- Pillar Lists -- */
.social-list {
  overflow: hidden;
  margin: 0;
}
.social-list li {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  opacity: .5;
  display: inline-block;
  margin: 0;
}
.social-list li:not(:last-child) {
  margin-right: 1.625em;
}
.social-list li:hover {
  opacity: 1;
}
.social-list li a {
  color: #232323;
}
.social-list.social-list--circles li {
  opacity: 1;
}
.social-list.social-list--circles li:not(:last-child) {
  margin-right: 0.8125em;
}
.social-list.social-list--circles li a {
  display: inline-block;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  text-align: center;
  padding-top: 2px;
}
.social-list.social-list--circles li a i {
  color: #fff;
  font-size: 12px;
}
.imagebg:not(.image--light) .social-list li a {
  color: #fff;
}
/*! -- Pillar Covers -- */
.imagebg:not(.image--light) .btn:not(.btn--primary) {
  border-color: rgba(255, 255, 255, 0.1);
}
.imagebg:not(.image--light) .btn:not(.btn--primary) .btn__text {
  color: #fff;
}
.imagebg:not(.image--light) .btn:not(.btn--primary):hover {
  border-color: rgba(255, 255, 255, 0.6);
}
.imagebg:not(.image--light) a,
.imagebg:not(.image--light) span {
  color: #fff;
}
.cover .social-list li:not(:last-child) {
  margin-right: 3.25em;
}
.cover .social-list li a {
  font-size: 1.4375em;
}
.cover .pos-bottom .social-list {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .cover .social-list li:not(:last-child) {
    margin-right: 1.625em;
  }
  .cover .social-list li a {
    font-size: 1em;
  }
  .cover .pos-bottom {
    position: relative;
    margin-bottom: 0;
  }
  .cover .pos-bottom .social-list {
    margin-top: 3.25em;
    margin-bottom: 0;
  }
}
.cover-1 .logo {
  max-height: 8.125em;
}
.cover-1 p.lead {
  position: relative;
  top: 48px;
}
.cover-1 .row:first-child:not(:last-child) {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .cover-1 .logo {
    max-height: 4.875em;
    margin-bottom: 1.625em;
  }
  .cover-1 p.lead {
    top: 0;
  }
  .cover-1 .row:first-child:not(:last-child) {
    margin-bottom: 1.625em;
  }
}
.cover-2,
.cover-8 {
  padding: 0;
  position: relative;
  overflow: hidden;
}
.cover-2 > div[class*='col-'],
.cover-8 > div[class*='col-'] {
  position: absolute;
  height: 100%;
}
.cover-2 > div[class*='col-']:first-child,
.cover-8 > div[class*='col-']:first-child {
  left: 0;
}
.cover-2 > div[class*='col-']:last-child,
.cover-8 > div[class*='col-']:last-child {
  right: 0;
}
@media all and (max-height: 600px) {
  .cover-2 > div[class*='col-'],
  .cover-8 > div[class*='col-'] {
    position: relative;
    height: auto;
  }
  .cover-2 > div[class*='col-']:first-child,
  .cover-8 > div[class*='col-']:first-child {
    height: 32.5em;
  }
  .cover-2 > div[class*='col-']:last-child,
  .cover-8 > div[class*='col-']:last-child {
    padding: 4.875em 0;
  }
}
@media all and (max-width: 767px) {
  .cover-2 > div[class*='col-'],
  .cover-8 > div[class*='col-'] {
    position: relative;
    height: auto;
  }
  .cover-2 > div[class*='col-']:first-child,
  .cover-8 > div[class*='col-']:first-child {
    height: 32.5em;
  }
  .cover-2 > div[class*='col-']:last-child,
  .cover-8 > div[class*='col-']:last-child {
    padding: 4.875em 0;
  }
}
.cover-2 .logo {
  max-height: 8.125em;
}
.cover-2 .pos-vertical-center {
  display: inline-block;
  text-align: left;
}
.cover-2 .btn:first-of-type {
  margin-top: 1.625em;
}
@media all and (max-width: 767px) {
  .cover-2 .logo {
    max-height: 4.875em;
  }
  .cover-2 .pos-vertical-center {
    text-align: center;
  }
}
.cover-3 .logo {
  max-height: 3.25em;
}
.cover-3 .pos-bottom {
  margin-bottom: 9.75em;
}
.cover-3 .modal-instance {
  margin-top: 3.25em;
}
@media all and (max-width: 767px) {
  .cover-3.slider .slides li {
    height: 100vh;
  }
  .cover-3.slider .slides li .container {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
  }
  .cover-3 .pos-bottom {
    margin-bottom: 19.5em;
  }
}
.cover-4 .modal-instance {
  display: inline-block;
  position: relative;
  top: 21px;
  margin-left: 1.625em;
}
.cover-4 p.lead + div {
  margin-top: 1.625em;
}
@media all and (max-width: 767px) {
  .cover-4 .modal-instance {
    display: block;
    margin-left: 0;
  }
}
.cover-6 .modal-instance {
  display: block;
  margin-bottom: 3.25em;
}
.cover-6 .logo {
  max-height: 8.125em;
}
.cover-6 form {
  margin-bottom: 4.875em;
}
@media all and (max-width: 767px) {
  .cover-6 .modal-instance {
    margin-bottom: 1.625em;
  }
  .cover-6 .logo {
    max-height: 3.25em;
  }
  .cover-6 .pos-bottom {
    margin-top: 3.25em;
  }
}
.cover-7 form {
  margin: 3.25em 0;
}
.cover-7 .h6 {
  display: inline-block;
  color: #fff;
}
.cover-7 .modal-instance {
  display: inline-block;
  margin-left: 1.625em;
}
@media all and (max-width: 767px) {
  .cover-7 form {
    margin: 1.625em 0 1.625em 0;
  }
  .cover-7 form input {
    margin: 0;
  }
}
.cover-8 .logo {
  max-height: 3.25em;
}
.cover-8 .pos-vertical-center {
  overflow: hidden;
}
.cover-8 p.lead + div {
  margin-top: 3.25em;
}
.cover-8 form {
  max-width: 60%;
  margin: 0 auto;
}
.cover-8 form .row {
  margin-bottom: 1.625em;
}
.cover-8 form .input-checkbox {
  margin-right: 0.8125em;
}
.cover-8 form .input-checkbox + span {
  position: relative;
  bottom: 8px;
}
@media all and (max-width: 1300px) {
  .cover-8 form {
    max-width: 80%;
  }
}
@media all and (max-width: 767px) {
  .cover-8 form input {
    margin-bottom: 0;
  }
}
.cover-9 {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
}
.cover-9:before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: '';
  top: -13em;
  left: 0;
  background: #f4f4f4;
}
.cover-9 .logo {
  max-height: 6.5em;
}
.cover-9 p.lead {
  margin-bottom: 2.26086956521739em;
}
.cover-9 .cover__image {
  margin-top: 4.875em;
}
.cover-9 + section:not(.imagebg):not(.bg--dark):not(.bg--primary) {
  padding-top: 0;
}
@media all and (max-width: 767px) {
  .cover-9 .logo {
    max-height: 3.25em;
  }
  .cover-9 p.lead {
    margin-bottom: 1em;
  }
  .cover-9 .btn:not(:last-of-type) {
    margin-bottom: 1.625em;
  }
  .cover-9 .cover__image {
    margin-top: 3.25em;
  }
}
.cover-10 .logo {
  max-height: 6.5em;
}
.cover-10 > .background-image-holder {
  height: 70%;
  opacity: .2 !important;
}
.cover-10 .video-cover {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  margin-top: 4.875em;
}
.cover-10 .video-cover iframe {
  height: 488px;
}
.cover-10 + section:not(.imagebg) {
  padding-top: 0;
}
@media all and (max-width: 767px) {
  .cover-10 .video-cover iframe {
    height: auto;
  }
}
.cover-11:not([class*='height-']) {
  padding: 0;
}
.cover-11 .boxed {
  padding-bottom: 3.25em;
  margin-bottom: 0;
}
.cover-11 .pos-bottom {
  margin-bottom: 1.625em;
}
.cover-11 p.lead + .modal-video-1 {
  margin-top: 3.25em;
}
@media all and (max-width: 990px) {
  .cover-11 .boxed {
    padding: 1.625em 0;
  }
  .cover-11 .boxed div[class*='col-'] {
    padding: 0 15px !important;
  }
}
@media all and (max-width: 767px) {
  .cover-11 .imagebg {
    overflow: hidden;
  }
  .cover-11 .pos-bottom {
    margin-top: 1.625em;
  }
}
.cover-12 form {
  margin-top: 3.25em;
}
.cover-12 form .input-with-icon {
  text-align: left;
  margin-bottom: 1.625em;
}
@media all and (max-width: 768px) {
  .cover-12 .modal-instance {
    margin-bottom: 1.625em;
  }
}
.cover-13 form {
  margin-top: 2.4375em;
}
.cover-14 .logo {
  margin-bottom: 2.4375em;
}
.cover-14 .pos-bottom {
  width: 100%;
  bottom: 1.625em;
}
.cover-14 .cover__title {
  margin-bottom: 3.25em;
}
.cover-15 form {
  margin-top: 3.25em;
  margin-bottom: 1.625em;
}
/*! -- Pillar Tabs -- */
.tabs-container {
  overflow: hidden;
}
.tabs-container .tabs {
  margin-bottom: 4.875em;
}
.tabs-container .tabs .tab__title:not(.btn) {
  padding: 0;
}
.tabs-container .tabs li:not(:last-child) {
  margin-right: 3.25em;
}
.tabs-container:not(.tabs-5) .tabs-content {
  margin-bottom: 0;
  position: relative;
}
.tabs-container:not(.tabs-5) .tabs-content > li.active .tab__content {
  -webkit-animation: fadeIn .75s ease forwards;
          animation: fadeIn .75s ease forwards;
}
.tabs-container:not(.tabs-5) .tabs-content > li:not(.active) {
  position: absolute;
  top: 0;
  pointer-events: none;
}
.tabs-container:not(.tabs-5) .tabs-content > li:not(.active) .tab__content {
  display: block;
  pointer-events: none;
  -webkit-animation: fadeOut .5s ease forwards;
          animation: fadeOut .5s ease forwards;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    -webkit-transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    -webkit-transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    -webkit-transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    -webkit-transform: translate3d(0, -20px, 0);
  }
}
@media all and (max-width: 767px) {
  .tabs-container .tabs {
    margin-bottom: 1.625em;
  }
  .tabs-container .tabs li {
    margin: 0 1.625em 0.40625em 1.625em;
  }
  .tabs-container .tabs li:not(:last-child) {
    margin-right: 0;
  }
}
.tabs-1 .tabs li {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.tabs-1 .tabs li:not(.active) {
  opacity: .4;
}
.tabs-1 .tabs li:hover {
  opacity: .9;
}
.tabs-1 .tabs li .btn {
  border-color: rgba(0, 0, 0, 0);
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.tabs-1 .tabs li:not(:last-child) {
  margin-right: 1.625em;
}
.tabs-1 .tabs li.active .btn {
  border-color: rgba(35, 35, 35, 0.9);
}
.tabs-2 .tabs h5,
.tabs-2 .tabs h6 {
  margin: 0;
}
.tabs-3.tabs-container .tabs li {
  margin-right: 0;
}
.tabs-3.tabs-container .tabs .tab__title {
  position: relative;
  overflow: hidden;
  width: 250px;
  height: 150px;
  text-align: center;
}
.tabs-3.tabs-container .tabs .tab__title h5 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  z-index: 2;
  color: #fff;
  width: 100%;
}
.tabs-4.tabs-container .tabs li {
  margin-right: 0;
}
.tabs-4.tabs-container .tabs li:first-child {
  position: relative;
  left: 5px;
}
.tabs-4.tabs-container .tabs li:first-child .btn {
  border-radius: 50px 0 0 50px;
}
.tabs-4.tabs-container .tabs li:last-child {
  position: relative;
  right: 5px;
}
.tabs-4.tabs-container .tabs li:last-child .btn {
  border-radius: 0 50px 50px 0;
}
.tabs-4.tabs-container .tabs li.active .btn {
  background: #232323;
}
.tabs-4.tabs-container .tabs li.active .btn .btn__text {
  color: #fff;
}
.tabs-4.tabs-container .tabs li:not(.active) .btn {
  border-color: #232323;
}
@media all and (max-width: 767px) {
  .tabs-4.tabs-container .tabs li {
    margin: 0;
  }
}
.tabs-5 {
  padding: 0;
}
.tabs-5 .tabs {
  margin-bottom: 0;
}
.tabs-5 .tabs li .tab__title {
  background: #fff;
  padding: 0.8125em 3.25em !important;
}
.tabs-5 .tabs li .tab__title h6 {
  margin: 0;
}
.tabs-5 .tabs li:not(.active) .tab__title {
  background: #f4f4f4;
}
.tabs-5 .tabs li:not(:last-child) {
  margin-right: 0;
}
.tabs-5 .tabs li:first-child .tab__title {
  border-radius: 20px 0 0 0;
}
.tabs-5 .tabs li:last-child .tab__title {
  border-radius: 0 20px 0 0;
}
.tabs-5 .tabs-content {
  background: #fff;
  padding-top: 6.5em;
  padding-bottom: 8.125em;
  margin-top: 0;
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .tabs-5 .tabs {
    margin-bottom: 1.625em;
  }
  .tabs-5 li .tab__title,
  .tabs-5 li:first-child .tab__title,
  .tabs-5 li:last-child .tab__title {
    border-radius: 50px !important;
  }
}
/*! -- Pillar Accordions -- */
.accordion .active .accordion__title span {
  color: #fff;
}
.accordion .active .accordion__content {
  -webkit-animation: fadeIn 0.7s ease forwards;
          animation: fadeIn 0.7s ease forwards;
}
.accordion li {
  position: relative;
}
.accordion li .accordion__content {
  position: relative;
}
.accordion li:not(.active) .accordion__content * {
  display: block;
  position: absolute;
  top: 0;
  -webkit-animation: fadeOut 0.7s ease forwards;
          animation: fadeOut 0.7s ease forwards;
}
.accordion li:not(.active):hover .accordion__title {
  background: #efefef;
}
/*! -- Pillar Twitter Feed -- */
.twitter-feed {
  position: relative;
}
.twitter-feed .timePosted {
  margin: 0;
}
.twitter-feed.twitter-feed--slider ul:not(.owl-carousel) {
  max-height: 8.125em;
  opacity: 0;
}
.twitter-feed .timePosted a {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  text-decoration: none;
  opacity: .5;
}
i + .twitter-feed {
  margin-top: 1.625em;
}
.twitter-feed-1 p {
  font-size: 1.4375em;
  line-height: 1.69565217em;
  margin-bottom: 1.1304347826087em;
  font-weight: 400;
}
.twitter-feed-1 p:last-child {
  margin-bottom: 0;
}
.twitter-feed-1 p a {
  color: #425cbb;
}
.twitter-feed-2 li .tweet {
  margin-bottom: 0.8125em;
}
.twitter-feed-2 li:not(:last-child) {
  margin-bottom: 2.4375em;
}
/*! -- Pillar Sliders -- */
section:not(.imagebg):not(.bg--dark):not(.bg--primary):not(.slider) .flex-direction-nav a,
.slider:not(.slider-controls--white) .flex-direction-nav a {
  color: #232323;
}
section:not(.imagebg):not(.bg--dark):not(.bg--primary):not(.slider) .flex-control-nav li a,
.slider:not(.slider-controls--white) .flex-control-nav li a {
  background: #232323;
  opacity: .4;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
section:not(.imagebg):not(.bg--dark):not(.bg--primary):not(.slider) .flex-control-nav li a.flex-active,
.slider:not(.slider-controls--white) .flex-control-nav li a.flex-active {
  opacity: 1;
}
.owl-dot {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.owl-dot.active {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  position: relative;
  bottom: 1px;
}
.owl-prev,
.owl-next {
  opacity: .2;
}
.owl-prev:hover,
.owl-next:hover {
  opacity: 1;
}
@media all and (max-width: 767px) {
  .owl-prev,
  .owl-next {
    display: none !important;
  }
}
.slider:not(section) .slides li {
  padding: 0;
}
.slider--animate div[class*='col-'] {
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  transform: translate3d(0, 100px, 0);
  -webkit-transform: translate3d(0, 100px, 0);
  -webkit-transition-delay: .5s;
          transition-delay: .5s;
  opacity: 0;
}
.slider--animate .active div[class*='col-'] {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.screenshot-slider {
  margin-top: 3.25em;
}
.screenshot-slider img {
  margin-bottom: 1.625em;
}
/*! -- Pillar Features -- */
.features-1 hr {
  margin: 4.875em 0 3.25em 0;
}
@media all and (max-width: 767px) {
  .features-1 hr {
    display: none;
  }
}
.features-2 p + .feature {
  margin-top: 4.875em;
}
@media all and (max-width: 767px) {
  .features-2 p + .feature {
    margin-top: 3.25em;
  }
}
.features-3 .feature:first-child {
  margin-top: 4.875em;
}
.features-3 .feature h6 {
  margin-bottom: 1.3em;
}
.features-3 .feature:not(:last-child) {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .features-3 .feature:first-child {
    margin-top: 3.25em;
  }
}
.features-4 {
  overflow: hidden;
  position: relative;
  padding-bottom: 0;
}
.features-4 h3 {
  margin-top: 0.8125em;
}
.features-4 .feature h5 {
  margin-bottom: 0.8125em;
}
.features-4 p + .feature {
  margin-top: 3.25em;
}
@media all and (max-width: 1200px) {
  .features-4 {
    padding-bottom: 8.125em;
  }
  .features-4 .features__avatar {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .features-4 .features__avatar img {
    margin: 0;
    position: relative;
    right: -50px;
    max-height: 32.5em;
  }
}
@media all and (max-width: 990px) {
  .features-4 .features__avatar img {
    right: -200px;
  }
}
@media all and (max-width: 767px) {
  .features-4 {
    padding-bottom: 0;
  }
  .features-4 .features__avatar {
    margin-top: 3.25em;
    position: relative;
  }
  .features-4 .features__avatar img {
    right: 0px;
  }
}
.features-5 {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
}
.features-5:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  top: -13em;
}
.features-5 p.lead {
  margin-bottom: 3.3913043478261em;
}
.features-5 img {
  margin-top: 6.5em;
}
@media all and (max-width: 767px) {
  .features-5 {
    background: #f4f4f4;
    padding-bottom: 4.875em;
  }
  .features-5 p.lead {
    margin-bottom: 1.1304347826087em;
  }
  .features-5 img {
    margin-top: 1.625em;
  }
}
.features-6 p + .feature {
  margin-top: 1.625em;
}
.features-6 .feature:not(:last-child) {
  margin-bottom: 1.625em;
}
.features-6 .feature {
  padding: 0;
}
.features-6 + .cta-2 {
  padding-top: 0;
}
@media all and (max-width: 767px) {
  .features-6 p + .feature {
    margin-top: 0;
  }
  .features-6 .feature:not(:last-child) {
    margin-bottom: 0;
  }
  .features-6 .feature:nth-child(even) {
    padding-left: 7.5px;
  }
}
.features-7 {
  padding-top: 9.75em;
}
.features-7 hr {
  opacity: .3;
  margin: 3.25em 0;
}
.features-7 .feature h6 {
  margin-bottom: 1.3em;
}
@media all and (max-width: 767px) {
  .features-7 {
    padding-top: 4.875em;
  }
}
.features-8 {
  padding-top: 9.75em;
}
.features-8 .boxed {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  margin-top: 4.875em;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}
.features-8 .boxed .feature:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.features-8 .boxed .feature h5 {
  margin: 1.625em 0;
}
.features-8 .boxed i {
  color: #fff;
}
@media all and (max-width: 767px) {
  .features-8 {
    padding-top: 4.875em;
  }
  .features-8 .boxed .feature:first-child {
    border-right: none;
  }
  .features-8 .boxed .feature:not(:last-child) {
    margin-bottom: 1.625em;
  }
}
.features-9 p.lead {
  margin-bottom: 3.3913043478261em;
}
.features-9 .feature:first-child {
  margin-top: 8.125em;
}
@media all and (max-width: 767px) {
  .features-9 p.lead {
    margin-bottom: 1.1304347826087em;
  }
  .features-9 .feature:first-child {
    margin-top: 0;
  }
  .features-9 img {
    margin-bottom: 3.25em;
  }
}
.features-10 {
  padding: 0;
  overflow: hidden;
}
.features-10 .feature {
  margin: 0;
  padding: 6.5em 0;
  min-height: 434px;
}
.features-10 .feature i {
  display: inline-block;
  margin-bottom: 0.40625em;
}
.features-10 .feature p {
  max-width: 60%;
  margin: 0 auto;
  min-height: 4.875em;
}
@media all and (max-width: 1500px) {
  .features-10 .feature p {
    max-width: 80%;
  }
}
@media all and (max-width: 1200px) {
  .features-10 .feature p {
    max-width: 90%;
  }
}
@media all and (max-width: 990px) {
  .features-10 .feature {
    padding: 3.25em 0;
    margin-bottom: 0;
    min-height: 0;
  }
  .features-10 .feature p {
    max-width: 80%;
    min-height: 0;
  }
}
.features-11 .row:last-child {
  margin-top: 3.25em;
}
@media all and (max-width: 767px) {
  .features-11 .row:last-child {
    margin-top: 1.625em;
  }
}
section.features .feature-2 {
  margin-bottom: 3.25em;
}
.features-12.imageblock > .container {
  padding-top: 13em;
  padding-bottom: 9.75em;
}
.features-12 .feature[class*='col-'] {
  padding: 0;
}
.features-12 p.lead {
  margin-bottom: 3.3913043478261em;
}
@media all and (max-width: 767px) {
  .features-12.imageblock > .container {
    padding-top: 4.875em;
    padding-bottom: 4.875em;
  }
}
/*! -- Pillar Modals -- */
.modal-content.height--natural {
  max-width: 450px;
}
.modal-content.bg--dark {
  background: #000;
}
.modal-instance {
  position: relative;
  display: inline-block;
}
@media all and (max-width: 1024px) {
  .modal-container .modal-content {
    max-width: 100%;
    max-height: 100%;
  }
}
/*! -- Pillar Video -- */
.video-play-icon {
  opacity: 1;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.video-play-icon:hover {
  opacity: .9;
}
.video-play-icon:not(.video-play-icon--dark):before {
  border-color: transparent transparent transparent #232323;
}
.video-play-icon.video-play-icon--sm:before {
  margin-top: -3px;
}
.modal-video-1 .video-play-icon {
  display: inline-block;
  margin-right: 1.625em;
}
.modal-video-1 .video-play-icon.video-play-icon--sm {
  margin-right: 0.8125em;
}
.modal-video-1 .h6 {
  position: relative;
  bottom: 48px;
}
.modal-video-1 .video-play-icon--sm + .h6 {
  bottom: 22px;
}
@media all and (max-width: 767px) {
  .modal-video-1 .h6 {
    bottom: 28px;
  }
}
.embedded-video-3 .text {
  margin-top: 3.25em;
}
@media all and (max-width: 767px) {
  .embedded-video-3 .text {
    margin-top: 0;
  }
}
/*! -- Pillar Charts -- */
.piechart {
  overflow: hidden;
  position: relative;
}
.piechart-1 .piechart__overlay {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  width: 100%;
}
@media all and (max-width: 990px) {
  .piechart-1 {
    margin-bottom: 1.625em;
  }
}
.piechart-2 .piechart__overlay {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  width: 100%;
}
.piechart-2 .piechart__description h6:first-child {
  margin: 0;
  opacity: .5;
}
.piechart-3 .piechart__overlay {
  width: 90%;
  height: 90%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.piechart-3 i {
  font-size: 2.8125em;
  color: #425cbb;
}
.piechart-3 + h5 {
  margin-top: 1.625em;
}
.piechart[data-size="260"] {
  width: 260px;
  height: 260px;
}
.barchart {
  position: relative;
}
.barchart .barchart__bar {
  background: #f4f4f4;
  border-radius: 50px;
}
.barchart .barchart__progress {
  background: #425cbb;
  border-radius: 50px;
}
.barchart .barchart__description {
  z-index: 3;
  position: relative;
}
.barchart:not(:last-child) {
  margin-bottom: 1.625em;
}
.barchart.barchart--vertical {
  display: inline-block;
}
.barchart.barchart--vertical:not(:last-child) {
  margin-right: 0.8125em;
}
.barchart.barchart--vertical .barchart__bar {
  height: 500px;
  margin-bottom: 1.625em;
}
.barchart.barchart--vertical .barchart__progress {
  width: 100%;
  top: auto;
  bottom: 0;
}
.barchart.barchart--vertical .barchart__progress:before {
  right: 0;
  width: 100%;
  text-align: center;
}
.barchart.barchart--vertical .barchart__description {
  position: relative;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  left: -1.625em;
  text-align: left;
}
.barchart.barchart--vertical .barchart__description span {
  color: #232323;
}
.barchart-1 .barchart__bar {
  height: 2.84375em;
}
.barchart-1 .barchart__description {
  top: 0;
  left: 1.625em;
  position: absolute;
  height: 2.84375em;
  width: 100%;
}
.barchart-1 .barchart__description span {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  margin: 0;
}
.barchart-1 .barchart__progress:before {
  content: attr(data-value);
  position: absolute;
  right: 1.625em;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  color: #fff;
  top: 0.8125em;
}
.barchart-1.barchart--vertical .barchart__bar {
  width: 2.84375em;
}
.barchart-2:before {
  content: attr(data-value) '%';
  position: absolute;
  right: 0;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  top: 0;
}
.barchart-2 .barchart__bar {
  height: 0.40625em;
  background: #efefef;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.barchart-2:not(.barchart--vertical) .barchart__bar {
  max-height: 0.40625em;
}
.barchart-2 .barchart__description {
  margin-bottom: 0.8125em;
}
.barchart-2.barchart--vertical .barchart__bar {
  width: 0.40625em;
}
.barchart-2.barchart--vertical .barchart__description {
  left: -3.25em;
}
.barchart-2.barchart--vertical:before {
  left: 0;
}
.bg--secondary .barchart {
  background: #e7e7e7;
}
.bg--dark .barchart-2:before {
  color: #fff;
}
/*! -- Pillar Pricing Options -- */
.pricing {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.pricing i:first-child {
  color: #232323;
}
.pricing h6:first-child,
.pricing h5:first-child {
  margin: 0;
}
.pricing .pricing__price {
  margin-top: 1.625em;
}
.pricing .pricing__dollar {
  vertical-align: top;
}
.pricing p:last-of-type {
  margin-bottom: 3.25em;
}
.pricing:not(.pricing-1):not(.pricing--emphasise) hr {
  border-color: #efefef;
}
.bg--dark .pricing-1 *:not(a) {
  color: #767676;
}
@media all and (max-width: 990px) {
  .pricing {
    margin-bottom: 30px;
  }
}
@media all and (max-width: 767px) {
  .pricing {
    margin-bottom: 15px;
  }
}
.pricing-1 {
  padding: 2.16666667em 3.25em 3.25em 3.25em;
  background: #fff;
}
.pricing-1:hover {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.pricing-1 p {
  min-height: 3.25em;
}
.pricing-2 {
  padding: 2.16666667em 3.25em 3.25em 3.25em;
}
.pricing-2.pricing--emphasise {
  background: #fff;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.pricing-2 p {
  min-height: 4.875em;
}
.pricing-3 img:first-child {
  margin-bottom: -1.625em;
}
.pricing-3 h5 {
  margin-top: 1.625em;
}
.pricing-3 .btn {
  margin-bottom: 1.625em;
  position: relative;
  z-index: 20;
}
.pricing-3 .pricing__body {
  background: #fff;
  padding: 0 3.25em 3.25em 3.25em;
}
.pricing-3 p {
  min-height: 3.25em;
}
.pricing-3 p:last-child {
  margin: 0;
}
.pricing-3:hover {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.pricing-4 {
  padding: 3.25em 3.25em 3.25em 3.25em;
  background: #fff;
}
.pricing-4 .pricing__price {
  margin-top: 0;
  margin-bottom: 1.625em;
}
.pricing-4 h6 {
  opacity: .5;
}
.pricing-4:hover {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.pricing-4 p {
  min-height: 4.875em;
}
@media all and (max-width: 990px) {
  .pricing-4 {
    margin-bottom: 1.625em;
  }
}
.pricing-5 {
  overflow: hidden;
}
.pricing-5 div[class*='col-'] {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  padding: 0;
}
.pricing-5 div[class*='col-'].pricing--emphasise {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  z-index: 2;
}
.pricing-5 div[class*='col-']:not(:first-child) ul li:last-child {
  height: auto !important;
}
.pricing-5 .pricing__title {
  min-height: 144px;
  padding: 1.625em 0;
  position: relative;
}
.pricing-5 .pricing__title h6,
.pricing-5 .pricing__title h5 {
  margin: 0;
}
.pricing-5 .pricing__title h6 {
  opacity: .5;
}
.pricing-5 .pricing__price {
  margin-top: 0.8125em;
}
.pricing-5 ul {
  margin: 0;
}
.pricing-5 ul li {
  line-height: 1.625em;
  padding: 1.625em 0;
}
.pricing-5 ul li span:not(.btn__text) {
  font-style: italic;
}
.pricing-5 ul li span:not(:first-child) {
  display: inline-block;
  margin-left: 0.8125em;
  position: relative;
  bottom: 6px;
}
.pricing-5 ul li:nth-child(odd) {
  background: #f4f4f4;
}
@media all and (min-width: 990px) {
  .pricing-5 ul li {
    height: 4.875em;
  }
}
@media all and (max-width: 990px) {
  .pricing-5 .pricing__title {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .pricing-5 ul {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }
  .pricing-5 ul li:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  .pricing-5 .pricing__title {
    min-height: auto;
  }
}
@media all and (max-width: 767px) {
  .pricing-5 div[class*='col-'] {
    margin-bottom: 1.625em;
  }
}
.checkmark,
.crossmark {
  width: 1.625em;
  height: 1.625em;
  line-height: 1.625em;
  background: #64b671;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  text-align: center;
}
.checkmark:before,
.crossmark:before {
  content: '\2713';
  position: absolute;
  color: #fff;
  width: 100%;
  left: 0;
  opacity: 1;
  font-size: 13px;
}
.crossmark {
  background: #e34747;
}
.crossmark:before {
  content: '\2715';
}
/*! -- Pillar Cards -- */
.card {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.card .card__image img {
  margin: 0;
}
.card .card__title h1,
.card .card__title h2,
.card .card__title h3,
.card .card__title h4,
.card .card__title h5,
.card .card__title h6 {
  margin: 0;
}
.card .card__body {
  margin-bottom: 0;
}
.card .card__lower span {
  margin-bottom: 0;
}
.card .card__lower span:not(.h6) {
  font-size: 0.625em;
  font-style: italic;
  display: inline-block;
  color: #767676;
}
.slider[data-items] .card {
  margin: 0 15px;
  margin-bottom: 3.25em;
}
@media all and (max-width: 990px) {
  .card .card__body {
    margin-bottom: 30px;
  }
}
@media all and (max-width: 767px) {
  .card .card__body {
    margin-bottom: 15px;
    min-width: 100%;
  }
}
.card--horizontal {
  overflow: hidden;
  position: relative;
}
.card--horizontal .card__image {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
}
.card--horizontal .card__body {
  float: right;
}
@media all and (max-width: 767px) {
  .card--horizontal .card__image {
    position: relative;
  }
  .card--horizontal .card__image .background-image-holder {
    position: relative;
    background: none;
  }
  .card--horizontal .card__image .background-image-holder img {
    display: block;
  }
}
a .card {
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
}
a .card p,
a .card span {
  color: #767676;
}
a .card:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
a .card.card-6:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
a:active .card {
  transform: scale(0.99);
  -webkit-transform: scale(0.99);
}
.card-1 .card__icon {
  background: #f4f4f4;
  padding: 4.875em 0;
  text-align: center;
}
.card-1 .card__title + p {
  margin-top: 1.625em;
}
@media all and (max-width: 767px) {
  .card-1 .card__icon {
    padding: 3.25em 0;
  }
}
.card-1,
.card-2 {
  margin-bottom: 30px;
}
.card-1 .card__title,
.card-2 .card__title {
  margin-bottom: 0.8125em;
}
.card-1 span:not(:last-child),
.card-2 span:not(:last-child) {
  margin-bottom: 1.625em;
  display: inline-block;
}
.card-1 .card__body p,
.card-2 .card__body p {
  min-height: 8.125em;
}
@media all and (max-width: 767px) {
  .card-1 .card__body p,
  .card-2 .card__body p {
    min-height: 0;
  }
}
.card-3 {
  margin-bottom: 30px;
}
.card-3 .card__body .card__title {
  margin-bottom: 0;
}
.card-4 {
  margin-bottom: 30px;
}
.card-4 .card__body h6:first-child {
  margin-bottom: 1.3em;
  color: #767676;
}
.card-4 .card__body h5 {
  font-weight: 400;
}
.card-4 .card__body .card__title {
  height: 4.875em;
  margin-bottom: 1.625em;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card-4 hr {
  margin-top: 0;
  margin-bottom: 1.625em;
}
.card-5 {
  margin-bottom: 1.625em;
}
.card-5 .card__body {
  padding-bottom: 16.25em;
}
.card-5 .card__body .card__title {
  margin-bottom: 0.40625em;
}
.card-5 .card__body .card__title + span {
  margin-bottom: 1.625em;
  display: inline-block;
}
.card-5 .card__body .card__title + p {
  margin-top: 1.625em;
}
.masonry .card-5 {
  margin-bottom: 0;
}
@media all and (max-width: 990px) {
  .card-5 .card__body {
    padding-bottom: 13em;
  }
}
@media all and (max-width: 767px) {
  .card-5 .card__body {
    padding-bottom: 0.8125em;
  }
}
.card-6 {
  margin-bottom: 1.625em;
}
.card-6 .card__body .card__title {
  margin-bottom: 1.625em;
}
.card-6 .card__body p:last-of-type {
  margin-bottom: 6.5em;
}
@media all and (max-width: 767px) {
  .card-6 .card__body p:last-of-type {
    margin-bottom: 3.25em;
  }
}
.card-7 {
  margin-bottom: 30px;
}
.card-7 .card__body {
  position: relative;
}
.card-7 .card__body .card__title h6,
.card-7 .card__body .card__title h5 {
  margin-bottom: 0;
  max-height: 1.625em;
}
.card-7 .card__body .card__title h6 {
  opacity: .3;
  margin-bottom: 0.65em;
}
.card-7 .card__body .card__price {
  position: absolute;
  top: 2.4375em;
  right: 2.4375em;
}
.card-7 .card__body .card__price span {
  display: block;
}
.card-7 .card__body .card__price span.type--strikethrough {
  opacity: .5;
}
@media all and (max-width: 1200px) {
  .card-7 .card__price {
    margin-bottom: 0.8125em;
  }
}
.card-8 {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.card-8 .boxed {
  padding: 3.25em 4.875em;
}
.card-8 .card__title h6 {
  opacity: .5;
}
.card-8 .card__title h3 {
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-8 .card__body {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}
.card-8 .card__image {
  padding: 0;
  overflow: hidden;
  background: #eee;
}
.card-8 .card__image img {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.card-8 .card__image:hover img {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
}
.card-8 hr {
  margin-bottom: 3.25em;
}
.card-8 p[class*='col-'] {
  padding: 0;
  float: none;
  margin-bottom: 4.875em;
}
.card-8 .card__price {
  position: absolute;
  top: 3.25em;
  right: 4.875em;
}
.card-8 .card__price span {
  font-size: 1.4375em;
}
.card-8 .card__price span.type--strikethrough {
  margin-right: 1.1304347826087em;
}
@media all and (max-width: 1200px) {
  .card-8 .card__body {
    position: relative;
    height: auto;
  }
  .card-8 hr {
    margin-bottom: 1.625em;
  }
  .card-8 p[class*='col-'] {
    margin-bottom: 3.25em;
  }
}
@media all and (max-width: 1024px) {
  .card-8 .boxed {
    padding: 3.25em 3.25em;
  }
  .card-8 .card__price {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 0.8125em;
  }
}
@media all and (max-width: 767px) {
  .card-8 {
    margin-bottom: 0;
  }
  .card-8 .card__title {
    margin-top: 0;
  }
  .card-8 .card__body {
    position: relative;
    height: auto;
  }
  .card-8 .card__price {
    margin-bottom: 1.625em;
  }
}
/*! -- Pillar Page Titles -- */
.page-title.page-title--animate .container * {
  opacity: 0;
  -webkit-animation: fadeUp 1s ease;
          animation: fadeUp 1s ease;
  -webkit-animation-delay: .5s;
          animation-delay: .5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
/*! -- Pillar Video Covers -- */
iframe {
  background: #f4f4f4;
}
/*! -- Pillar Members -- */
.member-1 {
  margin-bottom: 3.25em;
}
.member-1 h6 {
  margin: 0;
  opacity: .5;
}
.member-1 h5 {
  margin-bottom: 0.8125em;
}
.member-1 p {
  min-height: 6.5em;
}
@media all and (max-width: 767px) {
  .member-1 p {
    min-height: 0;
  }
}
.member-2 h5 {
  margin-bottom: 0.8125em;
}
.member-2 h6 {
  margin-bottom: 0;
  opacity: .75;
}
.member-2 .social-list {
  width: 100%;
  margin-bottom: 1.625em;
}
/*! -- Pillar Icon Features -- */
.feature {
  margin-bottom: 3.25em;
}
.feature.boxed {
  background: #fff;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.feature.boxed:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  z-index: 2;
}
.feature:not(.bg--primary):not(.bg--dark) i {
  color: #232323;
}
.imagebg .feature:not(.bg--primary):not(.bg--dark) i,
.bg--primary .feature:not(.bg--primary):not(.bg--dark) i {
  color: #fff;
}
.imagebg .feature-1 span,
.bg--primary .feature-1 span {
  color: #fff;
  opacity: .9;
}
@media all and (max-width: 767px) {
  .feature {
    margin-bottom: 1.625em;
  }
}
.feature-1 {
  margin-bottom: 30px;
}
.feature-1 i + h5 {
  margin: 1.625em 0 0.8125em 0;
}
.feature-1 i + h6 {
  margin-top: 1.3em;
  margin-bottom: 1.3em;
}
.feature-1 i + h4 {
  margin-top: 1.23076923076923em;
  margin-bottom: 1.23076923076923em;
}
.feature-1 h4:last-child,
.feature-1 h5:last-child,
.feature-1 h6:last-child {
  margin-bottom: 0;
}
.feature-1 p {
  min-height: 4.875em;
}
@media all and (max-width: 767px) {
  .feature-1 {
    margin-bottom: 1.625em;
  }
}
.feature-2 .feature__title {
  margin-bottom: 0.8125em;
}
.feature-2 .feature__title i {
  margin-right: 0.57777777777778em;
}
.feature-2 .feature__title h6 {
  margin: 0;
  display: inline-block;
  position: relative;
  bottom: 16px;
}
@media all and (max-width: 990px) {
  .feature-2 .feature__title h6 {
    bottom: 0;
    display: block;
    margin-top: 1.3em;
  }
}
.feature-3 {
  overflow: hidden;
}
.feature-3 .feature__left {
  float: left;
  margin-right: 1.625em;
}
.feature-3 .feature__right {
  float: left;
  max-width: 80%;
}
.feature-3 p {
  min-height: 4.875em;
}
@media all and (max-width: 767px) {
  .feature-3 p {
    min-height: 0;
  }
}
.feature-4 {
  margin-bottom: 3.25em;
}
.feature-4 p:last-of-type {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .feature-4 p:last-of-type {
    margin-bottom: 1.625em;
  }
}
/*! -- Pillar Calls to Action -- */
.cta-1 {
  padding: 4.875em 0;
}
.cta-1 .cta__title {
  margin: 0;
}
@media all and (max-width: 990px) {
  .cta-1 .btn:first-child:not(:last-child) {
    margin-bottom: 1.625em;
  }
}
@media all and (max-width: 767px) {
  .cta-1 {
    text-align: center;
  }
  .cta-1 p.lead {
    margin: 1.1304347826087em 0;
  }
  .cta-1 .btn:first-child:not(:last-child) {
    margin-bottom: 0;
  }
}
.cta-2 {
  padding: 4.875em 0;
}
.cta-2 .cta__body {
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.cta-2 .cta__body .cta__feature {
  display: inline-block;
  padding-left: 3.25em;
  position: relative;
}
.cta-2 .cta__body .cta__feature i {
  position: relative;
  top: 10px;
  margin-right: 0.56521739130435em;
}
.cta-2 .cta__body .btn {
  float: right;
  min-height: 3.25em;
}
.cta-2.imagebg:not(.image--light) i {
  color: #fff;
}
.cta-2.imagebg:not(.image--light) .h6 {
  color: #fff;
}
.cta-2.imagebg:not(.image--light) .cta__body {
  border: none;
  background: rgba(255, 255, 255, 0.3);
}
@media all and (max-width: 1200px) {
  .cta-2 .cta__body {
    text-align: center;
    height: auto;
    padding: 1.625em;
  }
  .cta-2 .cta__body .cta__feature {
    margin-bottom: 1.625em;
  }
  .cta-2 .cta__body .cta__feature:first-child {
    padding-left: 0;
  }
  .cta-2 .cta__body .btn {
    float: none;
  }
}
@media all and (max-width: 767px) {
  .cta-2 .cta__body .cta__feature {
    width: 100%;
    padding-left: 0;
    height: auto;
  }
}
.cta-3 span.h2 {
  display: block;
  margin-bottom: 1.15555555555556em;
}
.cta-3.imagebg {
  padding-top: 8.125em;
}
@media all and (max-width: 767px) {
  .cta-3.imagebg {
    padding-top: 4.875em;
  }
}
.cta-5 h4 {
  margin: 0;
  display: inline-block;
  position: relative;
  top: 3px;
  white-space: nowrap;
}
.cta-5 .btn {
  margin-left: 1.625em;
}
@media all and (max-width: 767px) {
  .cta-5 .btn {
    margin-left: 0;
    margin-top: 1.625em;
  }
}
.cta-6 .btn {
  margin-top: 1.625em;
}
.cta-7 form {
  margin-top: 2.4375em;
  margin-bottom: 1.625em;
}
.cta-8 .boxed h4 {
  margin: 0;
}
.cta-8 .boxed form {
  margin-top: 1.625em;
}
.cta-8 .boxed form input,
.cta-8 .boxed form button {
  width: 100%;
  margin-bottom: 0.40625em;
}
.cta-8 .boxed .btn {
  margin-top: 1.625em;
}
.cta-8 .boxed:first-child {
  margin: 0;
}
.cta-8.section--overlap:not(:first-child) [class*='col-']:last-child {
  margin-top: 13em;
}
.cta-8 .signature {
  display: inline-block;
  margin-top: 2.4375em;
}
@media all and (max-width: 767px) {
  .cta-8.section--overlap [class*='col-']:last-child {
    margin-top: 1.625em;
  }
}
/*! -- Pillar Masonry -- */
.wide-grid.masonry {
  -webkit-transform: none !important;
          transform: none !important;
}
.wide-grid .masonry__container {
  background: #f3f3f3;
}
.wide-grid .masonry__container .masonry__item {
  padding: 0;
}
.wide-grid.bg--dark .masonry__container {
  background: #282828;
}
.masonry {
  position: relative;
}
.masonry .masonry__container {
  overflow: hidden;
}
.masonry--gapless .masonry__item {
  padding: 0;
  margin-bottom: 0 !important;
}
.masonry--animate .masonry__item {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  transform: translate3d(0, 50px, 0);
  -webkit-transform: translate3d(0, 50px, 0);
}
.masonry--animate .masonry__item:nth-child(1) {
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}
.masonry--animate .masonry__item:nth-child(2) {
  -webkit-transition-delay: .2s;
          transition-delay: .2s;
}
.masonry--animate .masonry__item:nth-child(3) {
  -webkit-transition-delay: .3s;
          transition-delay: .3s;
}
.masonry--animate .masonry__item:nth-child(4) {
  -webkit-transition-delay: .4s;
          transition-delay: .4s;
}
.masonry--animate .masonry__item:nth-child(5) {
  -webkit-transition-delay: .5s;
          transition-delay: .5s;
}
.masonry--animate .masonry__item:nth-child(6) {
  -webkit-transition-delay: .6s;
          transition-delay: .6s;
}
.masonry--animate .masonry__item:nth-child(7) {
  -webkit-transition-delay: .7s;
          transition-delay: .7s;
}
.masonry--animate .masonry__item:nth-child(8) {
  -webkit-transition-delay: .8s;
          transition-delay: .8s;
}
.masonry--animate .masonry__item:nth-child(9) {
  -webkit-transition-delay: .9s;
          transition-delay: .9s;
}
.masonry--animate .masonry__item:nth-child(10) {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}
.masonry--animate .masonry__item:nth-child(11) {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}
.masonry--animate .masonry__item:nth-child(12) {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}
.masonry--animate .masonry__item:nth-child(13) {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}
.masonry--animate .masonry__item:nth-child(14) {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}
.masonry--animate .masonry__item:nth-child(15) {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}
.masonry--animate.masonry--active .masonry__item {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.masonry__filters {
  padding: 1.625em 0;
}
.masonry__filters .h6 {
  opacity: .3;
  position: relative;
  bottom: 2px;
  display: inline-block;
  margin: 0;
}
.masonry__filters .h6 + ul {
  margin-left: 1.625em;
}
.masonry__filters ul {
  display: inline-block;
  margin: 0;
}
.masonry__filters ul li {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  font-style: italic;
}
.masonry__filters ul li:not(:last-child) {
  margin-right: 0.8125em;
}
.masonry__filters ul li:not(:last-child):after {
  content: '/';
  margin-left: 0.8125em;
  opacity: 0.1;
}
.masonry__filters ul li:not(.active) {
  opacity: .5;
}
.masonry__filters ul li:not(.active):after {
  opacity: 0.2;
}
.masonry__filters ul li:hover {
  opacity: 1;
}
.masonry__filters ul li:hover:after {
  opacity: 0.1;
}
.masonry__filters.masonry__filters--outside {
  position: absolute;
  width: 100%;
  z-index: 5;
  top: -3.25em;
  background: none !important;
  padding: 0;
}
.masonry__filters.masonry__filters--outside ul li {
  color: #fff;
}
.masonry__filters.masonry__filters--outside ul li:after {
  opacity: .2;
}
@media all and (max-width: 767px) {
  .masonry__filters.masonry__filters--outside {
    position: relative;
    top: 0;
    padding: 1.625em 0;
  }
  .masonry__filters.masonry__filters--outside ul li {
    color: #767676;
  }
}
.row .masonry__filters {
  margin-bottom: 1.625em;
  padding-left: 15px;
  padding-right: 15px;
}
.row .masonry__item {
  margin-bottom: 22px;
}
.row .masonry__item .hover-element {
  margin: 0;
}
.row .masonry__item > a {
  display: block;
}
section:first-of-type.imagebg + .masonry-contained {
  padding-top: 1.625em;
}
.masonry-blog .masonry__item {
  margin-bottom: 30px;
}
.masonry-blog .masonry__item .card .card__body {
  margin-bottom: 0;
}
.masonry-blog .masonry__item .card:not(.card--horizontal) .card__image {
  position: relative;
  overflow: hidden;
}
.masonry-blog .masonry__item .card:not(.card--horizontal) .card__image img {
  transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
}
.masonry-blog .masonry__item .card:not(.card--horizontal):hover .card__image img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
}
.masonry-blog .masonry__item .card .video-cover iframe {
  min-height: 256px;
}
.masonry-videos .col-sm-4 .video-cover iframe {
  min-height: 20vw;
}
/*! -- Pillar Hover Elements & Portfolio -- */
.hover-element img {
  width: 100%;
}
.hover-element * {
  transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
}
.hover-element.bg--primary [data-overlay]:before {
  background: #425cbb;
}
.hover-element .modal-instance {
  position: relative;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  display: inline-block;
}
.hover-element .modal-instance:not(:first-child) {
  margin-left: 0.8125em;
}
.hover-element[data-title-position*="right"] {
  text-align: right;
}
.hover-element[data-title-position*=",center"] {
  text-align: center;
}
.hover-element[data-title-position*="center"] .hover-element__reveal .boxed {
  position: absolute;
  height: auto;
  top: 50%;
  width: 100%;
  transform: translate3d(10px, -50%, 0);
  -webkit-transform: translate3d(10px, -50%, 0);
}
.hover-element[data-title-position*="center"] .hover-element__reveal:hover .boxed {
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
}
.hover-element[data-title-position*="bottom"] .hover-element__reveal .boxed {
  position: absolute;
  height: auto;
  bottom: 0;
  width: 100%;
}
.hover-element .boxed {
  transform: translate3d(0, 10px, 0);
  -webkit-transform: translate3d(0, 10px, 0);
  opacity: 0;
}
.hover-element .boxed span {
  color: #fff;
}
.hover-element:hover .boxed,
.hover-element.hover--active .boxed {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  opacity: 1;
  z-index: 3;
}
.hover-element.hover--active img {
  transition: 0.6s ease;
  -webkit-transition: 0.6s ease;
  -moz-transition: 0.6s ease;
}
.hover-element.hover--active:hover img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
}
.hover-element .social-list li a i {
  color: #fff;
}
.hover-element-1 h5,
.hover-element-1 h6 {
  margin: 0;
}
.space--0 .hover-element {
  margin: 0;
}
.portfolio-item-1 {
  margin-bottom: 1.625em;
}
.portfolio-item-1 h5 {
  margin: 0;
}
.portfolio-item-1 span {
  opacity: .5;
}
.portfolio-item-1 img {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.portfolio-item-1:hover img {
  opacity: .7;
}
.portfolio-item-2 .portfolio-item__title {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: absolute;
  z-index: 5;
  color: #fff;
  width: 100%;
  bottom: 1.625em;
  text-align: center;
}
.portfolio-item-2 .portfolio-item__title h5,
.portfolio-item-2 .portfolio-item__title h4 {
  margin: 0;
  color: #fff;
}
.portfolio-item-2.reveal-video .portfolio-item__title {
  opacity: 0;
  pointer-events: none;
}
.project-single-title h2 {
  margin: 0;
}
.project-single-title .modal-video-1 {
  margin-bottom: 1.625em;
}
.project-single-description {
  padding: 6.5em 0 3.25em 0;
}
@media all and (max-width: 767px) {
  .project-single-description {
    padding: 0;
  }
}
.project-single-stacked .project__summary {
  margin-bottom: 4.875em;
}
.project-single-stacked .testimonial {
  margin-top: 3.25em;
}
.project__action {
  margin-top: 3.25em;
}
.project__action hr {
  margin: 3.25em 0;
}
.project__action h5 {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .project__action .btn {
    margin: 0;
  }
  .project__action .btn:not(:last-child) {
    margin-bottom: 0.8125em;
  }
}
.project-single-process span.h2 {
  position: absolute;
  opacity: .03;
  font-size: 10em;
  right: 0;
  top: 0;
}
.project-navigation {
  position: relative;
  overflow: hidden;
  padding: 0;
}
.project-navigation a {
  display: inline-block;
  padding: 3.25em 0;
  text-align: center;
  width: 33.333333333333333%;
  float: left;
  background: #f4f4f4;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.project-navigation a:hover {
  background: #ececec;
}
.project-navigation a:hover .larr {
  transform: translate3d(-6px, 0, 0);
  -webkit-transform: translate3d(-6px, 0, 0);
}
.project-navigation a:hover .rarr {
  transform: translate3d(6px, 0, 0);
  -webkit-transform: translate3d(6px, 0, 0);
}
.project-navigation a span {
  margin: 0;
  display: inline-block;
}
.project-navigation a span .larr,
.project-navigation a span .rarr {
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  position: relative;
  bottom: 2px;
  margin: 0 4px;
}
@media all and (max-width: 767px) {
  .project-navigation a {
    width: 100%;
    padding: 1.625em 0;
  }
}
/*! -- Pillar Blog -- */
.pagination-container {
  margin-top: 3.25em;
  text-align: center;
}
.pagination {
  display: inline-block;
  margin: 0 auto;
}
.pagination li {
  display: inline-block;
}
.pagination li a {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  display: inline-block;
  width: 3.54545454545454em;
  height: 3.54545454545454em;
  line-height: 0;
  padding-top: 4px;
  text-align: center;
  border-radius: 50% !important;
  background: none;
  border: none;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
}
.pagination li a:hover {
  background: #efefef;
  color: #232323;
}
.pagination li.active a {
  background: #ececec;
  color: #232323;
}
.pagination li.active a:hover {
  background: #e7e7e7;
  color: #232323;
}
.pagination li:first-child,
.pagination li:last-child {
  opacity: .3;
}
.sidebar .sidebar__widget:not(:last-child) {
  margin-bottom: 3.25em;
}
.tag-cloud li {
  display: inline-block;
}
.tag-cloud .btn--sm {
  padding: 0 0.8125em 1px 0.8125em;
}
.blog-item-1 h4 {
  margin-bottom: 0.56521739130435em;
}
.blog-item-1 .blog-item__author {
  margin-bottom: 1.625em;
}
.blog-item-1 img {
  margin-bottom: 1.625em;
}
.blog-item-1 p:last-of-type {
  margin: 0;
}
.blog-item-1:not(:last-child) hr {
  margin: 3.25em 0;
}
.blog-item-1:nth-last-child(2) hr {
  display: none;
}
@media all and (max-width: 767px) {
  .blog-item-1:not(:last-child) hr {
    margin: 1.625em 0;
  }
}
.blog-post {
  padding-top: 0;
}
.blog-post .blog-post__title {
  padding: 9.75em 0 9.75em 0;
}
.blog-post .blog-post__title:not(.imagebg) + .container {
  position: relative;
  z-index: 4;
  margin-top: -3.25em;
}
.blog-post .blog-post__title.imagebg {
  padding: 13em 0;
}
.blog-post .blog-post__title.imagebg span {
  color: #fff;
}
.blog-post .blog-post__title.imagebg + .container {
  padding-top: 6.5em;
}
.blog-post .blog-post__author {
  margin-top: 3.25em;
}
.blog-post .blog-post__author span:not(.h6) {
  font-size: 0.625em;
}
.blog-post .blog-post__hero {
  margin-bottom: 3.25em;
}
.blog-post blockquote {
  color: #232323;
  text-align: center;
}
.blog-post p + blockquote,
.blog-post img + blockquote {
  margin-top: 3.3913043478261em;
}
.blog-post blockquote + p,
.blog-post blockquote + h5,
.blog-post blockquote + img {
  margin-top: 4.875em;
}
.blog-post p + h5 {
  margin-top: 3.25em;
}
.blog-post hr:last-child {
  margin-top: 4.875em;
}
.blog-post .blog-post__share {
  margin-top: 1.625em;
}
.blog-post .blog-post__comments {
  margin-top: 3.25em;
}
.blog-post .blog-post__comments > ul {
  margin-top: 3.25em;
}
.blog-post .blog-post__comments ul {
  line-height: 1.625em !important;
}
.blog-post .blog-post__comments ul ul {
  padding-left: 20%;
}
.blog-post .blog-post__comments .comment {
  overflow: hidden;
}
.blog-post .blog-post__comments .comment .comment__image {
  display: inline-block;
  width: 20%;
  float: left;
}
.blog-post .blog-post__comments .comment .comment__image img {
  max-height: 4.875em;
}
.blog-post .blog-post__comments .comment .comment__text {
  width: 80%;
  float: left;
}
.blog-post .blog-post__comments .comment .comment__text h5 {
  margin: 0;
}
.blog-post .blog-post__comments .comment .comment__text h5 + span {
  opacity: .75;
  display: inline-block;
  margin-bottom: 0.8125em;
}
.blog-post .blog-post__comments .comment hr {
  margin: 2.4375em 0;
  display: block;
  width: 100%;
}
.blog-post .blog-post__comments .comment__form input {
  width: 100%;
  margin-bottom: 1.625em;
}
.blog-post .blog-post__comments .comment__form textarea {
  margin-bottom: 1.625em;
}
@media all and (max-width: 767px) {
  .blog-post .blog-post__share .btn {
    display: block;
    margin: 0 0 0.8125em 0;
  }
  .blog-post .blog-post__title {
    padding-top: 3.25em;
    padding-bottom: 6.5em;
  }
  .blog-post .blog-post__title.imagebg {
    padding: 4.875em 0;
  }
  .blog-post .blog-post__title.imagebg span {
    color: #fff;
  }
  .blog-post .blog-post__title.imagebg + .container {
    padding-top: 3.25em;
  }
  .blog-post .blog-post__author {
    margin-top: 1.625em;
  }
}
.blog-home {
  padding: 4.875em 0;
}
.blog-home .row:first-child h5 {
  margin: 0;
}
.blog-home .row:last-child .btn {
  margin-top: 1.625em;
}
.blog-home.bg--secondary + .blog-home.bg--secondary {
  padding-top: 0;
}
/*! -- Pillar Testimonials -- */
.testimonial-1 {
  margin-bottom: 30px;
}
.testimonial-1 img {
  max-height: 8.125em;
}
.testimonial-1 h5 {
  margin-bottom: 0;
}
.testimonial-1 h5 + span {
  font-style: italic;
  opacity: .8;
}
.testimonial-1 p {
  min-height: 4.875em;
}
.testimonial-1.boxed {
  margin-bottom: 30px;
}
.testimonial-2 .testimonial__quote {
  font-size: 6em;
  line-height: 1em;
  display: inline-block;
  margin-bottom: -0.54166667em;
}
.testimonial-2 img {
  max-height: 4.875em;
}
.testimonial-2 h5 {
  margin-bottom: 0;
}
.testimonial-2 h5 + span {
  font-style: italic;
  opacity: .8;
}
@media all and (max-width: 767px) {
  .testimonial-2 {
    margin-bottom: 1.625em;
  }
  .testimonial-2 .testimonial__quote {
    display: none;
  }
}
.testimonial-3 {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
}
.testimonial-3:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  bottom: -4.875em;
  background: #f4f4f4;
}
.testimonial-3 .testimonial__text {
  margin-top: 11.375em;
}
.testimonial-3 .testimonial__text h5 {
  margin: 0;
}
.testimonial-3 img {
  margin: 0;
}
@media all and (max-width: 1200px) {
  .testimonial-3:before {
    display: none;
  }
  .testimonial-3 .testimonial__text {
    margin-top: 3.25em;
  }
}
@media all and (max-width: 767px) {
  .testimonial-3 {
    text-align: center;
  }
  .testimonial-3 .testimonial__text {
    margin-top: 0;
    margin-bottom: 3.25em;
  }
}
.testimonial-4 h3 {
  margin-bottom: 1.625em;
}
@media all and (max-width: 767px) {
  .testimonial-4 {
    padding: 4.875em 0;
  }
}
.testimonial-5 img {
  max-height: 24.375em;
  margin: 0;
}
.testimonial-5 img + hr {
  margin-top: 0;
}
.testimonial-5 .slides {
  padding-bottom: 4.875em;
}
/*! -- Pillar Shop -- */
.cart-overview {
  width: 400px;
  height: 100vh;
  position: fixed;
  background: #fff;
  margin: 0;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  padding: 1.625em;
}
.cart-overview .cart-overview__subtotal {
  padding-top: 1.625em;
  border-top: 1px solid #f1f1f1;
}
.cart-overview .cart-overview__subtotal h5 {
  display: inline-block;
  margin: 0;
  margin-right: 1.625em;
}
.cart-overview .cart-overview__title {
  margin-bottom: 3.25em;
  border-bottom: 1px solid #f1f1f1;
}
.cart-overview .cart-overview__items {
  max-height: 70vh;
  overflow-x: auto;
  overflow-y: scroll;
  line-height: 1.625em !important;
}
.cart-overview .cart-overview__items li {
  overflow: hidden;
  position: relative;
}
.cart-overview .cart-overview__items li img {
  max-height: 4.875em;
  display: inline-block;
}
.cart-overview .cart-overview__items li .item__image {
  display: inline-block;
  margin-right: 1.625em;
}
.cart-overview .cart-overview__items li .item__detail {
  display: inline-block;
}
.cart-overview .cart-overview__items li .item__detail span {
  display: block;
}
.cart-overview .cart-overview__items li .item__remove {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: 0.2s lienar;
  -webkit-transition: 0.2s lienar;
  -moz-transition: 0.2s lienar;
  opacity: .5;
}
.cart-overview .cart-overview__items li .item__remove:before {
  content: '\2715';
}
.cart-overview .cart-overview__items li .item__remove:hover {
  opacity: 1;
}
.cart-overview .cart-overview__items li:not(:last-child) {
  margin-bottom: 1.625em;
}
.cart-overview .cart-overview__action {
  margin-top: 3.25em;
  text-align: center;
  width: 100%;
}
.cart-form .card {
  margin-bottom: 1.625em;
}
.cart-form .card .card__price {
  margin-bottom: 1.625em;
}
.cart-form .card input[type="text"] {
  max-width: 80px;
}
.order-charges {
  margin-top: 3.25em;
}
.order-charges ul {
  margin: 0;
}
.order-charges li span:last-child {
  float: right;
}
.order-charges li:last-child {
  margin-bottom: 0;
}
.shop-checkout .card {
  margin-bottom: 3.25em;
}
.shop-checkout hr {
  margin-bottom: 3.25em;
}
.shop-checkout .billing-title {
  padding: 0 15px;
  margin-bottom: 4.875em;
}
.shop-checkout .billing-title h5 {
  margin-bottom: 0.8125em;
}
.shop-checkout .billing-details {
  overflow: hidden;
}
.shop-checkout .billing-details input,
.shop-checkout .billing-details select {
  margin-bottom: 30px;
}
.shop-item-1 {
  overflow: hidden;
  background: #eee;
  padding: 1.625em;
  position: relative;
  z-index: 1;
}
.shop-item-1 .shop-item__price span.type--strikethrough {
  display: inline-block;
  margin-right: 1.625em;
}
.shop-item-1 .shop-item__price.hover--reveal {
  transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  transform: translate3d(0, -50px, 0);
  -webkit-transform: translate3d(0, -50px, 0);
  opacity: 0;
}
.shop-item-1 .shop-item__image {
  margin: 1.625em 0;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.shop-item-1 .shop-item__title h5 {
  margin-bottom: 0;
}
.shop-item-1:hover .shop-item__price.hover--reveal {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.shop-item-1:hover .shop-item__image {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
}
@media all and (max-width: 990px) {
  .shop-item-1 .shop-item__price.hover--reveal {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
.wide-grid.masonry-shop .masonry__filters {
  background: #eee;
}
.wide-grid.masonry-shop .masonry__container {
  background: #eee;
}
.wide-grid.masonry-shop .masonry__item .shop-item-1 {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.wide-grid.masonry-shop .masonry__item:hover .shop-item-1 {
  box-shadow: inset 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.masonry-contained .masonry-shop .masonry__item {
  margin-bottom: 0;
}
.masonry-contained .masonry-shop .masonry__item .boxed {
  margin-bottom: 30px;
}
.masonry-contained .masonry-shop .masonry__item .shop-item-1 {
  margin-bottom: 30px;
}
.masonry-contained .masonry-shop .masonry__item .card-8 {
  margin-bottom: 30px;
}
.masonry-contained.bg--dark {
  background: #202020;
}
.shop-item-detail.shop-item-detail-1 .item__addtocart {
  margin-bottom: 3.25em;
}
.shop-item-detail.shop-item-detail-1 .item__title {
  margin-top: 1.625em;
}
.shop-item-detail .item__price:not(:last-child) {
  margin-bottom: 3.25em;
}
.shop-item-detail .item__price span {
  display: inline-block;
  font-size: 1.4375em;
}
.shop-item-detail .item__price span.type--strikethrough {
  margin-right: 1.625em;
}
.shop-item-detail .item__addtocart {
  max-width: 80%;
}
.shop-item-detail .item__addtocart input[type="text"] {
  border-radius: 4px 4px 0 0;
  min-width: 100%;
  text-align: center;
}
.shop-item-detail .item__addtocart button {
  border-radius: 0 0 4px 4px;
  min-width: 100%;
}
.shop-item-detail .item__description:not(:last-child) {
  margin-bottom: 3.25em;
}
.shop-item-detail .item__description .item__subinfo:not(:last-child) {
  margin-bottom: 0.8125em;
}
.shop-item-detail .item__description .item__subinfo span {
  display: inline-block;
}
.shop-item-detail .item__description .item__subinfo span:first-child {
  width: 25%;
  color: #232323;
}
.shop-item-detail .item__reviews {
  line-height: 1.625em !important;
}
.shop-item-detail .item__reviews li {
  position: relative;
  overflow: hidden;
}
.shop-item-detail .item__reviews li img {
  float: left;
  max-height: 4.875em;
  margin-right: 1.625em;
}
.shop-item-detail .item__reviews li .review__text {
  width: 80%;
  float: right;
}
.shop-item-detail .item__reviews li .review__text h5 {
  margin: 0;
}
.shop-item-detail .item__reviews li .review__text span.type--fine-print {
  font-style: italic;
  display: inline-block;
  margin-bottom: 1.3em;
}
.shop-item-detail .item__reviews li .review__score {
  position: absolute;
  right: 0;
  top: 0;
}
.shop-item-detail .item__reviews li .review__score .star {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #ebab21;
  display: inline-block;
}
.shop-item-detail .item__reviews li .review__score .star:not(:last-child) {
  margin-right: 0.40625em;
}
.shop-item-detail .item__reviews li .review__score .star.empty {
  background: #e0e0e0;
}
.shop-item-detail .item__reviews li:not(:last-child) {
  margin-bottom: 1.625em;
}
.imagebg:not(.image--light) .shop-item-detail .item__price {
  color: #fff;
  opacity: .9;
}
@media all and (max-width: 767px) {
  .shop-item-detail .item__addtocart input {
    margin: 0;
  }
  .shop-item-detail .item__reviews li img {
    display: none;
  }
  .shop-item-detail .item__reviews li .review__text {
    width: 100%;
  }
}
.shop-item-detail-2 form {
  display: inline-block;
  margin-bottom: 3.25em;
}
.shop-item-detail-2 .item__subinfo:not(:last-child) {
  border-bottom: 1px solid #eee;
  padding-bottom: 0.8125em;
}
.related-products h4 {
  margin-bottom: 2.2608695652174em;
}
@media all and (max-width: 767px) {
  .related-products h4 {
    margin-bottom: 1.1304347826087em;
  }
  .related-products .shop-item {
    margin-bottom: 15px;
  }
}
.signature {
  max-height: 6.5em;
  margin-top: 1.625em;
}
.shop-home .masonry__container {
  margin-bottom: 1.625em;
}
/*! -- Pillar Modals -- */
.search-modal .modal-content {
  background: none;
}
/*! -- Pillar Notifications -- */
.notification {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  margin: 0;
}
.notification .notification-close-cross {
  width: 2.4375em;
  height: 2.4375em;
  background: #232323;
  text-align: center;
  border-radius: 50%;
}
.notification .notification-close-cross:before {
  font-size: 1em;
  color: #fff;
  line-height: 2.4375em;
}
.notification .boxed {
  margin-bottom: 0;
}
.cookies-notice .notification-close {
  margin-left: 0.8125em;
}
.growl-notice {
  margin: 1.625em;
  background: none !important;
}
.growl-notice .boxed {
  border-radius: 0.8125em;
}
@media all and (max-width: 767px) {
  .growl-notice {
    margin: 0;
  }
}
@media all and (max-width: 990px) {
  .cookies-notice .notification-close {
    margin-left: 0;
    margin-top: 0.8125em;
  }
}
.promo-notification h4,
.promo-notification p {
  margin: 0;
  display: inline-block;
  margin-right: 1.625em;
}
@media all and (max-width: 767px) {
  .promo-notification h4,
  .promo-notification p {
    margin-right: 0;
    margin-bottom: 0.8125em;
  }
}
/*! -- Pillar Footers -- */
footer .logo {
  max-height: 26px;
}
.footer-1 {
  padding-bottom: 0;
}
.footer-1 .footer__navigation {
  width: 50%;
  display: inline-block;
  float: left;
}
.footer-1 .logo {
  margin-bottom: 1.625em;
}
.footer-1 div[class*='col-'] {
  overflow: hidden;
}
.footer-1 .footer__lower {
  margin-top: 1.625em;
  padding: 1.625em 0;
}
.footer-1 .footer__lower .type--fine-print {
  opacity: .4;
}
.footer-1 .footer__lower hr {
  opacity: .05;
}
.footer-1 .footer__navigation li {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  line-height: 3.2em;
  margin: 0;
}
.footer-1 .footer__navigation li a {
  opacity: .5;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.footer-1 .footer__navigation li a:hover {
  opacity: 1;
}
@media all and (max-width: 767px) {
  .footer-1 .footer__lower {
    margin-top: 0;
  }
  .footer-1 .footer__lower .social-list {
    display: inline-block;
    margin-top: 0.8125em;
  }
}
.footer-2 .footer__navigation li {
  display: inline-block;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  font-size: 1em;
  line-height: 1.625em;
  margin-top: 0;
  margin-bottom: 1.625em;
  margin-bottom: 0;
}
.footer-2 .footer__navigation li:not(:last-child) {
  margin-right: 1.625em;
}
.footer-2 .footer__lower {
  margin-top: 3.25em;
}
@media all and (max-width: 767px) {
  .footer-2 .footer__lower {
    margin-top: 1.625em;
  }
  .footer-2 .social-list {
    margin-bottom: 1.625em;
  }
}
.footer-3 {
  padding: 4.875em 0;
  overflow: hidden;
}
.footer-3 div[class*='col-']:first-child {
  padding-left: 3.25em;
}
.footer-3 div[class*='col-']:last-child {
  padding-right: 3.25em;
}
.footer-3 .logo {
  max-height: 2.4375em;
  display: block;
  margin-bottom: 0.8125em;
}
.footer-3 a.type--underline {
  display: block;
}
.footer-3 .footer__navigation li {
  display: inline-block;
}
.footer-3 .footer__navigation li:not(:last-child) {
  margin-right: 3.25em;
}
.footer-3 .footer__navigation li a {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
}
.footer-3 .social-list {
  margin-bottom: 1.625em;
}
.footer-3 .type--fine-print {
  opacity: .5;
}
@media all and (max-width: 990px) {
  .footer-3 div[class*='col-']:first-child {
    padding-left: 1.625em;
  }
  .footer-3 div[class*='col-']:last-child {
    padding-right: 1.625em;
  }
}
@media all and (max-width: 767px) {
  .footer-3 div[class*='col-']:first-child {
    padding-left: 15px;
  }
  .footer-3 div[class*='col-']:last-child {
    padding-right: 15px;
  }
  .footer-3 .logo {
    margin: 0 auto;
    margin-bottom: 1.625em;
  }
  .footer-3 .footer__navigation {
    margin-top: 1.625em;
  }
}
.footer-4 {
  padding-bottom: 0;
}
.footer-4 .logo {
  max-height: 2.03125em;
  margin-bottom: 1.21875em;
}
.footer-4 p {
  opacity: .9;
}
.footer-4 .footer__navigation li {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  line-height: 3.2em;
  margin: 0;
}
.footer-4 .footer__navigation li a {
  opacity: .5;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.footer-4 .footer__navigation li a:hover {
  opacity: 1;
}
.footer-4 form {
  margin-bottom: 3.25em;
}
.footer-4 .footer__lower {
  position: relative;
  margin-top: 4.875em;
  background: #1e1e1e;
  padding: 1.625em 0;
}
.footer-4 .footer__lower .type--fine-print {
  opacity: .5;
}
.footer-4 .timePosted {
  display: none;
}
.top-link {
  width: 3.25em;
  height: 3.25em;
  border-radius: 50%;
  background: #3d3d3d;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 4;
  bottom: 3.25em;
  padding-top: 12px;
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
}
.top-link:hover {
  transform: translate3d(0, -5px, 0);
  -webkit-transform: translate3d(0, -5px, 0);
}
@media all and (max-width: 990px) {
  .top-link {
    bottom: 0;
  }
}
@media all and (max-width: 767px) {
  .top-link {
    margin-top: 1.625em;
  }
}
/*! -- Pillar Sections General -- */
section:not(.testimonial-3):not(.map-1):not(.imagebg):not(.imageblock):not(.bg--dark):not(.bg--secondary):not(.bg--primary):not(.bg--white):not(.features-5):not(.features-10):not(.wide-grid):not(.slider) + section:not(.testimonial-3):not(.map-1):not(.imagebg):not(.imageblock):not(.bg--dark):not(.bg--secondary):not(.bg--primary):not(.bg--white):not(.features-5):not(.features-10):not(.wide-grid):not(.slider) {
  padding-top: 0;
}
.wide-grid {
  max-width: 100%;
  padding: 0;
}
.wide-grid > div[class*='col-'] {
  padding: 0;
}
.wide-grid .hover-element {
  margin: 0;
}
.section--overlap:not(:first-child) {
  padding-top: 0;
  position: relative;
  z-index: 2;
  margin-top: -6.5em;
}
.section--overlap:not(:first-child) + .section--overlap {
  margin-top: -13em;
  z-index: 1;
}
.section--overlap:not(:first-child) + .section--overlap .pos-vertical-center {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
@media all and (max-width: 1024px) {
  .section--overlap {
    padding-top: 6.5em;
    margin-top: 0;
  }
}
@media all and (max-width: 767px) {
  .section--overlap {
    padding-top: 4.875em;
    margin-top: 0;
  }
}
section + img {
  margin-bottom: 0;
}
/*! -- Pillar Sections Specific  -- */
.process-1 {
  overflow: hidden;
  position: relative;
}
.process-1 .piechart {
  margin: 0 auto;
  margin-bottom: 6.5em;
}
.process-1:before {
  content: '';
  width: 2px;
  height: 40000px;
  position: absolute;
  top: -200px;
  border-right: 1px dashed #425cbb;
  opacity: .3;
  left: 50%;
  margin-left: -1px;
  z-index: 0;
}
.process-1.process-end:before {
  top: 0;
  height: 440px;
}
@media all and (max-width: 767px) {
  .process-1:before {
    display: none;
  }
}
.about-1 {
  padding-top: 4.875em;
}
.about-1 h4 {
  margin-bottom: 2.2608695652174em;
}
.about-1 p.lead + .feature {
  margin-top: 3.25em;
}
@media all and (max-width: 767px) {
  .about-1 {
    padding-top: 0;
  }
}
.about-2 {
  padding: 4.875em 0 3.25em 0;
}
@media all and (max-width: 767px) {
  .about-2 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.stats-1 h4:first-child {
  margin-bottom: 2.2608695652174em;
}
.stats-1 .feature-1 {
  max-height: 282px;
  margin-bottom: 30px;
}
.stats-1 .feature-1 h3 {
  margin: 0.40625em 0;
}
.service-1 hr {
  margin: 3.25em 0;
}
.blog-snippet-1 .row:last-child .btn:last-child {
  margin-top: 3.25em;
}
.partners-1 .slider {
  text-align: center;
}
.partners-1 .slider img {
  max-height: 1.625em;
  opacity: .3;
  margin: 0;
}
.map-1 {
  overflow: hidden;
  padding: 0;
}
.map-1 .map-container {
  position: relative;
  height: 450px;
  width: 100%;
}
.map-1 .map-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
@media all and (max-width: 767px) {
  .map-1 .map-container {
    height: 300px;
  }
}
/*! -- Pillar Utility Pages -- */
.page-error i {
  margin-bottom: 0.40625em;
  display: inline-block;
}
/*! -- Element Pages -- */
section[class*='elements-'] .row:not(:last-child) {
  margin-bottom: 6.5em;
}
section[class*='elements-'] .row + hr {
  margin: 0;
  border-color: #eee;
}
section[class*='elements-'] hr + .row {
  padding-top: 6.5em;
}
@media all and (max-width: 767px) {
  section[class*='elements-'] .row:not(:last-child) {
    margin-bottom: 3.25em;
  }
  section[class*='elements-'] hr + .row {
    padding-top: 3.25em;
  }
}
.elements--title {
  margin-bottom: 3.25em;
}
.elements-buttons .btn {
  margin: 0;
  margin-bottom: 26px;
}
.elements-tabs {
  padding-bottom: 0;
}
.elements-tabs h4 + .tabs-container {
  margin-top: 3.25em;
}
.elements-tabs .tabs-container:not(:last-child) {
  margin-bottom: 6.5em;
}
.elements-tabs + .tabs-container {
  margin-top: 3.25em;
}
@media all and (max-width: 990px) {
  .elements-pricing .pricing {
    margin-bottom: 1.625em;
  }
}
.elements-radials .row:not(:last-child) {
  margin-bottom: 6.5em;
}
.elements-radials h4 {
  margin: 0;
}
.elements-radials .piechart {
  margin-bottom: 1.625em;
}
@media all and (max-width: 767px) {
  .elements-video .video-cover {
    margin-bottom: 1.625em;
  }
}
.elements-forms form input {
  margin-bottom: 1.625em;
}
.elements-forms form > .row {
  margin-bottom: 0 !important;
}
.elements-forms form .input-checkbox {
  margin-right: 0.8125em;
}
.elements-forms form .input-checkbox + span {
  position: relative;
  bottom: 6px;
}
.elements-modals .modal-instance:not(:last-child) {
  margin-right: 0.8125em;
}
@media all and (max-width: 767px) {
  .elements-modals .modal-instance:not(:last-child) {
    margin-bottom: 0.8125em;
  }
}

/***************************
Add your custom styles below
****************************/
.resource-card {
    padding:"0 2em 0 2em";
    background-color:'black' !important;
    color:blue;
  }
.imgTile {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  height: 100%;
  overflow: hidden;
}

.navbar-brand h2 {
  font-size: 2.5rem;
  position: relative;
  bottom: 15px;
}

button.navbar-toggle,
button.navbar-toggle:hover,
button.navbar-toggle:active {
  border: none;
}

.nav-button-icon {
  position: relative;
  bottom: 15px;
  color: #121212;
}

.resource-card {
    padding-bottom: 15px;
}

.text-left {
    padding-bottom: 5px;
}
