/**!**************/
/**! Contents **/
/**! 1. Reset **/
/**! 2. Helper Classes **/
/**! 3. Typography **/
/**! 4. Position **/
/**! 5. Element Size **/
/**! 6. Images **/
/**! 7. Colors **/
/**! 8. Buttons **/
/**! 9. Icons **/
/**! 10. Lists **/
/**! 11. Lightbox **/
/**! 12. Nav Bar **/
/**! 13. Form Elements **/
/**! 14. Accordions **/
/**! 15. Breadcrumbs **/
/**! 16. Pie Charts **/
/**! 17. Tabs **/
/**! 18. Boxes **/
/**! 19. Sliders **/
/**! 20. Hover Elements **/
/**! 21. Masonry **/
/**! 22. Modals **/
/**! 23. Maps **/
/**! 24. Parallax **/
/**! 25. Notifications **/
/**! 26. Video **/
/**! 27. Image Blocks **/
/**! 28. Mail Chimp & Campaign Monitor **/
/**! 29. Twitter **/
/**! 30. Transitions **/
/**! 31. Spacing **/
/**! 32. Theme Overrides **/
/**!**************/
/**!**************/
/**!**************/
/**! 1. Reset **/
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a {
  margin: 0;
  padding: 0;
}
/**! 2. Helper Classes **/
.clearfix {
  overflow: hidden;
}
.allow-overflow {
  overflow: visible;
}
.container .row--gapless {
  padding-left: 15px;
  padding-right: 15px;
}
.container .row--gapless > div[class*='col-'] {
  padding: 0;
}
section > .row--gapless {
  padding-left: 0;
  padding-right: 0;
}
section > .row--gapless > div[class*='col-'] {
  padding: 0;
}
.unpad {
  padding: 0;
}
.unpad--bottom {
  padding-bottom: 0;
}
.unpad--top {
  padding-top: 0;
}
section.unpad--bottom {
  padding-bottom: 0;
}
section.unpad {
  padding: 0;
}
section.unpad--top {
  padding-top: 0;
}
.unmarg--bottom {
  margin-bottom: 0;
}
.unmarg {
  margin: 0;
}
div.right {
  float: right;
}
div.left {
  float: left;
}
/**! 3. Typography **/
html {
  font-size: 100%;
}
@media all and (max-width: 768px) {
  html {
    font-size: 87.5%;
  }
}
body {
  font-size: 1em;
  line-height: 1.625em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lora', 'Helvetica', 'Arial', sans-serif;
  color: #767676;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  /* color: #343434; */
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
}
h1,
.h1 {
  font-size: 4em;
  line-height: 1.015625em;
}
h2,
.h2 {
  font-size: 2.8125em;
  line-height: 1.15555556em;
}
h3,
.h3 {
  font-size: 2em;
  line-height: 1.421875em;
}
h4,
.h4 {
  font-size: 1.4375em;
  line-height: 1.41304348em;
}
h5,
.h5 {
  font-size: 1em;
  line-height: 1.625em;
}
h6,
.h6 {
  font-size: 0.625em;
  line-height: 2.6em;
}
.lead {
  font-size: 1.4375em;
  line-height: 1.69565217em;
}
@media all and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2.8125em;
    line-height: 1.15555556em;
  }
  h2,
  .h2 {
    font-size: 2em;
    line-height: 1.421875em;
  }
  h3,
  .h3 {
    font-size: 1.4375em;
    line-height: 1.625em;
  }
  .lead {
    font-size: 1.4375em;
    line-height: 1.41304348em;
  }
}
p,
ul,
ol,
pre,
table,
blockquote {
  margin-bottom: 1.625em;
}
ul,
ol {
  list-style: none;
}
ul:not(.menu):not(.slides):not(.accordion):not(.tabs):not(.tabs-content),
ol:not(.menu):not(.slides):not(.accordion):not(.tabs):not(.tabs-content) {
  line-height: 2.34375em;
}
ul.bullets {
  list-style: inside;
}
ol {
  list-style-type: upper-roman;
  list-style-position: inside;
}
blockquote {
  font-size: 2em;
  line-height: 1.421875em;
  padding: 0;
  margin: 0;
  border-left: 0;
}
strong {
  font-weight: 600;
}
hr {
  margin: 1.625em 0;
  border-color: #f4f4f4;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}
/*! Typography -- Helpers */
.type--fade {
  opacity: .5;
}
.type--uppercase {
  text-transform: uppercase;
}
.type--bold {
  font-weight: bold;
}
.type--italic {
  font-style: italic;
}
.type--fine-print {
  font-size: 0.6875em;
}
.type--strikethrough {
  text-decoration: line-through;
  opacity: .5;
}
.type--underline {
  text-decoration: underline;
}
.type--body-font {
  font-family: 'Lora', 'Helvetica';
}
/**! 4. Position **/
body {
  overflow-x: hidden;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.pos-absolute.container {
  left: 0;
  right: 0;
}
.pos-top {
  top: 0;
}
.pos-bottom {
  bottom: 0;
}
.pos-right {
  right: 0;
}
.pos-left {
  left: 0;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
@media all and (max-width: 767px) {
  .float-left,
  .float-right {
    float: none;
  }
  .float-left-xs {
    float: left;
  }
  .float-right-xs {
    float: right;
  }
}
.pos-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
@media all and (max-width: 767px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
@media all and (max-height: 600px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
.pos-vertical-align-columns {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.pos-vertical-align-columns > div[class*='col-'] {
  display: table-cell;
  float: none;
  vertical-align: middle;
}
@media all and (max-width: 990px) {
  .pos-vertical-align-columns {
    display: block;
    width: auto;
  }
  .pos-vertical-align-columns > div[class*='col-'] {
    display: block;
  }
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
@media all and (max-width: 767px) {
  .block--xs {
    display: block;
  }
}
@media all and (max-width: 990px) {
  .text-center-md {
    text-align: center;
  }
  .text-left-md {
    text-align: left;
  }
  .text-right-md {
    text-align: right;
  }
}
@media all and (max-width: 767px) {
  .text-center-xs {
    text-align: center;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
}
/**! 5. Element Size **/
.height-100,
.height-90,
.height-80,
.height-70,
.height-60,
.height-50,
.height-40,
.height-30,
.height-20,
.height-10 {
  height: auto;
  padding: 5em 0;
}
@media all and (max-width: 767px) {
  .height-100,
  .height-90,
  .height-80,
  .height-70,
  .height-60,
  .height-50,
  .height-40,
  .height-30,
  .height-20,
  .height-10 {
    height: auto;
    padding: 4em 0;
  }
}
@media all and (min-height: 600px) and (min-width: 767px) {
  .height-10 {
    height: 10vh;
  }
  .height-20 {
    height: 20vh;
  }
  .height-30 {
    height: 30vh;
  }
  .height-40 {
    height: 40vh;
  }
  .height-50 {
    height: 50vh;
  }
  .height-60 {
    height: 60vh;
  }
  .height-70 {
    height: 70vh;
  }
  .height-80 {
    height: 80vh;
  }
  .height-90 {
    height: 90vh;
  }
  .height-100 {
    height: 100vh;
  }
}
/**! 6. Images **/
img {
  max-width: 100%;
  margin-bottom: 1.625em;
}
/*p+img, img:last-child{
	margin-bottom: 0;
}*/
.img--fullwidth {
  width: 100%;
}
[data-grid="2"].masonry {
  -webkit-column-count: 2;
  -webkit-column-gap: 0;
  -moz-column-count: 2;
  -moz-column-gap: 0;
  column-count: 2;
  column-gap: 0;
}
[data-grid="2"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="2"] li {
  width: 50%;
  display: inline-block;
}
[data-grid="3"].masonry {
  -webkit-column-count: 3;
  -webkit-column-gap: 0;
  -moz-column-count: 3;
  -moz-column-gap: 0;
  column-count: 3;
  column-gap: 0;
}
[data-grid="3"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="3"] li {
  width: 33.33333333%;
  display: inline-block;
}
[data-grid="4"].masonry {
  -webkit-column-count: 4;
  -webkit-column-gap: 0;
  -moz-column-count: 4;
  -moz-column-gap: 0;
  column-count: 4;
  column-gap: 0;
}
[data-grid="4"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="4"] li {
  width: 25%;
  display: inline-block;
}
[data-grid="5"].masonry {
  -webkit-column-count: 5;
  -webkit-column-gap: 0;
  -moz-column-count: 5;
  -moz-column-gap: 0;
  column-count: 5;
  column-gap: 0;
}
[data-grid="5"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="5"] li {
  width: 20%;
  display: inline-block;
}
[data-grid="6"].masonry {
  -webkit-column-count: 6;
  -webkit-column-gap: 0;
  -moz-column-count: 6;
  -moz-column-gap: 0;
  column-count: 6;
  column-gap: 0;
}
[data-grid="6"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="6"] li {
  width: 16.66666667%;
  display: inline-block;
}
[data-grid="7"].masonry {
  -webkit-column-count: 7;
  -webkit-column-gap: 0;
  -moz-column-count: 7;
  -moz-column-gap: 0;
  column-count: 7;
  column-gap: 0;
}
[data-grid="7"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="7"] li {
  width: 14.28571429%;
  display: inline-block;
}
[data-grid="8"].masonry {
  -webkit-column-count: 8;
  -webkit-column-gap: 0;
  -moz-column-count: 8;
  -moz-column-gap: 0;
  column-count: 8;
  column-gap: 0;
}
[data-grid="8"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="8"] li {
  width: 12.5%;
  display: inline-block;
}
@media all and (max-width: 767px) {
  [data-grid]:not(.masonry) li {
    width: 33.333333%;
  }
  [data-grid="2"]:not(.masonry) li {
    width: 50%;
  }
  [data-grid].masonry {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.background-image-holder {
  will-change: transform, top;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
  background: #232323;
}
.background-image-holder:not([class*='col-']) {
  width: 100%;
}
.background-image-holder.background--bottom {
  background-position: 50% 100% !important;
}
.background-image-holder.background--top {
  background-position: 50% 0% !important;
}
.image--light .background-image-holder {
  background: none;
}
.background-image-holder img {
  display: none;
}
[data-overlay] {
  position: relative;
}
[data-overlay]:before {
  position: absolute;
  content: '';
  background: #232323;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
[data-overlay] *:not(.container):not(.background-image-holder) {
  z-index: 2;
}
[data-overlay].image--light:before {
  background: #fff;
}
[data-overlay].bg--primary:before {
  background: #425cbb;
}
[data-overlay="1"]:before {
  opacity: 0.1;
}
[data-overlay="2"]:before {
  opacity: 0.2;
}
[data-overlay="3"]:before {
  opacity: 0.3;
}
[data-overlay="4"]:before {
  opacity: 0.4;
}
[data-overlay="5"]:before {
  opacity: 0.5;
}
[data-overlay="6"]:before {
  opacity: 0.6;
}
[data-overlay="7"]:before {
  opacity: 0.7;
}
[data-overlay="8"]:before {
  opacity: 0.8;
}
[data-overlay="9"]:before {
  opacity: 0.9;
}
[data-overlay="10"]:before {
  opacity: 1;
}
[data-overlay="0"]:before {
  opacity: 0;
}
[data-scrim-bottom] {
  position: relative;
}
[data-scrim-bottom]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #232323 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, #232323));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #232323 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #232323 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #232323 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #232323 100%);
  bottom: 0;
  left: 0;
  z-index: 1;
  backface-visibility: hidden;
}
[data-scrim-bottom]:not(.image--light) h1,
[data-scrim-bottom]:not(.image--light) h2,
[data-scrim-bottom]:not(.image--light) h3,
[data-scrim-bottom]:not(.image--light) h4,
[data-scrim-bottom]:not(.image--light) h5,
[data-scrim-bottom]:not(.image--light) h6 {
  color: #b3b3b3;
}
[data-scrim-bottom]:not(.image--light) p,
[data-scrim-bottom]:not(.image--light) span,
[data-scrim-bottom]:not(.image--light) ul {
  color: #f6f6f6;
}
[data-scrim-bottom].image--light:before {
  background: #fff;
}
[data-scrim-bottom="1"]:before {
  opacity: 0.1;
}
[data-scrim-bottom="2"]:before {
  opacity: 0.2;
}
[data-scrim-bottom="3"]:before {
  opacity: 0.3;
}
[data-scrim-bottom="4"]:before {
  opacity: 0.4;
}
[data-scrim-bottom="5"]:before {
  opacity: 0.5;
}
[data-scrim-bottom="6"]:before {
  opacity: 0.6;
}
[data-scrim-bottom="7"]:before {
  opacity: 0.7;
}
[data-scrim-bottom="8"]:before {
  opacity: 0.8;
}
[data-scrim-bottom="9"]:before {
  opacity: 0.9;
}
[data-scrim-bottom="10"]:before {
  opacity: 1;
}
[data-scrim-top] {
  position: relative;
}
[data-scrim-top]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  background: -moz-linear-gradient(bottom, #232323 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left bottom, left bottom, color-stop(0%, #232323), color-stop(100%, rgba(0, 0, 0, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(bottom, #232323 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(bottom, #232323 0%, rgba(0, 0, 0, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(bottom, #232323 0%, rgba(0, 0, 0, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #232323 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: 1;
}
[data-scrim-top]:not(.image--light) h1,
[data-scrim-top]:not(.image--light) h2,
[data-scrim-top]:not(.image--light) h3,
[data-scrim-top]:not(.image--light) h4,
[data-scrim-top]:not(.image--light) h5,
[data-scrim-top]:not(.image--light) h6 {
  color: #fff;
}
[data-scrim-top]:not(.image--light) p,
[data-scrim-top]:not(.image--light) span,
[data-scrim-top]:not(.image--light) ul {
  color: #f6f6f6;
}
[data-scrim-top].image--light:before {
  background: #fff;
}
[data-scrim-top="1"]:before {
  opacity: 0.1;
}
[data-scrim-top="2"]:before {
  opacity: 0.2;
}
[data-scrim-top="3"]:before {
  opacity: 0.3;
}
[data-scrim-top="4"]:before {
  opacity: 0.4;
}
[data-scrim-top="5"]:before {
  opacity: 0.5;
}
[data-scrim-top="6"]:before {
  opacity: 0.6;
}
[data-scrim-top="7"]:before {
  opacity: 0.7;
}
[data-scrim-top="8"]:before {
  opacity: 0.8;
}
[data-scrim-top="9"]:before {
  opacity: 0.9;
}
[data-scrim-top="10"]:before {
  opacity: 1;
}
.imagebg {
  position: relative;
}
.imagebg .container {
  z-index: 2;
}
.imagebg .container:not(.pos-absolute) {
  position: relative;
}
.imagebg:not(.image--light) h1,
.imagebg:not(.image--light) h2,
.imagebg:not(.image--light) h3,
.imagebg:not(.image--light) h4,
.imagebg:not(.image--light) h5,
.imagebg:not(.image--light) h6,
.imagebg:not(.image--light) p,
.imagebg:not(.image--light) ul,
.imagebg:not(.image--light) blockquote {
  color: #fff;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6 {
  color: #232323;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white ul {
  color: #767676;
}
div[data-overlay] h1,
div[data-overlay] h2,
div[data-overlay] h3,
div[data-overlay] h4,
div[data-overlay] h5,
div[data-overlay] h6 {
  color: #fff;
}
div[data-overlay] p,
div[data-overlay] ul {
  color: #fff;
}
.parallax {
  overflow: hidden;
}
.parallax .background-image-holder {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
}
.image--xxs {
  max-height: 1.625em;
}
.image--xs {
  max-height: 3.25em;
}
.image--sm {
  max-height: 4.875em;
}
/**! 7. Colors **/
body {
  background: #f8f8f8;
}
.color--primary {
  color: #425cbb !important;
}
a {
  color: #425cbb;
}
.color--primary-1 {
  color: #7cc36a !important;
}
.color--primary-2 {
  color: #d14b4b !important;
}
.color--white {
  color: #fff;
}
.color--dark {
  color: #232323;
}
.bg--dark {
  background: #232323;
}
.bg--dark:not(.nav-bar) {
  color: #f5f5f5;
}
.bg--dark:not(.nav-bar) h1,
.bg--dark:not(.nav-bar) h2,
.bg--dark:not(.nav-bar) h3,
.bg--dark:not(.nav-bar) h4,
.bg--dark:not(.nav-bar) h5,
.bg--dark:not(.nav-bar) h6,
.bg--dark:not(.nav-bar) i,
.bg--dark:not(.nav-bar) span:not(.btn__text),
.bg--dark:not(.nav-bar) p {
  color: #f5f5f5;
}
.bg--dark:not(.nav-bar) a:not(.btn) {
  color: #fff;
}
.bg--site {
  background: #f8f8f8;
}
.bg--secondary {
  background: #f4f4f4;
}
.bg--primary {
  background: #425cbb;
}
.bg--primary p,
.bg--primary span,
.bg--primary ul,
.bg--primary a:not(.btn) {
  color: #fff;
}
.bg--primary h1,
.bg--primary h2,
.bg--primary h3,
.bg--primary h4,
.bg--primary h5,
.bg--primary h6,
.bg--primary i {
  color: #fff;
}
.bg--white {
  background: #fff;
}
.bg--white p,
.bg--white span,
.bg--white ul,
.bg--white a:not(.btn) {
  color: #767676;
}
.bg--white h1,
.bg--white h2,
.bg--white h3,
.bg--white h4,
.bg--white h5,
.bg--white h6,
.bg--white i {
  color: #232323;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white span,
.imagebg:not(.image--light) .bg--white ul,
.imagebg:not(.image--light) .bg--white a:not(.btn) {
  color: #767676;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6,
.imagebg:not(.image--light) .bg--white i {
  color: #232323;
}
.bg--primary-1 {
  background: #7cc36a;
}
.bg--primary-1 p,
.bg--primary-1 span,
.bg--primary-1 ul,
.bg--primary-1 a:not(.btn) {
  color: #fff;
}
.bg--primary-1 h1,
.bg--primary-1 h2,
.bg--primary-1 h3,
.bg--primary-1 h4,
.bg--primary-1 h5,
.bg--primary-1 h6,
.bg--primary-1 i {
  color: #fff;
}
.bg--primary-2 {
  background: #d14b4b;
}
.bg--primary-2 p,
.bg--primary-2 span,
.bg--primary-2 ul,
.bg--primary-2 a:not(.btn) {
  color: #fff;
}
.bg--primary-2 h1,
.bg--primary-2 h2,
.bg--primary-2 h3,
.bg--primary-2 h4,
.bg--primary-2 h5,
.bg--primary-2 h6,
.bg--primary-2 i {
  color: #fff;
}
.image-bg:not(.image-light) *:not(a) {
  color: #fff;
}
.color--facebook {
  color: #3b5998;
}
.color--twitter {
  color: #00aced;
}
.color--googleplus {
  color: #dd4b39;
}
.color--instagram {
  color: #125688;
}
.color--pinterest {
  color: #cb2027;
}
.color--dribbble {
  color: #ea4c89;
}
.color--behance {
  color: #053eff;
}
.bg--facebook {
  background: #3b5998;
  color: #fff;
}
.bg--twitter {
  background: #00aced;
  color: #fff;
}
.bg--googleplus {
  background: #dd4b39;
  color: #fff;
}
.bg--instagram {
  background: #125688;
  color: #fff;
}
.bg--pinterest {
  background: #cb2027;
  color: #fff;
}
.bg--dribbble {
  background: #ea4c89;
  color: #fff;
}
.bg--behance {
  background: #053eff;
  color: #fff;
}
/**! 8. Buttons **/
.btn {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  border-radius: 100px;
  padding-top: 0.54166667em;
  padding-bottom: 0.65em;
  padding-right: 3.25em;
  padding-left: 3.25em;
  border: 1px solid #232323;
  border-width: 2px;
  font-size: inherit;
  line-height: 1.625em;
  text-transform: uppercase;
}
.btn .btn__text,
.btn i {
  color: #343434;
  border-color: #343434;
  font-weight: 400;
  font-size: 0.625em;
}
.btn[class*='col-'] {
  margin-left: 0;
  margin-right: 0;
}
.btn:active {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.btn.bg--facebook,
.btn.bg--twitter,
.btn.bg--instagram,
.btn.bg--googleplus,
.btn.bg--pinterest,
.btn.bg--dribbble,
.btn.bg--behance,
.btn.bg--dark {
  border-color: rgba(0, 0, 0, 0) !important;
}
.btn.bg--facebook .btn__text,
.btn.bg--twitter .btn__text,
.btn.bg--instagram .btn__text,
.btn.bg--googleplus .btn__text,
.btn.bg--pinterest .btn__text,
.btn.bg--dribbble .btn__text,
.btn.bg--behance .btn__text,
.btn.bg--dark .btn__text {
  color: #fff;
}
.btn.bg--facebook .btn__text i,
.btn.bg--twitter .btn__text i,
.btn.bg--instagram .btn__text i,
.btn.bg--googleplus .btn__text i,
.btn.bg--pinterest .btn__text i,
.btn.bg--dribbble .btn__text i,
.btn.bg--behance .btn__text i,
.btn.bg--dark .btn__text i {
  color: #fff;
}
.btn.bg--facebook:hover,
.btn.bg--twitter:hover,
.btn.bg--instagram:hover,
.btn.bg--googleplus:hover,
.btn.bg--pinterest:hover,
.btn.bg--dribbble:hover,
.btn.bg--behance:hover,
.btn.bg--dark:hover {
  opacity: .9;
}
@media all and (min-width: 768px) {
  .btn + .btn {
    margin-left: 1.625em;
  }
}
.btn:first-child {
  margin-left: 0;
}
.btn:last-child {
  margin-right: 0;
}
.btn--xs {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1.625em;
  padding-left: 1.625em;
}
.btn--sm {
  padding-top: 0.36111111em;
  padding-bottom: 0.43333333em;
  padding-right: 2.16666667em;
  padding-left: 2.16666667em;
}
.btn--lg {
  padding-top: 0.67708333em;
  padding-bottom: 0.8125em;
  padding-right: 4.0625em;
  padding-left: 4.0625em;
}
.btn--lg .btn__text {
  font-size: 0.78125em;
}
.btn--primary,
.btn--primary:visited {
  background: #425cbb;
  border-color: #425cbb;
}
.btn--primary .btn__text,
.btn--primary:visited .btn__text {
  color: #fff;
}
.btn--primary .btn__text i,
.btn--primary:visited .btn__text i {
  color: #fff;
}
.btn--primary:hover {
  background: #546cc3;
}
.btn--primary:active {
  background: #3b53a8;
}
.btn--primary-1,
.btn--primary-1:visited {
  background: #7cc36a;
  border-color: #7cc36a;
}
.btn--primary-1 .btn__text,
.btn--primary-1:visited .btn__text {
  color: #fff;
}
.btn--primary-1:hover {
  background: #8cca7c;
}
.btn--primary-1:active {
  background: #6cbc58;
}
.btn--primary-2,
.btn--primary-2:visited {
  background: #d14b4b;
  border-color: #d14b4b;
}
.btn--primary-2 .btn__text,
.btn--primary-2:visited .btn__text {
  color: #fff;
}
.btn--primary-2:hover {
  background: #d65f5f;
}
.btn--primary-2:active {
  background: #cc3737;
}
.btn--secondary {
  background: #f4f4f4;
  border-color: #f4f4f4;
}
.btn--secondary:hover {
  background: #f9f9f9;
}
.btn--secondary:active {
  background: #efefef;
}
.btn--white {
  background: #fff;
  color: #343434;
  border-color: #fff;
}
.btn--white i {
  color: #343434;
}
.btn--transparent {
  background: none;
  border-color: rgba(0, 0, 0, 0);
  padding-left: 0;
  padding-right: 0;
}
.btn--transparent.btn--white .btn__text {
  color: #fff;
}
.btn--unfilled {
  background: none;
}
.btn--unfilled.btn--white .btn__text {
  color: #fff;
}
.btn--unfilled.btn--white i {
  color: #fff;
}
.btn--floating {
  position: fixed;
  bottom: 3.25em;
  right: 3.25em;
  z-index: 10;
}
/**! 9. Icons **/
.icon {
  line-height: 1em;
  font-size: 2.8125em;
}
.icon--xs {
  font-size: 1em;
}
.icon--sm {
  font-size: 2em;
}
.icon--lg {
  font-size: 4em;
}
/**! 10. Lists **/
ul:last-child {
  margin: 0;
}
.list-inline li {
  padding: 0 1em;
}
.list-inline li:first-child {
  padding-left: 0;
}
.list-inline li:last-child {
  padding-right: 0;
}
.list-inline.list-inline--narrow li {
  padding: 0 .5em;
}
.list-inline.list-inline--wide li {
  padding: 0 2em;
}
/**! 11. Lightbox **/
.lb-outerContainer {
  border-radius: 0;
}
.lb-outerContainer .lb-container {
  padding: 0;
}
.lb-outerContainer .lb-container img {
  margin: 0;
}
.lightbox-gallery {
  overflow: hidden;
}
.lightbox-gallery li {
  float: left;
}
.lightbox-gallery li img {
  margin: 0;
  width: 100%;
}
.lightbox-gallery.gallery--gaps li {
  padding: 0.40625em;
}
/**! 12. Nav Bar **/
nav {
  background: none;
  z-index: 10;
}
.nav-bar {
  will-change: scroll-position, transform;
  height: 65px;
  max-height: 65px;
  line-height: 62px;
}
.nav-bar .logo {
  max-height: 40%;
  margin: 0;
}
.nav-bar .nav-module {
  display: inline-block;
  height: 65px;
  padding: 0 1em;
}
.nav-bar .nav-module > a:not(.btn) {
  height: 100%;
  display: inline-block;
}
.nav-bar.nav--fixed {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 999;
  top: 0;
}
.nav-bar.nav--fixed:not(.bg--dark):not(.bg--primary):not(.bg--white) {
  background: #f8f8f8;
}
.nav-bar.nav--fixed + .nav-mobile-toggle {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  color: #222;
}
.nav-module.left {
  float: left;
}
.nav-module.right {
  float: right;
}
.nav-module:first-of-type {
  padding-left: 0;
}
.nav-module:last-of-type {
  padding-right: 0;
}
.nav-bar,
.utility-bar {
  padding: 0 3.25em 0 3.25em;
}
.utility-bar {
  overflow: hidden;
}
.utility-bar:not(.hidden) + .nav-bar + .nav-mobile-toggle {
  top: 65px;
}
@media all and (max-width: 1280px) {
  .nav-bar {
    padding: 0 15px;
  }
}
.container .nav-bar {
  padding: 0;
}
.menu {
  height: 65px;
}
.menu li {
  position: relative;
}
.menu > li {
  position: relative;
  float: left;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0px;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
}
.menu > li > a {
  padding: 0 1.3em;
  height: 100%;
  display: inline-block;
}
.menu > li:first-child > a {
  padding-left: 0;
}
.menu > li:last-child a {
  padding-right: 0;
}
.nav-bar.bg--white .menu > li > a {
  color: #767676;
}
.nav-bar.bg--dark .menu > li > a {
  color: #fff;
}
.menu > li ul {
  left: 0;
  margin: 0;
  line-height: 1.625em !important;
  padding: 1em 0;
  background: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  min-width: 13em;
  float: left;
}
.menu > li > ul a {
  padding: 0 1em 1.625em 1em;
  display: inline-block;
}
.menu > li > ul li:last-child > a {
  padding-bottom: 0;
}
.menu > li:hover > ul,
.menu > li > ul > li:hover ul {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}
.menu > li:hover .multi-column ul {
  position: relative !important;
  visibility: visible !important;
  opacity: 1 !important;
}
.menu > li > ul:not(.multi-column) ul {
  top: 0;
  left: 100%;
}
.multi-column {
  width: auto !important;
  white-space: nowrap;
}
.multi-column li {
  width: 13em;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}
.multi-column li ul {
  position: relative;
}
.multi-column ul li {
  float: none;
  display: block;
}
.module-applet {
  position: relative;
  padding: 0 1em;
}
.module-applet__handle {
  cursor: pointer;
  position: relative;
}
.module-applet__body {
  position: absolute;
  width: 26em;
  background: none;
  z-index: 10;
  padding: 1em;
  visibility: hidden;
  opacity: 0;
}
.module-applet__body * {
  line-height: 1.625em;
}
.module-applet:hover .module-applet__body {
  opacity: 1;
  visibility: visible;
}
.nav-mobile-toggle {
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 99;
  cursor: pointer;
  line-height: 62px;
  padding: 0 1em;
  user-select: none;
  -webkit-user-select: none;
}
@media all and (max-width: 990px) {
  .nav-bar {
    height: auto;
    overflow: hidden;
  }
  .nav-bar .nav-module.left,
  .nav-bar .nav-module.right {
    float: none;
    display: block;
  }
  .nav-bar .nav-module {
    padding: 0;
  }
  .nav-bar .nav-module:not(.logo-module) {
    height: auto;
    line-height: 2em;
  }
  .menu {
    height: auto;
  }
  .menu li {
    float: none;
    width: 100%;
  }
  .menu > li > a {
    padding: 0;
  }
  .menu > li ul {
    position: relative;
    float: none;
    display: none;
    width: 100%;
  }
  .menu > li ul ul {
    left: 0 !important;
    padding-left: 2em;
  }
  .menu > li > ul:not(.multi-column) ul {
    padding-top: 0;
    padding-bottom: 1.5em;
  }
  .menu li.active > ul,
  .menu li.active .multi-column ul {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .menu .multi-column {
    white-space: initial;
  }
  .menu .multi-column ul {
    padding-top: 0;
    padding-bottom: 0;
  }
  .menu .multi-column ul li a {
    padding-left: 0;
  }
  .menu .multi-column ul li:last-child a {
    padding-bottom: 1.625em;
  }
  .module-applet {
    padding: 0;
  }
  .module-applet .module-applet__body {
    position: relative;
    display: none;
    padding: 1em 0;
    opacity: 1;
    visibility: visible;
  }
  .module-applet.active .module-applet__body {
    display: block;
  }
}
.nav-open .nav-bar {
  max-height: 100000px !important;
}
.nav--fixed,
.nav--absolute {
  width: 100%;
  z-index: 99;
}
.nav--fixed {
  position: fixed;
}
.nav--absolute {
  position: absolute;
}
.nav--transparent:not(.nav--fixed) {
  background: none !important;
}
nav:not(.nav--fixed):not(.nav--absolute) {
  position: relative;
}
@media all and (max-width: 990px) {
  .nav-bar.nav--absolute,
  .nav-bar.nav--fixed {
    position: relative;
  }
  .nav-bar.nav--absolute + .nav-mobile-toggle,
  .nav-bar.nav--fixed + .nav-mobile-toggle {
    position: absolute;
  }
}
/**! 13. Form Elements **/
form {
  max-width: 100%;
}
form + form {
  margin-top: 30px;
}
form:before,
form:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
form:after {
  clear: both;
}
label {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
}
input[type],
textarea,
select {
  -webkit-appearance: none;
  background: #ffffff;
  padding: 0.8125em;
  border-radius: 50px;
  border: none;
}
input[type]:focus,
textarea:focus,
select:focus {
  outline: none;
}
input[type]:active,
textarea:active,
select:active {
  outline: none;
}
input[type]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #c3c3c3;
  font-size: 1em;
}
input[type]:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder {
  /* Firefox 18- */
  color: #c3c3c3;
  font-size: 1em;
}
input[type]::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
  /* Firefox 19+ */
  color: #c3c3c3;
  font-size: 1em;
}
input[type]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #c3c3c3;
  font-size: 1em;
}
textarea {
  display: block;
  width: 100%;
  max-width: 100%;
}
select {
  cursor: pointer;
  padding-right: 3.25em;
  -webkit-appearance: none;
}
select::ms-expand {
  display: none;
}
input[type="submit"] {
  background: none;
  outline: none;
  border: none;
  background: #425cbb;
  padding: 0.8125em 3.25em 0.8125em 3.25em;
  color: #fff;
}
@keyframes load {
  0% {
    opacity: 0;
    width: 0;
  }
  50% {
    width: 100%;
    opacity: .8;
    left: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
button {
  background: none;
}
button[type="submit"].btn--loading {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  color: rgba(0, 0, 0, 0);
}
button[type="submit"].btn--loading * {
  opacity: 0;
}
button[type="submit"].btn--loading:after {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  background: #ddd;
  animation: load 1.5s ease-out infinite;
  left: 0;
  top: 0;
}
button[type="submit"].btn--loading .btn__text {
  opacity: 0;
}
button:focus {
  outline: none !important;
}
.input-checkbox,
.input-radio,
.input-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  cursor: pointer;
}
.input-checkbox .inner,
.input-radio .inner,
.input-select .inner {
  display: inline-block;
}
.input-checkbox input,
.input-radio input,
.input-select input {
  display: none;
}
.input-checkbox.checked .inner,
.input-radio.checked .inner,
.input-select.checked .inner {
  background: #425cbb;
}
.input-checkbox label,
.input-radio label,
.input-select label {
  display: block;
}
.input-checkbox {
  padding: 0;
}
.input-checkbox .inner {
  width: 1.625em;
  height: 1.625em;
  border-radius: 50%;
  background: #000;
}
.input-radio {
  padding: 0;
}
.input-radio .inner {
  width: 1.625em;
  height: 1.625em;
  border-radius: 50%;
  background: #000;
}
.input-select {
  position: relative;
}
.input-select i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 1em;
  font-size: .87em;
}
.input-file {
  position: relative;
  display: inline-block;
}
.input-file input {
  display: none;
}
.form-error {
  margin-top: 1.5625em;
  padding: 0.78125em;
  background: #D84D4D;
  color: #fff;
  position: fixed;
  min-width: 350px;
  left: 50%;
  bottom: 1.5625em;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.form-success {
  margin-top: 1.5625em;
  padding: 0.78125em;
  background: #1DC020;
  color: #fff;
  position: fixed;
  min-width: 350px;
  left: 50%;
  bottom: 1.5625em;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.attempted-submit .field-error {
  border-color: #D84D4D !important;
}
/**! 14. Accordions **/
.accordion li .accordion__title,
.accordion li .accordion__content,
.accordion li .accordion__content * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.accordion li .accordion__title {
  cursor: pointer;
  padding: 1.08333333em 1.625em;
  border: 1px solid #dfdfdf;
  border-bottom: none;
  background: #f4f4f4;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.accordion li:last-child .accordion__title {
  border-bottom: 1px solid #dfdfdf;
}
.accordion li .accordion__content {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}
.accordion li .accordion__content > * {
  display: none;
}
.accordion li .accordion__content *:first-child {
  padding-top: 1.625em;
}
.accordion li .accordion__content *:last-child {
  padding-bottom: 1.625em;
}
.accordion li.active .accordion__title {
  background: #425cbb;
  border-bottom: 1px solid #dfdfdf;
}
.accordion li.active .accordion__content {
  opacity: 1;
  visibility: visible;
  max-height: 500px;
}
.accordion li.active .accordion__content > * {
  display: inline-block;
}
/**! 15. Breadcrumbs **/
.breadcrumb {
  padding: 0;
  margin: 0;
  background: none;
  display: inline-block;
}
.breadcrumb li {
  font-size: 1em;
  text-transform: uppercase;
}
.breadcrumb li + li:before {
  padding: 0 0.40625em;
}
/**! 17. Tabs **/
.tabs {
  display: block;
  margin-bottom: 0;
}
.tabs > li {
  display: inline-block;
  opacity: .5;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tabs > .active,
.tabs:hover {
  opacity: 1;
}
.tabs .tab__title {
  cursor: pointer;
}
.tabs .tab__title:not(.btn) {
  padding: 0 1.625em;
}
.tabs li:first-child .tab__title:not(.btn) {
  padding-left: 0;
}
.tabs .tab__content {
  display: none;
}
.tabs-content {
  margin-top: 1em;
}
.tabs-content li > .tab__content {
  width: 100%;
  display: none;
}
.tabs-content > .active > .tab__content {
  display: block;
}
.tabs-container[data-content-align="left"] .tabs-content {
  text-align: left;
}
/**! 18. Boxes **/
.boxed {
  position: relative;
  overflow: hidden;
  padding: 2.4375em;
  margin-bottom: 30px;
}
.boxed.boxed--lg {
  padding: 3.65625em;
}
.boxed.boxed--sm {
  padding: 1.625em;
}
.boxed.boxed--border {
  border: 1px solid #c9c9c9;
}
.boxed > div[class*='col-']:first-child:not(.boxed) {
  padding-left: 0;
}
.boxed > div[class*='col-']:last-child:not(.boxed) {
  padding-right: 0;
}
img + .boxed {
  margin-top: -1.625em;
}
@media all and (max-width: 767px) {
  .boxed {
    padding: 1.625em;
    margin-bottom: 15px;
  }
  .boxed.boxed--lg {
    padding: 1.625em;
  }
  .boxed div[class*='col-']:not(.boxed) {
    padding: 0;
  }
  .boxed:last-child {
    margin-bottom: 15px;
  }
  .row:last-child div[class*='col-']:last-child .boxed:last-child {
    margin-bottom: 0;
  }
}
/**! 19. Sliders **/
section.slider {
  padding: 0 !important;
  overflow: hidden;
  position: relative;
}
section.slider .owl-theme .owl-controls .owl-nav div.owl-prev,
section.slider .owl-theme .owl-controls .owl-nav div.owl-next {
  color: #fff;
}
.slides {
  margin: 0;
  overflow: hidden;
}
.slides li > [class*='col-']:first-child:last-child:not([class*='offset']) {
  width: 100% !important;
}
.slider {
  position: relative;
  overflow: hidden;
}
.slider .slides li {
  position: relative;
  padding: 6.5em 0;
}
.slider.space--lg .slides li {
  padding: 13em 0;
}
.slider.space--xlg .slides li {
  padding: 26em 0;
}
.slider:hover .flex-direction-nav a {
  opacity: 1;
}
.slider.height-10 {
  height: auto;
}
.slider.height-10 .slides li {
  height: 10vh;
  padding: 0;
}
.slider.height-20 {
  height: auto;
}
.slider.height-20 .slides li {
  height: 20vh;
  padding: 0;
}
.slider.height-30 {
  height: auto;
}
.slider.height-30 .slides li {
  height: 30vh;
  padding: 0;
}
.slider.height-40 {
  height: auto;
}
.slider.height-40 .slides li {
  height: 40vh;
  padding: 0;
}
.slider.height-50 {
  height: auto;
}
.slider.height-50 .slides li {
  height: 50vh;
  padding: 0;
}
.slider.height-60 {
  height: auto;
}
.slider.height-60 .slides li {
  height: 60vh;
  padding: 0;
}
.slider.height-70 {
  height: auto;
}
.slider.height-70 .slides li {
  height: 70vh;
  padding: 0;
}
.slider.height-80 {
  height: auto;
}
.slider.height-80 .slides li {
  height: 80vh;
  padding: 0;
}
.slider.height-90 {
  height: auto;
}
.slider.height-90 .slides li {
  height: 90vh;
  padding: 0;
}
.slider.height-100 {
  height: auto;
}
.slider.height-100 .slides li {
  height: 100vh;
  padding: 0;
}
.slider.slider-controls--white .flex-direction-nav a {
  color: #fff;
}
.slider.slider-controls--white .flex-control-paging a {
  background: #fff;
}
.slider[data-animation="slide"] .slides li {
  -webkit-backface-visibility: hidden;
}
@media all and (max-width: 767px) {
  .slider[class*='height-'] .slides li {
    height: auto;
    padding: 6.5em 0;
  }
  .slider.space--lg .slides li {
    padding: 9.75em 0;
  }
  .slider.space--xlg .slides li {
    padding: 9.75em 0;
  }
}
.owl-theme .owl-controls {
  margin: 0;
}
.owl-theme .owl-controls .owl-dots {
  position: absolute;
  width: 100%;
  bottom: 1.625em;
  z-index: 99;
  text-align: center;
}
.owl-theme .owl-controls .owl-dots .owl-dot {
  display: inline-block;
  margin: 0 0.40625em;
  overflow: hidden;
}
.owl-theme .owl-controls .owl-dots .owl-dot span {
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  border: none;
  box-shadow: none;
  margin: 0;
}
.owl-theme .owl-controls .owl-dots .owl-dot span:hover {
  background: #ffffff;
}
.owl-theme .owl-controls .owl-dots .owl-dot.active span {
  background: #ffffff;
}
.owl-theme .owl-controls .owl-nav {
  position: absolute;
  z-index: 99;
  top: 50%;
  width: 100%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
}
.owl-theme .owl-controls .owl-nav div {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  position: absolute;
  margin: 0;
  padding: 0;
  background: none;
}
.owl-theme .owl-controls .owl-nav div:before {
  font-family: 'pillar-interface';
  font-size: 2em;
  text-align: center;
}
.owl-theme .owl-controls .owl-nav div.owl-next {
  right: 1.625em;
}
.owl-theme .owl-controls .owl-nav div.owl-next:before {
  content: "\e802";
}
.owl-theme .owl-controls .owl-nav div.owl-prev {
  left: 1.625em;
}
.owl-theme .owl-controls .owl-nav div.owl-prev:before {
  content: "\e801";
}
.slider.slider--controlsoutside {
  overflow: visible;
}
.slider.slider--controlsoutside .slides li {
  padding: 0;
}
.slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots {
  position: relative;
  bottom: 0;
}
.slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots .owl-dot {
  display: inline-block;
}
.slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots .owl-dot span {
  background: #232323;
  display: inline-block;
}
.slider.slider--controlsoutside[data-arrows="true"] .owl-theme .owl-controls .owl-nav .owl-next {
  right: -4em;
}
.slider.slider--controlsoutside[data-arrows="true"] .owl-theme .owl-controls .owl-nav .owl-prev {
  left: -4em;
}
.imagebg .slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots,
section.bg--dark .slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots {
  position: relative;
  bottom: 0;
}
.imagebg .slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots .owl-dot span,
section.bg--dark .slider.slider--controlsoutside[data-paging="true"] .owl-theme .owl-controls .owl-dots .owl-dot span {
  background: #fff;
}
/**! 20. Hover Elements **/
.hover-element {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.hover-element * {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.hover-element .hover-element__reveal {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.hover-element .hover-element__reveal .boxed {
  height: 100%;
}
.hover-element:hover .hover-element__reveal,
.hover-element.hover--active .hover-element__reveal {
  opacity: 1;
}
.hover-element img {
  margin-bottom: 0;
}
@media all and (max-width: 1024px) {
  .hover-element {
    cursor: pointer;
  }
}
.row:last-child div[class*='col-']:last-child .hover-element {
  margin-bottom: 0;
}
/**! 21. Masonry **/
.masonry .masonry__container.masonry--active .masonry__item {
  opacity: 1;
  pointer-events: initial;
}
.masonry .masonry__container .masonry__item {
  opacity: 0;
  pointer-events: none;
}
.masonry .masonry__filters li {
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.masonry .masonry__filters li.active {
  cursor: default;
}
/**! 22. Modals **/
.modal-instance .modal-body {
  display: none;
}
.modal-container {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.modal-container.modal-active {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}
.modal-container:before {
  background: rgba(0, 0, 0, 0.85);
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.modal-container .modal-content {
  backface-visibility: hidden;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  max-height: 100%;
  overflow-y: auto;
  border: none;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
.modal-container .modal-content:not(.height--natural) {
  width: 50%;
  height: 50%;
}
.modal-container .modal-content .modal-close-cross {
  cursor: pointer;
  position: absolute;
  opacity: .5;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  top: 1em;
  right: 1em;
  z-index: 99;
}
.modal-container .modal-content .modal-close-cross:before {
  content: '\2715';
  font-size: 1.5em;
}
.modal-container .modal-content .modal-close-cross:hover {
  opacity: 1;
}
.modal-container .modal-content.imagebg:not(.image--light) .modal-close-cross:before {
  color: #fff;
}
.modal-container .modal-content iframe {
  width: 100%;
  outline: none;
  border: none;
  height: 100%;
  backface-visibility: hidden;
}
.modal-container .modal-content iframe:first-child + .modal-close-cross:last-child {
  top: -3.25em;
}
@media all and (max-width: 767px) {
  .modal-container .modal-content {
    width: 97% !important;
    height: auto !important;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
/**! 23. Maps **/
.map-container {
  position: relative;
  overflow: hidden;
}
.map-container iframe,
.map-container .map-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
/**! 24. Parallax **/
.parallax > .background-image-holder,
.parallax .slides li > .background-image-holder {
  height: 100vh;
  top: -50vh;
  transition: opacity 0.3s ease !important;
  -webkit-transition: opacity 0.3s ease !important;
  -webkit-transform-style: preserve-3d;
}
.parallax:first-child .slides li > .background-image-holder,
.parallax:first-child .background-image-holder {
  top: 0;
}
.main-container > a:first-child + .parallax .background-image-holder {
  top: 0;
}
@media all and (max-width: 1024px) {
  .parallax > .background-image-holder,
  .parallax .slides li > .background-image-holder {
    top: 0 !important;
    transform: none !important;
    -webkit-transform: none !important;
    height: 100%;
  }
}
.parallax {
  will-change: contents;
}
/**! 25. Notifications **/
.notification {
  max-width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: fixed;
  z-index: 99;
  pointer-events: none;
  padding: 0;
  margin: 1em;
  opacity: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.notification:not([class*='bg-']) {
  background: #fff;
}
.notification[class*='col-'] {
  min-width: 400px;
}
.notification .notification-close-cross {
  position: absolute;
  top: 1em;
  z-index: 99;
  right: 1em;
  cursor: pointer;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  opacity: .7;
}
.notification .notification-close-cross:before {
  content: '\2715';
  font-size: 1.5em;
}
.notification .notification-close-cross:hover {
  opacity: 1;
}
.notification.notification--reveal {
  z-index: 99;
  pointer-events: initial;
}
.notification.notification--reveal[data-animation="from-bottom"] {
  animation: from-bottom 0.3s linear 0s forwards;
  -webkit-animation: from-bottom 0.3s linear 0s forwards;
  -moz-animation: from-bottom 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-top"] {
  animation: from-top 0.3s linear 0s forwards;
  -webkit-animation: from-top 0.3s linear 0s forwards;
  -moz-animation: from-top 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-left"] {
  animation: from-left 0.3s linear 0s forwards;
  -webkit-animation: from-left 0.3s linear 0s forwards;
  -moz-animation: from-left 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-right"] {
  animation: from-right 0.3s linear 0s forwards;
  -webkit-animation: from-right 0.3s linear 0s forwards;
  -moz-animation: from-right 0.3s linear 0s forwards;
}
.notification.notification--dismissed {
  animation: fade-out 0.4s linear 0s forwards !important;
  -webkit-animation: fade-out 0.4s linear 0s forwards !important;
  -moz-animation: fade-out 0.4s linear 0s forwards !important;
  pointer-events: none;
}
a[data-notification-link] {
  text-decoration: none;
}
a[data-notification-link]:hover {
  text-decoration: none;
}
@media all and (max-width: 767px) {
  .notification[class*='col-'] {
    min-width: 0;
  }
}
@keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/**! 26. Video **/
iframe {
  width: 100%;
  min-height: 350px;
  border: none;
}
@media all and (max-width: 767px) {
  iframe {
    min-height: 220px;
  }
}
.videobg {
  background: #232323;
  position: relative;
  overflow: hidden;
}
.videobg .container,
.videobg .background-image-holder {
  opacity: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.videobg .background-image-holder {
  opacity: 0 !important;
}
.videobg.video-active .container {
  opacity: 1;
}
.videobg.video-active .loading-indicator {
  opacity: 0;
  visibility: hidden;
}
.videobg video {
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  z-index: 0 !important;
  left: 0;
}
@media all and (max-width: 1024px) {
  .videobg .background-image-holder,
  .videobg .container {
    opacity: 1 !important;
  }
  .videobg .loading-indicator {
    display: none;
  }
  .videobg video {
    display: none;
  }
}
.youtube-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 0 !important;
}
.youtube-background .mb_YTPBar {
  opacity: 0;
  height: 0;
  visibility: hidden;
}
@media all and (max-width: 1024px) {
  .youtube-background {
    display: none;
  }
}
.loading-indicator {
  position: absolute !important;
  top: 50%;
  left: 50%;
  z-index: 99 !important;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: loading-spinner 1s infinite ease-in-out;
  animation: loading-spinner 1s infinite ease-in-out;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
@-webkit-keyframes loading-spinner {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes loading-spinner {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.video-cover {
  position: relative;
}
.video-cover video {
  max-width: 100%;
}
.video-cover iframe {
  background: #232323;
}
.video-cover .background-image-holder {
  z-index: 3;
}
.video-cover .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
}
.video-cover .video-play-icon,
.video-cover .background-image-holder {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.video-cover.reveal-video .video-play-icon,
.video-cover.reveal-video .background-image-holder {
  opacity: 0 !important;
  pointer-events: none;
}
.video-cover[data-scrim-bottom]:before,
.video-cover[data-overlay]:before,
.video-cover[data-scrim-top]:before {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  z-index: 4;
}
.video-cover.reveal-video[data-scrim-bottom]:before,
.video-cover.reveal-video[data-overlay]:before,
.video-cover.reveal-video[data-scrim-top]:before {
  opacity: 0;
  pointer-events: none;
}
.video-play-icon {
  width: 6.5em;
  height: 6.5em;
  border-radius: 50%;
  position: relative;
  z-index: 4;
  display: inline-block;
  border: 2px solid #ffffff;
  cursor: pointer;
  background: #ffffff;
}
.video-play-icon.video-play-icon--sm {
  width: 3.25em;
  height: 3.25em;
}
.video-play-icon.video-play-icon--sm:before {
  border-width: 4px 0 4px 9px;
}
.video-play-icon.video-play-icon--xs {
  width: 1.625em;
  height: 1.625em;
}
.video-play-icon.video-play-icon--xs:before {
  border-width: 3px 0 3px 6px;
  margin-left: -3px;
}
.video-play-icon.bg--primary:before {
  border-color: transparent transparent transparent #fff;
}
.video-play-icon:before {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 50%;
  margin-left: -4px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 12px;
  border-color: transparent transparent transparent #ffffff;
  border-color: transparent transparent transparent #808080;
}
.video-play-icon.video-play-icon--dark {
  border-color: #232323;
  background: #232323;
}
.video-play-icon.video-play-icon--dark:before {
  border-color: transparent transparent transparent #232323;
}
.video-play-icon.video-play-icon--dark:before {
  border-color: transparent transparent transparent #fff;
}
@media all and (max-width: 767px) {
  .video-play-icon {
    width: 4.33333333em;
    height: 4.33333333em;
  }
}
div[class*='col-'][class*='12']:not([class*='xs-12']) .video-cover iframe {
  min-height: 550px;
}
@media all and (max-width: 990px) {
  div[class*='col-'][class*='12']:not([class*='xs-12']) .video-cover iframe {
    min-height: 350px;
  }
}
div[class*='col-'][class*='10'] .video-cover iframe {
  min-height: 450px;
}
div[class*='col-'][class*='8'] .video-cover iframe {
  min-height: 400px;
}
div[class*='col-'][class*='6'] .video-cover iframe {
  min-height: 350px;
}
@media all and (max-width: 1200px) {
  div[class*='col-'][class*='6'] .video-cover iframe {
    min-height: 300px;
  }
}
@media all and (max-width: 990px) {
  div[class*='col-'][class*='6'] .video-cover iframe {
    min-height: 220px;
  }
}
@media all and (max-width: 767px) {
  div[class*='col-'] .video-cover iframe {
    min-height: 220px !important;
  }
}
.modal-container video {
  max-width: 100%;
}
/**! 27. Image Blocks **/
.imageblock {
  position: relative;
  padding: 0;
}
.imageblock > .container,
.imageblock > div[class*='col-']:not(.imageblock__content) {
  padding-top: 6.5em;
  padding-bottom: 8.125em;
  float: none;
  overflow: hidden;
}
.imageblock.imageblock--lg > .container,
.imageblock.imageblock--lg > div[class*='col-']:not(.imageblock__content) {
  padding-top: 10.15625em;
  padding-bottom: 10.15625em;
  float: none;
  overflow: hidden;
}
.imageblock .imageblock__content {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;
  padding: 0;
}
.imageblock .imageblock__content .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.imageblock .imageblock__content .slider .slides > li {
  padding: 0;
  min-height: 100%;
  position: absolute !important;
}
.imageblock.allow-overflow .imageblock__content {
  overflow: visible;
}
@media all and (max-width: 767px) {
  .imageblock[class*='space-'] {
    padding-bottom: 0;
    padding-top: 0;
  }
  .imageblock .imageblock__content {
    position: relative;
    min-height: 16.25em;
  }
  .imageblock > .container,
  .imageblock > div[class*='col-']:not(.imageblock__content) {
    padding-top: 4.875em;
    padding-bottom: 4.875em;
    float: none;
    overflow: hidden;
  }
  .imageblock.imageblock--lg > .container,
  .imageblock.imageblock--lg > div[class*='col-']:not(.imageblock__content) {
    padding-top: 4.875em;
    padding-bottom: 4.875em;
    float: none;
    overflow: hidden;
  }
}
/**! 28. MailChimp & Campaign Monitor **/
form[action*='createsend.com'] * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
}
form[action*='createsend.com'].form--active * {
  opacity: 1;
}
form[action*='createsend.com'] .input-checkbox + br {
  display: none;
}
form[action*='createsend.com'].no-labels label {
  display: none;
}
form[action*='createsend.com'] br {
  display: none;
}
form[action*='createsend.com'] p > label:first-child {
  margin-bottom: 0.8125em;
}
form[action*='list-manage.com'] h2 {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 1.4375em;
  line-height: 1.41304348em;
  margin-top: 0;
  margin-bottom: 1.1304347826087em;
}
form[action*='list-manage.com'] .input-group ul {
  overflow: hidden;
}
form[action*='list-manage.com'] .input-group ul li {
  float: left;
}
form[action*='list-manage.com'] * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
}
form[action*='list-manage.com'].form--active * {
  opacity: 1;
}
form[action*='list-manage.com'].no-labels label {
  display: none;
}
form[action*='list-manage.com'] .small-meta {
  font-size: 0.5em;
}
/**! 29. Twitter **/
.twitter-feed .user {
  display: none;
}
.twitter-feed .interact {
  display: none;
}
.twitter-feed .timePosted {
  font-size: .87em;
}
/**! 30. Transitions **/
[class*='transition--'] {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  opacity: 0;
}
[class*='transition--'].transition--active {
  opacity: 1;
}
.transition--scale {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
}
.transition--scale.transition--active {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
}
.transition--slide {
  transform: translate3d(200px, 0, 0);
  -webkit-transform: translate3d(200px, 0, 0);
  transform: translate3d(30vw, 0, 0);
  -webkit-transform: translate3d(30vw, 0, 0);
}
.transition--slide.transition--active {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
/**! 31. Spacing **/
section,
footer {
  padding-top: 6.5em;
  padding-bottom: 8.125em;
}
section.space--xxs,
footer.space--xxs {
  padding-top: 1.625em;
  padding-bottom: 1.625em;
}
section.space--xs,
footer.space--xs {
  padding-top: 3.25em;
  padding-bottom: 3.25em;
}
section.space--sm,
footer.space--sm {
  padding-top: 4.33333333em;
  padding-bottom: 4.33333333em;
}
section.space--md,
footer.space--md {
  padding-top: 9.75em;
  padding-bottom: 9.75em;
}
section.space--lg,
footer.space--lg {
  padding-top: 13em;
  padding-bottom: 13em;
}
section.space--xlg,
footer.space--xlg {
  padding-top: 26em;
  padding-bottom: 32.5em;
}
section.space--0,
footer.space--0 {
  padding: 0;
}
section.section--even,
footer.section--even {
  padding-top: 8.125em;
  padding-bottom: 8.125em;
}
section.space-bottom--sm,
footer.space-bottom--sm {
  padding-bottom: 4.33333333em;
}
@media all and (max-width: 767px) {
  section,
  footer,
  section.section--even {
    padding: 4.875em 0;
  }
  section.space--lg,
  footer.space--lg,
  section.section--even.space--lg {
    padding: 4.875em 0;
  }
  section.space--xlg,
  footer.space--xlg,
  section.section--even.space--xlg {
    padding: 7.3125em 0;
  }
}
div[class*='col-'] > div[class*='col-']:first-child {
  padding-left: 0;
}
div[class*='col-'] > div[class*='col-']:last-child {
  padding-right: 0;
}
@media all and (max-width: 767px) {
  .col-xs-6:nth-child(odd) {
    padding-right: 7.5px;
  }
  .col-xs-6:nth-child(even) {
    padding-left: 7.5px;
  }
}
@media all and (min-width: 768px) {
  .mt--1 {
    margin-top: 1.625em;
  }
  .mt--2 {
    margin-top: 3.25em;
  }
  .mt--3 {
    margin-top: 4.875em;
  }
}
@media all and (max-width: 990px) {
  .mt--1,
  .mt--2 {
    margin-top: 1.625em;
  }
  .mt--3 {
    margin-top: 2.4375em;
  }
}
/**! 32. Theme Overrides **/
/*! -- Pillar Customizers -- */
body.theme--square .btn {
  border-radius: 0 0 0 0;
}
body.theme--square input {
  border-radius: 0 0 0 0;
}
body.theme--square button {
  border-radius: 0 0 0 0 !important;
}
body[data-scroll-reveal="true"] section:not(:first-child) {
  visibility: hidden;
}
body.theme--boxed {
  padding: 4.875em 0;
  background: #eeeeee;
}
body.theme--boxed .main-container,
body.theme--boxed nav,
body.theme--boxed .nav-bar.nav--fixed {
  max-width: 1400px;
  margin: 0 auto;
  left: 0;
  right: 0;
}
body.theme--boxed section:not(.bg--white):not(.bg--dark):not(.bg--secondary):not(.bg--dark):not(.bg--primary):not(.section--overlap) {
  background: #f8f8f8;
}
body.theme--boxed section.section--overlap + section:not(.bg--white):not(.bg--dark):not(.bg--secondary):not(.bg--dark):not(.bg--primary):not(.section--overlap) {
  padding-top: 6.5em !important;
}
@media all and (max-width: 1400px) {
  body.theme--boxed {
    padding: 0;
  }
}
.btn.btn--square {
  border-radius: 0 0 0 0;
}
.btn.btn--transparent {
  border: none !important;
}
.loader {
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
  overflow: hidden;
  pointer-events: none;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.loader.loader--fade {
  transition: none;
  -webkit-transition: none;
  opacity: 0;
}
.loader.loader--fade:before {
  animation: none;
}
.loader:before {
  display: block;
  position: absolute;
  content: "";
  left: -25%;
  width: 25%;
  height: 3px;
  background-color: #425cbb;
  animation: loading 5s linear infinite;
}
@keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 125%;
  }
}
/*! -- Pillar Typography -- */
@media all and (max-width: 1024px) {
  html {
    font-size: 87.5%;
  }
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
}
h1,
.h1 {
  /* margin-bottom: 0.40625em; */
}
h2,
.h2 {
  /* margin-bottom: 0.57777777777778em; */
}
h3,
.h3 {
  /* margin-bottom: 0.8125em; */
}
h4,
.h4 {
  /* margin-bottom: 1.1304347826087em; */
}
h5,
.h5 {
  /* margin-bottom: 1.625em; */
}
h6,
.h6 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
}
@media all and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2em;
    line-height: 1.421875em;
  }
}
.lead {
  margin-bottom: 1.1304347826087em;
  font-weight: 400;
}
.lead:last-child {
  margin-bottom: 0;
}
a {
  color: #232323;
}
a:hover {
  color: #232323;
}
span a,
p a {
  text-decoration: underline;
}
span a:hover,
p a:hover {
  text-decoration: underline;
  opacity: .9;
}
h1 em,
h2 em,
h3 em,
h4 em,
h5 em,
h6 em {
  font-family: 'Lora';
  font-weight: 400;
  font-style: italic;
}
.type--body-font {
  font-weight: 400;
}
.type--body-font em {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
}
.imagebg:not(.image--light) .h1,
.imagebg:not(.image--light) .h2,
.imagebg:not(.image--light) .h3,
.imagebg:not(.image--light) .h4,
.imagebg:not(.image--light) .h5,
.imagebg:not(.image--light) .h6,
.imagebg:not(.image--light) i {
  color: #fff;
}
.imagebg:not(.image--light) p {
  opacity: .9;
}
.imagebg:not(.image--light) p a {
  color: #fff;
}
hr,
.boxed.boxed--border {
  border-color: #ebebeb;
}
.bg--secondary hr {
  border-color: #e7e7e7;
}
blockquote {
  font-size: 1.4375em;
  line-height: 1.69565217391304em;
  font-style: italic;
  margin-bottom: 1.1304347826087em;
}
.bg--primary p,
.bg--primary span,
.bg--primary ul,
.bg--primary a:not(.btn),
.bg--primary .icon {
  color: #fff;
  opacity: .9;
}
.bg--primary h1,
.bg--primary h2,
.bg--primary h3,
.bg--primary h4,
.bg--primary h5,
.bg--primary h6,
.bg--primary i {
  color: #fff;
}
.link-list li:nth-child(even) {
  background: #f4f4f4;
}
.link-list li a {
  display: inline-block;
  padding: 0.40625em;
  font-style: italic;
  opacity: .5;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.link-list li a:before {
  content: '\203A';
  opacity: .5;
  margin-right: 0.8125em;
}
.link-list li a:hover {
  opacity: 1;
}
p[class*='col-'] {
  padding: 0;
}
span.label {
  display: inline-block;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  font-size: 10px;
  padding: 0 8px;
  color: #fff !important;
  background: #64b671;
  position: absolute;
  top: 13px;
  right: 13px;
  margin: 0;
}
.menu li a + span.label {
  right: 26px;
  top: 0;
  max-height: 20px;
  line-height: 20px;
}
/*! -- Pillar Mixins & Variable -- */
.box-shadow {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.box-shadow-light {
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
}
.box-shadow-inset {
  box-shadow: inset 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.box-shadow-wide {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.iconsmind {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*! -- Pillar Buttons -- */
.btn.btn--primary {
  border-color: #425cbb;
}
.btn.btn--primary:hover {
  border-color: #546cc3;
}
.btn.btn--success {
  background: #64b671;
  border-color: #64b671;
}
.btn.btn--success:hover {
  border-color: #75be81;
}
.btn.btn--success .btn__text {
  color: #fff;
}
@media all and (max-width: 767px) {
  .btn + .btn {
    margin-left: 0;
    margin-top: 4px;
  }
}
@media all and (min-width: 990px) {
  .btn--merge {
    border-radius: 100px 0 0 100px;
    position: relative;
  }
  .btn--merge + .btn {
    border-radius: 0 100px 100px 0;
    margin-left: -5px;
  }
  .btn--merge + .btn.btn--primary {
    background: #546cc3;
    border-color: #546cc3;
  }
  .btn--merge + .btn.btn--primary:hover {
    background: #5f75c7;
    border-color: #5f75c7;
  }
}
.btn--xs {
  padding-bottom: 1px;
}
.bg--primary .btn {
  border-color: rgba(255, 255, 255, 0.1);
}
.bg--primary .btn .btn__text {
  color: #fff;
}
.bg--primary .btn:hover {
  border-color: rgba(255, 255, 255, 0.6);
}
.btn-round {
  width: 4.0625em;
  height: 4.0625em;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: 0.2s linear !important;
  -webkit-transition: 0.2s linear !important;
  -moz-transition: 0.2s linear !important;
}
.btn-round i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  font-size: 1.5em;
  width: 100%;
}
.btn-round:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
}
/*! -- Pillar Nav Bar -- */
.logo {
  margin: 0;
}
.nav-bar {
  padding-right: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.nav-bar .logo {
  margin-right: 1.625em;
}
.nav-bar .logo-light {
  display: none;
}
.nav-bar .logo-dark {
  display: inline;
}
.nav-bar.nav--absolute {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-bar.nav--absolute:not(.nav--fixed) .nav-module.right:not(.logo-module):not(.menu-module):not( :nth-last-child(2)) {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-bar .nav-module.right:not(.logo-module):not(.menu-module) {
  padding: 0 1.625em;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.nav-bar .nav-module.right:not(.logo-module):not(.menu-module):not( :nth-last-child(2)) {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding-right: 0;
}
.nav-bar.nav--fixed {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  border-bottom: none;
}
.nav-bar.nav--fixed:not(.no-animation) {
  animation: navslide .5s ease forwards;
}
.nav-bar.bg--dark .logo-light {
  display: inline;
}
.nav-bar.bg--dark .logo-dark {
  display: none;
}
.nav-bar a.nav-function {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.nav-bar a.nav-function span {
  font-size: 10px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  letter-spacing: 1px;
  margin-left: 0.8125em;
  display: none;
}
.nav-bar a.nav-function i {
  font-size: 1.1em;
  display: inline-block;
  position: relative;
  top: 3px;
}
.nav-bar a.nav-function:hover {
  opacity: .5;
}
@media all and (min-width: 991px) {
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) .menu > li > a {
    color: #fff;
  }
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) .logo-dark {
    display: none;
  }
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) .logo-light {
    display: inline;
  }
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) a.nav-function i,
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) a.nav-function span {
    color: #fff;
  }
}
@media all and (max-width: 767px) {
  .nav-bar a.nav-function span {
    display: inline-block;
  }
}
@keyframes navslide {
  from {
    transform: translate3d(0, -100px, 0);
    -webkit-transform: translate3d(0, -100px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@media all and (max-width: 990px) {
  .nav-bar {
    padding: 0 15px;
  }
  .nav-bar .nav-module.right:not(.logo-module):not(.menu-module) {
    padding: 0;
    margin-bottom: 0.8125em;
  }
}
.menu > li {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.menu > li:hover > a {
  opacity: .6;
}
.menu > li a {
  color: #232323;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.menu > li ul {
  white-space: nowrap;
  background: #232323;
  margin-top: 3px;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  padding: 1.625em;
  min-width: 16em;
}
.menu > li ul li {
  padding-left: 0.42307692307692em;
}
.menu > li ul li a {
  padding-left: 0;
  width: 100%;
  color: #fff;
}
.menu > li ul li a:hover {
  transform: translate3d(5px, 0, 0);
  -webkit-transform: translate3d(5px, 0, 0);
}
.menu > li > ul {
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
}
.menu > li > ul:not(.multi-column) > li.dropdown > a:after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 4px;
  opacity: .4;
  transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  transform: translate3d(-10px, 0, 0);
  -webkit-transform: translate3d(-10px, 0, 0);
}
.menu > li > ul:not(.multi-column) > li.dropdown:hover > a {
  transform: translate3d(5px, 0, 0);
  -webkit-transform: translate3d(5px, 0, 0);
  opacity: 1;
}
.menu > li > ul:not(.multi-column) > li.dropdown:hover > a:after {
  opacity: 1;
  transform: translate3d(-3px, 0, 0);
  -webkit-transform: translate3d(-3px, 0, 0);
}
.menu > li > ul:not(.multi-column) ul {
  left: calc(112%);
}
.menu > li > ul a {
  padding: 0 1em 1.3em 1em;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  opacity: .5;
}
.menu > li > ul a:hover {
  opacity: 1;
}
@media all and (max-width: 990px) {
  .menu > li ul ul {
    padding-left: 0;
  }
}
.multi-column {
  padding: 2.4375em !important;
}
.multi-column li {
  width: 20em;
}
.multi-column li ul {
  padding: 0;
}
.multi-column .multi-column__title {
  display: inline-block;
  color: #fff;
  margin-bottom: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.utility-bar {
  padding-top: 0.8125em;
  padding-bottom: 0.8125em;
  padding-right: 1.625em;
}
.utility-bar .social-list {
  line-height: 1.625em !important;
}
.utility-bar .social-list li a {
  font-size: 13px;
}
.utility-bar .nav-module + .nav-module {
  margin-left: 1.625em;
}
.utility-bar:not(.hidden) + .nav-bar + .nav-mobile-toggle {
  top: 42px;
}
.nav-mobile-toggle {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  opacity: .75;
}
.nav-mobile-toggle i {
  display: inline-block;
  margin-top: 18px;
}
.nav-mobile-toggle:hover {
  opacity: 1;
}
@media all and (max-width: 767px) {
  .utility-bar:not(.hidden) + .nav-bar + .nav-mobile-toggle {
    top: 46px;
  }
}
/*! -- Pillar Nav Stack -- */
.nav-stack .nav-stack__upper {
  overflow: hidden;
  margin: 1.625em 0;
}
.nav-stack .nav-stack__upper .logo {
  max-height: 2.84375em;
  display: inline-block;
}
.nav-stack .nav-bar {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.nav-stack .nav-bar:not(.nav--fixed) {
  box-shadow: none;
}
.nav-stack .nav-bar:not(.nav--fixed) .logo-module {
  display: none !important;
}
.nav-stack .nav-bar:not(.nav--fixed) .menu-module {
  padding-left: 0;
}
.nav-stack .nav-bar.nav--fixed .logo-module {
  padding-left: 1.625em;
}
@media all and (max-width: 990px) {
  .nav-stack .menu-module {
    padding-top: 65px;
  }
  .nav-stack .nav-mobile-toggle {
    width: 100%;
    right: 0;
    text-align: center;
  }
}
/*! -- Pillar Offscreen Nav -- */
.nav-bar + .nav-mobile-toggle[data-modal-id] i {
  display: inline-block;
  margin-right: 7px;
}
.nav-bar + .nav-mobile-toggle[data-modal-id] span {
  position: relative;
  bottom: 10px;
}
@media all and (min-width: 991px) {
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) + .nav-mobile-toggle[data-modal-id] {
    color: #fff;
  }
  .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) + .nav-mobile-toggle[data-modal-id] .h6 {
    color: #fff;
  }
}
@media all and (max-width: 767px) {
  .nav-bar:not(.bg--dark) + .nav-mobile-toggle[data-modal-id] i,
  .nav-bar:not(.bg--dark) + .nav-mobile-toggle[data-modal-id] .h6 {
    color: #232323 !important;
  }
}
.nav-modal .menu,
.nav-modal .pos-bottom {
  transition: 0.5s ease-out;
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  transition-delay: .3s;
  opacity: 0;
}
.nav-modal .pos-vertical-center {
  overflow: hidden;
}
.nav-modal .pos-bottom {
  bottom: 3.25em;
  transform: translate3d(0, 100px, 0);
  -webkit-transform: translate3d(0, 100px, 0);
}
.nav-modal .menu {
  display: inline-block;
  transform: rotateY(90deg);
  -webkit-transform: rotateY(90deg);
  text-align: center;
}
.nav-modal .menu > li {
  display: block;
  text-align: center;
  float: none;
}
.nav-modal .menu > li:not(:last-child) {
  margin-bottom: 3.25em;
}
.nav-modal .menu > li > a {
  padding: 0;
}
.nav-modal .menu > li > a span {
  text-transform: capitalize;
  letter-spacing: 0;
}
.nav-modal .menu > li:not(:last-child) > a {
  margin-right: 0;
}
.nav-modal .menu > li ul {
  background: none;
  padding: 1.625em 0 0 0;
  text-align: left;
}
.nav-modal .menu > li ul li {
  padding-left: 0;
}
.nav-modal .bg--dark {
  background: rgba(35, 35, 35, 0.9);
}
.nav-modal .bg--primary {
  background: rgba(66, 92, 187, 0.9);
}
.nav-modal.modal-active .menu {
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  opacity: 1;
}
.nav-modal.modal-active .pos-bottom {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  opacity: 1;
}
@media all and (max-width: 767px) {
  .nav-modal .modal-content {
    width: 100% !important;
    height: 100% !important;
    padding: 0;
  }
  .nav-modal .modal-content .pos-vertical-center {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
  }
  .nav-modal .menu > li:not(:last-child) > a {
    margin-right: 0;
  }
}
/*! -- Pillar Nav Slide -- */
.nav-slide {
  min-height: 100vh;
  height: 100vh;
  padding: 3.25em;
}
.nav-slide .logo {
  display: block;
  margin-bottom: 3.25em;
}
.nav-slide .logo img {
  max-height: 4.875em;
}
.nav-slide .menu {
  height: auto;
  display: inline-block;
}
.nav-slide .menu li {
  float: none;
  margin-right: 0;
}
.nav-slide .menu li:not(:last-child) {
  margin-bottom: 1.625em;
}
.nav-slide .menu li a {
  padding: 0;
}
.nav-slide .menu li a span {
  text-transform: capitalize;
  letter-spacing: 0px;
}
.nav-slide .pos-bottom {
  bottom: 1.625em;
  width: 100%;
  left: 0;
}
.nav-slide .pos-bottom .social-list {
  margin-bottom: 0.40625em;
}
.nav-slide .pos-bottom span.type--fine-print {
  opacity: .5;
}
.nav-slide .nav-slide__content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  min-height: 100vh;
  opacity: 0;
}
.nav-slide.notification--reveal:not(.notification--dismissed) .nav-slide__content {
  animation: slidein .5s ease .3s forwards;
  -webkit-animation: slidein .5s ease .3s forwards;
}
@keyframes slidein {
  from {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
    -webkit-transform: translate3d(200px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slidein {
  from {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
    -webkit-transform: translate3d(200px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@media all and (max-width: 767px) {
  .nav-slide__content {
    padding-top: 4.875em;
  }
  .nav-slide__content .logo img {
    max-height: 3.25em;
  }
}
/*! -- Pillar Buttons -- */
.btn {
  border-color: rgba(35, 35, 35, 0.1);
}
.btn .btn__text {
  letter-spacing: 1px;
}
.btn:hover {
  border-color: rgba(35, 35, 35, 0.9);
}
/*! -- Pillar Forms -- */
::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}
form div[class*='col-'] > input {
  width: 100%;
}
form div[class*='col-'] > button[type="submit"] {
  width: 100%;
}
form .row {
  margin-left: 0;
  margin-right: 0;
}
form.form--labels .row button[type="submit"] {
  position: relative;
  top: 39px;
}
form.form--center-submit-button button[type="submit"] {
  display: block;
  margin: 0 auto;
}
form.attempted-submit .field-error {
  color: #e34747;
}
form.attempted-submit .field-error::-webkit-input-placeholder {
  color: #e34747;
  font-size: 1em;
}
form.attempted-submit .field-error:-moz-placeholder {
  /* Firefox 18- */
  color: #e34747;
  font-size: 1em;
}
form.attempted-submit .field-error::-moz-placeholder {
  /* Firefox 19+ */
  color: #e34747;
  font-size: 1em;
}
form.attempted-submit .field-error:-ms-input-placeholder {
  color: #e34747;
  font-size: 1em;
}
@media all and (max-width: 1024px) {
  form.form--labels .row button[type="submit"] {
    top: 34px;
  }
}
@media all and (max-width: 767px) {
  form.form--labels .row button[type="submit"] {
    top: 0;
  }
  form input[type='text'],
  form input[type='address'],
  form input[type='url'],
  form input[type='password'],
  form input[type='email'],
  form input[type="search"],
  form input[type="tel"],
  form textearea {
    margin-bottom: 1.625em;
  }
}
input[type='text'],
input[type='address'],
input[type='url'],
input[type='password'],
input[type='email'],
input[type="search"],
input[type="tel"] {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  color: #767676;
  padding-left: 1.625em;
  padding-right: 1.625em;
  height: 52px;
}
input[type='text']:focus,
input[type='address']:focus,
input[type='url']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type="search"]:focus,
input[type="tel"]:focus {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
textarea {
  margin-bottom: 1.625em;
}
.input-with-icon {
  position: relative;
}
.input-with-icon i {
  position: absolute;
  font-size: 1.4375em;
  margin-left: 1.1304347826087em;
  top: 13px;
  left: 0;
}
.input-with-icon label + i {
  top: 52px;
}
.input-with-icon input {
  padding-left: 4.0625em;
  width: 100%;
}
.input-with-icon label + i + input {
  margin-top: 0.8125em;
}
.input-with-icon:not(:last-child) {
  margin-bottom: 1.625em;
}
.input-with-icon + div > textarea {
  padding-left: 1.625em;
}
@media all and (max-width: 767px) {
  .input-with-icon:not(:last-child) {
    margin: 0;
  }
}
.imagebg:not(.image--light):not(.form--dark) input[type='text'],
.imagebg:not(.image--light):not(.form--dark) input[type='address'],
.imagebg:not(.image--light):not(.form--dark) input[type='url'],
.imagebg:not(.image--light):not(.form--dark) input[type='password'],
.imagebg:not(.image--light):not(.form--dark) input[type='email'],
.imagebg:not(.image--light):not(.form--dark) input[type="search"] {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}
.imagebg:not(.image--light):not(.form--dark) input[type='text']::-webkit-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='address']::-webkit-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='url']::-webkit-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='password']::-webkit-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='email']::-webkit-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type="search"]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
  font-size: 1em;
}
.imagebg:not(.image--light):not(.form--dark) input[type='text']:-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='address']:-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='url']:-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='password']:-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='email']:-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type="search"]:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.75);
  font-size: 1em;
}
.imagebg:not(.image--light):not(.form--dark) input[type='text']::-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='address']::-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='url']::-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='password']::-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='email']::-moz-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type="search"]::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.75);
  font-size: 1em;
}
.imagebg:not(.image--light):not(.form--dark) input[type='text']:-ms-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='address']:-ms-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='url']:-ms-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='password']:-ms-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type='email']:-ms-input-placeholder,
.imagebg:not(.image--light):not(.form--dark) input[type="search"]:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
  font-size: 1em;
}
.imagebg:not(.image--light):not(.form--dark) .input-with-icon i {
  color: #fff;
}
.imagebg:not(.image--light):not(.form--dark) label {
  color: #fff;
}
.form--dark .input-with-icon i {
  color: #232323;
}
.bg--white input[type='text'],
.bg--white input[type='address'],
.bg--white input[type='url'],
.bg--white input[type='password'],
.bg--white input[type='email'],
.bg--white input[type="search"] {
  background: #f8f8f8;
}
label {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  display: block;
  margin: 0;
  margin-left: 2.6em;
}
label + input {
  margin-top: 0.8125em;
}
button {
  height: 52px;
  font-size: 0.625em !important;
  letter-spacing: 1px;
  color: #fff;
  background: #425cbb;
  border-color: #425cbb;
}
button:hover,
button:focus,
button:active {
  color: #fff !important;
}
button:active,
button:hover,
button:focus {
  border-color: #425cbb !important;
}
button.btn--loading:after {
  background: #8c9cd7;
}
button.btn--loading:after {
  background: #fff;
  border-radius: 100px;
}
.form--merge {
  min-width: 100%;
  overflow: hidden;
}
.form--merge input {
  border-radius: 50px 0 0 50px;
}
.form--merge input + button[type="submit"] {
  margin-left: -4px;
}
.form--merge button[type="submit"] {
  border-radius: 0 50px 50px 0;
  position: relative;
  margin: 0;
}
.form--merge button[type="submit"].btn--loading:after {
  border-radius: 0 100px 100px 0;
}
.form--merge.form--square input,
.form--merge.form--square select {
  border-radius: 2px 0 0 2px;
}
.form--merge.form--square button[type="submit"] {
  border-radius: 0 2px 2px 0;
}
@media all and (max-width: 767px) {
  .form--merge input {
    width: 100%;
    border-radius: 50px;
  }
  .form--merge input + button[type="submit"] {
    margin-left: 0;
  }
  .form--merge button[type="submit"] {
    bottom: 0;
    border-radius: 50px;
    width: 100%;
  }
}
.form--square input,
.form--square textarea,
.form--square button,
.form--square select {
  border-radius: 5px;
}
select {
  max-width: 100%;
  min-width: 100%;
  padding-left: 1.625em;
}
.input-checkbox label {
  margin-left: 0;
}
.input-checkbox + span {
  position: relative;
  bottom: 2px;
}
.input-checkbox .inner {
  background: #ececec;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.input-checkbox .inner:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.input-checkbox .inner:before {
  opacity: 0;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.input-checkbox.checked .inner {
  text-align: center;
  position: relative;
  background: #64b671;
}
.input-checkbox.checked .inner:before {
  content: '\2713';
  position: absolute;
  color: #fff;
  width: 100%;
  left: 0;
  opacity: 1;
  font-size: 13px;
}
.input-checkbox + br + .input-checkbox {
  margin-left: 1.625em;
}
li .input-checkbox {
  line-height: 1.625em !important;
}
li + li .input-checkbox {
  margin-left: 1.625em;
}
.input-radio {
  display: block;
}
.input-radio .inner {
  position: relative;
  background: #ececec;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.input-radio .inner:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.input-radio .inner:after {
  content: '';
  position: absolute;
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background: #fff;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  opacity: 0;
}
.input-radio.checked .inner {
  background: #64b671;
}
.input-radio.checked .inner:after {
  opacity: 1;
}
.input-radio + label {
  margin-left: 0;
}
.input-select {
  width: 100%;
}
.input-select:not(:last-child) {
  margin-bottom: 1.625em;
}
.input-select select {
  width: 100%;
  padding-left: 1.625em;
}
/*! -- Pillar Subscribe Forms -- */
.subscribe .subscribe__title h1,
.subscribe .subscribe__title h2,
.subscribe .subscribe__title h3,
.subscribe .subscribe__title h4,
.subscribe .subscribe__title h5,
.subscribe .subscribe__title h6 {
  margin: 0;
}
.subscribe-1 {
  padding-bottom: 6.5em;
}
.subscribe-1 .subscribe__title {
  padding-top: 0.40625em;
}
.subscribe-1 + .footer-2 {
  padding-top: 0;
}
@media all and (max-width: 767px) {
  .subscribe-1 {
    padding-bottom: 4.875em;
  }
  .subscribe-1 .subscribe__title {
    margin-bottom: 1.625em;
  }
}
.subscribe-2 .subscribe__title {
  margin-bottom: 3.25em;
}
.subscribe-2 form {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .subscribe-2 form {
    margin-bottom: 1.625em;
  }
}
.subscribe-3 .subscribe__title {
  margin-bottom: 3.25em;
}
.subscribe-3 .subscribe__title p.lead {
  margin-top: 1.1304347826087em;
}
.subscribe-4 {
  padding-bottom: 0;
  position: relative;
}
.subscribe-4 .subscribe__title {
  margin-top: 1.625em;
  margin-bottom: 3.25em;
}
.subscribe-4 .subscribe__title h4 {
  margin-bottom: 1.1304347826087em;
}
.subscribe-4 .input-with-icon {
  margin-bottom: 1.625em;
}
@media all and (max-width: 1200px) {
  .subscribe-4 {
    padding-bottom: 8.125em;
  }
  .subscribe-4 .subscribe__avatar {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
@media all and (max-width: 767px) {
  .subscribe-4 {
    padding-bottom: 0;
  }
  .subscribe-4 .subscribe__avatar {
    position: relative;
    margin-top: 1.625em;
  }
}
.subscribe-5 .subscribe__title {
  margin-bottom: 3.25em;
}
.subscribe-5 .subscribe__title h4 {
  margin-bottom: 1.1304347826087em;
}
.subscribe-5 form .input-with-icon {
  margin-bottom: 1.625em;
}
.subscribe-5 form .input-with-icon:last-of-type {
  margin-bottom: 3.25em;
}
.subscribe-5 form div:last-child {
  margin-top: 3.25em;
}
.subscribe-5 form button[type="submit"]:last-child {
  display: block;
  margin-top: 4.875em;
}
.subscribe-5 .subscribe__feature {
  margin-bottom: 3.25em;
}
.subscribe-5 .subscribe__feature:first-child {
  margin-top: 4.875em;
}
.subscribe-5 .subscribe__feature h6 {
  margin-bottom: 1.3em;
}
@media all and (max-width: 767px) {
  .subscribe-5 .subscribe__feature {
    text-align: center;
    margin-bottom: 1.625em;
  }
  .subscribe-5 .subscribe__feature:first-child {
    margin-top: 1.625em;
  }
}
.subscribe-6 .subscribe__title {
  margin-bottom: 4.875em;
}
.subscribe-6 .subscribe__features {
  margin-bottom: 4.875em;
}
.subscribe-6 .subscribe__features li {
  display: inline-block;
}
.subscribe-6 .subscribe__features li:not(:last-child) {
  margin-right: 3.25em;
}
.subscribe-6 .subscribe__features li .checkmark {
  margin-right: 0.8125em;
}
.subscribe-6 .subscribe__features li h6 {
  display: inline-block;
  position: relative;
  bottom: 9px;
  margin: 0;
}
.subscribe-6 form {
  margin-bottom: 3.25em;
}
@media all and (max-width: 990px) {
  .subscribe-6 .subscribe__features li:not(:last-child) {
    margin-right: 1.625em;
  }
}
@media all and (max-width: 767px) {
  .subscribe-6 .subscribe__title {
    margin-bottom: 3.25em;
  }
  .subscribe-6 .subscribe__features {
    margin-bottom: 1.625em;
  }
  .subscribe-6 .subscribe__features li {
    display: block;
    margin-bottom: 0.8125em;
  }
  .subscribe-6 .subscribe__features li:not(:last-child) {
    margin-right: 0;
  }
  .subscribe-6 form {
    margin-bottom: 0;
  }
}
.form-subscribe-1 p.lead {
  margin-bottom: 1.76923076923076em;
}
/*! -- Pillar Lists -- */
.social-list {
  overflow: hidden;
  margin: 0;
}
.social-list li {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  opacity: .5;
  display: inline-block;
  margin: 0;
}
.social-list li:not(:last-child) {
  margin-right: 1.625em;
}
.social-list li:hover {
  opacity: 1;
}
.social-list li a {
  color: #232323;
}
.social-list.social-list--circles li {
  opacity: 1;
}
.social-list.social-list--circles li:not(:last-child) {
  margin-right: 0.8125em;
}
.social-list.social-list--circles li a {
  display: inline-block;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  text-align: center;
  padding-top: 2px;
}
.social-list.social-list--circles li a i {
  color: #fff;
  font-size: 12px;
}
.imagebg:not(.image--light) .social-list li a {
  color: #fff;
}
/*! -- Pillar Covers -- */
.imagebg:not(.image--light) .btn:not(.btn--primary) {
  border-color: rgba(255, 255, 255, 0.1);
}
.imagebg:not(.image--light) .btn:not(.btn--primary) .btn__text {
  color: #fff;
}
.imagebg:not(.image--light) .btn:not(.btn--primary):hover {
  border-color: rgba(255, 255, 255, 0.6);
}
.imagebg:not(.image--light) a,
.imagebg:not(.image--light) span {
  color: #fff;
}
.cover .social-list li:not(:last-child) {
  margin-right: 3.25em;
}
.cover .social-list li a {
  font-size: 1.4375em;
}
.cover .pos-bottom .social-list {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .cover .social-list li:not(:last-child) {
    margin-right: 1.625em;
  }
  .cover .social-list li a {
    font-size: 1em;
  }
  .cover .pos-bottom {
    position: relative;
    margin-bottom: 0;
  }
  .cover .pos-bottom .social-list {
    margin-top: 3.25em;
    margin-bottom: 0;
  }
}
.cover-1 .logo {
  max-height: 8.125em;
}
.cover-1 p.lead {
  position: relative;
  top: 48px;
}
.cover-1 .row:first-child:not(:last-child) {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .cover-1 .logo {
    max-height: 4.875em;
    margin-bottom: 1.625em;
  }
  .cover-1 p.lead {
    top: 0;
  }
  .cover-1 .row:first-child:not(:last-child) {
    margin-bottom: 1.625em;
  }
}
.cover-2,
.cover-8 {
  padding: 0;
  position: relative;
  overflow: hidden;
}
.cover-2 > div[class*='col-'],
.cover-8 > div[class*='col-'] {
  position: absolute;
  height: 100%;
}
.cover-2 > div[class*='col-']:first-child,
.cover-8 > div[class*='col-']:first-child {
  left: 0;
}
.cover-2 > div[class*='col-']:last-child,
.cover-8 > div[class*='col-']:last-child {
  right: 0;
}
@media all and (max-height: 600px) {
  .cover-2 > div[class*='col-'],
  .cover-8 > div[class*='col-'] {
    position: relative;
    height: auto;
  }
  .cover-2 > div[class*='col-']:first-child,
  .cover-8 > div[class*='col-']:first-child {
    height: 32.5em;
  }
  .cover-2 > div[class*='col-']:last-child,
  .cover-8 > div[class*='col-']:last-child {
    padding: 4.875em 0;
  }
}
@media all and (max-width: 767px) {
  .cover-2 > div[class*='col-'],
  .cover-8 > div[class*='col-'] {
    position: relative;
    height: auto;
  }
  .cover-2 > div[class*='col-']:first-child,
  .cover-8 > div[class*='col-']:first-child {
    height: 32.5em;
  }
  .cover-2 > div[class*='col-']:last-child,
  .cover-8 > div[class*='col-']:last-child {
    padding: 4.875em 0;
  }
}
.cover-2 .logo {
  max-height: 8.125em;
}
.cover-2 .pos-vertical-center {
  display: inline-block;
  text-align: left;
}
.cover-2 .btn:first-of-type {
  margin-top: 1.625em;
}
@media all and (max-width: 767px) {
  .cover-2 .logo {
    max-height: 4.875em;
  }
  .cover-2 .pos-vertical-center {
    text-align: center;
  }
}
.cover-3 .logo {
  max-height: 3.25em;
}
.cover-3 .pos-bottom {
  margin-bottom: 9.75em;
}
.cover-3 .modal-instance {
  margin-top: 3.25em;
}
@media all and (max-width: 767px) {
  .cover-3.slider .slides li {
    height: 100vh;
  }
  .cover-3.slider .slides li .container {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
  }
  .cover-3 .pos-bottom {
    margin-bottom: 19.5em;
  }
}
.cover-4 .modal-instance {
  display: inline-block;
  position: relative;
  top: 21px;
  margin-left: 1.625em;
}
.cover-4 p.lead + div {
  margin-top: 1.625em;
}
@media all and (max-width: 767px) {
  .cover-4 .modal-instance {
    display: block;
    margin-left: 0;
  }
}
.cover-6 .modal-instance {
  display: block;
  margin-bottom: 3.25em;
}
.cover-6 .logo {
  max-height: 8.125em;
}
.cover-6 form {
  margin-bottom: 4.875em;
}
@media all and (max-width: 767px) {
  .cover-6 .modal-instance {
    margin-bottom: 1.625em;
  }
  .cover-6 .logo {
    max-height: 3.25em;
  }
  .cover-6 .pos-bottom {
    margin-top: 3.25em;
  }
}
.cover-7 form {
  margin: 3.25em 0;
}
.cover-7 .h6 {
  display: inline-block;
  color: #fff;
}
.cover-7 .modal-instance {
  display: inline-block;
  margin-left: 1.625em;
}
@media all and (max-width: 767px) {
  .cover-7 form {
    margin: 1.625em 0 1.625em 0;
  }
  .cover-7 form input {
    margin: 0;
  }
}
.cover-8 .logo {
  max-height: 3.25em;
}
.cover-8 .pos-vertical-center {
  overflow: hidden;
}
.cover-8 p.lead + div {
  margin-top: 3.25em;
}
.cover-8 form {
  max-width: 60%;
  margin: 0 auto;
}
.cover-8 form .row {
  margin-bottom: 1.625em;
}
.cover-8 form .input-checkbox {
  margin-right: 0.8125em;
}
.cover-8 form .input-checkbox + span {
  position: relative;
  bottom: 8px;
}
@media all and (max-width: 1300px) {
  .cover-8 form {
    max-width: 80%;
  }
}
@media all and (max-width: 767px) {
  .cover-8 form input {
    margin-bottom: 0;
  }
}
.cover-9 {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
}
.cover-9:before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: '';
  top: -13em;
  left: 0;
  background: #f4f4f4;
}
.cover-9 .logo {
  max-height: 6.5em;
}
.cover-9 p.lead {
  margin-bottom: 2.26086956521739em;
}
.cover-9 .cover__image {
  margin-top: 4.875em;
}
.cover-9 + section:not(.imagebg):not(.bg--dark):not(.bg--primary) {
  padding-top: 0;
}
@media all and (max-width: 767px) {
  .cover-9 .logo {
    max-height: 3.25em;
  }
  .cover-9 p.lead {
    margin-bottom: 1em;
  }
  .cover-9 .btn:not(:last-of-type) {
    margin-bottom: 1.625em;
  }
  .cover-9 .cover__image {
    margin-top: 3.25em;
  }
}
.cover-10 .logo {
  max-height: 6.5em;
}
.cover-10 > .background-image-holder {
  height: 70%;
  opacity: .2 !important;
}
.cover-10 .video-cover {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  margin-top: 4.875em;
}
.cover-10 .video-cover iframe {
  height: 488px;
}
.cover-10 + section:not(.imagebg) {
  padding-top: 0;
}
@media all and (max-width: 767px) {
  .cover-10 .video-cover iframe {
    height: auto;
  }
}
.cover-11:not([class*='height-']) {
  padding: 0;
}
.cover-11 .boxed {
  padding-bottom: 3.25em;
  margin-bottom: 0;
}
.cover-11 .pos-bottom {
  margin-bottom: 1.625em;
}
.cover-11 p.lead + .modal-video-1 {
  margin-top: 3.25em;
}
@media all and (max-width: 990px) {
  .cover-11 .boxed {
    padding: 1.625em 0;
  }
  .cover-11 .boxed div[class*='col-'] {
    padding: 0 15px !important;
  }
}
@media all and (max-width: 767px) {
  .cover-11 .imagebg {
    overflow: hidden;
  }
  .cover-11 .pos-bottom {
    margin-top: 1.625em;
  }
}
.cover-12 form {
  margin-top: 3.25em;
}
.cover-12 form .input-with-icon {
  text-align: left;
  margin-bottom: 1.625em;
}
@media all and (max-width: 768px) {
  .cover-12 .modal-instance {
    margin-bottom: 1.625em;
  }
}
.cover-13 form {
  margin-top: 2.4375em;
}
.cover-14 .logo {
  margin-bottom: 2.4375em;
}
.cover-14 .pos-bottom {
  width: 100%;
  bottom: 1.625em;
}
.cover-14 .cover__title {
  margin-bottom: 3.25em;
}
.cover-15 form {
  margin-top: 3.25em;
  margin-bottom: 1.625em;
}
/*! -- Pillar Tabs -- */
.tabs-container {
  overflow: hidden;
}
.tabs-container .tabs {
  margin-bottom: 4.875em;
}
.tabs-container .tabs .tab__title:not(.btn) {
  padding: 0;
}
.tabs-container .tabs li:not(:last-child) {
  margin-right: 3.25em;
}
.tabs-container:not(.tabs-5) .tabs-content {
  margin-bottom: 0;
  position: relative;
}
.tabs-container:not(.tabs-5) .tabs-content > li.active .tab__content {
  animation: fadeIn .75s ease forwards;
}
.tabs-container:not(.tabs-5) .tabs-content > li:not(.active) {
  position: absolute;
  top: 0;
  pointer-events: none;
}
.tabs-container:not(.tabs-5) .tabs-content > li:not(.active) .tab__content {
  display: block;
  pointer-events: none;
  animation: fadeOut .5s ease forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    -webkit-transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    -webkit-transform: translate3d(0, -20px, 0);
  }
}
@media all and (max-width: 767px) {
  .tabs-container .tabs {
    margin-bottom: 1.625em;
  }
  .tabs-container .tabs li {
    margin: 0 1.625em 0.40625em 1.625em;
  }
  .tabs-container .tabs li:not(:last-child) {
    margin-right: 0;
  }
}
.tabs-1 .tabs li {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.tabs-1 .tabs li:not(.active) {
  opacity: .4;
}
.tabs-1 .tabs li:hover {
  opacity: .9;
}
.tabs-1 .tabs li .btn {
  border-color: rgba(0, 0, 0, 0);
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  backface-visibility: hidden;
}
.tabs-1 .tabs li:not(:last-child) {
  margin-right: 1.625em;
}
.tabs-1 .tabs li.active .btn {
  border-color: rgba(35, 35, 35, 0.9);
}
.tabs-2 .tabs h5,
.tabs-2 .tabs h6 {
  margin: 0;
}
.tabs-3.tabs-container .tabs li {
  margin-right: 0;
}
.tabs-3.tabs-container .tabs .tab__title {
  position: relative;
  overflow: hidden;
  width: 250px;
  height: 150px;
  text-align: center;
}
.tabs-3.tabs-container .tabs .tab__title h5 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  z-index: 2;
  color: #fff;
  width: 100%;
}
.tabs-4.tabs-container .tabs li {
  margin-right: 0;
}
.tabs-4.tabs-container .tabs li:first-child {
  position: relative;
  left: 5px;
}
.tabs-4.tabs-container .tabs li:first-child .btn {
  border-radius: 50px 0 0 50px;
}
.tabs-4.tabs-container .tabs li:last-child {
  position: relative;
  right: 5px;
}
.tabs-4.tabs-container .tabs li:last-child .btn {
  border-radius: 0 50px 50px 0;
}
.tabs-4.tabs-container .tabs li.active .btn {
  background: #232323;
}
.tabs-4.tabs-container .tabs li.active .btn .btn__text {
  color: #fff;
}
.tabs-4.tabs-container .tabs li:not(.active) .btn {
  border-color: #232323;
}
@media all and (max-width: 767px) {
  .tabs-4.tabs-container .tabs li {
    margin: 0;
  }
}
.tabs-5 {
  padding: 0;
}
.tabs-5 .tabs {
  margin-bottom: 0;
}
.tabs-5 .tabs li .tab__title {
  background: #fff;
  padding: 0.8125em 3.25em !important;
}
.tabs-5 .tabs li .tab__title h6 {
  margin: 0;
}
.tabs-5 .tabs li:not(.active) .tab__title {
  background: #f4f4f4;
}
.tabs-5 .tabs li:not(:last-child) {
  margin-right: 0;
}
.tabs-5 .tabs li:first-child .tab__title {
  border-radius: 20px 0 0 0;
}
.tabs-5 .tabs li:last-child .tab__title {
  border-radius: 0 20px 0 0;
}
.tabs-5 .tabs-content {
  background: #fff;
  padding-top: 6.5em;
  padding-bottom: 8.125em;
  margin-top: 0;
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .tabs-5 .tabs {
    margin-bottom: 1.625em;
  }
  .tabs-5 li .tab__title,
  .tabs-5 li:first-child .tab__title,
  .tabs-5 li:last-child .tab__title {
    border-radius: 50px !important;
  }
}
/*! -- Pillar Accordions -- */
.accordion .active .accordion__title span {
  color: #fff;
}
.accordion .active .accordion__content {
  animation: fadeIn 0.7s ease forwards;
}
.accordion li {
  position: relative;
}
.accordion li .accordion__content {
  position: relative;
}
.accordion li:not(.active) .accordion__content * {
  display: block;
  position: absolute;
  top: 0;
  animation: fadeOut 0.7s ease forwards;
}
.accordion li:not(.active):hover .accordion__title {
  background: #efefef;
}
/*! -- Pillar Twitter Feed -- */
.twitter-feed {
  position: relative;
}
.twitter-feed .timePosted {
  margin: 0;
}
.twitter-feed.twitter-feed--slider ul:not(.owl-carousel) {
  max-height: 8.125em;
  opacity: 0;
}
.twitter-feed .timePosted a {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  text-decoration: none;
  opacity: .5;
}
i + .twitter-feed {
  margin-top: 1.625em;
}
.twitter-feed-1 p {
  font-size: 1.4375em;
  line-height: 1.69565217em;
  margin-bottom: 1.1304347826087em;
  font-weight: 400;
}
.twitter-feed-1 p:last-child {
  margin-bottom: 0;
}
.twitter-feed-1 p a {
  color: #425cbb;
}
.twitter-feed-2 li .tweet {
  margin-bottom: 0.8125em;
}
.twitter-feed-2 li:not(:last-child) {
  margin-bottom: 2.4375em;
}
/*! -- Pillar Sliders -- */
section:not(.imagebg):not(.bg--dark):not(.bg--primary):not(.slider) .flex-direction-nav a,
.slider:not(.slider-controls--white) .flex-direction-nav a {
  color: #232323;
}
section:not(.imagebg):not(.bg--dark):not(.bg--primary):not(.slider) .flex-control-nav li a,
.slider:not(.slider-controls--white) .flex-control-nav li a {
  background: #232323;
  opacity: .4;
  backface-visibility: hidden;
}
section:not(.imagebg):not(.bg--dark):not(.bg--primary):not(.slider) .flex-control-nav li a.flex-active,
.slider:not(.slider-controls--white) .flex-control-nav li a.flex-active {
  opacity: 1;
}
.owl-dot {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.owl-dot.active {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  position: relative;
  bottom: 1px;
}
.owl-prev,
.owl-next {
  opacity: .2;
}
.owl-prev:hover,
.owl-next:hover {
  opacity: 1;
}
@media all and (max-width: 767px) {
  .owl-prev,
  .owl-next {
    display: none !important;
  }
}
.slider:not(section) .slides li {
  padding: 0;
}
.slider--animate div[class*='col-'] {
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  transform: translate3d(0, 100px, 0);
  -webkit-transform: translate3d(0, 100px, 0);
  transition-delay: .5s;
  opacity: 0;
}
.slider--animate .active div[class*='col-'] {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.screenshot-slider {
  margin-top: 3.25em;
}
.screenshot-slider img {
  margin-bottom: 1.625em;
}
/*! -- Pillar Features -- */
.features-1 hr {
  margin: 4.875em 0 3.25em 0;
}
@media all and (max-width: 767px) {
  .features-1 hr {
    display: none;
  }
}
.features-2 p + .feature {
  margin-top: 4.875em;
}
@media all and (max-width: 767px) {
  .features-2 p + .feature {
    margin-top: 3.25em;
  }
}
.features-3 .feature:first-child {
  margin-top: 4.875em;
}
.features-3 .feature h6 {
  margin-bottom: 1.3em;
}
.features-3 .feature:not(:last-child) {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .features-3 .feature:first-child {
    margin-top: 3.25em;
  }
}
.features-4 {
  overflow: hidden;
  position: relative;
  padding-bottom: 0;
}
.features-4 h3 {
  margin-top: 0.8125em;
}
.features-4 .feature h5 {
  margin-bottom: 0.8125em;
}
.features-4 p + .feature {
  margin-top: 3.25em;
}
@media all and (max-width: 1200px) {
  .features-4 {
    padding-bottom: 8.125em;
  }
  .features-4 .features__avatar {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .features-4 .features__avatar img {
    margin: 0;
    position: relative;
    right: -50px;
    max-height: 32.5em;
  }
}
@media all and (max-width: 990px) {
  .features-4 .features__avatar img {
    right: -200px;
  }
}
@media all and (max-width: 767px) {
  .features-4 {
    padding-bottom: 0;
  }
  .features-4 .features__avatar {
    margin-top: 3.25em;
    position: relative;
  }
  .features-4 .features__avatar img {
    right: 0px;
  }
}
.features-5 {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
}
.features-5:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  top: -13em;
}
.features-5 p.lead {
  margin-bottom: 3.3913043478261em;
}
.features-5 img {
  margin-top: 6.5em;
}
@media all and (max-width: 767px) {
  .features-5 {
    background: #f4f4f4;
    padding-bottom: 4.875em;
  }
  .features-5 p.lead {
    margin-bottom: 1.1304347826087em;
  }
  .features-5 img {
    margin-top: 1.625em;
  }
}
.features-6 p + .feature {
  margin-top: 1.625em;
}
.features-6 .feature:not(:last-child) {
  margin-bottom: 1.625em;
}
.features-6 .feature {
  padding: 0;
}
.features-6 + .cta-2 {
  padding-top: 0;
}
@media all and (max-width: 767px) {
  .features-6 p + .feature {
    margin-top: 0;
  }
  .features-6 .feature:not(:last-child) {
    margin-bottom: 0;
  }
  .features-6 .feature:nth-child(even) {
    padding-left: 7.5px;
  }
}
.features-7 {
  padding-top: 9.75em;
}
.features-7 hr {
  opacity: .3;
  margin: 3.25em 0;
}
.features-7 .feature h6 {
  margin-bottom: 1.3em;
}
@media all and (max-width: 767px) {
  .features-7 {
    padding-top: 4.875em;
  }
}
.features-8 {
  padding-top: 9.75em;
}
.features-8 .boxed {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  margin-top: 4.875em;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}
.features-8 .boxed .feature:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.features-8 .boxed .feature h5 {
  margin: 1.625em 0;
}
.features-8 .boxed i {
  color: #fff;
}
@media all and (max-width: 767px) {
  .features-8 {
    padding-top: 4.875em;
  }
  .features-8 .boxed .feature:first-child {
    border-right: none;
  }
  .features-8 .boxed .feature:not(:last-child) {
    margin-bottom: 1.625em;
  }
}
.features-9 p.lead {
  margin-bottom: 3.3913043478261em;
}
.features-9 .feature:first-child {
  margin-top: 8.125em;
}
@media all and (max-width: 767px) {
  .features-9 p.lead {
    margin-bottom: 1.1304347826087em;
  }
  .features-9 .feature:first-child {
    margin-top: 0;
  }
  .features-9 img {
    margin-bottom: 3.25em;
  }
}
.features-10 {
  padding: 0;
  overflow: hidden;
}
.features-10 .feature {
  margin: 0;
  padding: 6.5em 0;
  min-height: 434px;
}
.features-10 .feature i {
  display: inline-block;
  margin-bottom: 0.40625em;
}
.features-10 .feature p {
  max-width: 60%;
  margin: 0 auto;
  min-height: 4.875em;
}
@media all and (max-width: 1500px) {
  .features-10 .feature p {
    max-width: 80%;
  }
}
@media all and (max-width: 1200px) {
  .features-10 .feature p {
    max-width: 90%;
  }
}
@media all and (max-width: 990px) {
  .features-10 .feature {
    padding: 3.25em 0;
    margin-bottom: 0;
    min-height: 0;
  }
  .features-10 .feature p {
    max-width: 80%;
    min-height: 0;
  }
}
.features-11 .row:last-child {
  margin-top: 3.25em;
}
@media all and (max-width: 767px) {
  .features-11 .row:last-child {
    margin-top: 1.625em;
  }
}
section.features .feature-2 {
  margin-bottom: 3.25em;
}
.features-12.imageblock > .container {
  padding-top: 13em;
  padding-bottom: 9.75em;
}
.features-12 .feature[class*='col-'] {
  padding: 0;
}
.features-12 p.lead {
  margin-bottom: 3.3913043478261em;
}
@media all and (max-width: 767px) {
  .features-12.imageblock > .container {
    padding-top: 4.875em;
    padding-bottom: 4.875em;
  }
}
/*! -- Pillar Modals -- */
.modal-content.height--natural {
  max-width: 450px;
}
.modal-content.bg--dark {
  background: #000;
}
.modal-instance {
  position: relative;
  display: inline-block;
}
@media all and (max-width: 1024px) {
  .modal-container .modal-content {
    max-width: 100%;
    max-height: 100%;
  }
}
/*! -- Pillar Video -- */
.video-play-icon {
  opacity: 1;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.video-play-icon:hover {
  opacity: .9;
}
.video-play-icon:not(.video-play-icon--dark):before {
  border-color: transparent transparent transparent #232323;
}
.video-play-icon.video-play-icon--sm:before {
  margin-top: -3px;
}
.modal-video-1 .video-play-icon {
  display: inline-block;
  margin-right: 1.625em;
}
.modal-video-1 .video-play-icon.video-play-icon--sm {
  margin-right: 0.8125em;
}
.modal-video-1 .h6 {
  position: relative;
  bottom: 48px;
}
.modal-video-1 .video-play-icon--sm + .h6 {
  bottom: 22px;
}
@media all and (max-width: 767px) {
  .modal-video-1 .h6 {
    bottom: 28px;
  }
}
.embedded-video-3 .text {
  margin-top: 3.25em;
}
@media all and (max-width: 767px) {
  .embedded-video-3 .text {
    margin-top: 0;
  }
}
/*! -- Pillar Charts -- */
.piechart {
  overflow: hidden;
  position: relative;
}
.piechart-1 .piechart__overlay {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  width: 100%;
}
@media all and (max-width: 990px) {
  .piechart-1 {
    margin-bottom: 1.625em;
  }
}
.piechart-2 .piechart__overlay {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  width: 100%;
}
.piechart-2 .piechart__description h6:first-child {
  margin: 0;
  opacity: .5;
}
.piechart-3 .piechart__overlay {
  width: 90%;
  height: 90%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.piechart-3 i {
  font-size: 2.8125em;
  color: #425cbb;
}
.piechart-3 + h5 {
  margin-top: 1.625em;
}
.piechart[data-size="260"] {
  width: 260px;
  height: 260px;
}
.barchart {
  position: relative;
}
.barchart .barchart__bar {
  background: #f4f4f4;
  border-radius: 50px;
}
.barchart .barchart__progress {
  background: #425cbb;
  border-radius: 50px;
}
.barchart .barchart__description {
  z-index: 3;
  position: relative;
}
.barchart:not(:last-child) {
  margin-bottom: 1.625em;
}
.barchart.barchart--vertical {
  display: inline-block;
}
.barchart.barchart--vertical:not(:last-child) {
  margin-right: 0.8125em;
}
.barchart.barchart--vertical .barchart__bar {
  height: 500px;
  margin-bottom: 1.625em;
}
.barchart.barchart--vertical .barchart__progress {
  width: 100%;
  top: auto;
  bottom: 0;
}
.barchart.barchart--vertical .barchart__progress:before {
  right: 0;
  width: 100%;
  text-align: center;
}
.barchart.barchart--vertical .barchart__description {
  position: relative;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  left: -1.625em;
  text-align: left;
}
.barchart.barchart--vertical .barchart__description span {
  color: #232323;
}
.barchart-1 .barchart__bar {
  height: 2.84375em;
}
.barchart-1 .barchart__description {
  top: 0;
  left: 1.625em;
  position: absolute;
  height: 2.84375em;
  width: 100%;
}
.barchart-1 .barchart__description span {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  margin: 0;
}
.barchart-1 .barchart__progress:before {
  content: attr(data-value);
  position: absolute;
  right: 1.625em;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  color: #fff;
  top: 0.8125em;
}
.barchart-1.barchart--vertical .barchart__bar {
  width: 2.84375em;
}
.barchart-2:before {
  content: attr(data-value) '%';
  position: absolute;
  right: 0;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  top: 0;
}
.barchart-2 .barchart__bar {
  height: 0.40625em;
  background: #efefef;
  backface-visibility: hidden;
}
.barchart-2:not(.barchart--vertical) .barchart__bar {
  max-height: 0.40625em;
}
.barchart-2 .barchart__description {
  margin-bottom: 0.8125em;
}
.barchart-2.barchart--vertical .barchart__bar {
  width: 0.40625em;
}
.barchart-2.barchart--vertical .barchart__description {
  left: -3.25em;
}
.barchart-2.barchart--vertical:before {
  left: 0;
}
.bg--secondary .barchart {
  background: #e7e7e7;
}
.bg--dark .barchart-2:before {
  color: #fff;
}
/*! -- Pillar Pricing Options -- */
.pricing {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.pricing i:first-child {
  color: #232323;
}
.pricing h6:first-child,
.pricing h5:first-child {
  margin: 0;
}
.pricing .pricing__price {
  margin-top: 1.625em;
}
.pricing .pricing__dollar {
  vertical-align: top;
}
.pricing p:last-of-type {
  margin-bottom: 3.25em;
}
.pricing:not(.pricing-1):not(.pricing--emphasise) hr {
  border-color: #efefef;
}
.bg--dark .pricing-1 *:not(a) {
  color: #767676;
}
@media all and (max-width: 990px) {
  .pricing {
    margin-bottom: 30px;
  }
}
@media all and (max-width: 767px) {
  .pricing {
    margin-bottom: 15px;
  }
}
.pricing-1 {
  padding: 2.16666667em 3.25em 3.25em 3.25em;
  background: #fff;
}
.pricing-1:hover {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.pricing-1 p {
  min-height: 3.25em;
}
.pricing-2 {
  padding: 2.16666667em 3.25em 3.25em 3.25em;
}
.pricing-2.pricing--emphasise {
  background: #fff;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.pricing-2 p {
  min-height: 4.875em;
}
.pricing-3 img:first-child {
  margin-bottom: -1.625em;
}
.pricing-3 h5 {
  margin-top: 1.625em;
}
.pricing-3 .btn {
  margin-bottom: 1.625em;
  position: relative;
  z-index: 20;
}
.pricing-3 .pricing__body {
  background: #fff;
  padding: 0 3.25em 3.25em 3.25em;
}
.pricing-3 p {
  min-height: 3.25em;
}
.pricing-3 p:last-child {
  margin: 0;
}
.pricing-3:hover {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.pricing-4 {
  padding: 3.25em 3.25em 3.25em 3.25em;
  background: #fff;
}
.pricing-4 .pricing__price {
  margin-top: 0;
  margin-bottom: 1.625em;
}
.pricing-4 h6 {
  opacity: .5;
}
.pricing-4:hover {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.pricing-4 p {
  min-height: 4.875em;
}
@media all and (max-width: 990px) {
  .pricing-4 {
    margin-bottom: 1.625em;
  }
}
.pricing-5 {
  overflow: hidden;
}
.pricing-5 div[class*='col-'] {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  padding: 0;
}
.pricing-5 div[class*='col-'].pricing--emphasise {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  z-index: 2;
}
.pricing-5 div[class*='col-']:not(:first-child) ul li:last-child {
  height: auto !important;
}
.pricing-5 .pricing__title {
  min-height: 144px;
  padding: 1.625em 0;
  position: relative;
}
.pricing-5 .pricing__title h6,
.pricing-5 .pricing__title h5 {
  margin: 0;
}
.pricing-5 .pricing__title h6 {
  opacity: .5;
}
.pricing-5 .pricing__price {
  margin-top: 0.8125em;
}
.pricing-5 ul {
  margin: 0;
}
.pricing-5 ul li {
  line-height: 1.625em;
  padding: 1.625em 0;
}
.pricing-5 ul li span:not(.btn__text) {
  font-style: italic;
}
.pricing-5 ul li span:not(:first-child) {
  display: inline-block;
  margin-left: 0.8125em;
  position: relative;
  bottom: 6px;
}
.pricing-5 ul li:nth-child(odd) {
  background: #f4f4f4;
}
@media all and (min-width: 990px) {
  .pricing-5 ul li {
    height: 4.875em;
  }
}
@media all and (max-width: 990px) {
  .pricing-5 .pricing__title {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .pricing-5 ul {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }
  .pricing-5 ul li:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  .pricing-5 .pricing__title {
    min-height: auto;
  }
}
@media all and (max-width: 767px) {
  .pricing-5 div[class*='col-'] {
    margin-bottom: 1.625em;
  }
}
.checkmark,
.crossmark {
  width: 1.625em;
  height: 1.625em;
  line-height: 1.625em;
  background: #64b671;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  text-align: center;
}
.checkmark:before,
.crossmark:before {
  content: '\2713';
  position: absolute;
  color: #fff;
  width: 100%;
  left: 0;
  opacity: 1;
  font-size: 13px;
}
.crossmark {
  background: #e34747;
}
.crossmark:before {
  content: '\2715';
}
/*! -- Pillar Cards -- */
.card {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.card .card__image img {
  margin: 0;
}
.card .card__title h1,
.card .card__title h2,
.card .card__title h3,
.card .card__title h4,
.card .card__title h5,
.card .card__title h6 {
  margin: 0;
}
.card .card__body {
  margin-bottom: 0;
}
.card .card__lower span {
  margin-bottom: 0;
}
.card .card__lower span:not(.h6) {
  font-size: 0.625em;
  font-style: italic;
  display: inline-block;
  color: #767676;
}
.slider[data-items] .card {
  margin: 0 15px;
  margin-bottom: 3.25em;
}
@media all and (max-width: 990px) {
  .card .card__body {
    margin-bottom: 30px;
  }
}
@media all and (max-width: 767px) {
  .card .card__body {
    margin-bottom: 15px;
    min-width: 100%;
  }
}
.card--horizontal {
  overflow: hidden;
  position: relative;
}
.card--horizontal .card__image {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
}
.card--horizontal .card__body {
  float: right;
}
@media all and (max-width: 767px) {
  .card--horizontal .card__image {
    position: relative;
  }
  .card--horizontal .card__image .background-image-holder {
    position: relative;
    background: none;
  }
  .card--horizontal .card__image .background-image-holder img {
    display: block;
  }
}
a .card {
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
}
a .card p,
a .card span {
  color: #767676;
}
a .card:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
a .card.card-6:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
a:active .card {
  transform: scale(0.99);
  -webkit-transform: scale(0.99);
}
.card-1 .card__icon {
  background: #f4f4f4;
  padding: 4.875em 0;
  text-align: center;
}
.card-1 .card__title + p {
  margin-top: 1.625em;
}
@media all and (max-width: 767px) {
  .card-1 .card__icon {
    padding: 3.25em 0;
  }
}
.card-1,
.card-2 {
  margin-bottom: 30px;
}
.card-1 .card__title,
.card-2 .card__title {
  margin-bottom: 0.8125em;
}
.card-1 span:not(:last-child),
.card-2 span:not(:last-child) {
  margin-bottom: 1.625em;
  display: inline-block;
}
.card-1 .card__body p,
.card-2 .card__body p {
  min-height: 8.125em;
}
@media all and (max-width: 767px) {
  .card-1 .card__body p,
  .card-2 .card__body p {
    min-height: 0;
  }
}
.card-3 {
  margin-bottom: 30px;
}
.card-3 .card__body .card__title {
  margin-bottom: 0;
}
.card-4 {
  margin-bottom: 30px;
}
.card-4 .card__body h6:first-child {
  margin-bottom: 1.3em;
  color: #767676;
}
.card-4 .card__body h5 {
  font-weight: 400;
}
.card-4 .card__body .card__title {
  height: 4.875em;
  margin-bottom: 1.625em;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card-4 hr {
  margin-top: 0;
  margin-bottom: 1.625em;
}
.card-5 {
  margin-bottom: 1.625em;
}
.card-5 .card__body {
  padding-bottom: 16.25em;
}
.card-5 .card__body .card__title {
  margin-bottom: 0.40625em;
}
.card-5 .card__body .card__title + span {
  margin-bottom: 1.625em;
  display: inline-block;
}
.card-5 .card__body .card__title + p {
  margin-top: 1.625em;
}
.masonry .card-5 {
  margin-bottom: 0;
}
@media all and (max-width: 990px) {
  .card-5 .card__body {
    padding-bottom: 13em;
  }
}
@media all and (max-width: 767px) {
  .card-5 .card__body {
    padding-bottom: 0.8125em;
  }
}
.card-6 {
  margin-bottom: 1.625em;
}
.card-6 .card__body .card__title {
  margin-bottom: 1.625em;
}
.card-6 .card__body p:last-of-type {
  margin-bottom: 6.5em;
}
@media all and (max-width: 767px) {
  .card-6 .card__body p:last-of-type {
    margin-bottom: 3.25em;
  }
}
.card-7 {
  margin-bottom: 30px;
}
.card-7 .card__body {
  position: relative;
}
.card-7 .card__body .card__title h6,
.card-7 .card__body .card__title h5 {
  margin-bottom: 0;
  max-height: 1.625em;
}
.card-7 .card__body .card__title h6 {
  opacity: .3;
  margin-bottom: 0.65em;
}
.card-7 .card__body .card__price {
  position: absolute;
  top: 2.4375em;
  right: 2.4375em;
}
.card-7 .card__body .card__price span {
  display: block;
}
.card-7 .card__body .card__price span.type--strikethrough {
  opacity: .5;
}
@media all and (max-width: 1200px) {
  .card-7 .card__price {
    margin-bottom: 0.8125em;
  }
}
.card-8 {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.card-8 .boxed {
  padding: 3.25em 4.875em;
}
.card-8 .card__title h6 {
  opacity: .5;
}
.card-8 .card__title h3 {
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-8 .card__body {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}
.card-8 .card__image {
  padding: 0;
  overflow: hidden;
  background: #eee;
}
.card-8 .card__image img {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.card-8 .card__image:hover img {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
}
.card-8 hr {
  margin-bottom: 3.25em;
}
.card-8 p[class*='col-'] {
  padding: 0;
  float: none;
  margin-bottom: 4.875em;
}
.card-8 .card__price {
  position: absolute;
  top: 3.25em;
  right: 4.875em;
}
.card-8 .card__price span {
  font-size: 1.4375em;
}
.card-8 .card__price span.type--strikethrough {
  margin-right: 1.1304347826087em;
}
@media all and (max-width: 1200px) {
  .card-8 .card__body {
    position: relative;
    height: auto;
  }
  .card-8 hr {
    margin-bottom: 1.625em;
  }
  .card-8 p[class*='col-'] {
    margin-bottom: 3.25em;
  }
}
@media all and (max-width: 1024px) {
  .card-8 .boxed {
    padding: 3.25em 3.25em;
  }
  .card-8 .card__price {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 0.8125em;
  }
}
@media all and (max-width: 767px) {
  .card-8 {
    margin-bottom: 0;
  }
  .card-8 .card__title {
    margin-top: 0;
  }
  .card-8 .card__body {
    position: relative;
    height: auto;
  }
  .card-8 .card__price {
    margin-bottom: 1.625em;
  }
}
/*! -- Pillar Page Titles -- */
.page-title.page-title--animate .container * {
  opacity: 0;
  animation: fadeUp 1s ease;
  animation-delay: .5s;
  animation-fill-mode: forwards;
}
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
/*! -- Pillar Video Covers -- */
iframe {
  background: #f4f4f4;
}
/*! -- Pillar Members -- */
.member-1 {
  margin-bottom: 3.25em;
}
.member-1 h6 {
  margin: 0;
  opacity: .5;
}
.member-1 h5 {
  margin-bottom: 0.8125em;
}
.member-1 p {
  min-height: 6.5em;
}
@media all and (max-width: 767px) {
  .member-1 p {
    min-height: 0;
  }
}
.member-2 h5 {
  margin-bottom: 0.8125em;
}
.member-2 h6 {
  margin-bottom: 0;
  opacity: .75;
}
.member-2 .social-list {
  width: 100%;
  margin-bottom: 1.625em;
}
/*! -- Pillar Icon Features -- */
.feature {
  margin-bottom: 3.25em;
}
.feature.boxed {
  background: #fff;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.feature.boxed:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  z-index: 2;
}
.feature:not(.bg--primary):not(.bg--dark) i {
  color: #232323;
}
.imagebg .feature:not(.bg--primary):not(.bg--dark) i,
.bg--primary .feature:not(.bg--primary):not(.bg--dark) i {
  color: #fff;
}
.imagebg .feature-1 span,
.bg--primary .feature-1 span {
  color: #fff;
  opacity: .9;
}
@media all and (max-width: 767px) {
  .feature {
    margin-bottom: 1.625em;
  }
}
.feature-1 {
  margin-bottom: 30px;
}
.feature-1 i + h5 {
  margin: 1.625em 0 0.8125em 0;
}
.feature-1 i + h6 {
  margin-top: 1.3em;
  margin-bottom: 1.3em;
}
.feature-1 i + h4 {
  margin-top: 1.23076923076923em;
  margin-bottom: 1.23076923076923em;
}
.feature-1 h4:last-child,
.feature-1 h5:last-child,
.feature-1 h6:last-child {
  margin-bottom: 0;
}
.feature-1 p {
  min-height: 4.875em;
}
@media all and (max-width: 767px) {
  .feature-1 {
    margin-bottom: 1.625em;
  }
}
.feature-2 .feature__title {
  margin-bottom: 0.8125em;
}
.feature-2 .feature__title i {
  margin-right: 0.57777777777778em;
}
.feature-2 .feature__title h6 {
  margin: 0;
  display: inline-block;
  position: relative;
  bottom: 16px;
}
@media all and (max-width: 990px) {
  .feature-2 .feature__title h6 {
    bottom: 0;
    display: block;
    margin-top: 1.3em;
  }
}
.feature-3 {
  overflow: hidden;
}
.feature-3 .feature__left {
  float: left;
  margin-right: 1.625em;
}
.feature-3 .feature__right {
  float: left;
  max-width: 80%;
}
.feature-3 p {
  min-height: 4.875em;
}
@media all and (max-width: 767px) {
  .feature-3 p {
    min-height: 0;
  }
}
.feature-4 {
  margin-bottom: 3.25em;
}
.feature-4 p:last-of-type {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .feature-4 p:last-of-type {
    margin-bottom: 1.625em;
  }
}
/*! -- Pillar Calls to Action -- */
.cta-1 {
  padding: 4.875em 0;
}
.cta-1 .cta__title {
  margin: 0;
}
@media all and (max-width: 990px) {
  .cta-1 .btn:first-child:not(:last-child) {
    margin-bottom: 1.625em;
  }
}
@media all and (max-width: 767px) {
  .cta-1 {
    text-align: center;
  }
  .cta-1 p.lead {
    margin: 1.1304347826087em 0;
  }
  .cta-1 .btn:first-child:not(:last-child) {
    margin-bottom: 0;
  }
}
.cta-2 {
  padding: 4.875em 0;
}
.cta-2 .cta__body {
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.cta-2 .cta__body .cta__feature {
  display: inline-block;
  padding-left: 3.25em;
  position: relative;
}
.cta-2 .cta__body .cta__feature i {
  position: relative;
  top: 10px;
  margin-right: 0.56521739130435em;
}
.cta-2 .cta__body .btn {
  float: right;
  min-height: 3.25em;
}
.cta-2.imagebg:not(.image--light) i {
  color: #fff;
}
.cta-2.imagebg:not(.image--light) .h6 {
  color: #fff;
}
.cta-2.imagebg:not(.image--light) .cta__body {
  border: none;
  background: rgba(255, 255, 255, 0.3);
}
@media all and (max-width: 1200px) {
  .cta-2 .cta__body {
    text-align: center;
    height: auto;
    padding: 1.625em;
  }
  .cta-2 .cta__body .cta__feature {
    margin-bottom: 1.625em;
  }
  .cta-2 .cta__body .cta__feature:first-child {
    padding-left: 0;
  }
  .cta-2 .cta__body .btn {
    float: none;
  }
}
@media all and (max-width: 767px) {
  .cta-2 .cta__body .cta__feature {
    width: 100%;
    padding-left: 0;
    height: auto;
  }
}
.cta-3 span.h2 {
  display: block;
  margin-bottom: 1.15555555555556em;
}
.cta-3.imagebg {
  padding-top: 8.125em;
}
@media all and (max-width: 767px) {
  .cta-3.imagebg {
    padding-top: 4.875em;
  }
}
.cta-5 h4 {
  margin: 0;
  display: inline-block;
  position: relative;
  top: 3px;
  white-space: nowrap;
}
.cta-5 .btn {
  margin-left: 1.625em;
}
@media all and (max-width: 767px) {
  .cta-5 .btn {
    margin-left: 0;
    margin-top: 1.625em;
  }
}
.cta-6 .btn {
  margin-top: 1.625em;
}
.cta-7 form {
  margin-top: 2.4375em;
  margin-bottom: 1.625em;
}
.cta-8 .boxed h4 {
  margin: 0;
}
.cta-8 .boxed form {
  margin-top: 1.625em;
}
.cta-8 .boxed form input,
.cta-8 .boxed form button {
  width: 100%;
  margin-bottom: 0.40625em;
}
.cta-8 .boxed .btn {
  margin-top: 1.625em;
}
.cta-8 .boxed:first-child {
  margin: 0;
}
.cta-8.section--overlap:not(:first-child) [class*='col-']:last-child {
  margin-top: 13em;
}
.cta-8 .signature {
  display: inline-block;
  margin-top: 2.4375em;
}
@media all and (max-width: 767px) {
  .cta-8.section--overlap [class*='col-']:last-child {
    margin-top: 1.625em;
  }
}
/*! -- Pillar Masonry -- */
.wide-grid.masonry {
  transform: none !important;
}
.wide-grid .masonry__container {
  background: #f3f3f3;
}
.wide-grid .masonry__container .masonry__item {
  padding: 0;
}
.wide-grid.bg--dark .masonry__container {
  background: #282828;
}
.masonry {
  position: relative;
}
.masonry .masonry__container {
  overflow: hidden;
}
.masonry--gapless .masonry__item {
  padding: 0;
  margin-bottom: 0 !important;
}
.masonry--animate .masonry__item {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  transform: translate3d(0, 50px, 0);
  -webkit-transform: translate3d(0, 50px, 0);
}
.masonry--animate .masonry__item:nth-child(1) {
  transition-delay: .1s;
}
.masonry--animate .masonry__item:nth-child(2) {
  transition-delay: .2s;
}
.masonry--animate .masonry__item:nth-child(3) {
  transition-delay: .3s;
}
.masonry--animate .masonry__item:nth-child(4) {
  transition-delay: .4s;
}
.masonry--animate .masonry__item:nth-child(5) {
  transition-delay: .5s;
}
.masonry--animate .masonry__item:nth-child(6) {
  transition-delay: .6s;
}
.masonry--animate .masonry__item:nth-child(7) {
  transition-delay: .7s;
}
.masonry--animate .masonry__item:nth-child(8) {
  transition-delay: .8s;
}
.masonry--animate .masonry__item:nth-child(9) {
  transition-delay: .9s;
}
.masonry--animate .masonry__item:nth-child(10) {
  transition-delay: 1s;
}
.masonry--animate .masonry__item:nth-child(11) {
  transition-delay: 1.1s;
}
.masonry--animate .masonry__item:nth-child(12) {
  transition-delay: 1.2s;
}
.masonry--animate .masonry__item:nth-child(13) {
  transition-delay: 1.3s;
}
.masonry--animate .masonry__item:nth-child(14) {
  transition-delay: 1.4s;
}
.masonry--animate .masonry__item:nth-child(15) {
  transition-delay: 1.5s;
}
.masonry--animate.masonry--active .masonry__item {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.masonry__filters {
  padding: 1.625em 0;
}
.masonry__filters .h6 {
  opacity: .3;
  position: relative;
  bottom: 2px;
  display: inline-block;
  margin: 0;
}
.masonry__filters .h6 + ul {
  margin-left: 1.625em;
}
.masonry__filters ul {
  display: inline-block;
  margin: 0;
}
.masonry__filters ul li {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  font-style: italic;
}
.masonry__filters ul li:not(:last-child) {
  margin-right: 0.8125em;
}
.masonry__filters ul li:not(:last-child):after {
  content: '/';
  margin-left: 0.8125em;
  opacity: 0.1;
}
.masonry__filters ul li:not(.active) {
  opacity: .5;
}
.masonry__filters ul li:not(.active):after {
  opacity: 0.2;
}
.masonry__filters ul li:hover {
  opacity: 1;
}
.masonry__filters ul li:hover:after {
  opacity: 0.1;
}
.masonry__filters.masonry__filters--outside {
  position: absolute;
  width: 100%;
  z-index: 5;
  top: -3.25em;
  background: none !important;
  padding: 0;
}
.masonry__filters.masonry__filters--outside ul li {
  color: #fff;
}
.masonry__filters.masonry__filters--outside ul li:after {
  opacity: .2;
}
@media all and (max-width: 767px) {
  .masonry__filters.masonry__filters--outside {
    position: relative;
    top: 0;
    padding: 1.625em 0;
  }
  .masonry__filters.masonry__filters--outside ul li {
    color: #767676;
  }
}
.row .masonry__filters {
  margin-bottom: 1.625em;
  padding-left: 15px;
  padding-right: 15px;
}
.row .masonry__item {
  margin-bottom: 22px;
}
.row .masonry__item .hover-element {
  margin: 0;
}
.row .masonry__item > a {
  display: block;
}
section:first-of-type.imagebg + .masonry-contained {
  padding-top: 1.625em;
}
.masonry-blog .masonry__item {
  margin-bottom: 30px;
}
.masonry-blog .masonry__item .card .card__body {
  margin-bottom: 0;
}
.masonry-blog .masonry__item .card:not(.card--horizontal) .card__image {
  position: relative;
  overflow: hidden;
}
.masonry-blog .masonry__item .card:not(.card--horizontal) .card__image img {
  transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
}
.masonry-blog .masonry__item .card:not(.card--horizontal):hover .card__image img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
}
.masonry-blog .masonry__item .card .video-cover iframe {
  min-height: 256px;
}
.masonry-videos .col-sm-4 .video-cover iframe {
  min-height: 20vw;
}
/*! -- Pillar Hover Elements & Portfolio -- */
.hover-element img {
  width: 100%;
}
.hover-element * {
  transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
}
.hover-element.bg--primary [data-overlay]:before {
  background: #425cbb;
}
.hover-element .modal-instance {
  position: relative;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  display: inline-block;
}
.hover-element .modal-instance:not(:first-child) {
  margin-left: 0.8125em;
}
.hover-element[data-title-position*="right"] {
  text-align: right;
}
.hover-element[data-title-position*=",center"] {
  text-align: center;
}
.hover-element[data-title-position*="center"] .hover-element__reveal .boxed {
  position: absolute;
  height: auto;
  top: 50%;
  width: 100%;
  transform: translate3d(10px, -50%, 0);
  -webkit-transform: translate3d(10px, -50%, 0);
}
.hover-element[data-title-position*="center"] .hover-element__reveal:hover .boxed {
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
}
.hover-element[data-title-position*="bottom"] .hover-element__reveal .boxed {
  position: absolute;
  height: auto;
  bottom: 0;
  width: 100%;
}
.hover-element .boxed {
  transform: translate3d(0, 10px, 0);
  -webkit-transform: translate3d(0, 10px, 0);
  opacity: 0;
}
.hover-element .boxed span {
  color: #fff;
}
.hover-element:hover .boxed,
.hover-element.hover--active .boxed {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  opacity: 1;
  z-index: 3;
}
.hover-element.hover--active img {
  transition: 0.6s ease;
  -webkit-transition: 0.6s ease;
  -moz-transition: 0.6s ease;
}
.hover-element.hover--active:hover img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
}
.hover-element .social-list li a i {
  color: #fff;
}
.hover-element-1 h5,
.hover-element-1 h6 {
  margin: 0;
}
.space--0 .hover-element {
  margin: 0;
}
.portfolio-item-1 {
  margin-bottom: 1.625em;
}
.portfolio-item-1 h5 {
  margin: 0;
}
.portfolio-item-1 span {
  opacity: .5;
}
.portfolio-item-1 img {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.portfolio-item-1:hover img {
  opacity: .7;
}
.portfolio-item-2 .portfolio-item__title {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  backface-visibility: hidden;
  position: absolute;
  z-index: 5;
  color: #fff;
  width: 100%;
  bottom: 1.625em;
  text-align: center;
}
.portfolio-item-2 .portfolio-item__title h5,
.portfolio-item-2 .portfolio-item__title h4 {
  margin: 0;
  color: #fff;
}
.portfolio-item-2.reveal-video .portfolio-item__title {
  opacity: 0;
  pointer-events: none;
}
.project-single-title h2 {
  margin: 0;
}
.project-single-title .modal-video-1 {
  margin-bottom: 1.625em;
}
.project-single-description {
  padding: 6.5em 0 3.25em 0;
}
@media all and (max-width: 767px) {
  .project-single-description {
    padding: 0;
  }
}
.project-single-stacked .project__summary {
  margin-bottom: 4.875em;
}
.project-single-stacked .testimonial {
  margin-top: 3.25em;
}
.project__action {
  margin-top: 3.25em;
}
.project__action hr {
  margin: 3.25em 0;
}
.project__action h5 {
  margin-bottom: 3.25em;
}
@media all and (max-width: 767px) {
  .project__action .btn {
    margin: 0;
  }
  .project__action .btn:not(:last-child) {
    margin-bottom: 0.8125em;
  }
}
.project-single-process span.h2 {
  position: absolute;
  opacity: .03;
  font-size: 10em;
  right: 0;
  top: 0;
}
.project-navigation {
  position: relative;
  overflow: hidden;
  padding: 0;
}
.project-navigation a {
  display: inline-block;
  padding: 3.25em 0;
  text-align: center;
  width: 33.333333333333333%;
  float: left;
  background: #f4f4f4;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.project-navigation a:hover {
  background: #ececec;
}
.project-navigation a:hover .larr {
  transform: translate3d(-6px, 0, 0);
  -webkit-transform: translate3d(-6px, 0, 0);
}
.project-navigation a:hover .rarr {
  transform: translate3d(6px, 0, 0);
  -webkit-transform: translate3d(6px, 0, 0);
}
.project-navigation a span {
  margin: 0;
  display: inline-block;
}
.project-navigation a span .larr,
.project-navigation a span .rarr {
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  position: relative;
  bottom: 2px;
  margin: 0 4px;
}
@media all and (max-width: 767px) {
  .project-navigation a {
    width: 100%;
    padding: 1.625em 0;
  }
}
/*! -- Pillar Blog -- */
.pagination-container {
  margin-top: 3.25em;
  text-align: center;
}
.pagination {
  display: inline-block;
  margin: 0 auto;
}
.pagination li {
  display: inline-block;
}
.pagination li a {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  display: inline-block;
  width: 3.54545454545454em;
  height: 3.54545454545454em;
  line-height: 0;
  padding-top: 4px;
  text-align: center;
  border-radius: 50% !important;
  background: none;
  border: none;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
}
.pagination li a:hover {
  background: #efefef;
  color: #232323;
}
.pagination li.active a {
  background: #ececec;
  color: #232323;
}
.pagination li.active a:hover {
  background: #e7e7e7;
  color: #232323;
}
.pagination li:first-child,
.pagination li:last-child {
  opacity: .3;
}
.sidebar .sidebar__widget:not(:last-child) {
  margin-bottom: 3.25em;
}
.tag-cloud li {
  display: inline-block;
}
.tag-cloud .btn--sm {
  padding: 0 0.8125em 1px 0.8125em;
}
.blog-item-1 h4 {
  margin-bottom: 0.56521739130435em;
}
.blog-item-1 .blog-item__author {
  margin-bottom: 1.625em;
}
.blog-item-1 img {
  margin-bottom: 1.625em;
}
.blog-item-1 p:last-of-type {
  margin: 0;
}
.blog-item-1:not(:last-child) hr {
  margin: 3.25em 0;
}
.blog-item-1:nth-last-child(2) hr {
  display: none;
}
@media all and (max-width: 767px) {
  .blog-item-1:not(:last-child) hr {
    margin: 1.625em 0;
  }
}
.blog-post {
  padding-top: 0;
}
.blog-post .blog-post__title {
  padding: 9.75em 0 9.75em 0;
}
.blog-post .blog-post__title:not(.imagebg) + .container {
  position: relative;
  z-index: 4;
  margin-top: -3.25em;
}
.blog-post .blog-post__title.imagebg {
  padding: 13em 0;
}
.blog-post .blog-post__title.imagebg span {
  color: #fff;
}
.blog-post .blog-post__title.imagebg + .container {
  padding-top: 6.5em;
}
.blog-post .blog-post__author {
  margin-top: 3.25em;
}
.blog-post .blog-post__author span:not(.h6) {
  font-size: 0.625em;
}
.blog-post .blog-post__hero {
  margin-bottom: 3.25em;
}
.blog-post blockquote {
  color: #232323;
  text-align: center;
}
.blog-post p + blockquote,
.blog-post img + blockquote {
  margin-top: 3.3913043478261em;
}
.blog-post blockquote + p,
.blog-post blockquote + h5,
.blog-post blockquote + img {
  margin-top: 4.875em;
}
.blog-post p + h5 {
  margin-top: 3.25em;
}
.blog-post hr:last-child {
  margin-top: 4.875em;
}
.blog-post .blog-post__share {
  margin-top: 1.625em;
}
.blog-post .blog-post__comments {
  margin-top: 3.25em;
}
.blog-post .blog-post__comments > ul {
  margin-top: 3.25em;
}
.blog-post .blog-post__comments ul {
  line-height: 1.625em !important;
}
.blog-post .blog-post__comments ul ul {
  padding-left: 20%;
}
.blog-post .blog-post__comments .comment {
  overflow: hidden;
}
.blog-post .blog-post__comments .comment .comment__image {
  display: inline-block;
  width: 20%;
  float: left;
}
.blog-post .blog-post__comments .comment .comment__image img {
  max-height: 4.875em;
}
.blog-post .blog-post__comments .comment .comment__text {
  width: 80%;
  float: left;
}
.blog-post .blog-post__comments .comment .comment__text h5 {
  margin: 0;
}
.blog-post .blog-post__comments .comment .comment__text h5 + span {
  opacity: .75;
  display: inline-block;
  margin-bottom: 0.8125em;
}
.blog-post .blog-post__comments .comment hr {
  margin: 2.4375em 0;
  display: block;
  width: 100%;
}
.blog-post .blog-post__comments .comment__form input {
  width: 100%;
  margin-bottom: 1.625em;
}
.blog-post .blog-post__comments .comment__form textarea {
  margin-bottom: 1.625em;
}
@media all and (max-width: 767px) {
  .blog-post .blog-post__share .btn {
    display: block;
    margin: 0 0 0.8125em 0;
  }
  .blog-post .blog-post__title {
    padding-top: 3.25em;
    padding-bottom: 6.5em;
  }
  .blog-post .blog-post__title.imagebg {
    padding: 4.875em 0;
  }
  .blog-post .blog-post__title.imagebg span {
    color: #fff;
  }
  .blog-post .blog-post__title.imagebg + .container {
    padding-top: 3.25em;
  }
  .blog-post .blog-post__author {
    margin-top: 1.625em;
  }
}
.blog-home {
  padding: 4.875em 0;
}
.blog-home .row:first-child h5 {
  margin: 0;
}
.blog-home .row:last-child .btn {
  margin-top: 1.625em;
}
.blog-home.bg--secondary + .blog-home.bg--secondary {
  padding-top: 0;
}
/*! -- Pillar Testimonials -- */
.testimonial-1 {
  margin-bottom: 30px;
}
.testimonial-1 img {
  max-height: 8.125em;
}
.testimonial-1 h5 {
  margin-bottom: 0;
}
.testimonial-1 h5 + span {
  font-style: italic;
  opacity: .8;
}
.testimonial-1 p {
  min-height: 4.875em;
}
.testimonial-1.boxed {
  margin-bottom: 30px;
}
.testimonial-2 .testimonial__quote {
  font-size: 6em;
  line-height: 1em;
  display: inline-block;
  margin-bottom: -0.54166667em;
}
.testimonial-2 img {
  max-height: 4.875em;
}
.testimonial-2 h5 {
  margin-bottom: 0;
}
.testimonial-2 h5 + span {
  font-style: italic;
  opacity: .8;
}
@media all and (max-width: 767px) {
  .testimonial-2 {
    margin-bottom: 1.625em;
  }
  .testimonial-2 .testimonial__quote {
    display: none;
  }
}
.testimonial-3 {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
}
.testimonial-3:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  bottom: -4.875em;
  background: #f4f4f4;
}
.testimonial-3 .testimonial__text {
  margin-top: 11.375em;
}
.testimonial-3 .testimonial__text h5 {
  margin: 0;
}
.testimonial-3 img {
  margin: 0;
}
@media all and (max-width: 1200px) {
  .testimonial-3:before {
    display: none;
  }
  .testimonial-3 .testimonial__text {
    margin-top: 3.25em;
  }
}
@media all and (max-width: 767px) {
  .testimonial-3 {
    text-align: center;
  }
  .testimonial-3 .testimonial__text {
    margin-top: 0;
    margin-bottom: 3.25em;
  }
}
.testimonial-4 h3 {
  margin-bottom: 1.625em;
}
@media all and (max-width: 767px) {
  .testimonial-4 {
    padding: 4.875em 0;
  }
}
.testimonial-5 img {
  max-height: 24.375em;
  margin: 0;
}
.testimonial-5 img + hr {
  margin-top: 0;
}
.testimonial-5 .slides {
  padding-bottom: 4.875em;
}
/*! -- Pillar Shop -- */
.cart-overview {
  width: 400px;
  height: 100vh;
  position: fixed;
  background: #fff;
  margin: 0;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  padding: 1.625em;
}
.cart-overview .cart-overview__subtotal {
  padding-top: 1.625em;
  border-top: 1px solid #f1f1f1;
}
.cart-overview .cart-overview__subtotal h5 {
  display: inline-block;
  margin: 0;
  margin-right: 1.625em;
}
.cart-overview .cart-overview__title {
  margin-bottom: 3.25em;
  border-bottom: 1px solid #f1f1f1;
}
.cart-overview .cart-overview__items {
  max-height: 70vh;
  overflow-x: auto;
  overflow-y: scroll;
  line-height: 1.625em !important;
}
.cart-overview .cart-overview__items li {
  overflow: hidden;
  position: relative;
}
.cart-overview .cart-overview__items li img {
  max-height: 4.875em;
  display: inline-block;
}
.cart-overview .cart-overview__items li .item__image {
  display: inline-block;
  margin-right: 1.625em;
}
.cart-overview .cart-overview__items li .item__detail {
  display: inline-block;
}
.cart-overview .cart-overview__items li .item__detail span {
  display: block;
}
.cart-overview .cart-overview__items li .item__remove {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: 0.2s lienar;
  -webkit-transition: 0.2s lienar;
  -moz-transition: 0.2s lienar;
  opacity: .5;
}
.cart-overview .cart-overview__items li .item__remove:before {
  content: '\2715';
}
.cart-overview .cart-overview__items li .item__remove:hover {
  opacity: 1;
}
.cart-overview .cart-overview__items li:not(:last-child) {
  margin-bottom: 1.625em;
}
.cart-overview .cart-overview__action {
  margin-top: 3.25em;
  text-align: center;
  width: 100%;
}
.cart-form .card {
  margin-bottom: 1.625em;
}
.cart-form .card .card__price {
  margin-bottom: 1.625em;
}
.cart-form .card input[type="text"] {
  max-width: 80px;
}
.order-charges {
  margin-top: 3.25em;
}
.order-charges ul {
  margin: 0;
}
.order-charges li span:last-child {
  float: right;
}
.order-charges li:last-child {
  margin-bottom: 0;
}
.shop-checkout .card {
  margin-bottom: 3.25em;
}
.shop-checkout hr {
  margin-bottom: 3.25em;
}
.shop-checkout .billing-title {
  padding: 0 15px;
  margin-bottom: 4.875em;
}
.shop-checkout .billing-title h5 {
  margin-bottom: 0.8125em;
}
.shop-checkout .billing-details {
  overflow: hidden;
}
.shop-checkout .billing-details input,
.shop-checkout .billing-details select {
  margin-bottom: 30px;
}
.shop-item-1 {
  overflow: hidden;
  background: #eee;
  padding: 1.625em;
  position: relative;
  z-index: 1;
}
.shop-item-1 .shop-item__price span.type--strikethrough {
  display: inline-block;
  margin-right: 1.625em;
}
.shop-item-1 .shop-item__price.hover--reveal {
  transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  transform: translate3d(0, -50px, 0);
  -webkit-transform: translate3d(0, -50px, 0);
  opacity: 0;
}
.shop-item-1 .shop-item__image {
  margin: 1.625em 0;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.shop-item-1 .shop-item__title h5 {
  margin-bottom: 0;
}
.shop-item-1:hover .shop-item__price.hover--reveal {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.shop-item-1:hover .shop-item__image {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
}
@media all and (max-width: 990px) {
  .shop-item-1 .shop-item__price.hover--reveal {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
.wide-grid.masonry-shop .masonry__filters {
  background: #eee;
}
.wide-grid.masonry-shop .masonry__container {
  background: #eee;
}
.wide-grid.masonry-shop .masonry__item .shop-item-1 {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.wide-grid.masonry-shop .masonry__item:hover .shop-item-1 {
  box-shadow: inset 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}
.masonry-contained .masonry-shop .masonry__item {
  margin-bottom: 0;
}
.masonry-contained .masonry-shop .masonry__item .boxed {
  margin-bottom: 30px;
}
.masonry-contained .masonry-shop .masonry__item .shop-item-1 {
  margin-bottom: 30px;
}
.masonry-contained .masonry-shop .masonry__item .card-8 {
  margin-bottom: 30px;
}
.masonry-contained.bg--dark {
  background: #202020;
}
.shop-item-detail.shop-item-detail-1 .item__addtocart {
  margin-bottom: 3.25em;
}
.shop-item-detail.shop-item-detail-1 .item__title {
  margin-top: 1.625em;
}
.shop-item-detail .item__price:not(:last-child) {
  margin-bottom: 3.25em;
}
.shop-item-detail .item__price span {
  display: inline-block;
  font-size: 1.4375em;
}
.shop-item-detail .item__price span.type--strikethrough {
  margin-right: 1.625em;
}
.shop-item-detail .item__addtocart {
  max-width: 80%;
}
.shop-item-detail .item__addtocart input[type="text"] {
  border-radius: 4px 4px 0 0;
  min-width: 100%;
  text-align: center;
}
.shop-item-detail .item__addtocart button {
  border-radius: 0 0 4px 4px;
  min-width: 100%;
}
.shop-item-detail .item__description:not(:last-child) {
  margin-bottom: 3.25em;
}
.shop-item-detail .item__description .item__subinfo:not(:last-child) {
  margin-bottom: 0.8125em;
}
.shop-item-detail .item__description .item__subinfo span {
  display: inline-block;
}
.shop-item-detail .item__description .item__subinfo span:first-child {
  width: 25%;
  color: #232323;
}
.shop-item-detail .item__reviews {
  line-height: 1.625em !important;
}
.shop-item-detail .item__reviews li {
  position: relative;
  overflow: hidden;
}
.shop-item-detail .item__reviews li img {
  float: left;
  max-height: 4.875em;
  margin-right: 1.625em;
}
.shop-item-detail .item__reviews li .review__text {
  width: 80%;
  float: right;
}
.shop-item-detail .item__reviews li .review__text h5 {
  margin: 0;
}
.shop-item-detail .item__reviews li .review__text span.type--fine-print {
  font-style: italic;
  display: inline-block;
  margin-bottom: 1.3em;
}
.shop-item-detail .item__reviews li .review__score {
  position: absolute;
  right: 0;
  top: 0;
}
.shop-item-detail .item__reviews li .review__score .star {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #ebab21;
  display: inline-block;
}
.shop-item-detail .item__reviews li .review__score .star:not(:last-child) {
  margin-right: 0.40625em;
}
.shop-item-detail .item__reviews li .review__score .star.empty {
  background: #e0e0e0;
}
.shop-item-detail .item__reviews li:not(:last-child) {
  margin-bottom: 1.625em;
}
.imagebg:not(.image--light) .shop-item-detail .item__price {
  color: #fff;
  opacity: .9;
}
@media all and (max-width: 767px) {
  .shop-item-detail .item__addtocart input {
    margin: 0;
  }
  .shop-item-detail .item__reviews li img {
    display: none;
  }
  .shop-item-detail .item__reviews li .review__text {
    width: 100%;
  }
}
.shop-item-detail-2 form {
  display: inline-block;
  margin-bottom: 3.25em;
}
.shop-item-detail-2 .item__subinfo:not(:last-child) {
  border-bottom: 1px solid #eee;
  padding-bottom: 0.8125em;
}
.related-products h4 {
  margin-bottom: 2.2608695652174em;
}
@media all and (max-width: 767px) {
  .related-products h4 {
    margin-bottom: 1.1304347826087em;
  }
  .related-products .shop-item {
    margin-bottom: 15px;
  }
}
.signature {
  max-height: 6.5em;
  margin-top: 1.625em;
}
.shop-home .masonry__container {
  margin-bottom: 1.625em;
}
/*! -- Pillar Modals -- */
.search-modal .modal-content {
  background: none;
}
/*! -- Pillar Notifications -- */
.notification {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  margin: 0;
}
.notification .notification-close-cross {
  width: 2.4375em;
  height: 2.4375em;
  background: #232323;
  text-align: center;
  border-radius: 50%;
}
.notification .notification-close-cross:before {
  font-size: 1em;
  color: #fff;
  line-height: 2.4375em;
}
.notification .boxed {
  margin-bottom: 0;
}
.cookies-notice .notification-close {
  margin-left: 0.8125em;
}
.growl-notice {
  margin: 1.625em;
  background: none !important;
}
.growl-notice .boxed {
  border-radius: 0.8125em;
}
@media all and (max-width: 767px) {
  .growl-notice {
    margin: 0;
  }
}
@media all and (max-width: 990px) {
  .cookies-notice .notification-close {
    margin-left: 0;
    margin-top: 0.8125em;
  }
}
.promo-notification h4,
.promo-notification p {
  margin: 0;
  display: inline-block;
  margin-right: 1.625em;
}
@media all and (max-width: 767px) {
  .promo-notification h4,
  .promo-notification p {
    margin-right: 0;
    margin-bottom: 0.8125em;
  }
}
/*! -- Pillar Footers -- */
footer .logo {
  max-height: 26px;
}
.footer-1 {
  padding-bottom: 0;
}
.footer-1 .footer__navigation {
  width: 50%;
  display: inline-block;
  float: left;
}
.footer-1 .logo {
  margin-bottom: 1.625em;
}
.footer-1 div[class*='col-'] {
  overflow: hidden;
}
.footer-1 .footer__lower {
  margin-top: 1.625em;
  padding: 1.625em 0;
}
.footer-1 .footer__lower .type--fine-print {
  opacity: .4;
}
.footer-1 .footer__lower hr {
  opacity: .05;
}
.footer-1 .footer__navigation li {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  line-height: 3.2em;
  margin: 0;
}
.footer-1 .footer__navigation li a {
  opacity: .5;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.footer-1 .footer__navigation li a:hover {
  opacity: 1;
}
@media all and (max-width: 767px) {
  .footer-1 .footer__lower {
    margin-top: 0;
  }
  .footer-1 .footer__lower .social-list {
    display: inline-block;
    margin-top: 0.8125em;
  }
}
.footer-2 .footer__navigation li {
  display: inline-block;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  font-size: 1em;
  line-height: 1.625em;
  margin-top: 0;
  margin-bottom: 1.625em;
  margin-bottom: 0;
}
.footer-2 .footer__navigation li:not(:last-child) {
  margin-right: 1.625em;
}
.footer-2 .footer__lower {
  margin-top: 3.25em;
}
@media all and (max-width: 767px) {
  .footer-2 .footer__lower {
    margin-top: 1.625em;
  }
  .footer-2 .social-list {
    margin-bottom: 1.625em;
  }
}
.footer-3 {
  padding: 4.875em 0;
  overflow: hidden;
}
.footer-3 div[class*='col-']:first-child {
  padding-left: 3.25em;
}
.footer-3 div[class*='col-']:last-child {
  padding-right: 3.25em;
}
.footer-3 .logo {
  max-height: 2.4375em;
  display: block;
  margin-bottom: 0.8125em;
}
.footer-3 a.type--underline {
  display: block;
}
.footer-3 .footer__navigation li {
  display: inline-block;
}
.footer-3 .footer__navigation li:not(:last-child) {
  margin-right: 3.25em;
}
.footer-3 .footer__navigation li a {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
}
.footer-3 .social-list {
  margin-bottom: 1.625em;
}
.footer-3 .type--fine-print {
  opacity: .5;
}
@media all and (max-width: 990px) {
  .footer-3 div[class*='col-']:first-child {
    padding-left: 1.625em;
  }
  .footer-3 div[class*='col-']:last-child {
    padding-right: 1.625em;
  }
}
@media all and (max-width: 767px) {
  .footer-3 div[class*='col-']:first-child {
    padding-left: 15px;
  }
  .footer-3 div[class*='col-']:last-child {
    padding-right: 15px;
  }
  .footer-3 .logo {
    margin: 0 auto;
    margin-bottom: 1.625em;
  }
  .footer-3 .footer__navigation {
    margin-top: 1.625em;
  }
}
.footer-4 {
  padding-bottom: 0;
}
.footer-4 .logo {
  max-height: 2.03125em;
  margin-bottom: 1.21875em;
}
.footer-4 p {
  opacity: .9;
}
.footer-4 .footer__navigation li {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  line-height: 3.2em;
  margin: 0;
}
.footer-4 .footer__navigation li a {
  opacity: .5;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.footer-4 .footer__navigation li a:hover {
  opacity: 1;
}
.footer-4 form {
  margin-bottom: 3.25em;
}
.footer-4 .footer__lower {
  position: relative;
  margin-top: 4.875em;
  background: #1e1e1e;
  padding: 1.625em 0;
}
.footer-4 .footer__lower .type--fine-print {
  opacity: .5;
}
.footer-4 .timePosted {
  display: none;
}
.top-link {
  width: 3.25em;
  height: 3.25em;
  border-radius: 50%;
  background: #3d3d3d;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 4;
  bottom: 3.25em;
  padding-top: 12px;
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
}
.top-link:hover {
  transform: translate3d(0, -5px, 0);
  -webkit-transform: translate3d(0, -5px, 0);
}
@media all and (max-width: 990px) {
  .top-link {
    bottom: 0;
  }
}
@media all and (max-width: 767px) {
  .top-link {
    margin-top: 1.625em;
  }
}
/*! -- Pillar Sections General -- */
section:not(.testimonial-3):not(.map-1):not(.imagebg):not(.imageblock):not(.bg--dark):not(.bg--secondary):not(.bg--primary):not(.bg--white):not(.features-5):not(.features-10):not(.wide-grid):not(.slider) + section:not(.testimonial-3):not(.map-1):not(.imagebg):not(.imageblock):not(.bg--dark):not(.bg--secondary):not(.bg--primary):not(.bg--white):not(.features-5):not(.features-10):not(.wide-grid):not(.slider) {
  padding-top: 0;
}
.wide-grid {
  max-width: 100%;
  padding: 0;
}
.wide-grid > div[class*='col-'] {
  padding: 0;
}
.wide-grid .hover-element {
  margin: 0;
}
.section--overlap:not(:first-child) {
  padding-top: 0;
  position: relative;
  z-index: 2;
  margin-top: -6.5em;
}
.section--overlap:not(:first-child) + .section--overlap {
  margin-top: -13em;
  z-index: 1;
}
.section--overlap:not(:first-child) + .section--overlap .pos-vertical-center {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
@media all and (max-width: 1024px) {
  .section--overlap {
    padding-top: 6.5em;
    margin-top: 0;
  }
}
@media all and (max-width: 767px) {
  .section--overlap {
    padding-top: 4.875em;
    margin-top: 0;
  }
}
section + img {
  margin-bottom: 0;
}
/*! -- Pillar Sections Specific  -- */
.process-1 {
  overflow: hidden;
  position: relative;
}
.process-1 .piechart {
  margin: 0 auto;
  margin-bottom: 6.5em;
}
.process-1:before {
  content: '';
  width: 2px;
  height: 40000px;
  position: absolute;
  top: -200px;
  border-right: 1px dashed #425cbb;
  opacity: .3;
  left: 50%;
  margin-left: -1px;
  z-index: 0;
}
.process-1.process-end:before {
  top: 0;
  height: 440px;
}
@media all and (max-width: 767px) {
  .process-1:before {
    display: none;
  }
}
.about-1 {
  padding-top: 4.875em;
}
.about-1 h4 {
  margin-bottom: 2.2608695652174em;
}
.about-1 p.lead + .feature {
  margin-top: 3.25em;
}
@media all and (max-width: 767px) {
  .about-1 {
    padding-top: 0;
  }
}
.about-2 {
  padding: 4.875em 0 3.25em 0;
}
@media all and (max-width: 767px) {
  .about-2 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.stats-1 h4:first-child {
  margin-bottom: 2.2608695652174em;
}
.stats-1 .feature-1 {
  max-height: 282px;
  margin-bottom: 30px;
}
.stats-1 .feature-1 h3 {
  margin: 0.40625em 0;
}
.service-1 hr {
  margin: 3.25em 0;
}
.blog-snippet-1 .row:last-child .btn:last-child {
  margin-top: 3.25em;
}
.partners-1 .slider {
  text-align: center;
}
.partners-1 .slider img {
  max-height: 1.625em;
  opacity: .3;
  margin: 0;
}
.map-1 {
  overflow: hidden;
  padding: 0;
}
.map-1 .map-container {
  position: relative;
  height: 450px;
  width: 100%;
}
.map-1 .map-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
@media all and (max-width: 767px) {
  .map-1 .map-container {
    height: 300px;
  }
}
/*! -- Pillar Utility Pages -- */
.page-error i {
  margin-bottom: 0.40625em;
  display: inline-block;
}
/*! -- Element Pages -- */
section[class*='elements-'] .row:not(:last-child) {
  margin-bottom: 6.5em;
}
section[class*='elements-'] .row + hr {
  margin: 0;
  border-color: #eee;
}
section[class*='elements-'] hr + .row {
  padding-top: 6.5em;
}
@media all and (max-width: 767px) {
  section[class*='elements-'] .row:not(:last-child) {
    margin-bottom: 3.25em;
  }
  section[class*='elements-'] hr + .row {
    padding-top: 3.25em;
  }
}
.elements--title {
  margin-bottom: 3.25em;
}
.elements-buttons .btn {
  margin: 0;
  margin-bottom: 26px;
}
.elements-tabs {
  padding-bottom: 0;
}
.elements-tabs h4 + .tabs-container {
  margin-top: 3.25em;
}
.elements-tabs .tabs-container:not(:last-child) {
  margin-bottom: 6.5em;
}
.elements-tabs + .tabs-container {
  margin-top: 3.25em;
}
@media all and (max-width: 990px) {
  .elements-pricing .pricing {
    margin-bottom: 1.625em;
  }
}
.elements-radials .row:not(:last-child) {
  margin-bottom: 6.5em;
}
.elements-radials h4 {
  margin: 0;
}
.elements-radials .piechart {
  margin-bottom: 1.625em;
}
@media all and (max-width: 767px) {
  .elements-video .video-cover {
    margin-bottom: 1.625em;
  }
}
.elements-forms form input {
  margin-bottom: 1.625em;
}
.elements-forms form > .row {
  margin-bottom: 0 !important;
}
.elements-forms form .input-checkbox {
  margin-right: 0.8125em;
}
.elements-forms form .input-checkbox + span {
  position: relative;
  bottom: 6px;
}
.elements-modals .modal-instance:not(:last-child) {
  margin-right: 0.8125em;
}
@media all and (max-width: 767px) {
  .elements-modals .modal-instance:not(:last-child) {
    margin-bottom: 0.8125em;
  }
}
